import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

@Component({
  selector: 'ninety-error-overlay',
  standalone: true,
  imports: [CommonModule, TerraIconModule],
  template: ` <terra-icon icon="info-circle" size="24" />`,
  styles: [
    `
      :host {
        height: 24px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorOverlayComponent {
  @Output() ignoreError = new EventEmitter<boolean>();
}
