import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, switchMap, tap } from 'rxjs';

import { SignupStateActions } from '@ninety/login/_state/sign-up/signup-state.actions';
import { ReferralService } from '@ninety/ui/legacy/core/services/referral.service';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';

import { ReferralActions } from './referral.actions';

@Injectable()
export class ReferralEffects {
  checkUserReferralStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupStateActions.checkEmail),
      switchMap(() => {
        return this.referralService.getReferralCode().then(referralCode => {
          if (!referralCode) {
            return ReferralActions.userHasNoReferral();
          }
          return ReferralActions.userHasReferral({ referralCode: referralCode });
        });
      })
    )
  );

  userHasReferral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralActions.userHasReferral),
      map(({ referralCode }) => {
        return SignupStateActions.setReferralCode({ referralCode: referralCode });
      })
    )
  );

  getReferralJwt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralActions.getReferralJWT),
      exhaustMap(() =>
        this.referralService.getReferralJwt().pipe(
          map(response => {
            if (!response) {
              return ReferralActions.getReferralJWTFailure({
                error: null,
                customMessage: 'Unable to retrieve referral JWT token.',
              });
            }
            return ReferralActions.getReferralJWTSuccess({ jwt: response });
          })
        )
      )
    )
  );

  getReferralJwtSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReferralActions.getReferralJWTSuccess),
        tap(({ jwt }) => {
          window.impactToken = jwt['body'];
        })
      ),
    { dispatch: false }
  );

  actionStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralActions.getReferralJWT),
      map(() => SpinnerActions.startPrimary({ source: 'Referral' }))
    )
  );

  actionEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralActions.getReferralJWTSuccess, ReferralActions.getReferralJWTFailure),
      map(() => SpinnerActions.stopPrimary({ source: 'Referral' }))
    )
  );

  constructor(private actions$: Actions, private referralService: ReferralService) {}
}
