import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { OptionListDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/option-list.directive';
import { OptionDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/option.directive';
import { SelectLauncherDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/select-launcher.directive';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { selectRoleSelectData } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';

@Component({
  selector: 'ninety-inline-role-select',
  standalone: true,
  imports: [
    CommonModule,
    SelectLauncherDirective,
    TerraIconModule,
    OptionListDirective,
    OptionDirective,
    TerraDividerModule,
    LetDirective,
    NinetyTooltipDirective,
    PushPipe,
  ],
  templateUrl: './inline-role-select.component.html',
  styleUrls: ['./inline-role-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineRoleSelectComponent),
      multi: true,
    },
  ],
})
export class InlineRoleSelectComponent implements ControlValueAccessor {
  @Input() selectedRole: RoleSelectOption;
  @Input() disabled: boolean;

  @Output() updateRole = new EventEmitter<RoleSelectOption>();

  protected readonly roles$ = this.store.select(selectRoleSelectData);
  @ViewChild(SelectLauncherDirective) protected selectLauncher: SelectLauncherDirective;

  constructor(private store: Store) {}

  selectRole(role: RoleSelectOption): void {
    if (role.isItemDisabled || role.cannotAssign) return;
    this.updateRole.emit(role);
    this.selectLauncher.isOpen = false;
  }

  /** ControlValueAccessor implementation */
  onChange: (value: RoleSelectOption) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: RoleSelectOption): void {
    if (!value) return;
    this.selectedRole = value;
    this.selectRole(value);
    this.onChange(value);
  }

  registerOnChange(fn: (value: RoleSelectOption) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
