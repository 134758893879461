import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import ObjectId from 'bson-objectid';
import { cloneDeep as _cloneDeep, isEqual as _isEqual, pick as _pick } from 'lodash';
import { BehaviorSubject, Subscription, debounceTime, filter, map, tap } from 'rxjs';

import { TerraIconName } from '@ninety/terra';

import { FilterService } from '../../../_core/services/filter.service';
import { StateService } from '../../../_core/services/state.service';
import { CurrentUserSelectors, FeatureFlagFacade } from '../../../_state';
import { FeatureFlagKeys } from '../../../_state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../../../_state/app-entities/feature-flag/feature-flag-state.selectors';
import { NotificationActions } from '../../../_state/app-global/notifications/notification.actions';
import {
  CompanyMeetingAgendaPush,
  CompanyMeetingAgendaUpdateByType,
  CompanyMeetingAgendas,
  DeleteTeamCustomAgenda,
  MeetingAgendas,
  Team,
  TeamMeetingAgendaPush,
  TeamMeetingAgendaUpdateByType,
} from '../../models/_shared/team';
import { getCustomMeetingDefaultSections } from '../../models/_shared/team-meeting-agendas';
import { BusinessOperatingSystem } from '../../models/company/business-operating-system.enum';
import { MeetingLanguage } from '../../models/language/custom-language';
import { MeetingAgenda } from '../../models/meetings/meeting-agenda';
import { MeetingAgendaType } from '../../models/meetings/meeting-agenda-type.enum';
import { MeetingRoutes } from '../../models/meetings/meeting-routes';
import { MeetingSection } from '../../models/meetings/meeting-section';
import { ReservedSectionNames } from '../../models/meetings/reserved-section-names';
import { Template } from '../../models/partner-hub/template';
import { FilterOutUsedDefaultSectionsPipe } from '../../pipes/filter-out-used-default-sections.pipe';
import { ConfirmDialogData } from '../_mdc-migration/confirm-dialog/models/confirm-dialog-data';
import { WarningConfirmDialogComponent } from '../_mdc-migration/confirm-dialog/warning-confirm-dialog.component';

interface MeetingForm {
  agenda: FormArray<FormControl<MeetingSection>>;
  name: FormControl<string>;
  _id?;
  isCustom?;
}

@Component({
  selector: 'ninety-meeting-agendas',
  templateUrl: './meeting-agendas.component.html',
  styleUrls: ['./meeting-agendas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingAgendasComponent implements OnInit, OnChanges, OnDestroy {
  private _agendas: CompanyMeetingAgendas;
  private _template: Template;

  loggedInUserTeams$ = this.store.select(CurrentUserSelectors.selectTeams);

  get agendas() {
    return this._agendas;
  }
  @Input() set agendas(agendas: CompanyMeetingAgendas) {
    if (!agendas || !Object.keys(agendas).length) return;
    this._agendas = agendas;
    this.customAgendasCopy = _cloneDeep(agendas?.custom);
    this.makeSureConcludeIsLast();
    this.initForm();
    setTimeout(() => this.somethingChanged$.next(false));
  }

  get template() {
    return this._template;
  }
  @Input() set template(config: Template) {
    this._template = config;
    this.meetingLang = config.labels.meeting;
    this.agendas = _cloneDeep(config.meetingAgendas);
    this.setAvailableSections();
  }

  @Input() selectedTeam: Team;
  @Input() resetAgendas: MeetingAgendas;
  @Input() readonly: boolean;
  @Input() subtitle?: string;
  @Input() hidePushButton = false;

  //Agendas V2 Inputs
  @Input() agendaV2SelectedTypeOrIdOrNewCustom: MeetingAgendaType | string; //string for custom or new-custom-agenda, see MeetingRoutes
  @Input() agendaV2 = false;
  //Agendas V2 Outputs
  @Output() validForm = new EventEmitter<boolean>();
  @Output() newChanges = new EventEmitter<boolean>();

  // todo - make the saving of company and config agendas to patch instead of needing all agendas
  @Output() saveTeamAgendaByType = new EventEmitter<TeamMeetingAgendaUpdateByType>();
  @Output() saveAgendasForTemplate = new EventEmitter<CompanyMeetingAgendas>();
  @Output() saveCompanyAgendaByType = new EventEmitter<CompanyMeetingAgendaUpdateByType>();

  @Output() deleteTeamCustomAgenda = new EventEmitter<DeleteTeamCustomAgenda>();
  @Output() deleteCustomAgenda = new EventEmitter<string>();

  @Output() pushTeamAgenda = new EventEmitter<TeamMeetingAgendaPush>();
  @Output() pushCompanyAgenda = new EventEmitter<CompanyMeetingAgendaPush>();

  @Output() getResetAgendas = new EventEmitter<void>();
  @Output() getTeamWithSettings = new EventEmitter<Team>();

  selectedMeetingType$ = new BehaviorSubject<MeetingAgendaType>(MeetingAgendaType.weekly);
  selectedAgendaId: MeetingAgendaType | string = MeetingAgendaType.weekly;
  somethingChanged$ = new BehaviorSubject<boolean>(false);
  agendaCopy: MeetingAgenda;
  customAgendasCopy: MeetingAgenda[];
  MeetingAgendaType = MeetingAgendaType;
  meetingSectionsForm!: FormGroup<MeetingForm>;
  meetingName$ = new BehaviorSubject<string>(null);
  totalDuration: number;
  selectedIndex: number;
  selectedSection$ = new BehaviorSubject<MeetingSection>(null);
  iframeVisible = false;
  meetingLang: MeetingLanguage = this.stateService.language.meeting;
  iframeWarning = false;
  iframeWidth = 400;
  iframeHeight = 300;
  customAgendaIndex: number;
  availableDefaultSections: Array<MeetingSection & { terraIcon?: TerraIconName }>;
  cancelDisabled = false;
  isSmallScreen = (window.innerWidth || document.body.clientWidth) < 600;
  newAgenda: boolean;
  isMasteryDisabled = false;
  masteryDisabledTooltip: string;

  subscriptions = new Subscription();

  // TODO this belongs in a selector and should source BOS from NGRX
  showLongTermIssuesAsSeparateTab$ = this.store
    .select(selectFeatureFlag(FeatureFlagKeys.coreNinetyDefaultVTO))
    .pipe(map(flagIsTrue => flagIsTrue && this.stateService.company.bos === BusinessOperatingSystem.ninetyOS));

  showLongTermIssuesAsSeparateTab = false;

  private readonly meetingsV2$ = this.featureFlags.getFlag(FeatureFlagKeys.meetingsV2);
  meetingsV2 = false;

  @ViewChildren('nameInput') nameInputs: QueryList<ElementRef>;
  @ViewChild('iframeDiv') iframeDiv: ElementRef;
  @ViewChild('agendaSelect') agendaSelect: MatSelect;

  constructor(
    public stateService: StateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private filterService: FilterService,
    private filterOutUsedDefaultSectionsPipe: FilterOutUsedDefaultSectionsPipe,
    private dialog: MatDialog,
    private store: Store,
    private featureFlags: FeatureFlagFacade
  ) {
    this.filterService.setOptions({ filtersToolbar: false });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.showLongTermIssuesAsSeparateTab$.subscribe(flag => {
        this.showLongTermIssuesAsSeparateTab = flag;
        this.setAvailableSections();
      })
    );
    this.subscriptions.add(
      this.meetingsV2$.subscribe(meetingsV2 => {
        this.meetingsV2 = meetingsV2;
      })
    );
    this.subscriptions.add(
      this.meetingSectionsForm?.statusChanges.subscribe(status => {
        this.validForm.emit(status === 'VALID');
      })
    );

    this.subscriptions.add(
      this.somethingChanged$.subscribe(changed => {
        this.newChanges.emit(changed);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTeam?.currentValue) {
      this.setTeamAgendas(this.selectedTeam, true);
    }

    if (changes.resetAgendas?.currentValue && !changes.resetAgendas?.firstChange) {
      const type = this.selectedMeetingType$.getValue();
      if (type === MeetingAgendaType.custom) return;

      this.agendas[type].agenda = _cloneDeep(this.resetAgendas[type].agenda);
      this.buildForm(false);
      setTimeout(() => this.somethingChanged$.next(true));
      this.closeDetails();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initForm(): void {
    if (!this.agendas) return;

    if (this.template) {
      //on partner hub templates
      this.isMasteryDisabled = !this.template.mastery?.enabled;
      this.masteryDisabledTooltip = 'Mastery is currently turned off for this configuration';
    } else {
      //company/meeting template
      this.isMasteryDisabled = !this.stateService.company.settings.mastery.enabled;
      this.masteryDisabledTooltip = `${this.stateService.language.mastery.route} is currently turned off for the company`;
    }

    this.selectedMeetingType$.next(this.selectedMeetingType$.getValue());
    if (!this.selectedAgendaId) this.selectedAgendaId = MeetingAgendaType.weekly;
    this.setMeetingName();

    if (this.selectedAgendaId === MeetingRoutes.newCustomAgenda) {
      this.newCustomAgenda();
    } else {
      this.buildForm();
    }

    this.selectedSection$.next(null);
  }

  checkForChanges(): void {
    const agenda = this.meetingSectionsForm.value;
    setTimeout(() => this.somethingChanged$.next(!_isEqual(agenda, this.agendaCopy)));
    // we've inverted the isHidden at this point...we should do a db script to invert this to isVisible so we don't
    // have to invert for the "visible toggle" to work
    this.totalDuration = agenda.agenda.filter(a => !!a.isHidden).reduce((a, b) => a + b.duration, 0);
  }

  addSection(s?: MeetingSection): void {
    const sections = this.meetingSectionsForm.get('agenda') as FormArray;
    const i = sections.length - 1;
    const sectionToAdd = new MeetingSection(i, s);
    const section: FormGroup = this.createSectionFormGroup(sectionToAdd);
    sections.insert(i, section as FormGroup);
    if (!s && !this.isSmallScreen) this.selectSection(section.value, i);
    this.setAvailableSections();
    setTimeout(() => this.nameInputs.toArray()[i].nativeElement.focus());
  }

  removeSection(index: number) {
    const sections = this.meetingSectionsForm.get('agenda') as FormArray;
    sections.removeAt(index);
    this.setAvailableSections();
  }

  buildForm(setCopy = true, agenda?: MeetingAgenda) {
    const type = this.selectedMeetingType$.getValue();
    if (!agenda && this.selectedAgendaId) {
      if (type === MeetingAgendaType.custom) {
        agenda = this.agendas.custom.find(a => a._id === this.selectedAgendaId);
      } else agenda = this.agendas[type];
    }
    this.meetingSectionsForm = new FormGroup<MeetingForm>({
      agenda: this.buildSectionFormArray(agenda),
      name: new FormControl<string>(agenda?.name ?? '', [Validators.required]),
    });
    if (type === MeetingAgendaType.custom) {
      this.meetingSectionsForm.addControl('_id', new FormControl(agenda._id));
      this.meetingSectionsForm.addControl('isCustom', new FormControl(agenda.isCustom));
      if (this.selectedAgendaId !== agenda?._id) this.selectedAgendaId = agenda._id;
      this.meetingSectionsForm.get('name').valueChanges.subscribe({
        next: name => {
          this.agendas.custom[this.customAgendaIndex].name = name;
          setTimeout(() => this.somethingChanged$.next(true));
        },
      });
    } else {
      this.meetingSectionsForm.controls['name'].clearValidators();
      const type = this.selectedMeetingType$.getValue();
      if (this.selectedAgendaId !== type) this.selectedAgendaId = type;
    }
    this.meetingSectionsForm.valueChanges.pipe(debounceTime(600)).subscribe({
      next: () => this.checkForChanges(),
    });
    if (setCopy) this.agendaCopy = _cloneDeep(this.meetingSectionsForm.value as MeetingAgenda);
    if (this.readonly) this.meetingSectionsForm.disable();
    this.checkForChanges();
    this.setAvailableSections();
    this.cancelDisabled = false;
  }

  buildSectionFormArray(agenda?: MeetingAgenda): FormArray<FormControl<MeetingSection>> {
    const type = this.selectedMeetingType$.getValue();
    if (!agenda) agenda = this.agendas[type] as MeetingAgenda;
    if (!agenda?.agenda) {
      this.selectedMeetingType$.next(MeetingAgendaType.weekly);
      this.initForm();
      return;
    }
    return agenda.agenda.reduce((form: FormArray, section: MeetingSection) => {
      form.push(this.createSectionFormGroup(section));
      return form;
    }, this.formBuilder.array([]));
  }

  setAvailableSections(): void {
    //on partner hub use bos config language
    const language = this.template ? this.template.labels : this.stateService.language;

    this.availableDefaultSections = this.filterOutUsedDefaultSectionsPipe
      .transform(
        getCustomMeetingDefaultSections(language),
        this.meetingSectionsForm?.value?.agenda ?? [],
        this.isMasteryDisabled
      )
      .filter(
        s =>
          this.selectedMeetingType$.getValue() === MeetingAgendaType.custom ||
          //TODO: remove  s.path !== 'eos-tools' && s.path !== 'ninety-tools'
          // when all eos-tools and ninety-tools are removed from custom agenda
          (this.stateService.isManagerOrAbove &&
            s.path !== 'eos-tools' &&
            s.path !== 'ninety-tools' &&
            s.path !== 'mastery') ||
          (!this.showLongTermIssuesAsSeparateTab && s.path !== 'vision#long-term')
      );
  }

  createSectionFormGroup(section: MeetingSection): FormGroup {
    return this.formBuilder.group({
      name: new FormControl<string>(section.name, [
        Validators.required,
        this.reservedNameValidator(!!section.isCustom, this.selectedMeetingType$.getValue()),
      ]),
      duration: new FormControl<number>(section.duration / 60, [Validators.min(1), Validators.max(480)]),
      isHidden: new FormControl<boolean>({ value: !section.isHidden, disabled: this.readonly }),
      isCustom: new FormControl<boolean>(!!section.isCustom),
      isDefault: new FormControl<boolean>(!!section.isDefault),
      iframeUrl: new FormControl<string>(section.iframeUrl),
      details: new FormControl<string>(section.details),
      ordinal: new FormControl<number>(section.ordinal),
      path: new FormControl<string>(section ? section.path : ''),
      isEditable: new FormControl<boolean>(section?.isEditable ?? false),
      subtitle: new FormControl<string>(section?.subtitle ?? ''),
    });
  }

  save(isNewAgenda = false): void {
    if (this.meetingSectionsForm?.invalid) {
      this.store.dispatch(
        NotificationActions.showError({
          message: `Form is invalid`,
          title: 'Invalid Form',
        })
      );
      return;
    }
    const type: MeetingAgendaType = this.selectedMeetingType$.getValue();
    if (type === MeetingAgendaType.custom) {
      const { agenda, ...form } = this.meetingSectionsForm.value;
      this.customAgendaIndex = this.agendas.custom?.findIndex(a => a._id === form._id) ?? 0;
      this.agendas.custom[this.customAgendaIndex] = {
        ...form,
        agenda: this.fixSectionsForDb(_cloneDeep(agenda)),
      };
      this.customAgendasCopy = _cloneDeep(this.agendas.custom);
      this.newAgenda = false;
    } else {
      this.agendas[type].agenda = this.fixSectionsForDb(_cloneDeep(this.meetingSectionsForm.value.agenda));
      this.agendaCopy = _cloneDeep(this.agendas[type]);
    }

    //todo update saving the agendas on the config so that it can patch individual agendas
    if (this.selectedTeam) {
      this.saveTeamAgendaByType.emit({
        teamId: this.selectedTeam?._id,
        newAgenda: isNewAgenda,
        agendaType: type,
        teamAgenda: this.agendas[type],
      });
    } else {
      this.saveAgendasForTemplate.emit(this.agendas);
      this.saveCompanyAgendaByType.emit({ agendaType: type, teamAgenda: this.agendas[type] });
    }
    setTimeout(() => this.somethingChanged$.next(false));
    this.closeDetails();
  }

  // todo - normalize the data so that durations are in minutes instead of seconds
  //  and isHidden becomes visible
  fixSectionsForDb(sections: MeetingSection[]): MeetingSection[] {
    return sections.map((s, i) => ({
      ...s,
      duration: (s.duration *= 60),
      isHidden: !s.isHidden,
      ordinal: i,
      path: s.isCustom ? s.name.replace(/\W+/g, '-').toLowerCase() : s.path,
    }));
  }

  selectMeetingType(type: MeetingAgendaType): void {
    if (!type || !Object.values(MeetingAgendaType).includes(type)) return;
    this.selectedMeetingType$.next(type);
    this.setMeetingName();
    this.buildForm();
    this.closeDetails();
  }

  selectCustomAgenda(agenda: MeetingAgenda, i: number): void {
    this.customAgendaIndex = i;
    this.selectedMeetingType$.next(MeetingAgendaType.custom);
    this.newAgenda = false;
    this.buildForm(true, agenda);
    this.closeDetails();
  }

  newCustomAgenda(): void {
    const customAgenda = new MeetingAgenda();
    this.customAgendaIndex = this.agendas.custom.length;
    this.agendas.custom.push(customAgenda);
    this.selectedAgendaId = customAgenda._id;
    this.selectedMeetingType$.next(MeetingAgendaType.custom);
    this.newAgenda = true;
    this.setMeetingName(customAgenda.name);
    this.buildForm(true, customAgenda);
    this.cancelDisabled = true;
    setTimeout(() => document.getElementById('meeting-title').focus());
  }

  deleteAgenda(resetBackToWeekly = true): void {
    const data: ConfirmDialogData = {
      title: 'Are you sure?',
      message: 'Once an agenda is deleted, all its content will be lost.',
      additionalMessage: '<strong>This action cannot be undone.</strong>',
    };

    this.dialog
      .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, { data })
      .afterClosed()
      .pipe(
        filter(accepted => !!accepted),
        tap(() => {
          const copyIndex = this.customAgendasCopy.findIndex(c => c._id === this.selectedAgendaId);
          if (copyIndex !== -1) {
            this.selectedTeam
              ? this.deleteTeamCustomAgenda.emit({ teamId: this.selectedTeam?._id, agendaId: this.selectedAgendaId })
              : this.deleteCustomAgenda.emit(this.selectedAgendaId);
            this.customAgendasCopy.splice(copyIndex, 1);
          }
          if (resetBackToWeekly) this.resetBackToWeekly();
        })
      )
      .subscribe();
  }

  resetBackToWeekly(): void {
    this.agendas.custom.splice(this.customAgendaIndex, 1);
    this.selectedMeetingType$.next(MeetingAgendaType.weekly);
    this.selectedAgendaId = MeetingAgendaType.weekly;
    this.newAgenda = false;
    this.setMeetingName();
    setTimeout(() => this.somethingChanged$.next(false));
    this.buildForm();
    this.closeDetails();
  }

  cancel(): void {
    const type = this.selectedMeetingType$.getValue();
    let resetValue: MeetingAgenda;
    if (type === MeetingAgendaType.custom) {
      const copyIndex = this.customAgendasCopy.findIndex(c => c._id === this.selectedAgendaId);
      const agenda = this.customAgendasCopy[copyIndex].agenda.map(s => ({
        ...s,
        isHidden: !s.isHidden,
      }));
      resetValue = _cloneDeep({ ...this.agendaCopy, agenda });
      this.agendas.custom[this.selectedIndex] = resetValue;
    } else {
      const agenda = this.agendaCopy.agenda.map(s => ({ ...s, duration: s.duration * 60, isHidden: !s.isHidden }));
      resetValue = _cloneDeep({ ...this.agendaCopy, agenda });
      this.agendas[type] = resetValue;
    }
    setTimeout(() => this.somethingChanged$.next(false));
    this.buildForm(true, type === MeetingAgendaType.custom && resetValue);
    this.closeDetails();
  }

  drop(event: CdkDragDrop<string[]>) {
    const sections = this.meetingSectionsForm.get('agenda');
    const formArray = sections['controls'];
    moveItemInArray(formArray, event.previousIndex, Math.min(event.currentIndex, formArray.length - 2)); // keep conclude last
    moveItemInArray(sections.value, event.previousIndex, Math.min(event.currentIndex, sections.value.length - 2)); // keep conclude last
    sections.value.forEach((s, i) => (s.ordinal = i));
    this.meetingSectionsForm.patchValue(formArray);
    this.checkForChanges();
  }

  private reservedNameValidator(
    isCustom: boolean,
    type: MeetingAgendaType
  ): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const path = control.value?.toLowerCase().replace(/\s/g, '-') ?? '';
      return isCustom && ReservedSectionNames[type].includes(path) ? { reservedName: true } : null;
    };
  }

  selectSection(s: MeetingSection, i: number): void {
    this.selectedIndex = i;
    this.iframeVisible = false;
    this.selectedSection$.next(s.isCustom || s.isEditable ? s : null);
  }

  closeDetails(): void {
    this.selectedIndex = null;
    this.selectedSection$.next(null);
  }

  updateSelectedSection(update: Partial<MeetingSection>): void {
    const selectedSection = this.selectedSection$.getValue();
    this.selectedSection$.next({ ...selectedSection, ...update });
    const section = this.meetingSectionsForm.get('agenda')['controls'][this.selectedIndex];
    section.patchValue(update);
  }

  updateSectionName(i: number): void {
    if (this.selectedIndex !== i) return;
    const section = this.meetingSectionsForm.get('agenda')['controls'][this.selectedIndex];
    const name = section['controls']['name'].value;
    const selectedSection = this.selectedSection$.getValue();
    this.selectedSection$.next({ ...selectedSection, name });
  }

  setMeetingName(meetingName?: string): void {
    const name =
      meetingName ||
      (() => {
        switch (this.selectedMeetingType$.getValue()) {
          case MeetingAgendaType.quarterly:
            return this.meetingLang.quarterlySession;
          case MeetingAgendaType.annualDayOne:
            return `${this.meetingLang.annualSession} - Day One`;
          case MeetingAgendaType.annualDayTwo:
            return `${this.meetingLang.annualSession} - Day Two`;
          case MeetingAgendaType.focusDay:
            return this.meetingLang.focusDay;
          case MeetingAgendaType.visionBuildingDayOne:
            return `${this.meetingLang.visionBuildingDayOne}`;
          case MeetingAgendaType.visionBuildingDayTwo:
            return `${this.meetingLang.visionBuildingDayTwo}`;
          case MeetingAgendaType.weekly:
            return this.meetingLang.levelTen;
        }
      })();
    this.meetingName$.next(name);
  }

  teamsAreSame(option, value): boolean {
    return option._id === value._id;
  }

  setTeamAgendas(team: Team = this.selectedTeam, resetMeetingType = true): void {
    if (!team) return;

    if (!team.settings) {
      this.getTeamWithSettings.emit(team);
    }

    if (resetMeetingType) {
      this.selectedAgendaId = this.agendaV2SelectedTypeOrIdOrNewCustom || MeetingAgendaType.weekly;
      if (ObjectId.isValid(this.selectedAgendaId)) {
        this.customAgendaIndex = team.settings.custom.findIndex(a => a._id === this.selectedAgendaId);
        this.selectedMeetingType$.next(MeetingAgendaType.custom);
      } else if (this.selectedAgendaId === MeetingRoutes.newCustomAgenda) {
        //agenda v2 - add support for new agenda creation from URL
        this.selectedMeetingType$.next(MeetingAgendaType.custom);
      } else {
        this.selectedMeetingType$.next(this.selectedAgendaId as MeetingAgendaType);
      }
    }

    this.setMeetingName();
    const settings = _cloneDeep(team.settings);
    this.agendas = _pick(settings, Object.values(MeetingAgendaType)) as MeetingAgendas;
  }

  goBackToMeetings(): void {
    this.router.navigate(this.meetingsV2 ? ['/meetings-v2'] : ['/meetings']);
  }

  showIframe(): void {
    this.iframeWidth = this.iframeDiv.nativeElement.offsetWidth;
    this.iframeHeight = this.iframeWidth * 0.75;
    this.iframeVisible = true;
  }

  makeSureConcludeIsLast(): void {
    if (!this.agendas) return;

    const type = this.selectedMeetingType$.getValue();
    if (type === MeetingAgendaType.custom) return;
    const sections = this.agendas[type]?.agenda;
    if (sections[sections.length - 1].path !== 'conclude') {
      sections.push(
        sections.splice(
          sections.findIndex(s => s.path === 'conclude'),
          1
        )[0]
      );
      sections.forEach((s, i) => (s.ordinal = i));
      this.save();
    }
  }

  push(): void {
    const type = this.selectedMeetingType$.getValue();

    const confirmPushDialogRef = this.dialog.open<WarningConfirmDialogComponent, ConfirmDialogData>(
      WarningConfirmDialogComponent,
      {
        data: {
          title:
            `This action will push this version of the agenda to all existing teams in your company. ` +
            `If you do not push to all teams, this version of the meeting will only be applied to newly created teams.`,
          confirmButtonText: 'Push',
        },
      }
    );

    confirmPushDialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          if (this.selectedTeam) {
            this.pushTeamAgenda.emit({
              teamId: this.selectedTeam._id,
              agendaType: type,
              agendaId: this.selectedAgendaId,
            });
          } else {
            this.pushCompanyAgenda.emit({
              agendaType: type,
              agendaId: this.selectedAgendaId,
            });
          }
        }
      },
    });
  }
}
