import { GridStackOptions } from 'gridstack/dist/types';

/**
 * Options to configure GridStack.
 *
 * TODO Lots of classes modify GridStack options not Ninety options, thus exposing GridStack to the outside world.
 *  This could use better encapsulation - dont extend, pick only the valid options, thus being explicit about what the
 *  current implementation supports.
 */
export interface GridOptions extends GridStackOptions {
  /**
   * Adds width to the one column breakpoint observer, which handles updating the grid when it begins in one column mode
   * and moves to three column mode. In pixels.
   */
  oneColumnExtraPadding?: number;
  resizeDebounce: number;
}

export namespace GridOptions {
  /**
   * Bellow this pixel count, show only 1 column.
   *
   * Duplicated as SCSS variable => src/styles/grid.scss $one-column-grid-cutoff
   * Updates here should also be made there.
   */
  export const DEFAULT_SINGLE_COLUMN_BREAKPOINT = 1000;

  export function defaults(options?: Partial<GridOptions>): GridOptions {
    return {
      resizeDebounce: 100,
      column: 3, // Only allow 3 columns exactly, not variable up to 12 like default
      cellHeight: 300, // Min cell height
      margin: 8, // Between cards
      oneColumnSize: DEFAULT_SINGLE_COLUMN_BREAKPOINT,
      float: false, // Sections "float" by not compacting vertically when there is space above them
      resizable: { autoHide: false, handles: 'all' }, // By default, only show handle in SE corner - override to all
      ...options,
    };
  }
}
