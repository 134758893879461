import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, filter, map, of } from 'rxjs';

import { CatchError } from '@ninety/ui/legacy/core/decorators/catch-error';
import { CatchErrorAndNotify } from '@ninety/ui/legacy/core/decorators/catch-error-and-notify';
import { SpinnerAndCatchError } from '@ninety/ui/legacy/core/decorators/spinner-and-catch-error';
import { SpinnerAndNotifyError } from '@ninety/ui/legacy/core/decorators/spinner-and-notify-error';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Person } from '@ninety/ui/legacy/shared/models/_shared/person';
import { PartnerGroup } from '@ninety/ui/legacy/shared/models/partner-hub/_shared/partner-group';
import { PartnerStackResponse } from '@ninety/ui/legacy/shared/models/partner-hub/_shared/partner-stack-response';
import { PartnerTeam } from '@ninety/ui/legacy/shared/models/partner-hub/_shared/partner-team';
import { CreatePartnerDTO } from '@ninety/ui/legacy/shared/models/partner-hub/create-parter.dto';
import { Customer } from '@ninety/ui/legacy/shared/models/partner-hub/customer';
import { CustomerData } from '@ninety/ui/legacy/shared/models/partner-hub/customer-data';
import { CustomerExistsValidationError } from '@ninety/ui/legacy/shared/models/partner-hub/customer-exists-validation-error';
import { GetPartnersParams } from '@ninety/ui/legacy/shared/models/partner-hub/get-partners-params';
import { PaginatedPartnerList } from '@ninety/ui/legacy/shared/models/partner-hub/paginated-partner-list';
import { Partner } from '@ninety/ui/legacy/shared/models/partner-hub/partner';
import { UpdatePartnerDto } from '@ninety/ui/legacy/shared/models/partner-hub/update-partner.dto';

import { CustomerUpdate } from '../../_state/models/partner-detail-state.model';
import { AlreadyExists } from '../models/already-exists';
import { PartnerKeyUpdate, PartnerKeyUpdateDto } from '../models/partner-key-update';
import { PersonAndPartner } from '../models/person-and-partner';

@Injectable({
  providedIn: 'root',
})
export class PartnerStackService {
  private customersApi = '/api/v4/Customers';
  private partnersApi = '/api/v4/Partners';
  private partnerHubApi = '/api/v4/PartnerHub';

  customer: Customer;
  partner_key: string;
  partner_email: string;

  constructor(
    private http: HttpClient,
    private _spinnerService: SpinnerService,
    private _errorService: ErrorService,
    private stateService: StateService
  ) {
    this.stateService.currentPerson$.pipe(filter(p => !!p)).subscribe({
      next: p => {
        this.partner_key = p.partner_key || p._id;
        this.partner_email = p.partner_email || p.primaryEmail;
      },
    });
  }

  @CatchErrorAndNotify
  getPartners(params: GetPartnersParams = { limit: 25 }): Observable<PaginatedPartnerList> {
    const qs = QueryParamsService.build(params, true);
    return this.http.get<PaginatedPartnerList>(`${this.partnersApi}?${qs}`);
  }

  getPartner(keyOrEmail: string): Observable<Partner> {
    return this.http.get<Partner>(`/api/v4/Partner/${keyOrEmail}`);
  }

  checkIfPartnerExists(keyOrEmail: string): Observable<AlreadyExists> {
    return this.getPartner(keyOrEmail).pipe(
      map((): AlreadyExists => ({ exists: true })),
      catchError(() => of(null))
    );
  }

  @CatchErrorAndNotify
  getPartnerTeam(team_key: string): Observable<PartnerTeam> {
    return this.http.get<PartnerTeam>(`${this.partnersApi}/Team/${team_key}`);
  }

  @SpinnerAndCatchError
  findPartnerByKeyOrEmail(keyOrEmail: string): Observable<Partner[]> {
    return this.http.get<Partner[]>(`/api/v4/Partners/Find/${keyOrEmail}`);
  }

  @SpinnerAndNotifyError
  becomeAPartner(partner: CreatePartnerDTO): Observable<Partner> {
    return this.http.post<Partner>(this.partnersApi, partner);
  }

  @SpinnerAndNotifyError
  createPartner({ partner, person }: PersonAndPartner): Observable<Partner> {
    return this.http
      .post<Partner>(this.partnersApi, { ...partner, personId: person._id })
      .pipe(map(p => ({ ...p, person: { ...person, partnerType: partner.partnerType, bosTypes: partner.bosTypes } })));
  }

  updatePartner(internal_key: string, update: UpdatePartnerDto): Observable<Partner> {
    return this.http.patch<Partner>(`${this.partnersApi}/${internal_key}`, update);
  }

  /** Want to hit a different endpoint than the person service for simple backend permissions checks */
  @SpinnerAndNotifyError
  updatePerson(
    personId: string,
    update: Pick<Person, 'partner_key' | 'bosTypes' | 'partnerType' | 'partner_email'>
  ): Observable<Person> {
    return this.http.patch<Person>(`${this.partnersApi}/Person/${personId}`, update);
  }

  @CatchErrorAndNotify
  updatePartnerKey(update: PartnerKeyUpdate): Observable<Partner> {
    const body = new PartnerKeyUpdateDto(update);
    return this.http.patch<void>(`${this.partnersApi}/UpdatePartnerKey`, body).pipe(
      map(() => {
        const {
          partner: { person },
          keyUpdate: partner_key,
        } = update;
        return { ...update.partner, partner_key, person: { ...person, partner_key } };
      })
    );
  }

  @SpinnerAndNotifyError
  deletePartner({ partner_key, key: internalKey }: Partner): Observable<string> {
    return this.http
      .delete<PartnerStackResponse>(`${this.partnersApi}/${internalKey}/${partner_key}`)
      .pipe(map(() => internalKey));
  }

  @CatchError
  getPartnerGroups(): Observable<PartnerGroup[]> {
    return this.http.get<PartnerGroup[]>(`${this.partnersApi}/Groups`);
  }

  @SpinnerAndCatchError
  getCustomersByPartnerKey(partner_key?: string): Observable<Customer[]> {
    return this.http.get<Customer[]>(`${this.customersApi}?partner_key=${partner_key}`);
  }

  @SpinnerAndCatchError
  getCustomer(internal_key: string): Observable<Customer> {
    return this.http.get<Customer>(`${this.customersApi}/${internal_key}`);
  }

  @CatchErrorAndNotify
  checkIfCustomerExists(keyOrEmail: string): Observable<CustomerExistsValidationError | null> {
    return this.http.get<{ exists: boolean }>(`${this.customersApi}/Exists/${keyOrEmail}`);
  }

  @SpinnerAndNotifyError
  createCustomer(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.customersApi, customer);
  }

  @SpinnerAndNotifyError
  updateCustomer(customer_key: string, update: CustomerUpdate): Observable<Customer> {
    return this.http.patch<Customer>(`${this.customersApi}/${customer_key}`, update);
  }

  @SpinnerAndNotifyError
  deleteCustomer(customer_key: string): Observable<PartnerStackResponse> {
    return this.http.delete<PartnerStackResponse>(`${this.customersApi}/${customer_key}`);
  }

  findCompany(companyId: string): Observable<CustomerData> {
    return this.http.get<CustomerData>(`${this.customersApi}/FindCompany/${companyId}`);
  }
}
