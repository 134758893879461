import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { LinkedItems } from '@ninety/ui/legacy/shared/models/linked-items/linked-items-response.model';

@Injectable({
  providedIn: 'root',
})
export class LinkedItemsService {
  private linkedItemsApi = '/api/v4/linked-items';

  http = inject(HttpClient);
  stateService = inject(StateService);
  errorService = inject(ErrorService);

  getLinkedItems(itemType: LinkedItemTypeEnum, itemId: string): Observable<LinkedItems> {
    // Note: "all" was added to the GET URL path to fix an issue with GET and DELETE having the similar starting URL path.
    return this.http
      .get<LinkedItems>(`${this.linkedItemsApi}/all/${this.stateService.companyId}/${itemType}/${itemId}?limit=1000`)
      .pipe(
        map(result => result),
        catchError((e: unknown) => this.errorService.notify(e, `Could not get linked items. Please try again.`))
      );
  }

  deleteLinkedItem(linkId: string): Observable<void> {
    return this.http.delete<void>(`${this.linkedItemsApi}/${this.stateService.companyId}/${linkId}`).pipe(
      map(result => result),
      catchError((e: unknown) => this.errorService.notify(e, `Could not delete linked item. Please try again.`))
    );
  }
}
