import { formatDate } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { catchError, concatMap, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { DetailViewActions } from '@ninety/detail-view/_state/detail-view.actions';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { MeetingsV2Service } from '@ninety/pages/meetings/_services/meetings-v2.service';
import { FileService, FilterService, StateService, TeamsApiService, UserService } from '@ninety/ui/legacy/core/index';
import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import {
  MeetingAgendaPrintParams,
  PrintApi,
  PrintOptions,
  PrintService,
} from '@ninety/ui/legacy/core/services/print.service';
import { FilterBarActions } from '@ninety/ui/legacy/layouts/_state/filterbar/filterbar-state.actions';
import { TopToolbarActions } from '@ninety/ui/legacy/layouts/_state/top-toolbar/top-toolbar-state.actions';
import {
  ConfirmDialogData,
  ItemType,
  MeetingAgenda,
  MeetingType,
  WarningConfirmDialogComponent,
} from '@ninety/ui/legacy/shared/index';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import {
  selectCompanyId,
  selectIsAgreementsBasedTodosCompany,
} from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';
import { FeatureFlagKeys, selectFeatureFlag, TeamListStateActions } from '@ninety/ui/legacy/state/index';

import { AgendaV2Service } from '../../_services/agenda-v2.service';
import { PrintAgendasDialogComponent } from '../../meetings-page/print-meeting-agenda/print-meeting-agenda.component';
import { StartMeetingDialogComponent } from '../../meetings-page/start-meeting-dialog/start-meeting-dialog.component';
import {
  MeetingConcludeActions,
  MeetingDialogActions,
  MeetingRealTimeActions,
  MeetingsAgendasV2Actions,
  MeetingSchedulingActions,
  MeetingsPageActions,
  MeetingsTabsActions,
  PastMeetingsPageActions,
} from '../meetings.actions';
import { MeetingsStateSelectors } from '../meetings.selectors';

@Injectable()
export class MeetingsPageEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private meetingsV2Service = inject(MeetingsV2Service);
  private meetingService = inject(MeetingService);
  private router = inject(Router);
  private errorService = inject(ErrorService);
  private dialog = inject(MatDialog);
  private channelService = inject(ChannelService);
  private printService = inject(PrintService);
  private createDialogService = inject(CreateDialogService);
  private filterService = inject(FilterService);
  private userService = inject(UserService);
  private teamApiService = inject(TeamsApiService);
  private agendaV2Service = inject(AgendaV2Service);
  private stateService = inject(StateService);
  private fileService = inject(FileService);

  setPageTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsTabsActions.init),
        concatLatestFrom(() => [this.store.select(selectLanguage)]),
        map(([, language]) => this.stateService.setTitle(language.meeting.route))
      ),
    { dispatch: false }
  );

  hideToolbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsTabsActions.init),
      map(() => TopToolbarActions.toolbarHidden({ toolbarHidden: true }))
    )
  );

  hideFilterBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsTabsActions.init),
      map(() => FilterBarActions.hide())
    )
  );

  stopSpinnerOnInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsTabsActions.init),
      map(() => SpinnerActions.stopPrimary({ source: 'Meetings Page Init' }))
    )
  );

  showToolbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsTabsActions.reset),
      map(() => TopToolbarActions.toolbarHidden({ toolbarHidden: false }))
    )
  );

  showFilterBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsTabsActions.reset),
      map(() => FilterBarActions.show())
    )
  );

  openUniversalCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.openUniversalCreateModal),
        switchMap(_ => this.createDialogService.open({ itemType: ItemType.headline }))
      ),
    { dispatch: false }
  );

  teamSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.selectTeam, MeetingsAgendasV2Actions.selectTeam, PastMeetingsPageActions.selectTeam),
        tap(({ team }) => {
          window.sessionStorage.setItem('lastAccessedTeamId', team._id);
          //NOTE: this might be needed but I couldn't find why, keeping for now, remove in 6 months if no features require it
          // this.stateService.currentCompanyUser$.value.lastAccessedTeamId = team._id;
          this.userService.update({ lastAccessedTeamId: team._id }).subscribe();
          this.filterService.setTeamId(team._id);
        })
      ),
    { dispatch: false }
  );

  loadPastMeetings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingsPageActions.selectTeam,
        PastMeetingsPageActions.selectMeetingType,
        MeetingsPageActions.selectMeetingType,
        MeetingsPageActions.paginationChange,
        MeetingsPageActions.sortChange,
        MeetingsPageActions.deleteMeetingSuccess
      ),
      concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectTeamId)]),
      filter(([, teamId]) => !!teamId),
      concatMap(() => of(MeetingsPageActions.loadPastMeetings()))
    )
  );

  getPastMeetings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.loadPastMeetings),
      concatLatestFrom(() => [
        this.store.select(selectCompanyId),
        this.store.select(MeetingsStateSelectors.selectPage),
        this.store.select(MeetingsStateSelectors.selectSort),
        this.store.select(MeetingsStateSelectors.selectFilters),
      ]),
      switchMap(([_, companyId, page, sort, { meetingType, agendaId, teamId }]) =>
        this.meetingsV2Service
          .getPastMeetings(companyId, teamId, {
            pageIndex: page.index,
            pageSize: page.size,
            sortDirection: sort.direction,
            sortField: sort.field,
            meetingType,
            agendaId,
          })
          .pipe(
            map(pastMeetingsResponse => MeetingsPageActions.loadPastMeetingsSuccess({ pastMeetingsResponse })),
            catchError((error: unknown) => of(MeetingsPageActions.loadPastMeetingsFailure({ error })))
          )
      )
    )
  );

  getActiveMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingsPageActions.selectTeam,
        MeetingsPageActions.getActiveMeetingInfo,
        MeetingsPageActions.deleteActiveMeetingSuccess,
        MeetingsPageActions.deleteMeetingSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(selectCompanyId),
        this.store.select(MeetingsStateSelectors.selectTeamId),
      ]),
      filter(([, companyId, teamId]) => !!companyId && !!teamId),
      switchMap(([, companyId, teamId]) =>
        this.meetingsV2Service.getActiveMeetingInfo(companyId, teamId).pipe(
          map(response => MeetingsPageActions.getActiveMeetingInfoSuccess({ meeting: response.meeting })),
          catchError((error: unknown) => of(MeetingsPageActions.getActiveMeetingInfoFailure({ error })))
        )
      )
    )
  );

  getMeetingSchedules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.selectTeam),
      exhaustMap(() => of(MeetingSchedulingActions.getMeetingSchedules()))
    )
  );

  notifyOnDeleteActiveMeetingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteActiveMeetingSuccess),
      map(_ => NotificationActions.notify({ message: 'Delete successful' }))
    )
  );

  notifyOnDeleteActiveMeetingFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteActiveMeetingFailure),
      map(({ error }) => NotificationActions.notifyError({ error, message: 'Delete failed. Please try again.' }))
    )
  );

  navigateHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsTabsActions.navigate),
        tap(({ route }) => {
          this.router.navigate([`/meetings-v2/${route}`]);
        })
      ),
    { dispatch: false }
  );

  loadPastMeetingsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.loadPastMeetingsFailure),
        concatLatestFrom(() => [this.store.select(selectLanguage)]),
        tap(([error, language]) => {
          this.errorService.notify(error, `Could load ${language.meeting.items}. Please try again.`);
        })
      ),
    { dispatch: false }
  );

  /**
   * Updates team/meeting settings to persist auto-archive and send-recap settings when completing a meeting
   */
  updateMeetingSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingConcludeActions.updateMeetingSettings),
      concatLatestFrom(() => [this.store.select(selectIsAgreementsBasedTodosCompany)]),
      switchMap(([action, isAgreementsBasedTodosCompany]) =>
        this.teamApiService.getTeamById(action.teamId).pipe(
          map(team => ({ ...action, team, isAgreementsBasedTodosCompany })),
          filter(
            ({ team, autoArchive, shouldSendRecap, acceptPendingAgreementBasedTodos, showIssueDescOnRecapEmail }) =>
              this.meetingSettingsHaveChanges(
                team,
                autoArchive,
                shouldSendRecap,
                acceptPendingAgreementBasedTodos,
                showIssueDescOnRecapEmail
              )
          ),
          map(
            ({
              team,
              autoArchive,
              shouldSendRecap,
              acceptPendingAgreementBasedTodos,
              isAgreementsBasedTodosCompany,
              showIssueDescOnRecapEmail,
            }) => {
              const update = {
                sendMeetingRecapEmail: shouldSendRecap,
                ...(isAgreementsBasedTodosCompany ? { acceptPendingAgreementBasedTodos } : {}),
                autoArchive,
                showIssueDescOnRecapEmail,
              };
              return TeamListStateActions.updateSettings({ teamId: team._id, update });
            }
          )
        )
      )
    )
  );

  openStartMeetingDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingDialogActions.openStartMeetingDialog),
        tap(() => {
          this.dialog.open(StartMeetingDialogComponent);
        })
      ),
    { dispatch: false }
  );

  createMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingDialogActions.createMeeting),
      concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectTeamId)]),
      switchMap(([{ meetingType, agendaId }, teamId]) =>
        this.meetingService
          .createNewMeeting({
            type: meetingType === MeetingType.annualDayTwo ? MeetingType.annualDayOne : meetingType,
            teamId,
            agendaId,
          })
          .pipe(
            map(meeting => MeetingDialogActions.createMeetingSuccess({ meeting: cloneDeep(meeting) })),
            catchError((error: unknown) => of(MeetingDialogActions.createMeetingFailure({ error })))
          )
      )
    )
  );

  getMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.getMeeting),
      concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectMeetingStatus)]),
      switchMap(([, meeting]) =>
        this.meetingsV2Service.startMeeting(meeting._id).pipe(
          map(meeting => MeetingsPageActions.getMeetingSuccess({ meeting })),
          catchError((error: unknown) => of(MeetingsPageActions.getMeetingFailure({ error })))
        )
      )
    )
  );

  getMeetingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.getMeetingSuccess, MeetingsPageActions.resumeMeetingSuccess),
      concatLatestFrom(() => [this.store.select(TeamSelectors.selectAll)]),
      tap(([{ meeting }, teams]) => {
        this.meetingService.initializeMeeting(cloneDeep(meeting));
        this.meetingService.team$.next(teams.find(t => t._id === meeting.teamId));
        this.meetingService.navigateToMeeting(meeting);
      }),
      map(([{ meeting }]) => MeetingRealTimeActions.checkMeetingPresenterExists({ meeting }))
    )
  );

  /** The same as getMeeting but will dispatch a meeting state change in response to resumeMeetingSuccess */
  resumeMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.resumeMeeting),
      concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectMeetingStatus)]),
      switchMap(([, meeting]) =>
        this.meetingsV2Service.startMeeting(meeting._id).pipe(
          map(meeting => MeetingsPageActions.resumeMeetingSuccess({ meeting })),
          catchError((error: unknown) => of(MeetingsPageActions.resumeMeetingFailure({ error })))
        )
      )
    )
  );

  /** Makes the grant request to listen to each specific meeting section (rocks, todos, issues, etc.) */
  subscribeToMeetingChannels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.getMeeting),
        concatLatestFrom(() => [
          this.store.select(MeetingsStateSelectors.selectMeetingStatus),
          this.store.select(MeetingsStateSelectors.selectTeamId),
        ]),
        switchMap(([, meeting, teamId]) => this.channelService.subscribeToMeetingChannels(meeting._id, teamId))
      ),
    { dispatch: false }
  );

  joinMeetingFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.getMeetingFailure),
        concatLatestFrom(() => [this.store.select(selectLanguage)]),
        tap(([e, { meeting }]) => {
          this.errorService.notify(e, `Could not get ${meeting.item}.  Please try again.`);
        })
      ),
    { dispatch: false }
  );

  openDeleteMeetingDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.openDeleteMeetingDialog),

      concatLatestFrom(() => [
        this.store.select(selectLanguage),
        this.store.select(MeetingsStateSelectors.selectMeetingStatus),
      ]),
      exhaustMap(([, language, { inProgress, paused }]) => {
        const message =
          inProgress && !paused
            ? `Deleting will remove all active participants from the ${language.meeting.item}.
            To save the ${language.meeting.item}, join and conclude. This action cannot be undone.`
            : '';

        const confirmDeleteDialogRef = this.dialog.open<WarningConfirmDialogComponent, ConfirmDialogData>(
          WarningConfirmDialogComponent,
          {
            data: {
              title: `Are you sure you want to delete this ${language.meeting.item}?`,
              message,
              confirmButtonText: 'Delete',
            },
          }
        );

        return confirmDeleteDialogRef
          .afterClosed()
          .pipe(
            map(deleteConfirmed =>
              deleteConfirmed
                ? MeetingsPageActions.deleteActiveMeeting()
                : MeetingsPageActions.cancelDeleteActiveMeeting()
            )
          );
      })
    )
  );

  deleteActiveMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteActiveMeeting),
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectMeetingStatus),
        this.store.select(selectCompanyId),
        this.store.select(TeamSelectors.selectFilterBarTeamId),
      ]),
      switchMap(([, meeting, companyId, teamId]) =>
        this.meetingsV2Service.deleteMeeting(meeting._id, companyId, teamId).pipe(
          map(() => MeetingsPageActions.deleteActiveMeetingSuccess({ meetingId: meeting._id })),
          catchError((error: unknown) => of(MeetingsPageActions.deleteActiveMeetingFailure({ error })))
        )
      )
    )
  );

  selectMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.selectMeeting),
      map(({ meeting }) => {
        const _id = meeting.previousMeetingId || meeting._id;
        return DetailViewActions.opened({
          config: { pathParts: [DetailType.meeting, _id] },
          source: 'Meeting Selected',
        });
      })
    )
  );

  updateMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.updateMeeting),
      switchMap(action =>
        this.meetingService.updateMeetingHttp(action._id, action.update).pipe(
          map(() => MeetingsPageActions.updateMeetingSuccess()),
          catchError((error: unknown) => of(MeetingsPageActions.updateMeetingFailure({ error })))
        )
      )
    )
  );

  deleteMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteMeeting),
      concatLatestFrom(() => [this.store.select(selectFeatureFlag(FeatureFlagKeys.meetingsV2))]),
      filter(([, featureFlag]) => !!featureFlag),
      switchMap(([{ meeting }]) =>
        this.meetingService.deleteMeeting(meeting).pipe(
          map(() => MeetingsPageActions.deleteMeetingSuccess()),
          catchError((error: unknown) => of(MeetingsPageActions.deleteMeetingFailure({ error })))
        )
      )
    )
  );

  deleteMeetingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsPageActions.deleteMeetingSuccess),
      map(() => DetailViewActions.close())
    )
  );

  openPrintMeetingAgendas$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsPageActions.printAgenda),
        tap(() => {
          this.dialog.open(PrintAgendasDialogComponent);
        })
      ),
    { dispatch: false }
  );

  printMeetingAgendas$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingDialogActions.printMeetingAgenda),
        concatLatestFrom(() => [this.store.select(MeetingsStateSelectors.selectTeamId)]),
        switchMap(([{ meetingType, sortOptions, customAgendaId }, teamId]) =>
          this.printService.openPdf<MeetingAgendaPrintParams>(PrintApi.meetingAgenda, {
            teamId,
            meetingType,
            ...(sortOptions ? { sortOptions } : null),
            printOptions: new PrintOptions(),
            ...(customAgendaId ? { customAgendaId } : {}),
          })
        )
      ),
    { dispatch: false }
  );

  private meetingSettingsHaveChanges(
    team: Team,
    autoArchive: boolean,
    sendMeetingRecapEmail: boolean,
    acceptPendingAgreementBasedTodos: boolean,
    showIssueDescOnRecapEmail: boolean
  ): boolean {
    const { settings } = team;
    return (
      settings?.autoArchive !== autoArchive ||
      settings?.sendMeetingRecapEmail !== sendMeetingRecapEmail ||
      settings?.acceptPendingAgreementBasedTodos !== acceptPendingAgreementBasedTodos ||
      settings?.showIssueDescOnRecapEmail !== showIssueDescOnRecapEmail
    );
  }

  triggerSaveNewCustomAgenda$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.triggerSaveNewCustomAgenda),
        tap(_ => this.agendaV2Service.triggerSaveNewCustomAgenda())
      ),
    { dispatch: false }
  );

  saveNewCustomAgenda_updateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsAgendasV2Actions.saveNewCustomAgenda),
      map(({ update }) => {
        return TeamListStateActions.updateTeamMeetingAgenda({ update });
      })
    )
  );

  saveNewCustomAgenda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsAgendasV2Actions.saveNewCustomAgenda),
      map(({ update }) => {
        const customAgendas = update.teamAgenda as MeetingAgenda[];
        const newCustomAgenda = customAgendas[customAgendas.length - 1];
        return MeetingsAgendasV2Actions.saveNewCustomAgendaSuccess({ agenda: newCustomAgenda });
      })
    )
  );

  saveNewCustomAgendaSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.saveNewCustomAgendaSuccess),
        tap(({ agenda }) => {
          this.router.navigate([`/${MeetingRoutes.home}/${MeetingRoutes.agendasV2}/${agenda._id}`]);
        })
      ),
    { dispatch: false }
  );

  triggerUpdateAgenda$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.triggerUpdateAgenda),
        tap(_ => this.agendaV2Service.triggerUpdateAgenda())
      ),
    { dispatch: false }
  );

  updateAgenda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsAgendasV2Actions.updateAgenda),
      map(({ update }) => TeamListStateActions.updateTeamMeetingAgenda({ update }))
    )
  );

  triggerDeleteCustomAgenda$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.triggerDeleteCustomAgenda),
        tap(_ => this.agendaV2Service.triggerDeleteCustomAgenda())
      ),
    { dispatch: false }
  );

  deleteCustomAgenda$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsAgendasV2Actions.deleteCustomAgenda),
      map(({ teamId, agendaId }) => TeamListStateActions.deleteCustomAgenda({ teamId, agendaId }))
    )
  );

  redirectOnDeleteCustomAgendaSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.deleteCustomAgendaSuccess, TeamListStateActions.deleteCustomAgendaSuccess),
        tap(() => {
          this.router.navigate([`/${MeetingRoutes.home}/${MeetingRoutes.agendasV2}`]);
        })
      ),
    { dispatch: false }
  );

  triggerDiscardChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.triggerDiscardChanges),
        tap(_ => this.agendaV2Service.triggerDiscardChanges())
      ),
    { dispatch: false }
  );

  triggerResetAgenda$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingsAgendasV2Actions.triggerResetAgenda),
        tap(_ => this.agendaV2Service.resetAgenda())
      ),
    { dispatch: false }
  );

  stopSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingsAgendasV2Actions.saveNewCustomAgendaSuccess,
        MeetingsAgendasV2Actions.saveNewCustomAgendaFailure,
        MeetingsAgendasV2Actions.updateAgendaSuccess,
        MeetingsAgendasV2Actions.updateAgendaFailure,
        MeetingsAgendasV2Actions.deleteCustomAgendaSuccess,
        MeetingsAgendasV2Actions.deleteCustomAgendaFailure
      ),
      map(({ type }) => SpinnerActions.stopPrimary({ source: type }))
    )
  );

  startSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MeetingsAgendasV2Actions.saveNewCustomAgenda,
        MeetingsAgendasV2Actions.updateAgenda,
        MeetingsAgendasV2Actions.deleteCustomAgenda
      ),
      map(({ type }) => SpinnerActions.startPrimary({ source: type }))
    )
  );

  downloadExcelPastMeetings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PastMeetingsPageActions.downloadExcel),
      concatLatestFrom(() => [
        this.store.select(selectCompanyId),
        this.store.select(MeetingsStateSelectors.selectPage),
        this.store.select(MeetingsStateSelectors.selectSort),
        this.store.select(selectLanguage),
        this.store.select(MeetingsStateSelectors.selectFilters),
        this.store.select(MeetingsStateSelectors.selectSelectedTeamName),
      ]),

      switchMap(([_, companyId, page, sort, language, { meetingType, agendaId, teamId }, team]) =>
        this.meetingsV2Service
          .downloadExcelPastMeetings(companyId, teamId, {
            pageIndex: page.index,
            pageSize: page.size,
            sortDirection: sort.direction,
            sortField: sort.field,
            meetingType,
            agendaId,
          })
          .pipe(
            tap(response => {
              const name = `Past_${language.meeting.items}__${team}`;
              const fileName = `${name}_${formatDate(new Date(), 'yyyy-MM-dd', 'en-US')}.xlsx`;
              this.fileService.downloadExcelFile(response, fileName);
            })
          )
          .pipe(
            map(() => PastMeetingsPageActions.downloadExcelSuccess()),
            catchError(() => of(PastMeetingsPageActions.downloadExcelFailure()))
          )
      )
    )
  );
}
