import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TerraFormFieldModule, TerraIconModule, TerraTextInputModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';

/** Could be extended to be used in other places.
 * Would want to add other inputs like disabled and readonly, etc.  */
@Component({
  selector: 'ninety-inline-editable-input',
  standalone: true,
  imports: [
    CommonModule,
    TerraTextInputModule,
    TerraIconModule,
    ButtonComponent,
    NinetyTooltipDirective,
    FormsModule,
    TerraFormFieldModule,
  ],
  templateUrl: './inline-editable-input.component.html',
  styleUrls: ['./inline-editable-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineEditableInputComponent),
      multi: true,
    },
  ],
})
export class InlineEditableInputComponent implements ControlValueAccessor {
  @Input() placeholder = 'Enter a value';
  editing = false;
  protected _value: string;

  @ViewChild('input') set input(input: ElementRef<HTMLInputElement>) {
    if (input) {
      input.nativeElement.focus();
      input.nativeElement.dispatchEvent(new FocusEvent('focus'));
    }
  }

  toggleEdit() {
    this.editing = !this.editing;
  }

  /** ControlValueAccessor implementation */
  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: string): void {
    if (!value) {
      this.editing = true;
      return;
    }
    this._value = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
