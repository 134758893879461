import { popperVariation, provideTippyConfig } from '@ngneat/helipopper';
import { TippyConfig } from '@ngneat/helipopper/lib/tippy.types';

export function provideDefaultTippyConfig(config?: Partial<TippyConfig>) {
  return provideTippyConfig({
    defaultVariation: 'tooltip',
    delay: 500,
    variations: {
      tooltip: {
        arrow: true,
      },
      popper: popperVariation,
    },
    ...config,
  });
}
