import { NinetyFrontEndEnvironment } from './environment.interface';

export function updateEnvironmentBasedOnHost(rootEnvironment: NinetyFrontEndEnvironment): NinetyFrontEndEnvironment {
  switch (document.location.hostname) {
    case 'localhost':
      rootEnvironment.apiOrigin = 'https://api.dev1.90srv.com';
      rootEnvironment.dashboardOrigin = 'https://develop.dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://maz.dev1.90srv.com';
      rootEnvironment.ngOrigin = 'https://ng-ninety-builds.s3-website.us-east-1.amazonaws.com/2286';

      rootEnvironment.redirectProtocol = 'http';
      rootEnvironment.refreshCookieName = 'DEVELOPMENT-AUTH-REFRESH-TOKEN';
      rootEnvironment.xsrfCookieName = 'DEVELOPMENT-AUTH-XSRF-TOKEN';
      break;
    case 'dev1.app.ninety.io':
    case 'app.dev1.90srv.com':
      rootEnvironment.apiOrigin = 'https://api.dev1.90srv.com';
      rootEnvironment.dashboardOrigin = 'https://develop.dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://maz.dev1.90srv.com';
      rootEnvironment.ngOrigin = 'https://ng-ninety-builds.s3-website.us-east-1.amazonaws.com/55';
      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      rootEnvironment.cognitoDomain = 'ninety-dev1.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      rootEnvironment.userPoolId = 'us-east-1_0yFtMZvnR';
      rootEnvironment.userPoolWebClientId = 'kaflbn71uiubkmvhgqkbu1cgh';
      break;
    case 'dev2.app.ninety.io':
      // TODO - some infrastructure missing in dev2, update if deploying to dev2
      // rootEnvironment.apiOrigin = 'https://dev2-api.ninety.io';
      // rootEnvironment.dashboardOrigin = 'https://develop.dashboard.ninety.io';
      // rootEnvironment.mazApiOrigin = 'https://maz.dev2.90srv.com';
      // rootEnvironment.ngOrigin = 'https://ng-ninety-builds-dev2.s3-website.us-east-1.amazonaws.com/55';
      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      rootEnvironment.cognitoDomain = 'ninetydev2.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      rootEnvironment.userPoolId = 'us-east-1_F7FYA9XJ8';
      rootEnvironment.userPoolWebClientId = '1df2pkcejtqkn413aaf9v7fu6m';
      break;
    case 'new-staging.app.ninety.io':
    case 'staging.ninety.io':
    case 'pinnacle.staging.ninety.io':
    case 'eos.staging.ninety.io':
    case 'empire.staging.ninety.io':
    case 'fireproof.staging.ninety.io':
    case 'app.staging-green.90srv.com':
    case 'app.staging-blue.90srv.com':
    case 'app.new-staging.90srv.com':
    case 'app.staging.90srv.com':
      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      rootEnvironment.cognitoDomain = 'ninetytest.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      rootEnvironment.userPoolId = 'us-east-1_0Ftkxe9D5';
      rootEnvironment.userPoolWebClientId = '5co1ttsia6f2als1tb3ni004b3';
      break;
    case 'new-production.app.ninety.io':
    case 'app.new-production.90srv.com':
    case 'beta.app.ninety.io':
    case 'next.ninety.io':
      rootEnvironment.apiOrigin = 'https://api.new-production.90srv.com';
      rootEnvironment.dashboardOrigin = 'https://dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://new-production.maz.90srv.com';
      rootEnvironment.ngOrigin =
        'https://ng-ninety-builds-new-production.s3-website.us-east-1.amazonaws.com/55';
      rootEnvironment.production = true;
      rootEnvironment.refreshCookieName = 'PRODUCTION-.NINETY.IO-REFRESH-TOKEN';
      rootEnvironment.xsrfCookieName = 'PRODUCTION-.NINETY.IO-XSRF-TOKEN';
      rootEnvironment.stripePublishableKey = 'pk_live_F2KkGhoOzwVBnRRMWwBSqFn0';

      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      rootEnvironment.cognitoDomain = 'ninetyprod.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      rootEnvironment.userPoolId = 'us-east-1_03ms0HbVE';
      rootEnvironment.userPoolWebClientId = '2o5r1bu7rv2nrrjoc470546jo7';
      break;
    case 'app.prod-green.90srv.com':
    case 'app.ninety.io':
      rootEnvironment.apiOrigin = 'https://api.prod-green.90srv.com';
      rootEnvironment.dashboardOrigin = 'https://dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://prod-green.maz.90srv.com';
      rootEnvironment.ngOrigin = 'https://ng-ninety-builds-prod-green.s3-website.us-east-2.amazonaws.com/55';
      rootEnvironment.production = true;
      rootEnvironment.refreshCookieName = 'PRODUCTION-.NINETY.IO-REFRESH-TOKEN';
      rootEnvironment.xsrfCookieName = 'PRODUCTION-.NINETY.IO-XSRF-TOKEN';
      rootEnvironment.stripePublishableKey = 'pk_live_F2KkGhoOzwVBnRRMWwBSqFn0';

      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      rootEnvironment.cognitoDomain = 'ninetyprod.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      rootEnvironment.userPoolId = 'us-east-1_03ms0HbVE';
      rootEnvironment.userPoolWebClientId = '2o5r1bu7rv2nrrjoc470546jo7';
      break;
    case 'app.qa1.90srv.com':
      rootEnvironment.apiOrigin = 'https://api.qa1.90srv.com';
      rootEnvironment.dashboardOrigin = 'https://develop.dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://qa1.maz.90srv.com';
      rootEnvironment.ngOrigin = 'https://ng-ninety-builds-qa1.s3-website-us-east-1.amazonaws.com/55';
      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      // rootEnvironment.cognitoDomain = 'ninety-qa1.auth.us-east-1.amazoncognito.com';
      rootEnvironment.cognitoDomain = 'ninety-dev1.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      // rootEnvironment.userPoolId = 'us-east-1_fjAGUvbM6';
      rootEnvironment.userPoolId = 'us-east-1_0yFtMZvnR';
      // rootEnvironment.userPoolWebClientId = '1u1c7n6hv769li3rpqhef32e1s'; // qa1-app-client-v2
      rootEnvironment.userPoolWebClientId = 'kaflbn71uiubkmvhgqkbu1cgh';
      break;
    case 'app.qa2.90srv.com':
      rootEnvironment.apiOrigin = 'https://api.qa2.90srv.com';
      rootEnvironment.dashboardOrigin = 'https://develop.dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://qa2.maz.90srv.com';
      rootEnvironment.ngOrigin = 'https://ng-ninety-builds-qa2.s3-website-us-east-1.amazonaws.com/55';
      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      // rootEnvironment.cognitoDomain = 'ninety-qa1.auth.us-east-1.amazoncognito.com';
      rootEnvironment.cognitoDomain = 'ninety-dev1.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      // rootEnvironment.userPoolId = 'us-east-1_fjAGUvbM6';
      rootEnvironment.userPoolId = 'us-east-1_0yFtMZvnR';
      // rootEnvironment.userPoolWebClientId = '1u1c7n6hv769li3rpqhef32e1s'; // qa1-app-client-v2
      rootEnvironment.userPoolWebClientId = 'kaflbn71uiubkmvhgqkbu1cgh';
      break;
  }

  // Match partial host names for Mescius ActiveReportsJS (GrapeCity) license key configurations
  if (document.location.hostname.endsWith('.90srv.com')) {
    rootEnvironment.ACTIVE_REPORTS_KEY =
      // eslint-disable-next-line max-len
      '*.90srv.com,E884273678354993#B1xFcZ4hzZhFTVB5GO7Z5M9syVDNHNrZUcxg7UxVGNix6bmRkZPVUQ8oUeixGOOtkMUFVatJ5ct5EOVZHbRtiVYxUU9IWQ95Edzl7KslDU7k6Tx5WdQlke5ZleURnRS94MipkU4hlW9JUcltWTQNWVPdzaCZES994RYp4RrlnR9UWO9JkbqBFb6hmYzt4anR5MqNWUrAzKol6ZEV4YYxGOmpUUDd4T6EGSMN6VYBVaS9kQPNXVudTU0hUVNd6R95EVmZkZzgXVZVDM9U6KLJnd4Q4dVhEbDFkQ6hHWy46RBd4YwFUaVxUbBxUUwklNCtEMXJ6La9meJNzUHdkI0IyUiwiIFRTOwgTMEdjI0ICSiwCOyEzNzETMzATM0IicfJye35XX3JCVGZVWiojIDJCLiQjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiI7AjNzkDMgETM8AzMyAjMiojI4J7QiwiIwEDOwQjMwIjI0ICc8VkIsISbvNmL6J7cwkjLqIiOiMXbEJCLi2WaukHdl9WaOJiOiEmTDJCLlVnc4pjIsZXRiwiIzkTO4UzM8cjNzcjM4gDOiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TU03CdxlUcq5WahB7MsR7RPdFSx9UYph5byV5Y4VWQFZFU7gkbShXYJ56KxZESXllc9A7KSVTO7Z6ZxgFNhlDWthXesVnQClWaBxmZhNEZ7ZVd7N6TzcXNZBZY';
  } else if (document.location.hostname.endsWith('.ninety.io')) {
    rootEnvironment.ACTIVE_REPORTS_KEY =
      // eslint-disable-next-line max-len
      '*.ninety.io,161249381814764#B1rnjotSeRBnSrpXZFFzboFDb6dWdrFkYSRHb8smWzIkYBpGZ4YXcF34L7dzdCVncplUd6RGavcjNol7UIJFd65We7gDWzcHTpR7KlJ7SsRzMURTZJZHNjtESERWSZZ4VZNUZVpEVvklVEZGU8M7ZTJ6YhFFe9F5aNVGb4oXTEVnUvgUTvNkS6sWY9pkU0RFO9cjdtplerADahJ5Ky2ETOBFOJ94L6NUe4xmW7l4LvNlQrU4aEpEZHBDWLp6VBNDeEZmajBlQrlGV9pVUSB5N7QHOih4bwo4ZWBnN0FVcoRnVIdzb78mby8WMKNWUiojITJCLiIkMxMTR4YkMiojIIJCL9IDMyEDMxkDO0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIiM5kDMwEDI6ATOwMjMwIjI0ICdyNkIsIybp9Se4Vmbp9mLqIiOiMXbEJCLikHdl9WaOJiOiEmTDJCLiQjN7QTM8EDOzkDNyEjNxIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVzdQp6d8JTT4lVRPN5QLt6MjBXRox6THp6dyhFcyRHWYFDR5tUeLRWVkh7b4QlWHl5R7kHVzFUZXNWTUVlYGBHZONDc8c4d4cjV5kVcBpUS944R9d6S7d6RyNXRZ3ya7pXTlJWMCz5D0';
  } else if (!rootEnvironment.ACTIVE_REPORTS_KEY) {
    rootEnvironment.ACTIVE_REPORTS_KEY =
      // eslint-disable-next-line max-len
      'Ninety.io,E884273678354993#B1t5qIdWNStmc8pFeohVcxYHSzU5LxgnQPBnUJFzbwEldU96cxs6ZaVDcwokdjpFejd7d8JlTFVXWhF6aIJlV7M5cPdjV4AVMXd7UQVHNGNTdZdVZr3idxNnZTBHRtFkNBRke8BnYEFXb8MEVGFGUaVmS45kbrRGbNtGcJ5UOvoUdJ5GNrk7NW5WQXx4KPRjezEHZvQ6dSRlR7EGMyAzKvUVOuVEbmxmbFVnNwpUc5N5TI5WYhJnWycmS09kT8djUwUWZo3CaxYDOl3UZzgUYzJkaCd7TMBnTtNTdip6VtJUTN9ETXJEe7Q6ULlkQ8JleIJmI0IyUiwiI4I4Q9MUMEZjI0ICSiwCO7MzNycTN9ATM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIyNwkTM9ADIxEDOwMjMwIjI0ICdyNkIsICMxgDM4IDMyIiOiAHeFJCLi2WaukHdl9WaOJiOiEmTDJCLlVnc4pjIsZXRiwiIzkTO4UzM8cjNzcjM4gDOiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdvVnWPhWZhV6bupVWRZWeUpGOqJTd4pkQzgXWJtkR7ZHMXBDMHh6ZvZDcNhGctNUQ6g7d0ZEbDZTQxwmbRNXRHhUMwcUWGlTTFJTNysmWrkWSGRkQ8ElYGdHM0BHUohEUrD4vw';
  }

  // Match partial host names (e.g. 7453.app.dev1.90srv.com)
  // Sorry for gross pattern matching :)
  // Ideally we'd serve this kind of runtime configuration from the API or from a
  //  appConfig.json or similar that we can populate when we deploy rather than
  //  building all the runtime configuration into the source code with the
  //  build-time configuration.
  switch (true) {
    case /[0-9]+\.app\.dev1\.90srv\.com/.test(document.location.hostname):
    case /pr-[0-9]+\.app\.dev1\.90srv\.com/.test(document.location.hostname):
    case /build-[0-9]+\.app\.dev1\.90srv\.com/.test(document.location.hostname):
      rootEnvironment.apiOrigin = ''; // should run against local BE
      rootEnvironment.dashboardOrigin = 'https://develop.dashboard.ninety.io';
      rootEnvironment.mazApiOrigin = 'https://maz.dev1.90srv.com';
      rootEnvironment.ngOrigin = 'https://ng-ninety-builds.s3-website.us-east-1.amazonaws.com/55';
      // Same as the dev1 block above for now, but if/when we deploy a cognito app client
      //  as part of a cloud-formation stack, we will need to be able to pass this configuration
      //  at runtime.
      rootEnvironment.authenticationFlowType = 'USER_SRP_AUTH';
      rootEnvironment.cognitoDomain = 'ninety-dev1.auth.us-east-1.amazoncognito.com';
      rootEnvironment.redirectProtocol = 'https';
      rootEnvironment.region = 'us-east-1';
      rootEnvironment.userPoolId = 'us-east-1_0yFtMZvnR';
      rootEnvironment.userPoolWebClientId = 'kaflbn71uiubkmvhgqkbu1cgh';
      break;
    default:
      // nothing matched
      break;
  }

  return rootEnvironment;
}
