import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, filter, map, Subject } from 'rxjs';

import { FilterOptions } from '../../_shared/models/_shared/filter-options';
import { Team } from '../../_shared/models/_shared/team';
import { DirectoryUserStatus } from '../../_shared/models/directory/directory-user-status';
import { MasteryFilterStatus } from '../../_shared/models/mastery/mastery-status';
import type { RockStatusCode } from '../../_shared/models/rocks/rock-status-code';
import { TeamListStateActions } from '../../_state';
import { TeamSelectors } from '../../_state/app-entities/team-list/team-list-state.selectors';
import { selectCurrentUser } from '../../_state/app-entities/users/users-state.selectors';

import { ExcelExportType } from './_state/filter-service/excel-export-types.enum';
import { FilterServiceActions } from './_state/filter-service/filter.service.actions';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private defaultOptions: FilterOptions = FilterOptions.Default();
  options$ = new BehaviorSubject<FilterOptions>(FilterOptions.Default());

  // Inputs ======================================
  searchText$ = new BehaviorSubject<string>('');

  // toggles ====================================
  showArchived$ = new BehaviorSubject<boolean>(false);
  showCompleted$ = new BehaviorSubject<boolean>(false);

  // Buttons =================================
  archiveCopy$ = new Subject();
  archiveCompleted$ = new Subject();
  collapseAll$ = new Subject();
  downloadCsv$ = new Subject();
  downloadQuarterlyExport$ = new Subject();
  expandAll$ = new Subject();
  printPdf$ = new Subject();
  refresh$ = new Subject<string>(); // need to check url in the subscriber to prevent multiple windows opening when components are nested
  resetTopSeat$ = new Subject();
  /** @deprecated use new chart filter state */
  zoomScale$ = new BehaviorSubject(1);
  settings$ = new Subject<string>();

  // Dropdowns ==================================

  selectedUserId$ = new BehaviorSubject<string>('all');

  selectedRockStatusCode$ = new BehaviorSubject<RockStatusCode | 'all'>('all');

  selectedMasteryStatus$ = new BehaviorSubject<MasteryFilterStatus>(MasteryFilterStatus.All);

  selectedTeamId$ = this.store.select(TeamSelectors.selectFilterBarTeamId);
  selectedTeam$ = this.store.select(TeamSelectors.selectFilterBarTeam);

  selectedOwnerId$ = new BehaviorSubject<string>('all');

  selectedUserStatusId$ = new BehaviorSubject<DirectoryUserStatus>(DirectoryUserStatus.all);

  sideNavExpanded$ = new BehaviorSubject<boolean>(false);

  filtersToolbarExpanded$ = new BehaviorSubject<boolean>(false);

  exportToExcel$ = new Subject<{ url: string; exportType: ExcelExportType }>(); // Issues is still out of the store, need something to subscribe

  private isFetchingDataBS$ = new BehaviorSubject(true);
  public isFetchingData$ = this.isFetchingDataBS$.asObservable();

  showGoalForecastingTable$ = new Subject<boolean>();

  constructor(private router: Router, private store: Store) {
    this.store
      .select(selectCurrentUser)
      .pipe(
        filter(u => !!u),
        map(u => u.settings?.sidenavPinned),
        distinctUntilChanged()
      )
      .subscribe(state => this.sideNavExpanded$.next(state === 'expanded'));
  }

  // Options ======================================

  /**
   * @see setOptions resets any options not passed to their default.
   *
   * This method only changes the props explicitly passed.
   */
  setOptionsExplicit(options: FilterOptions) {
    const current = _cloneDeep(this.options$.value);
    const newOpts = { ...current, ...options };
    this.options$.next(newOpts);
  }

  setOptions(options?: FilterOptions) {
    const current = _cloneDeep(this.options$.value);
    const newOptions = Object.assign({}, this.options$.value, this.defaultOptions, options);
    if (!_isEqual(current, newOptions)) this.options$.next(newOptions);
    // reset when updating options (changing major components)
    this.onToggleArchive(false);
    this.onToggleComplete(false);
    this.searchText$.next('');
  }

  setOption(option: keyof FilterOptions, value: boolean) {
    this.options$.next(Object.assign({}, this.options$.value, { [option]: value }));
  }

  // Inputs ======================================
  setSearchText(input = '') {
    this.searchText$.next(input);
    this.store.dispatch(FilterServiceActions.searchTextUpdated({ searchText: input }));
  }

  // toggles ====================================
  onToggleArchive(value: boolean) {
    this.showArchived$.next(value);
  }

  onToggleComplete(value: boolean) {
    this.showCompleted$.next(value);
  }

  onToggleFiltersToolbar(): void {
    this.filtersToolbarExpanded$.next(!this.filtersToolbarExpanded$.value);
  }

  // Buttons =================================
  onArchiveCompleted() {
    this.archiveCompleted$.next(null);
  }

  onDownloadCsv() {
    this.downloadCsv$.next(null);
  }

  onDownloadQuarterlyExport() {
    this.downloadQuarterlyExport$.next(null);
  }

  onPrintPdf() {
    this.printPdf$.next(this.router.url.toLowerCase());
  }

  onCreateExcel(exportType: ExcelExportType) {
    this.store.dispatch(FilterServiceActions.exportToExcel({ exportType }));
    this.exportToExcel$.next({ url: this.router.url.toLowerCase(), exportType });
  }

  onGoalForecasting() {
    this.showGoalForecastingTable$.next(true);
  }

  onRefresh() {
    this.refresh$.next(null);
    this.store.dispatch(FilterServiceActions.refreshed());
  }

  onShowCompletedConversations() {
    this.store.dispatch(FilterServiceActions.showCompletedConversationsToggled());
  }

  /** @deprecated and replaced by ZoomScale model. Can be removed after GA of MAC */
  zoomIn() {
    this.zoomScale$.next(this.zoomScale$.value + 0.05);
  }

  /** @deprecated and replaced by ZoomScale model. Can be removed after GA of MAC */
  zoomOut() {
    this.zoomScale$.value <= 0.1 ? this.zoomScale$.next(0.1) : this.zoomScale$.next(this.zoomScale$.value - 0.05);
  }

  /** @deprecated and replaced by ZoomScale model. Can be removed after GA of MAC */
  zoomReset() {
    if (this.zoomScale$.value !== 1) this.zoomScale$.next(1);
  }

  onSettings() {
    this.settings$.next(this.router.url.toLowerCase());
  }

  // Dropdowns ==================================
  setIsFetchingData(value: boolean) {
    this.isFetchingDataBS$.next(value);
    this.store.dispatch(FilterServiceActions.isFetchingSet({ isFetchingData: value }));
  }

  setUserId(id: string) {
    this.selectedUserId$.next(id);
    this.store.dispatch(FilterServiceActions.selectedUserChanged({ selectedUserId: id }));
  }

  setRockStatusFilter(value: RockStatusCode | 'all') {
    this.selectedRockStatusCode$.next(value);
  }

  setMasteryStatusFilter(value: MasteryFilterStatus) {
    this.selectedMasteryStatus$.next(value);
  }

  setTeamId(id: string) {
    this.store.dispatch(TeamListStateActions.changeFilterBarTeam({ id }));

    // ToDo: this is only used in one place and should probably be refactored
    this.store.dispatch(FilterServiceActions.teamIdChanged({ selectedTeamId: id }));
  }

  setTeam(team: Team) {
    this.store.dispatch(TeamListStateActions.getSelectedTeam({ id: team._id }));
  }

  setOwnerId(id: string) {
    this.selectedOwnerId$.next(id);
  }

  setUserStatusId(id: DirectoryUserStatus) {
    this.selectedUserStatusId$.next(id);
  }
}
