import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ReferralActions = createActionGroup({
  source: 'Impact',
  events: {
    'Get Referral JWT': emptyProps(),
    'Get Referral JWT Success': props<{ jwt: object }>(),
    'Get Referral JWT Failure': props<{ error: unknown; customMessage: string }>(),
    'User Has Referral': props<{ referralCode: string }>(),
    'User Has No Referral': emptyProps(),
  },
});
