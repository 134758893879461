import { createActionGroup, props } from '@ngrx/store';

import { AttachmentEvent } from '@ninety/ui/legacy/shared/index';
import { Conversation } from '@ninety/ui/legacy/shared/models/feedback/conversation';
import { ConversationRealTimeMessage } from '@ninety/ui/legacy/shared/models/feedback/conversation-real-time.models';

export const ConversationRealTimeEffectActions = createActionGroup({
  source: 'ConversationRealTimeEffectActions',
  events: {
    'Send Message': props<{ channelId: string; message: ConversationRealTimeMessage }>(),
    'Show Button': props<Pick<Conversation, '_id'>>(),
    'Meeting Started': props<Pick<Conversation, '_id'>>(),
    'Meeting Ended': props<Pick<Conversation, '_id'>>(),
    'Attachment Updated': props<{ attachment: Omit<AttachmentEvent, 'parent'> }>(),
  },
});
