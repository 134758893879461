import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, identity, map, tap } from 'rxjs';

import { BreakpointObserverConfig, ElementBreakpointRecord } from './element-resize-aware.model';
import { BreakpointObserverConfigFactory } from './services/breakpoint-observer-config-factory.service';
import { ResizeObserverService } from './services/resize-observer.service';

@Directive({
  selector: '[ninetyElementResizeAware]',
  standalone: true,
})
/**
 * A directive that provides a simple API for observing element resize events. It uses the ResizeObserver API under the hood.
 * Caution: When using this in components which are nested, be sure that the child component lets the parent respond to resize before it does.
 * Not doing so may cause resize loops.
 */
export class ElementResizeAwareDirective implements OnDestroy {
  private static _nextId = 1;
  private static getId = () => ElementResizeAwareDirective._nextId++;

  private readonly id: number;
  private readonly _event$: Subject<ResizeObserverEntry>;

  constructor(
    private host: ElementRef,
    private resizeObserverService: ResizeObserverService,
    private optsFactory: BreakpointObserverConfigFactory
  ) {
    this.id = ElementResizeAwareDirective.getId();
    const onResize = (entry: ResizeObserverEntry) => this._event$.next(entry);
    this.resizeObserverService.observe(this.id, this.host, onResize);

    this._event$ = new Subject();
  }

  ngOnDestroy(): void {
    this.resizeObserverService.unobserve(this.id);
  }

  /**
   * Emits the current width of the element. Optimized for performance and common use cases. Width is based on the
   * `contentRect`, one of a few available options. See MDN for more details.
   *
   * @see [Resize Observer - MDN](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#specifications)
   * @see [Resize Observer Entry - MDN](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry)
   * @see BreakpointObserverConfig
   */
  getWidthObserver(opts?: Partial<BreakpointObserverConfig>): Observable<number> {
    const completeOpts = this.optsFactory.merge(opts);

    return this._event$.pipe(
      map(event => event.contentRect.width),
      completeOpts.disableDebounce ? identity : debounceTime(completeOpts.debounceTime),
      completeOpts.emitOnZeroWidth ? identity : filter(w => w > 0),
      distinctUntilChanged()
    );
  }

  /**
   * Emits true when the width of the element is strictly less than minWidth. Modeled after the behavior of
   * BreakpointObserver.
   */
  getBreakpointObserver(minWidth: number, opts?: Partial<BreakpointObserverConfig>): Observable<boolean> {
    return this.getWidthObserver(opts).pipe(
      map(width => width < minWidth),
      distinctUntilChanged()
    );
  }

  /** Transforms a list of minWidths into a record of minWidth -> boolean. */
  getManyBreakpointObservers(
    minWidths: number[],
    opts?: Partial<BreakpointObserverConfig>
  ): Observable<ElementBreakpointRecord> {
    minWidths = minWidths.sort();

    return this.getWidthObserver(opts).pipe(
      map(currentWidth =>
        minWidths.reduce((acc, minWidth) => {
          acc[minWidth] = currentWidth < minWidth;
          return acc;
        }, {})
      ),
      // `distinctUntilChanged` uses reference equality, we want object equality. Prefer this optimized method over
      // lodash.isEqual.
      distinctUntilChanged((a, b) => {
        for (const widthKey of minWidths) {
          if (a[widthKey] !== b[widthKey]) return false;
        }

        return true;
      })
    );
  }

  /** Emits dimension changes as width/height, excluding 0-width instances */
  getDimensionsChangeObserver(
    opts?: Partial<BreakpointObserverConfig>
  ): Observable<Pick<DOMRectReadOnly, 'width' | 'height'>> {
    const completeOpts = this.optsFactory.merge(opts);

    return this._event$.pipe(
      completeOpts.disableDebounce ? identity : debounceTime(completeOpts.debounceTime),
      completeOpts.emitOnZeroWidth ? identity : filter(({ contentRect }) => contentRect.width > 0),
      distinctUntilChanged((prev, curr) => {
        return prev.contentRect.width === curr.contentRect.width && prev.contentRect.height === curr.contentRect.height;
      }),
      map(({ contentRect: { width, height } }) => {
        return { width, height };
      })
    );
  }
}
