import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { User } from '../../../_shared';

import { UserListModel } from './api/user-list.model';

export const UserListStateActions = createActionGroup({
  source: 'User List',
  events: {
    'Get User List': emptyProps(),
    'Get User List Success': props<{ users: UserListModel[] }>(),
    'Set Logged In User': props<{ loggedInUser: User }>(),
  },
});
