<div class="comments-container">
  <h4 class="muted terra-body-bold">Comments</h4>
  <mat-list class="ninety-list" *ngIf="item?.comments?.length">
    <mat-list-item class="list-item" *ngFor="let comment of item.comments; let i = index">
      <div class="comment-list-item">
        <div class="user-input">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(comment.userId)) | ngrxPush as avatar"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL" />
          <mat-form-field>
            <textarea
              attr.data-cy="item-comments_{{ comment.text }}"
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              aria-label="comment"
              [(ngModel)]="comment.text"
              (focusout)="onCommentFocusOut(comment)"
              [readonly]="comment.userId !== currentUserId"></textarea>
          </mat-form-field>
          <button
            attr.data-cy="item-comments_delete-{{ comment.text }}"
            mat-icon-button
            (click)="onDeleteComment(i)"
            *ngIf="comment.userId === currentUserId"
            matTooltip="Delete Comment"
            matTooltipPosition="above"
            aria-label="Delete Comment">
            <terra-icon icon="x" />
          </button>
        </div>
        <div class="comment-date">
          <small class="muted">
            <span *ngIf="comment.editedDate"
              >(edited) {{ comment.editedDate | date : 'medium' : undefined : locale }}</span
            >
            <span *ngIf="!comment.editedDate">{{ comment.createdDate | date : 'medium' : undefined : locale }}</span>
          </small>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
  <div class="new-comment" *ngIf="canAddComment">
    <mat-form-field>
      <mat-label>Add comment</mat-label>
      <textarea
        data-cy="item-comments_comment-input-text"
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        aria-label="new comment"
        [(ngModel)]="newCommentText"
        (keyup.enter)="onAddComment()"
        [readonly]="readOnly"></textarea>
    </mat-form-field>
    <button
      data-cy="item-comments_add-comment-button"
      mat-raised-button
      (click)="onAddComment()"
      color="accent"
      [disabled]="!newCommentText">
      COMMENT
    </button>
  </div>
</div>
