import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { find, remove } from 'lodash';
import Pubnub from 'pubnub';
import {
  EMPTY,
  Observable,
  Observer,
  Subject,
  Subscription,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  switchMap,
  tap,
} from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

import { CompanyUser } from '../../_shared/models/company/company-user';
import { Issue } from '../../_shared/models/issues/issue';
import { Headline } from '../../_shared/models/meetings/headline';
import type { RealtimeMessage, ReceivedRealtimeMessage } from '../../_shared/models/meetings/realtime-message';
import { Rock } from '../../_shared/models/rocks/rock';
import { Todo } from '../../_shared/models/todos/todo';
import { Vto } from '../../_shared/models/vto/vto';
import { RealTimeActions } from '../../_state/app-global/real-time/real-time.actions';

import { StateService } from './state.service';

export interface ChannelSubscription {
  channelId: string;
  withPresence: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private pubnubApi = '/api/v4/Pubnub';
  presenceChanged$ = new Subject();
  currentPresenceInfo$ = new Subject();
  /** deprecated, use RealTimeActions.messageReceived */
  messageReceived$ = new Subject<ReceivedRealtimeMessage>();
  subscriptions = new Subscription();
  pubnub: Pubnub;
  /** deprecating soon, work still in progress */
  channels: ChannelSubscription[] = [];
  listeners: Pubnub.ListenerParameters;
  validChannelPrefixes = ['issue', 'todo', 'rock', 'headline', 'measurable', 'vto', 'linkeditems'];

  private currentCompanyUser: CompanyUser = null;

  constructor(private stateService: StateService, private http: HttpClient, private store: Store) {
    this.subscriptions.add(
      this.stateService.currentCompanyUser$
        .pipe(
          filter(companyUser => !companyUser), //e.g. logout
          tap(() => {
            this.destroyPubnub();
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.stateService.currentCompanyUser$
        .pipe(
          filter(user => !!user?.company?._id),
          distinctUntilKeyChanged('_id'),
          tap(currentCompanyUser => {
            if (this.currentCompanyUser) {
              this.destroyPubnub();
            }
            this.currentCompanyUser = currentCompanyUser;
            this.initPubnub(currentCompanyUser._id);
          }),
          switchMap(user => this.subscribeToChannel(user.company._id, false, user._id))
        )
        .subscribe()
    );
  }

  getAuthToken(userId: string): Observable<string> {
    return this.http
      .post<string>(`${this.pubnubApi}/${userId}/Grant`, {
        channels: this.channels,
      })
      .pipe(tap(response => this.pubnub.setToken(response)));
  }

  /**
   * Get auth token for a specific channel. Used by meetings to check meeting presence
   * on a channel the user isn't subscribed to.
   */
  authorizeChannelId(userId: string, channelId: string): Observable<string> {
    return this.http
      .post<string>(`${this.pubnubApi}/${userId}/Grant`, {
        channels: [...this.channels, { channelId, withPresence: true }],
      })
      .pipe(tap(response => this.pubnub.setToken(response)));
  }

  getPresenceByChannelId(channelId: string): Observable<string[]> {
    return from(
      this.pubnub.hereNow({
        channels: [channelId],
        includeUUIDs: true,
      })
    ).pipe(map(response => response.channels[channelId]?.occupants.map(o => o.uuid) ?? []));
  }

  subscribeToMeetingChannels(
    meetingId: string,
    teamId: string,
    userId = this.stateService.currentCompanyUser$.value?._id
  ): Observable<string> {
    if (!find(this.channels, { channelId: meetingId }) && userId) {
      this.channels.push({ channelId: meetingId, withPresence: true });
      this.store.dispatch(RealTimeActions.addChannel({ channelId: meetingId, withPresence: true }));
    }

    this.validChannelPrefixes.map(p => {
      const channelId = `${p}-${this.stateService.companyId}-${teamId}`;
      if (!find(this.channels, { channelId: channelId }) && userId) {
        this.channels.push({ channelId: channelId, withPresence: false });
        this.store.dispatch(RealTimeActions.addChannel({ channelId, withPresence: false }));
      }
    });

    return this.getAuthToken(userId).pipe(
      tap(_ => {
        // Subscribe to presence channels
        this.channels
          .filter(c => c.withPresence)
          .map(channel => {
            this.pubnub.subscribe({
              channels: [channel.channelId],
              withPresence: channel.withPresence,
            });
          });
        // Subscribe to non-presence channels
        this.pubnub.subscribe({
          channels: this.channels.filter(c => !c.withPresence).map(c => c.channelId),
          withPresence: false,
        });

        //Notify other users that you've joined the meeting.
        this.getCurrentPresence();
      })
    );
  }

  unsubscribeFromMeetingChannels(meetingId: string, teamId: string): void {
    this.unsubscribe(meetingId);
    this.validChannelPrefixes.map(p => {
      const channelId = `${p}-${this.stateService.companyId}-${teamId}`;
      this.unsubscribe(channelId);
    });
  }

  subscribeToChannel(
    channelId: string,
    withPresence = false,
    userId = this.stateService.currentCompanyUser$.value?._id
  ): Observable<string> {
    if (!find(this.channels, { channelId: channelId }) && userId) {
      this.channels.push({ channelId: channelId, withPresence: withPresence });
      this.store.dispatch(RealTimeActions.addChannel({ channelId, withPresence }));

      return this.getAuthToken(userId).pipe(
        tap(_ => {
          this.pubnub.subscribe({
            channels: [channelId],
            withPresence: withPresence,
          });

          if (withPresence) {
            this.getCurrentPresence();
          }
        })
      );
    } else {
      return EMPTY;
    }
  }

  unsubscribe(channelId: string): void {
    remove(this.channels, channel => channel.channelId == channelId);
    this.store.dispatch(RealTimeActions.removeChannel({ channelId }));
    this.pubnub.unsubscribe({ channels: [channelId] });
  }

  initPubnub(userId: string) {
    this.pubnub = new Pubnub({
      publishKey: environment.pubnubPublishKey,
      subscribeKey: environment.pubnubSubscribeKey,
      uuid: userId,
      presenceTimeout: environment.pubnubPresenceExpiration,
    });

    this.listeners = {
      status: (status: Pubnub.StatusEvent) => {
        if (status.category === Pubnub.CATEGORIES.PNBadRequestCategory) {
          console.error(status);
        } else if (status.category === Pubnub.CATEGORIES.PNAccessDeniedCategory) {
          this.resubscribeToChannels().subscribe();
        }
      },
      message: (message: Pubnub.MessageEvent) => {
        if (message?.message?.emitterUserId !== this.stateService.currentUser._id) {
          this.messageReceived$.next(message.message);
          this.store.dispatch(
            RealTimeActions.messageReceived({
              channelId: message.channel,
              message: message.message,
              emitterId: message.publisher,
            })
          );
        }
      },
      presence: (presence: Pubnub.PresenceEvent) => {
        this.presenceChanged$.next(presence);

        this.store.dispatch(
          RealTimeActions.presenceChanged({
            userId: presence.uuid,
            channelId: presence.channel,
            action: presence.action,
          })
        );
      },
    };

    this.pubnub.addListener(this.listeners);
  }

  resubscribeToChannels() {
    return this.getAuthToken(this.stateService.currentCompanyUser$.value?._id).pipe(
      tap(_ => {
        const presenceChannels = this.channels.filter(c => c.withPresence).map(c => c.channelId);

        this.pubnub.subscribe({
          channels: presenceChannels,
          withPresence: true,
        });

        const nonPresenceChannels = this.channels.filter(c => !c.withPresence).map(c => c.channelId);

        this.pubnub.subscribe({
          channels: nonPresenceChannels,
          withPresence: false,
        });

        this.getCurrentPresence();
      })
    );
  }

  destroyPubnub(): void {
    if (this.listeners) {
      this.pubnub?.removeListener(this.listeners);
      this.listeners = undefined;
    }
    this.pubnub?.unsubscribeAll();
    this.channels = [];
    this.store.dispatch(RealTimeActions.resetChannels());
  }

  /***
   * Accomplishes the same as sendMessage() but without message size checks
   * and the only-sometimes-functional observable. Do not use for messages over 32KB.
   */
  publishMessage(userId: string, channelId: string, message: RealtimeMessage): void {
    this.pubnub.publish({
      channel: channelId,
      sendByPost: true,
      message: { ...message, emitterUserId: userId },
    });
  }

  /** deprecated, use channelService sendMessage action*/
  sendMessage(channelId: string, message: RealtimeMessage): Observable<any> {
    return new Observable((observer: Observer<void>) => {
      const size = this.calculatePayloadSize(channelId, message.document);
      if (size < 32768) {
        from(
          this.pubnub.publish({
            channel: channelId,
            sendByPost: true,
            message: { ...message, emitterUserId: this.stateService.currentUser._id },
          })
        ).pipe(tap(() => observer.next(null)));
      } else {
        if (message.messageType !== 'change-stage') {
          const msg = {
            messageType: 'fetch-object',
            originalMessageType: message.messageType,
            emitterUserId: this.stateService.currentUser._id,
            document: {},
          };
          if (
            message.messageType !== 'meeting' &&
            message.messageType !== 'worked-issues' &&
            message.messageType !== 'done-headlines' &&
            message.messageType !== 'cascading-headlines'
          ) {
            const oid = (message.document as Todo | Headline | Rock | Issue | Vto)._id;
            msg.document = {
              _id: oid,
            };
          }

          from(
            this.pubnub.publish({
              channel: channelId,
              sendByPost: true,
              message: msg,
            })
          ).pipe(tap(() => observer.next(null)));
        }
      }
    });
  }

  getCurrentPresence() {
    const presenceChannels = this.channels.filter(c => c.withPresence).map(c => c.channelId);

    if (presenceChannels.length) {
      presenceChannels.forEach(channelId => {
        this.pubnub.hereNow(
          {
            channels: [channelId],
            includeUUIDs: true,
          },
          (status, response) => {
            if (!status.error) {
              const channel = response.channels[channelId];
              this.currentPresenceInfo$.next({
                channel: channelId,
                occupants: channel.occupants.map(o => o.uuid),
              });
            }
          }
        );
      });
    }
  }

  calculatePayloadSize(channel: string, message: any): number {
    return encodeURIComponent(channel + JSON.stringify(message)).length + 100;
  }
}
