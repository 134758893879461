import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import { MilestoneService } from '../milestone.service';

@Component({
  selector: 'ninety-milestone-create-dialog',
  templateUrl: './milestone-create-dialog.component.html',
  styleUrls: ['./milestone-create-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, MaterialMdcModule, FormsModule, ButtonComponent],
})
export class MilestoneCreateDialogComponent implements OnDestroy {
  milestone: Milestone;
  spinner = false;

  constructor(
    public dialogRef: MatDialogRef<MilestoneCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { rock: Rock },
    private milestoneService: MilestoneService,
    public stateService: StateService
  ) {
    this.milestone = new Milestone(this.data.rock);
  }

  ngOnDestroy(): void {
    (document.activeElement as HTMLButtonElement).blur();
  }

  closeMilestoneDialog() {
    this.dialogRef.close();
  }

  onSelectUser(user: User) {
    this.milestone.ownedByUserId = user._id;
  }

  milestoneIsValid(): boolean {
    return this.milestone.title && this.milestone.title.length > 0 && !!this.milestone.dueDate;
  }

  addMilestoneToRock() {
    this.spinner = true;
    this.milestone.teamId = (this.data.rock as Rock).teamId;

    this.milestoneService.createMilestone(this.milestone).subscribe({
      next: (milestone: Milestone) => {
        this.dialogRef.close(milestone);
        this.spinner = false;
      },
      error: () => (this.spinner = false),
    });
  }
}
