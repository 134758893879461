import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTable } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { ImportUserFormArray } from '@ninety/data-import/models/import-user-form';
import { UserImportFormService } from '@ninety/data-import/services/user-import-form.service';

@Component({
  selector: 'ninety-user-import-preview-table',
  templateUrl: './user-import-preview-table.component.html',
  styleUrls: ['./user-import-preview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserImportPreviewTableComponent implements OnInit {
  form: ImportUserFormArray;
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'teams', 'role', 'delete'];

  @ViewChild(MatTable) table: MatTable<ImportUserFormArray>;

  constructor(
    private store: Store,
    private userImportFormService: UserImportFormService,
    private destroyRef: DestroyRef
  ) {
    this.form = this.userImportFormService.form;
  }

  ngOnInit() {
    this.userImportFormService.formChanged$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.table.renderRows())
      )
      .subscribe();
  }

  deleteUser(index: number) {
    this.store.dispatch(DataImportActions.deleteUser({ index }));
  }
}
