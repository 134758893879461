<div class="phase-details">
  <div class="top-content">
    <div class="toolbar">
      <div class="header">
        <ninety-button flat icon class="close-btn" (click)="goBack()">
          <terra-icon size="20" icon="caret-left" />
        </ninety-button>
        <div class="title">{{ phase.title }}</div>
        <ninety-button flat icon class="close-btn" (click)="close()">
          <terra-icon size="20" icon="x" />
        </ninety-button>
      </div>
      <div class="steps-completed">{{ phase.stepsCompleted }} of {{ phase.steps?.length }} steps complete</div>
    </div>

    <div class="description" [innerHTML]="phase.details_description"></div>
  </div>

  <div class="steps">
    <ninety-phase-step *ngFor="let step of phase.steps" [step]="step" [mazChatEnabled]="mazChatEnabled" />
  </div>
</div>
