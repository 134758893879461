import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { merge, startWith, Subject, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../models';
import { TerraIconComponent, TerraIconModule } from '../terra-icon';

import { TerraOptionIconTemplateRefDirective } from './terra-option-slots.directive';
import { TerraOptionBase, TERRA_OPTION_BASE } from './terra-option.interface';

let iconOptionUniqueId = 1;
@Component({
  selector: 'terra-icon-option',
  standalone: true,
  exportAs: 'terraIconOption',
  imports: [CommonModule, TerraIconModule],
  templateUrl: './terra-icon-option.component.html',
  styleUrls: ['./terra-icon-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TERRA_OPTION_BASE,
      useExisting: TerraIconOptionComponent,
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.aria-label]': 'null',
  },
})
export class TerraIconOptionComponent implements TerraOptionBase, OnDestroy, AfterContentInit {
  /** @ignore */
  @ContentChild(TerraOptionIconTemplateRefDirective, { read: TemplateRef, static: true })
  _iconTemplate: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TemplateRef<any> | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChildren(TerraIconComponent) private _icons!: QueryList<TerraIconComponent<any>>;

  /** Ouput when an option has been selected */
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() select = new EventEmitter<any>();

  private _destroyed$ = new Subject<void>();

  protected _highlighted = false;

  private _optionId = `terra-icon-option-${iconOptionUniqueId++}`;
  get optionId() {
    return this._optionId;
  }

  /**
   * Selected state of the option
   * @default false
   */
  get selected(): boolean {
    return this._selected;
  }
  set selected(value: boolean) {
    this._selected = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _selected = false;

  /**
   * Disabled state of the select
   * @default false
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Set the value of the option, required
   *
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ required: true }) get value(): any {
    return this._value;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(value: any) {
    this._value = value;
    this._changeDetectorRef.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _value: any;

  /**
   * Aria-label for the component, required because only icons are allowed
   * @required
   */
  @Input({ alias: 'aria-label', required: true }) ariaLabel!: string;

  constructor(private readonly _elementRef: ElementRef, private readonly _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    const iconEnforceSize$ = this._icons.changes.pipe(
      startWith(this._icons),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((icons: QueryList<TerraIconComponent<any>>) => {
        icons.map(icon => {
          icon.size = 20;
        });
        this._changeDetectorRef.markForCheck();
      })
    );

    merge(iconEnforceSize$).pipe(takeUntil(this._destroyed$)).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /** @ignore */
  public _onClick($event: Event): void {
    $event.stopImmediatePropagation(); // Stops native click from continuing to propagate
    !this.disabled && this.select.emit(this._value);
  }

  // Below needed as part of KeyManager interface

  /** @ignore */
  getLabel(): string {
    return this.ariaLabel;
  }

  /** @ignore */
  setActiveStyles() {
    this._highlighted = true;
    this._changeDetectorRef.markForCheck();
  }

  /** @ignore */
  setInactiveStyles(): void {
    this._highlighted = false;
    this._changeDetectorRef.markForCheck();
  }
}
