import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraSearchInputComponent } from './terra-search-input.component';

@NgModule({
  imports: [CommonModule, TerraSearchInputComponent],
  exports: [TerraSearchInputComponent],
})
export class TerraSearchInputModule {}
