import { TerraIconVariant } from '../terra-icon';

import { TerraBadgeIcon } from './terra-badge.component';

export interface TerraBadgeIconModel {
  name: TerraBadgeIcon;
  variant: TerraIconVariant;
}

export const TERRA_BADGE_ICONS: TerraBadgeIconModel[] = [
  {
    name: 'check',
    variant: 'bold',
  },
  {
    name: 'edit',
    variant: 'bold',
  },
  {
    name: 'clock-hands',
    variant: 'bold',
  },
  {
    name: 'question-mark',
    variant: 'bold',
  },
  {
    name: 'exclamation-mark',
    variant: 'bold',
  },
  {
    name: 'x',
    variant: 'bold',
  },
];
