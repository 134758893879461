import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ninetyPopoverOrigin]',
  exportAs: 'ninetyPopoverOrigin',
  standalone: true,
})
export class PopoverOriginDirective extends CdkOverlayOrigin {
  constructor(public elementRef: ElementRef, private renderer: Renderer2) {
    super(elementRef);
  }
}
