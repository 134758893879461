import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, Observable } from 'rxjs';

import { environment } from '@ninety/ui/web/environments';

import { LoggingActions } from '../../_state/app-logging/app-logging.actions';

import { ErrorService } from './error.service';

declare global {
  interface Window {
    impact: any;
    ire: any;
    impactToken: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  private isLoaded$ = new BehaviorSubject<boolean>(false);
  private renderer = this.rendererFactory.createRenderer(this.document.head, null);
  private referralApi = '/api/v4/referral';

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
    private http: HttpClient,
    private errorService: ErrorService
  ) {
    this.loadScript();
  }
  loaded(): Observable<boolean> {
    return this.isLoaded$.asObservable();
  }

  private loadScript(): void {
    if (window.hasOwnProperty('impact')) {
      this.isLoaded$.next(true);
      return;
    }

    const script: HTMLScriptElement = this.renderer.createElement('script');
    script.onload = () => this.isLoaded$.next(true);
    script.src = `https://utt.impactcdn.com/A5363308-38ea-4a1d-8162-6307f751fa991.js`;
    this.renderer.appendChild(this.document.head, script);
  }

  public getReferralJwt(): Observable<object> {
    return this.http
      .get(`${this.referralApi}/generateReferralJwt`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Unable to retrieve referral JWT token.`)));
  }

  public getReferralCode(): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        window.impact
          .api()
          .referralCookie()
          .then(function (response) {
            const element = document.getElementById('impact_referredby_code');
            if (element !== null) {
              (element as HTMLInputElement).value = response.codes[environment.IMPACT_REFERRAL_PROGRAM_ID];
            }
            resolve(response.codes[environment.IMPACT_REFERRAL_PROGRAM_ID]);
          });
      } catch (e) {
        this.store.dispatch(
          LoggingActions.error({
            log: {
              message: '[Impact Referral] Error retrieving referral code',
              error: e,
              data: {
                impact: window.impact ?? 'not loaded',
              },
            },
          })
        );
        reject(e);
      }
    });
  }
}
