import { Pipe, PipeTransform } from '@angular/core';
import { FuseResultMatch } from 'fuse.js';

@Pipe({
  name: 'boldSearchResults',
  standalone: true,
})
export class BoldSearchResultsPipe implements PipeTransform {
  transform(value: string, matches: readonly FuseResultMatch[]) {
    const allMatchIndices = matches
      .map(match => match.indices)
      .flat()
      // Reverse sort so we can replace from the end of the string
      // Otherwise the indices would be offset by each replace and we'd have to recalculate them
      .sort((a, b) => b[0] - a[0]);

    const newValue = allMatchIndices.reduce((acc: string, match) => {
      let [start, end] = match;
      // Failsafe in case Fuse returns the indices in reverse order. Not sure this will happen.
      if (start > end) {
        [end, start] = match;
      }

      /*
        Increment end by 1 to account for string.slice behavior with end index from Fuse.
        Given a string `testing` and a search string `test`, the indices will be [[0, 3]].
        String.slice will return the string from 0 to 3, but not including 3, so we increment.
        Without incrementing, we get `<strong>tes</strong>ting` instead of `<strong>test</strong>ing`.
      */
      end += 1;
      return `${acc.slice(0, start)}<strong>${acc.slice(start, end)}</strong>${acc.slice(end)}`;
    }, value);
    return newValue;
  }
}
