import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { PersonService } from '../../../_core/services/person.service';
import { NotificationActions } from '../notifications/notification.actions';

import { CurrentPersonStateActions } from './current-person.actions';

@Injectable()
export class CurrentPersonEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly personService: PersonService
  ) {}

  updateCurrentPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentPersonStateActions.updateCurrentPerson),
      switchMap(({ update }) =>
        this.personService.updatePerson(update).pipe(
          map(() => CurrentPersonStateActions.updateCurrentPersonSuccess({ update })),
          catchError((error: unknown) => of(NotificationActions.notifyError({ error, message: 'Failed to update.' })))
        )
      )
    )
  );
}
