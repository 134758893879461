import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';

import { TerraAvatarModule, TerraIconModule, TerraIconName, TerraIconVariant } from '@ninety/terra';

import { NinetyTooltipDirective } from '../../../_directives/ninety-tooltip.directive';
import { Theme } from '../../../_shared/models/enums/theme';

@Component({
  selector: 'ninety-navigation-menu-item',
  templateUrl: './navigation-menu-item.component.html',
  styleUrls: ['./navigation-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, TerraAvatarModule, TippyDirective, NinetyTooltipDirective, TerraIconModule],
})
export class NavigationMenuItemComponent implements OnChanges {
  /**
   * item title
   */
  @Input() title: string;
  /**
   * name of the Terra icon
   */
  @Input() iconKey: TerraIconName;

  /**
   * variant for the icon
   */
  @Input() iconVariant: TerraIconVariant = 'regular';

  /**
   * url to the image we wish to show instead of the icon
   */
  @Input() pictureURL: string;
  /**
   * user initials to show if we don't have a picture
   */
  @Input() userInitials: string;
  /**
   * indicates whether or not the menu is expanded. If expanded, it shows the title. If not, it only shows the icon
   */
  @Input() isExpanded = true;
  /**
   * Override whether to show or hide the tooltip
   */
  @Input() showTooltip = true;
  /**
   * designates an optional second icon on the right side of the item to show when in expanded mode
   */
  @Input() secondaryIcon: TerraIconName;

  /**
   * variant for the secondary icon
   */
  @Input() secondaryIconVariant: TerraIconVariant;

  /**
   * indicates the theme we want to use to color this item
   */
  @Input() theme = Theme.LIGHT;
  /**
   * url to direct to on click
   */
  @Input() href: string;
  /**
   * set optional CSS class to apply to the secondary icon
   */
  @Input() secondaryIconClass: string;
  /**
   * if set to true, menu item will be 56 pixels tall instead of 36
   */
  @Input() isTall = false;
  /**
   * optional name of CSS class to be applied to the title span
   */
  @Input() titleClass: string;
  /**
   * if disabled is true, show different styles and don't allow navClick
   */
  @Input() disabled = false;

  readonly Theme = Theme;

  public titleClasses: string;

  // TODO: this sets theme to light if user does not have a theme. This check can be deleted once we have default themes on all users.
  ngOnChanges(): void {
    if (!this.theme) {
      this.theme = Theme.LIGHT;
    }

    this.titleClasses =
      'terra-body-squashed' +
      (this.secondaryIcon ? ' short-title' : '') +
      (this.titleClass ? ' ' + this.titleClass : '');
  }

  navClick() {
    if (this.href && !this.disabled) {
      window.location.href = this.href;
    }
  }
}
