<div *ngIf="form" class="preview-users-table-wrapper">
  <table [dataSource]="form.controls" class="preview-users-table" mat-table>
    <ng-container matColumnDef="firstName">
      <th *matHeaderCellDef mat-header-cell>First&nbsp;Name</th>
      <td *matCellDef="let user" [formGroup]="user" mat-cell>
        <div [class.error]="user.get('firstName').errors" class="preview-cell">
          <ninety-inline-editable-input formControlName="firstName" placeholder="First name..." />
          <ninety-error-overlay />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th *matHeaderCellDef mat-header-cell>Last&nbsp;Name</th>
      <td *matCellDef="let user" [formGroup]="user" mat-cell>
        <div [class.error]="user.get('lastName').errors" class="preview-cell">
          <ninety-inline-editable-input formControlName="lastName" placeholder="Last name..." />
          <ninety-error-overlay />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th *matHeaderCellDef mat-header-cell>Email</th>
      <td *matCellDef="let user" [formGroup]="user" mat-cell>
        <div [class.error]="user.get('email').errors" class="preview-cell">
          <ninety-inline-editable-input formControlName="email" placeholder="Email..." />
          <ninety-error-overlay />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="teams">
      <th *matHeaderCellDef mat-header-cell>Team(s)</th>
      <td *matCellDef="let user; let i = index" [formGroup]="user" mat-cell>
        <div
          [ngClass]="{
          error: user.get('selectedTeams').errors,
          'non-matched-names': user.get('nonMatchingTeamNames').value,
        }"
          class="preview-cell">
          <ninety-teams-cell formControlName="selectedTeams" id="data-import-teams-cell-{{ i }}" />
          <ninety-error-overlay />
          <!--<div *ngFor="let name of form.get('nonMatchingTeamNames').value" class="team-name">{{ name }}</div>-->
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th *matHeaderCellDef mat-header-cell>
        Role
        <ninety-role-info-overlay />
      </th>
      <td *matCellDef="let user; let i = index" [formGroup]="user" mat-cell>
        <div [class.error]="user.get('role').errors" class="preview-cell">
          <ninety-role-cell formControlName="role" />
          <ninety-error-overlay />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let i = index" mat-cell>
        <ninety-button (click)="deleteUser(i)" compact flat icon negative>
          <terra-icon icon="delete" size="24" />
        </ninety-button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; let i = index; columns: displayedColumns" mat-row></tr>
  </table>
</div>
