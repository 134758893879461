export enum NinetyLayoutType {
  normal = 'normal',
  meeting = 'meeting',
  conversation = 'conversation',
  noLeftNav = 'no-left-nav',
}

export interface PageStateModel {
  layoutType: NinetyLayoutType;
}

export const initialPageState: PageStateModel = {
  layoutType: NinetyLayoutType.normal,
};
