import { EmailType } from '../directory/email-type';

export class Email {
  email?: string;
  typeCode?: EmailType;

  constructor(email: string, typeCode: EmailType) {
    this.email = email;
    this.typeCode = typeCode;
  }
}
