import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';

import { GridLayoutSelectors } from '@ninety/layouts/grid-layout/_state/grid-layout-state.selectors';
import { ElementResizeAwareDirective } from '@ninety/ui/legacy/shared/directives/element-resize-aware/element-resize-aware.directive';

import { GridLayoutActions } from '../../_state/grid-layout-state.actions';
import { GridItemTemplateDefaultDirective } from '../../directives/grid-item-template-default.directive';
import { GridItemTemplateDirective } from '../../directives/grid-item-template.directive';
import { GridTemplate } from '../../models/grid-template.model';
import { GridOptions } from '../../models/grid.options';
import { NgGridStackService } from '../../services/ng-grid-stack.service';

/**
 * Renders a grid layout using GridStack
 *
 * Positioning is based on an X-Y cartesian plane, but inverted (what the heck is that called again....)
 *
 *  x > 0---1---2 (x axis)
 * y  0 A   A   B
 * v  |
 *    1 C   D   B
 *
 * In the case above, A, B, C, D are different components with the following sizes
 *
 *   |w|h|x|y
 * A: 2 1 0 0
 * B: 1 2 2 0
 * C: 1 1 0 1
 * D: 1 1 1 1
 *
 * The component supports rendering a single one of its templates as a full-width singleton. This is especially
 * useful for when the grid is opposite the detail view. In those cases, {@link NgGridStackService} will render the
 * template at the #whereToRenderOnlyTemplate element and hide the grid. Note, its expected that the template is not
 * destroyed when moving between the grid and the singleton. See {@link NgGridStackService#setSingleton} and
 * {@link NgGridStackService#clearSingleton}.
 *
 * This component supports a Ninety implementation of a one column mode. This is useful for small viewports. See
 * {@link NgGridStackService#enterOneColumMode} and {@link NgGridStackService#exitOneColumnMode}.
 *
 * TODO internalize the 1 column alert to this component. Do not show when rendering the singleton.
 */
@Component({
  selector: 'ninety-grid-layout',
  styles: [], // see src/styles/grid.scss
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <!--
      Its important that the ninetyElementResizeAware is on an element who always has a display value != none.
      Otherwise, it emits events when switching between the singleton and grid view - a no no.
    -->
    <div ninetyElementResizeAware>
      <div class="grid-stack" [class.hide-instead-of-ngIf]="(showGrid$ | async) === false">
        <ng-container #whereToRenderGrid>
          <ng-content></ng-content>
        </ng-container>
      </div>

      <div
        class="grid-alternate"
        [ngClass]="{
          singleton: (isSingleton$ | async) === true,
          multiple: (isOneColumn$ | async) === true,
          'hide-instead-of-ngIf': (showGridAlternate$ | async) === false
        }">
        <ng-container #alternateTemplate></ng-container>
      </div>
    </div>
  `,
})
export class GridLayoutComponent implements AfterViewInit, OnDestroy {
  private readonly subscriptions = new Subscription();

  @Input() options?: Partial<GridOptions>; // TODO attribute - cannot change once initialized

  /**
   * When true, this component will handle dispatching GridLayoutActions.destroy when this component is destroyed.
   *
   * This is the default behavior because clients, such as My90 v2, should not *have* to care about dispatching destroy.
   *
   * However, VTO needs a finer control. There are multiple tabs, each with its own GridLayoutComponent that is created
   * when the tab gets selected and destroyed when it gets un-selected. VTO needs the edit layout state
   * (enabled/disabled) to persist across these tabs so that you can remain in edit layout mode and switch tabs. Thus,
   * the VtoComponent dispatches destroy (its HTML creates the tabs, so it does not get destroyed when changing tabs)
   * and VtoGridComponent (which creates this component and is destroyed between tabs) passes false to this input.
   */
  @Input() dispatchOnDestroy = true;

  @Input() legacyOneColumn = false;

  @ViewChild('whereToRenderGrid', { read: ViewContainerRef, static: true }) gridViewRef!: ViewContainerRef;
  @ViewChild('alternateTemplate', { read: ViewContainerRef, static: true })
  alternateTemplateRef!: ViewContainerRef;
  @ViewChild(ElementResizeAwareDirective) resizeAwareDirective: ElementResizeAwareDirective;

  @ContentChildren(GridItemTemplateDirective) templates!: GridItemTemplateDirective[];
  @ContentChild(GridItemTemplateDefaultDirective) defaultTemplate?: GridItemTemplateDefaultDirective;

  /**
   * @deprecated - this was only added for backwards compatability for VTO. Future implementors should use NGRX and
   *               react to the events dispatched from this.registerBreakpointObserver. VTO 3.0 will remove this.
   */
  @Output() isLessThanBreakpoint = new EventEmitter<boolean>();

  showGridAlternate$ = this.store
    .select(GridLayoutSelectors.selectShouldShowGridAlternate)
    .pipe(map(show => !this.legacyOneColumn && show));

  showGrid$ = this.store
    .select(GridLayoutSelectors.selectShouldShowGridAlternate)
    .pipe(map(show => this.legacyOneColumn || !show));

  isOneColumn$ = this.store.select(GridLayoutSelectors.selectIsOneColumnMode);
  isSingleton$ = this.store.select(GridLayoutSelectors.selectIsSingletonActive);

  constructor(private hostViewRef: ViewContainerRef, private store: Store, private ngGridStack: NgGridStackService) {}

  ngAfterViewInit() {
    // Initialize grid
    this.ngGridStack.setGridTemplate(this.getTemplate());

    // Frustratingly, ngAfterViewInit seems to be called before the template has actually been rendered. This causes
    // GridStack to fail to initialize - it requires that a DOM element with class .gridstack be present. To work around
    // that, we use setTimeout.
    setTimeout(() => {
      const options: GridOptions = Object.assign({}, GridOptions.defaults(), this.options);
      this.options = options;
      this.store.dispatch(GridLayoutActions.initialize({ opts: options }));

      // Monitor width and emit events when crossing breakpoint
      this.registerBreakpointObserver();
    });
  }

  ngOnDestroy() {
    if (this.dispatchOnDestroy) this.store.dispatch(GridLayoutActions.destroy({ clearState: true }));
    this.subscriptions.unsubscribe();
  }

  private registerBreakpointObserver() {
    this.subscriptions.add(
      this.resizeAwareDirective
        // Increased debounce from default to prevent one column mode from flashing when exiting layout mode at certain
        // widths.
        .getBreakpointObserver(this.options.oneColumnSize, { debounceTime: this.options.resizeDebounce })
        .subscribe(isLessThanBreakpoint => {
          const action = isLessThanBreakpoint
            ? GridLayoutActions.lessThanBreakpoint()
            : GridLayoutActions.greaterThanBreakpoint();
          this.store.dispatch(action);

          // Legacy VTO support
          this.isLessThanBreakpoint.emit(isLessThanBreakpoint);
        })
    );
  }

  private getTemplate(): GridTemplate {
    const { templates, hostViewRef, gridViewRef, alternateTemplateRef } = this;

    const template: GridTemplate = {
      templates,
      hostViewRef,
      gridViewRef,
      alternateTemplateRef,
    };

    if (this.defaultTemplate) template.default = this.defaultTemplate;

    return template;
  }
}
