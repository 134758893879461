import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';

import { DataImportModule } from '@ninety/data-import/data-import.module';
import { DirectoryAddInviteMobileModalComponent } from '@ninety/directory/directory-add-invite/directory-add-invite-mobile-modal.component';
import { DirectoryAddInviteDialogComponent } from '@ninety/directory/directory-add-invite/directory-add-invite.component';
import { ConversationSidenavComponent } from '@ninety/feedback/conversation-sidenav/conversation-sidenav.component';
import { GettingStartedModule } from '@ninety/getting-started/getting-started.module';
import { LayoutStateModule } from '@ninety/layouts/_state/layout-state.module';
import { MeetingNotesComponent } from '@ninety/meeting/meeting-notes/meeting-notes.component';
import { MeetingSidenavComponent } from '@ninety/meeting/meeting-sidenav/meeting-sidenav.component';
import { MeetingComponent } from '@ninety/meeting/meeting.component';
import { MeetingDurationComponent } from '@ninety/meeting/meetings/meeting-duration/meeting-duration.component';
import { MeetingsListComponent } from '@ninety/meeting/meetings/meetings-list/meetings-list.component';
import { TeamSearchComponent } from '@ninety/settings/company/teams/search/team-search/team-search.component';
import TrialCountdownComponent from '@ninety/settings/company/trial-countdown-component/trial-countdown.component';
import { SettingsMenuComponent } from '@ninety/settings/settings-menu/settings-menu.component';
import {
  TerraAvatarModule,
  TerraCheckboxModule,
  TerraDividerModule,
  TerraIconModule,
  TerraSearchInputModule,
  TerraSwitchModule,
} from '@ninety/terra';
import { AngularMaterialModule } from '@ninety/ui/legacy/angular-material/angular-material.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { ButtonRowComponent } from '@ninety/ui/legacy/components/buttons/button-row/button-row.component';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/index';
import { NavigationMenuItemComponent } from '@ninety/ui/legacy/components/menu-items/navigation-menu-item/navigation-menu-item.component';
import { NinetyChipComponent } from '@ninety/ui/legacy/components/ninety-chip/ninety-chip.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { AlertDialogComponent } from '@ninety/ui/legacy/shared/components/alert-dialog/alert-dialog.component';
import { NotificationBannerComponent } from '@ninety/ui/legacy/shared/components/banner/notification-banner.component';
import { ContactCardHoverDirective } from '@ninety/ui/legacy/shared/components/contact-card-container/contact-card-hover.directive';
import { OwnerOnTeamPipe } from '@ninety/ui/legacy/shared/pipes/user/owner-on-team';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import { BecomeAPartnerComponent } from './components/become-a-partner/become-a-partner.component';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { CompanyMenuComponent } from './components/company-menu/company-menu.component';
import { LayoutActionsGroupComponent } from './components/filters-toolbar/components/layout-actions-group/layout-actions-group.component';
import { FiltersToolbarComponent } from './components/filters-toolbar/filters-toolbar.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { HelpfulComponent } from './components/helpful/helpful.component';
import { HomeComponent } from './components/home/home.component';
import { TopToolbarComponent } from './components/top-toolbar/top-toolbar.component';
import { ItemLabelByTypePipe } from './components/universal-create/item-type-select/item-label-by-type.pipe';
import { ItemTypeSelectComponent } from './components/universal-create/item-type-select/item-type-select.component';
import { UniversalCreateComponent } from './components/universal-create/universal-create.component';
import { WhatNextComponent } from './components/what-next/what-next.component';
import { GridLayoutModule } from './grid-layout/grid-layout.module';
import { MainHeaderComponent } from './main-layout/main-header/main-header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MainSidebarComponent } from './main-layout/main-sidebar/main-sidebar.component';

@NgModule({
  declarations: [
    SettingsMenuComponent,
    FiltersToolbarComponent,
    TopToolbarComponent,
    UniversalCreateComponent,
    ConversationSidenavComponent,
    HelpDialogComponent,
    MeetingsListComponent,
    MeetingDurationComponent,
    MeetingComponent,
    MeetingSidenavComponent,
    MeetingNotesComponent,
    HelpfulComponent,
    BecomeAPartnerComponent,
    MainLayoutComponent,
    MainHeaderComponent,
    HomeComponent,
    NotificationBannerComponent,
    UniversalCreateComponent,
    ItemTypeSelectComponent,
    ItemLabelByTypePipe,
  ],
  exports: [HomeComponent, HelpfulComponent, SettingsMenuComponent],
  providers: [],
  imports: [
    WhatNextComponent,
    ButtonComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DatePipe,
    AngularMaterialModule,
    SharedModule,
    LayoutStateModule,
    DataImportModule,
    FlexLayoutModule,
    GridLayoutModule,
    LayoutActionsGroupComponent,
    ContactCardHoverDirective,
    NavigationMenuItemComponent,
    MainSidebarComponent,
    CompanyLogoComponent,
    CompanyMenuComponent,
    PushPipe,
    NinetyTooltipDirective,
    TrialCountdownComponent,
    DirectoryAddInviteDialogComponent,
    DirectoryAddInviteMobileModalComponent,
    TerraDividerModule,
    ButtonRowComponent,
    TerraIconModule,
    AlertDialogComponent,
    GettingStartedModule,
    TerraSwitchModule,
    LetDirective,
    TerraCheckboxModule,
    OwnerOnTeamPipe,
    TeamSearchComponent,
    TerraAvatarModule,
    QuickFilterComponent,
    NinetyChipComponent,
    TerraSearchInputModule,
  ],
})
export class LayoutModule {}
