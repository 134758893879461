import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  Input,
  OnDestroy,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../../models/terra-input-boolean.models';
import { TerraTextInputComponent } from '../../inputs';
import { TerraSelectComponent } from '../../terra-select';
import { TerraErrorStateMatcher } from '../terra-error-state-matcher';

@Component({
  selector: 'terra-form-field',
  standalone: true,
  exportAs: 'terraFormField',
  imports: [CommonModule],
  templateUrl: './terra-form-field.component.html',
  styleUrls: ['./terra-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraFormFieldComponent implements AfterContentInit, OnDestroy, DoCheck {
  @ContentChild(NgControl) private _formControl!: NgControl;
  @ContentChild(TerraTextInputComponent) private _textInput?: TerraTextInputComponent;
  @ContentChild(TerraSelectComponent) private _selectInput?: TerraSelectComponent;

  private get _formFieldInput(): TerraTextInputComponent | TerraSelectComponent | undefined {
    return this._textInput || this._selectInput;
  }

  private _destroyed$ = new Subject<void>();
  private _wasTouched = false;
  private _wasDirty = false;

  /**
   * Sets whether the form field is marked as "(optional)"
   * @default false
   */
  @Input() get optional(): boolean {
    return this._optional;
  }
  set optional(value: TerraInputBoolean) {
    this._optional = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _optional = false;

  /**
   * Sets whether the form field shows a character count
   * @default false
   */
  @Input() get charCount(): boolean {
    return this._charCount;
  }
  set charCount(value: TerraInputBoolean) {
    this._charCount = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _charCount = false;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _terraErrorStateMatcher: TerraErrorStateMatcher
  ) {}

  protected _isFormControlInvalid(): boolean {
    return this._terraErrorStateMatcher.isErrorState(this._formControl);
  }

  protected _currCharCount(): number {
    return this._formControl?.value?.length || 0;
  }

  protected _getMaxLength(): number {
    return this._formFieldInput instanceof TerraTextInputComponent
      ? coerceNumberProperty(this._formFieldInput?.maxlength)
      : 0;
  }

  ngAfterContentInit() {
    if (this._formFieldInput instanceof TerraSelectComponent) {
      this.charCount = false;
    }

    this._formControl?.statusChanges
      ?.pipe(
        tap(() => {
          this._changeDetectorRef.markForCheck();
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  ngDoCheck() {
    /** OnPush workaround to force change detection when form control is touched */
    const touchedChanged = this._formControl?.touched !== this._wasTouched;
    const dirtyChanged = this._formControl?.dirty !== this._wasDirty;

    if (touchedChanged || dirtyChanged) {
      this._wasTouched = !!this._formControl?.touched;
      this._wasDirty = !!this._formControl?.dirty;
      this._changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
