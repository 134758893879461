import { createSelector } from '@ngrx/store';

import { RoleCode } from '../../../_shared/models/_shared/role-code';
import { selectEntitiesState, UserListStateKey } from '../index';
import { TeamSelectors } from '../team-list/team-list-state.selectors';
import { selectCurrentUser } from '../users/users-state.selectors';

import { UserListModel } from './api/user-list.model';
import { userListInitialState, userListStateAdapter } from './user-list-state.model';

const { selectAll: adapterSelectAll, selectEntities } = userListStateAdapter.getSelectors();

const selectUserListState = createSelector(selectEntitiesState, appEntities =>
  appEntities ? appEntities[UserListStateKey] : userListInitialState
);

export const UserListSelectors = {
  selectAll: createSelector(selectUserListState, adapterSelectAll),
  selectAllDictionary: createSelector(selectUserListState, selectEntities),
  get selectAllNoObservers() {
    return createSelector(this.selectAll, (users: UserListModel[]) =>
      users.filter(user => user.roleCode !== RoleCode.observer)
    );
  },
  get selectById() {
    return (id: string) => createSelector(this.selectAllDictionary, users => (users ? users[id] : null));
  },
};

export namespace CurrentUserSelectors {
  export const selectUser = createSelector(selectUserListState, state => state.loggedInUser);
  export const selectUserId = createSelector(selectUserListState, state => state.loggedInUser._id);

  export const selectIds = createSelector(selectUser, user => {
    return user.teams.map(t => t.teamId);
  });
  export const selectIsImplementer = createSelector(selectUser, user => user.isImplementer);

  export const selectTeams = createSelector(selectCurrentUser, TeamSelectors.selectAll, (user, teams) => {
    const teamList = teams.filter(team => user.teams.some(ut => ut.teamId === team._id));

    // Admin, Owners, and Implementers can see all non-private teams
    if (user.isAdminOrOwner || user.isImplementer) {
      teamList.push(...teams.filter(team => !team.private && !teamList.includes(team)));
    }

    return teamList.sort((a, b) => a.name.localeCompare(b.name));
  });

  export const selectTeamIds = createSelector(selectTeams, teams => teams.map(team => team._id));
}
