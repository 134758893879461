import { createActionGroup, props } from '@ngrx/store';

import { CreateDialogInput } from '@ninety/ui/legacy/shared/models/_shared/create-dialog-input-params';
import { DialogMode } from '@ninety/ui/legacy/shared/models/_shared/dialog-mode-types';

export const UniversalCreateActions = createActionGroup({
  source: 'UniversalCreate',
  events: {
    Open: props<{ data?: CreateDialogInput; mode?: DialogMode }>(),
  },
});
