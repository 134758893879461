<ninety-card>
  <!-- Card Header -->
  <ninety-card-header header>
    <!-- Card title/description -->
    <div class="dialog-heading">
      <div class="terra-title-small">Bulk Import Users to Ninety</div>
    </div>
  </ninety-card-header>

  <div class="user-import-dialog-body">
    <div class="subtitle">Before you proceed, please make sure that:</div>
    <div class="checkboxes">
      <terra-checkbox [(ngModel)]="acknowledgeCost">
        You are prepared to invite all imported users to Ninety, as invitations will be automatically sent upon
        completion of the import process. <span class="cost-notice">Subscription costs may apply.</span>
      </terra-checkbox>
      <terra-checkbox [(ngModel)]="teamsAreReady">
        The teams you're assigning users to are already set up. Visit the
        <a (click)="close()" ninetyStopPropagation routerLink="/settings/company/teams">Teams page</a>
        to create any required teams before proceeding.
      </terra-checkbox>
    </div>
  </div>

  <ninety-card-footer footer>
    <div class="dialog-footer-action-group">
      <ninety-button (click)="close()" flat>Cancel</ninety-button>
      <ninety-button (click)="proceedToImport()" [disabled]="!acknowledgeCost || !teamsAreReady" filled primary>
        Proceed
      </ninety-button>
    </div>
  </ninety-card-footer>
</ninety-card>
