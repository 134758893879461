import Fuse, { FuseResult, FuseSearchOptions, IFuseOptions } from 'fuse.js';
import { Observable } from 'rxjs';

/** Internal ComponentStore state for the {@link FuseSearchService} */
export interface FuseSearchState<T> {
  /** The options passed to Fuse whenever an index is created */
  fuseOptions: IFuseOptions<T> | null;
  /** The search index. Lazily created on search. */
  index: Fuse<T> | null;
  /** The data to be searched */
  data: T[];
  /** The data managed by this service. Manipulated on search */
  managedOptions: ManagedOptions<T>[];
  /**
   * The initial options are shown before a query and after a query becomes empty. They are a mapping of data with all
   * options visible and in their order in the data source.
   */
  initialOptions: ManagedOptions<T>[];
  /** See {@link SearchResultsType} */
  searchResultsType: SearchResultsType;
}

/** Type for distinguishing between no results, results, and no query (inferred from the state of search results */
export type SearchResultsType = 'no-results' | 'results' | 'no-query';

/** The wrapping model that the {@link FuseSearchService} uses to manage a data item */
export interface ManagedOptions<T> {
  /** The option being managed */
  value: T;
  /** The result of the search query. Null when no match is possible */
  match: FuseResult<T> | null;
  /**
   * Whether the option should be visible in the UI. This is true when there is no search query + at init. Note, in
   * both those cases, match is undefined. When a query exists, this value is equivalent to match !== null.
   */
  visible: boolean;
}

export function createInitialManagedOptions<T>(data: T[]): ManagedOptions<T>[] {
  return data.map(option => ({
    value: option,
    visible: true,
    match: null,
  }));
}

export interface FuseSearchInitParams<T> {
  /** The complete datasource to build an index from */
  allOptionsDataSource$: Observable<T[]>;

  /**
   * Options used to configure the Fuse index. Expected to at least contain the `keys` property, which is how fuse
   * extracts the indexed terms from the data.
   *
   * @see [FuseJS - Searching By Key](https://www.fusejs.io/examples.html#search-string-array)
   * @see [FuseJS - Options](https://fusejs.io/api/options.html)
   */
  fuseOptions: IFuseOptions<T>;
}

export type FuseSearchDoQueryParams = { query: string } & Partial<FuseSearchOptions>;
