import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';

export class ImportUserRecord extends InviteUserPayload {
  role: RoleSelectOption;
  selectedTeams: Team[];
  nonMatchingTeamNames: string[];

  static fromFormData({ role, selectedTeams, nonMatchingTeamNames, ...formData }: ImportUserRecord): InviteUserPayload {
    return formData;
  }
}
