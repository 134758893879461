import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DirectoryFilters, DirectoryMatSorting } from '@ninety/directory-v2/_state/directory-state.model';
import { DirectoryRoleFilterOption } from '@ninety/directory-v2/models/directory-role-filter-option';
import { DirectorySort } from '@ninety/directory-v2/models/directory-sort';
import { DirectoryUserStatusOption } from '@ninety/directory-v2/models/directory-user-status-options';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { MatSortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';

export const DirectoryActions = createActionGroup({
  source: 'Directory',
  events: {
    Init: emptyProps(),
    Destroy: emptyProps(),
    'Get Directory Users': emptyProps(),
    'Get Directory Users Success': props<{ users: User[]; count: number }>(),

    /** Action bar filters */
    'Init Filter Options': props<{ filters: DirectoryFilters & DirectoryMatSorting }>(),
    'Set Query Param': props<{ key: string; value: string }>(),
    'Select Role Filter Option': props<{ selectedRole: DirectoryRoleFilterOption }>(),
    'Select User Status Filter Option': props<{ selectedUserStatus: DirectoryUserStatusOption }>(),
    'Select Team Filter Option': props<{ teamId: string }>(),
    'Set Search Text': props<{ searchText: string }>(),
    'Search Text Changed': props<{ searchText: string }>(),

    /** Table actions */
    'Sort Changed': props<{ sortField: DirectorySort; sortDirection: MatSortDirection }>(),
    'Pagination Changed': props<{ pageIndex: number; pageSize: number }>(),
    'Select User': props<{ user: User }>(),
    'Close User Detail': emptyProps(),
    'Update User': props<{ userId: string; update: Partial<User> }>(),
    'Update User Success': props<{ userId: string; update: Partial<User> }>(),
    'Update User Teams': props<{ user: User; teamIds: string[] }>(),
    'Update User Role': props<{ user: User; role: RoleSelectOption }>(),
    'Copy Invite Link': props<{ user: User }>(),

    /** Dialogs */
    'Open Add Users Dialog': emptyProps(),
    'Add Users': props<{ users: User[] }>(),
    'Add Users Success': emptyProps(),

    'Toggle User To Invite': props<{ checked: boolean; user: User }>(),
    'Toggle All Users To Invite': props<{ checked: boolean }>(),
    'Open Invite Users Dialog': props<{ users: User[] }>(),
    'Open Deactivate User Dialog': props<{ user: User }>(),
    'Open Delete User Dialog': props<{ user: User }>(),
    'Invite Paid Users': props<{ users: InviteUserPayload[] }>(),
    'Invite Paid Users Success': props<{ users: User[] }>(),
    'Invite Paid Users Failure': emptyProps(),

    'Open Universal Create Modal': emptyProps(),
  },
});
