import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MeetingAgendas } from '../../_shared/models/_shared/team';
import { BusinessOperatingSystem } from '../../_shared/models/company/business-operating-system.enum';
import { SpinnerAndNotifyError } from '../decorators/spinner-and-notify-error';

import { ErrorService } from './error.service';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class MeetingAgendaService {
  private agendasApi = '/api/v4/MeetingAgendas';

  constructor(private http: HttpClient, private _errorService: ErrorService, private _spinnerService: SpinnerService) {}

  @SpinnerAndNotifyError
  public getCurrentCompanyAgendas(): Observable<MeetingAgendas> {
    return this.http.get<MeetingAgendas>(this.agendasApi);
  }

  @SpinnerAndNotifyError
  public getCompanyResetAgendas(companyId: string): Observable<MeetingAgendas> {
    return this.http.get<MeetingAgendas>(`${this.agendasApi}/Reset/${companyId}`);
  }

  @SpinnerAndNotifyError
  public getDefaultBosAgendas(bos: BusinessOperatingSystem): Observable<MeetingAgendas> {
    return this.http.get<MeetingAgendas>(`${this.agendasApi}/Default/${bos}`);
  }
}
