/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  Input,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

import { NinetyChipComponent } from '@ninety/ui/legacy/components/ninety-chip/ninety-chip.component';
import { OptionOverlayComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/components/option-list-overlay/option-overlay.component';
import { SelectClearButtonComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/components/select-clear-button/select-clear-button.component';
import { NinetySelectToggleButtonComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/components/select-toggle-button.component';
import { OptionListDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/option-list.directive';
import { SelectLauncherDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/select-launcher.directive';
import { HasErrorDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/directives/has-error.directive';
import { InputVariantDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/text-input/directives/input-variant.directive';

import { SelectChipTemplateDirective } from '../../directives/select-chip-template.directive';

export type SelectBoxViewRenderType = 'placeholder' | 'single' | 'multi';

/**
 * A component which emulates an input that can be used as a multi/single select.
 *
 * This component is only concerned with rendering the non-open select view. It uses the select launcher to render the
 * overlay where users can select items.
 */
@Component({
  selector: 'ninety-select-box',
  styleUrls: ['./select-box.component.scss'],
  templateUrl: './select-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SelectClearButtonComponent,
    NinetySelectToggleButtonComponent,
    NgTemplateOutlet,
    NgSwitchCase,
    NgSwitch,
    SelectLauncherDirective,
    NinetyChipComponent,
    SelectChipTemplateDirective,
    NgIf,
    OptionOverlayComponent,
    NgForOf,
    InputVariantDirective,
  ],
})
export class SelectBoxComponent<T = unknown> implements AfterContentInit {
  /** An optional template which will be projected into `NinetyChip` if present */
  @Input() protected chipTemplate: SelectChipTemplateDirective;

  /** Placeholder text shown when there are no items selected. */
  @Input() protected placeholder = '';

  /** When true, the component cannot be interacted with, but does not have disabled styling */
  @Input({ transform: coerceBooleanProperty }) protected readonly: BooleanInput = false;

  // TODO disabled belongs here not in the option list

  /** Access to the underlying directive which powers the select behavior */
  @ContentChild(OptionListDirective) protected optionList: OptionListDirective<T>;

  /** Internal template passed to `SelectLauncher` */
  @ViewChild('menuTemplate', { static: true }) protected menuTemplate: TemplateRef<unknown>;

  @ViewChild(SelectLauncherDirective) selectLauncher: SelectLauncherDirective;

  constructor(
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    @Optional() protected hasError: HasErrorDirective
  ) {}

  ngAfterContentInit() {
    // Ensure that we update our view when the underlying option list changes
    this.optionList.changeStream.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.cdr.detectChanges());

    // When a single select, close launcher when the value changes and at least one item is selected
    this.optionList.valueChanged
      .pipe(
        filter(change => !change.listbox.multiple && change.value.length > 0),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => setTimeout(() => this.selectLauncher.destroyOverlay()));

    // Helps initialize chips correctly
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 0);
  }

  protected get viewToRender(): SelectBoxViewRenderType {
    if (!this.optionList.value?.length) return 'placeholder';
    return this.optionList.multiple ? 'multi' : 'single';
  }
}
