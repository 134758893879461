<button
  class="menu-list-item terra-body"
  routerLinkActive="active"
  (click)="navClick()"
  [ngClass]="isExpanded ? '' : 'nav-closed'"
  [ngClass]="{ 'nav-closed': !isExpanded, 'light-theme': theme === Theme.LIGHT, 'tall-item': isTall }"
  [class.nav-disabled]="disabled"
  [ninetyTooltip]="title"
  tpPlacement="right"
  [tpIsEnabled]="!isExpanded && showTooltip">
  <terra-icon *ngIf="iconKey" [icon]="iconKey" [variant]="iconVariant" />
  <terra-avatar *ngIf="!iconKey" [src]="pictureURL" [initials]="userInitials" size="large" />
  <span attr.data-cy="navigation-menu-item__current-company-{{ title }}" *ngIf="isExpanded" [class]="titleClasses">
    {{ title }}
  </span>
  <terra-icon
    *ngIf="secondaryIcon && isExpanded"
    [icon]="secondaryIcon"
    class="secondary-icon"
    size="20"
    [class]="secondaryIconClass" />
  <div class="highlight-background"></div>
</button>
