import { Directive, ElementRef, Inject, Injector, Input, NgZone, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { TippyConfig, TippyDirective, TIPPY_CONFIG } from '@ngneat/helipopper';
import { ViewService } from '@ngneat/overview';

/*
This directive is a wrapper for the Tippy.js library. Tippy properties such as placement and animation should work.
example usage: <div ninetyTooltip="My tooltip text" placement="right"></div>
In this example, the tooltip is placed to the right. Other options are top, bottom, left, right-start, left-end, etc. Default is top.
You can find more information about Tippy.js and its properties here: https://atomiks.github.io/tippyjs/
*/

@Directive({
  standalone: true,
  selector: '[ninetyTooltip]',
})
export class NinetyTooltipDirective extends TippyDirective {
  @Input()
  set ninetyTooltip(content: string) {
    // Sets Tippy.js configuration
    this.setProps({
      // Setting content here does not work, the other options do. I don't know why.
      // content,

      theme: 'ninety-tippy-theme',

      // Uncomment to debug tooltips. They will activate as soon as their trigger enters the DOM and stay active.
      // showOnCreate: true,
      // hideOnClick: false,
    });

    this.content = content;
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(TIPPY_CONFIG) globalConfig: TippyConfig,
    injector: Injector,
    viewService: ViewService,
    vcr: ViewContainerRef,
    zone: NgZone,
    hostRef: ElementRef
  ) {
    super(platformId, globalConfig, injector, viewService, vcr, zone, hostRef);
  }
}
