import { createReducer, on } from '@ngrx/store';
import _cloneDeep from 'lodash/cloneDeep';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { MazActions } from '@ninety/getting-started/maz/_state/maz.actions';
import { INITIAL_MAZ_STATE, MazState } from '@ninety/getting-started/maz/_state/maz.model';
import { MazMessage } from '@ninety/getting-started/maz/models/maz-message';
import { MazMessageSender } from '@ninety/getting-started/maz/models/maz-message-sender.enum';

export const MazReducer = createReducer(
  INITIAL_MAZ_STATE,
  on(
    GuideActions.setGuideInfo,
    (state, { guideInfo: { common_questions } }): MazState => ({
      ...state,
      commonQuestions: common_questions,
    })
  ),
  on(
    GuideActions.toggleGuide,
    GuideActions.closeGuide,
    (state): MazState => ({
      ...state,
      mazChatExpanded: false,
    })
  ),
  on(
    MazActions.getConversationHistorySuccess,
    (state, { mazMessages }): MazState => ({ ...state, mazMessages: _cloneDeep(mazMessages) })
  ),
  on(MazActions.toggleMazChat, (state): MazState => ({ ...state, mazChatExpanded: !state.mazChatExpanded })),
  on(MazActions.sendMessageToMaz, (state, { userInput }): MazState => {
    const mazMessages = [..._cloneDeep(state.mazMessages), new MazMessage(userInput)];
    return { ...state, mazMessages, mazChatExpanded: true };
  }),
  on(MazActions.receiveMessageFromMaz, (state, { response: { response, _id } }): MazState => {
    const mazMessages = [..._cloneDeep(state.mazMessages), new MazMessage(response, MazMessageSender.assistant, _id)];
    return { ...state, mazMessages, waitingForMazResponse: false };
  }),
  on(MazActions.waitingForMazReply, (state): MazState => ({ ...state, waitingForMazResponse: true })),
  on(MazActions.failedToReceiveMessageFromMaz, (state): MazState => ({ ...state, waitingForMazResponse: false })),
  on(MazActions.toggleMazFeedbackFlag, (state, { messageId, mazFeedbackFlag }): MazState => {
    const mazMessages = _cloneDeep(state.mazMessages);
    const incorrectMessage = mazMessages.find(m => m._id === messageId);
    incorrectMessage.feedback = { flag: mazFeedbackFlag };
    return { ...state, mazMessages };
  })
);
