<sup>
  <terra-icon
    #rolesInfoTrigger="ninetyPopoverOrigin"
    (mouseenter)="showRoleInfoOverlay = true"
    class="role-info-circle"
    icon="info-circle"
    ninetyPopoverOrigin
    size="16" />
</sup>

<ninety-popover
  (popoverClosed)="showRoleInfoOverlay = false"
  [content]="rolesInfo"
  [isOpen]="showRoleInfoOverlay"
  [trigger]="rolesInfoTrigger"></ninety-popover>

<ng-template #rolesInfo>
  <div class="roles-info-overlay">
    <div class="overlay-header">
      <div class="overlay-title">Company Roles</div>
    </div>
    <terra-divider />
    <div class="overlay-body">
      <div *ngFor="let role of roles$ | ngrxPush" class="role-wrapper">
        <div class="role-title">
          {{ role.title }} <span class="role-subtitle">{{ role.subtitle }}</span>
        </div>
        <div class="role-description">{{ role.description }}</div>
      </div>
    </div>
  </div>
</ng-template>
