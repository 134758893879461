import { Injectable } from '@angular/core';

import { RoleName } from '../../../../_shared/models/_shared/role-name';
import { SubmitEvent } from '../../../../_shared/models/form/submit-event';
import { RoleService } from '../../../services/role.service';
import { StateService } from '../../../services/state.service';
import { LocalStorageService } from '../../../services/storage.service';
import { DataLayer, DataLayerKeys } from '../model/data-layer';
import { DataLayerEvent } from '../model/data-layer-event';
import { UrlSanitizer } from '../model/url-sanitizer';

import { GoogleAnalyticsService } from './google-analytics.service';

declare let window: any;

@Injectable()
export class DataLayerService {
  private isInitialized = false;
  private gaClientId: string;

  constructor(
    private stateService: StateService,
    private storage: LocalStorageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  private get loaded() {
    return window.hasOwnProperty('dataLayer');
  }

  public init() {
    if (this.isInitialized) return;

    this.setClientId();

    this.isInitialized = true;
  }

  // Get Google Analytics Client ID
  private async setClientId(): Promise<void> {
    this.gaClientId = await this.googleAnalyticsService.getClientId();
  }

  private get requiredFields(): Partial<DataLayer> {
    // Check person.personId for when a user is between sign-up but before company is created
    const isLoggedIn = !!this.stateService.currentUser?.personId;
    const lastLoginDate = this.storage.get('lastLoginDate');

    const fields: Partial<DataLayer> = {
      [DataLayerKeys.gaClientId]: this.gaClientId,
      [DataLayerKeys.isLoggedIn]: isLoggedIn ? 'true' : 'false',
      [DataLayerKeys.authenticatedUserId]: this.stateService.currentUser$.value?._id || undefined,
      [DataLayerKeys.lastLoginDate]: lastLoginDate,
      [DataLayerKeys.userStatus]: isLoggedIn || lastLoginDate ? 'User' : 'Lead',
      [DataLayerKeys.userRole]:
        (RoleService.roleName(this.stateService.currentUser?.roleCode).toLocaleLowerCase() as RoleName) || undefined,
      [DataLayerKeys.personId]: this.stateService.currentPerson$.value?._id || undefined,
      [DataLayerKeys.companyId]: this.stateService.currentUser?.company.companyId || undefined,
    };

    return fields;
  }

  /**
   * Be sure to dereference layer
   *
   * Google adds a unique id to each entry in the data layer, which updates
   * this service state if it's not dereferenced and blocks new pushes
   */
  public push(fields: Partial<DataLayer>): void {
    if (this.loaded) {
      const layer = new DataLayer({ ...fields, ...this.requiredFields });
      window.dataLayer.push(layer.value);
    }
  }

  public getClickMeta(target: HTMLElement) {
    if (!target) return {};

    return {
      [DataLayerKeys.clickClasses]: target.classList.value || undefined,
      [DataLayerKeys.clickElement]: target.outerHTML || undefined,
      [DataLayerKeys.clickId]: target.id || undefined,
      [DataLayerKeys.clickText]: target.textContent.trim() || undefined,
    };
  }

  public trackClick(target: HTMLElement, extraFields: Partial<DataLayer> = {}): void {
    this.push({
      event: DataLayerEvent.click,
      ...this.getClickMeta(target),
      ...this.getUrlMeta(new URL(location.href)),
      ...extraFields,
    });
  }

  public getFormMeta(submitEvent: SubmitEvent) {
    const { target, submitter } = submitEvent;

    const form = target as HTMLFormElement;

    return {
      [DataLayerKeys.formClasses]: form?.classList.value || undefined,
      [DataLayerKeys.formElement]: form?.outerHTML || undefined,
      [DataLayerKeys.formUrl]: form?.action || undefined,
      [DataLayerKeys.formId]: form?.id || undefined,
      ...this.getClickMeta(submitter),
    };
  }

  public trackFormSubmit(submitEvent: SubmitEvent, extraFields: Partial<DataLayer> = {}): void {
    this.push({
      event: DataLayerEvent.submit,
      ...this.getFormMeta(submitEvent),
      ...this.getUrlMeta(new URL(location.href)),
      ...extraFields,
    });
  }

  public getUrlMeta(url: URL) {
    if (!url) return {};

    const safeUrl: URL = new UrlSanitizer(['firstname', 'lastname', 'email']).sanitize(url);

    return {
      [DataLayerKeys.pageHostname]: safeUrl.hostname,
      [DataLayerKeys.pagePath]: safeUrl.pathname,
      [DataLayerKeys.pageUrl]: safeUrl.href,
      [DataLayerKeys.pageHash]: safeUrl.hash || undefined,
      [DataLayerKeys.queryParams]: safeUrl.search || undefined,
      [DataLayerKeys.utm_source]: safeUrl.searchParams.get('utm_source') || undefined,
      [DataLayerKeys.utm_medium]: safeUrl.searchParams.get('utm_medium') || undefined,
      [DataLayerKeys.utm_campaign]: safeUrl.searchParams.get('utm_campaign') || undefined,
      [DataLayerKeys.utm_term]: safeUrl.searchParams.get('utm_term') || undefined,
      [DataLayerKeys.utm_content]: safeUrl.searchParams.get('utm_content') || undefined,
    };
  }
}
