import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import _cloneDeep from 'lodash/cloneDeep';
import { from, Observable, Subscription } from 'rxjs';
import { filter, mergeMap, take, toArray } from 'rxjs/operators';

import { NinetyButtonSizes } from '../../../_components/buttons/button/button.component';
import { StateService } from '../../../_core/services/state.service';
import { UserService } from '../../../_core/services/user.service';
import { FeatureFlagFacade } from '../../../_state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '../../../_state/app-entities/feature-flag/feature-flag-state.model';
import { selectLanguage } from '../../../_state/app-global/language/language.selectors';
import { RoleCode } from '../../models/_shared/role-code';
import { User } from '../../models/_shared/user';
import { SeatHolder } from '../../models/accountability-chart/seat-holder';
import { SortByUserNamePipe } from '../../pipes/sort-by-user-name.pipe';

@Component({
  selector: 'ninety-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userId?: string | null;
  @Input() userIds?: string[];
  @Input() user?: User | null;
  @Input() users?: User[];
  @Input() selectedUserIds?: string[] = [];
  @Input() multiple?: boolean;
  @Input() disabled?: boolean;
  @Input() iconBtn?: boolean;
  @Input() isMeeting?: boolean;
  @Input() filterOptions?: object;
  @Input() disableNotInSeatUsers?: boolean;
  @Input() disableUnconfirmedUsers?: boolean; //disables not confirmed users / users without a personId, shows (unconfirmed user)
  @Input() seatHolders?: SeatHolder[];
  @Input() filterSelf?: boolean;
  @Input() doNotFilterSelectedUsers: boolean;
  @Input() disableSaveIfNoSelectedUsers: boolean;
  @Input() required: boolean;
  @Input() submitAllUsersOnEverySelect: boolean;
  @Input() updateAlways: boolean;
  @Input() accountabilityChart?: boolean;
  @Input() showRemoveBtn?: boolean;
  @Input() addButtonSize?: NinetyButtonSizes = 'large';
  @Input() warningBtn?: boolean;
  @Input() includeObservers?: boolean;
  @Input() includeNonActivated?: boolean;
  @Input() includeLiteUsers?: boolean;
  @Input() filterOutUsersByIds?: string[];
  @Input() clearSelectedAfterClose?: boolean;
  @Input() tooltipOverride?: string;
  @Input() hideCountBadge: boolean;

  @Output() selectUser = new EventEmitter<User>();
  @Output() selectUsersIds = new EventEmitter<string[]>();
  @Output() selectUsers = new EventEmitter<User[]>();
  @Output() removeUser = new EventEmitter();

  @ViewChild('addUserButton', { read: ElementRef }) addUserButton: ElementRef;

  tooltip = '';
  subscriptions = new Subscription();
  changed = false;
  removeBtnDisplay: 'none' | 'inherit' = 'none';
  filteredUsers: User[];
  contactCardEnabled$: Observable<boolean>;
  contactCardEnabled = false;
  language$ = this.store.select(selectLanguage);

  @HostListener('click', ['$event'])
  @HostListener('mousedown', ['$event'])
  onClick(e) {
    e.stopPropagation();
  }

  constructor(
    private userService: UserService,
    public stateService: StateService,
    private sortByUserNamePipe: SortByUserNamePipe,
    private featureFlagFacade: FeatureFlagFacade,
    private store: Store
  ) {
    this.contactCardEnabled$ = this.featureFlagFacade.getFlag(FeatureFlagKeys.enableContactCard);
    this.contactCardEnabled$.pipe(take(1)).subscribe(isEnabled => {
      this.contactCardEnabled = isEnabled;
    });
  }

  ngOnInit(): void {
    if (this.users && this.users.length) {
      if (!this.user) this.getUser();
    } else {
      this.subscriptions.add(
        (this.includeObservers ? this.userService.allUsers$ : this.userService.users$)
          .pipe(filter(u => !!u))
          .subscribe({
            next: (users: User[]) => {
              this.users = users;
              this.activateFilters();
              if (!this.user) this.getUser();
            },
          })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.userId &&
      !changes.userId.firstChange &&
      ((this.user && this.user._id !== changes.userId.currentValue) || !this.user)
    ) {
      this.getUser();
    }
    if (changes.user) this.getToolTip();
    if ((changes.userIds && this.users) || changes.users) this.activateFilters();
    if (changes.filterOutUsersByIds && !changes.filterOutUsersByIds.firstChange) this.activateFilters();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  activateFilters(): void {
    if (!this.users) return;
    let users = _cloneDeep(this.users);
    if (this.filterOutUsersByIds?.length) {
      users = users.filter(u => !(this.filterOutUsersByIds || []).includes(u._id));
    }
    if (this.selectedUserIds.length && !this.doNotFilterSelectedUsers && this.userIds)
      users = users.filter((user: User) => !this.userIds.includes(user._id));
    if (this.filterOptions) {
      const keys = Object.keys(this.filterOptions);
      users = users.filter((user: User) => keys.every(key => user[key] !== this.filterOptions[key]));
    }
    if (this.filterSelf) users = users.filter((user: User) => user._id !== this.stateService.companyUserId);
    if (this.userIds && this.userIds.length) users = users.filter(u => !this.userIds.includes(u._id));

    users = this.sortByUserNamePipe.transform(
      users.filter(u => !u.isImplementer && (this.includeLiteUsers || u.roleCode !== RoleCode.lite))
    );
    const index = users.findIndex(u => u._id === this.stateService.currentUser._id);
    if (index !== -1) {
      users.splice(index, 1);
      users = [this.stateService.currentUser, ...users];
    }

    this.filteredUsers =
      this.includeNonActivated || this.users ? users : users.filter((user: User) => user.hasBeenInvited || user.active);

    if (this.filteredUsers.length) {
      this.filteredUsers = this.filteredUsers.filter(filteredUser => filteredUser.metadata);
    }

    if (this.filteredUsers.length && (this.disableNotInSeatUsers || this.disableUnconfirmedUsers)) {
      //add additional fields to disable unconfirmed users and users not in a seat
      this.filteredUsers.forEach((user: User & { isUnconfirmed?: boolean; hasNoSeat: boolean }) => {
        if (this.disableUnconfirmedUsers && !user.personId) user.isUnconfirmed = true;
        if (
          !user.isUnconfirmed &&
          (this.disableNotInSeatUsers || !this.seatHolders) &&
          !this.seatHolders.some((s: SeatHolder) => s.userId === user._id)
        )
          user.hasNoSeat = true;
      });
    }

    this.getToolTip();
  }

  isSelected(user: User): boolean {
    return this.selectedUserIds.includes(user._id);
  }

  getUser(): void {
    if (!this.userId) {
      this.user = null;
    }
    this.userService.getUser(this.userId).subscribe({
      next: u => {
        this.user = u;
        this.getToolTip();
      },
    });
  }

  getToolTip(): void {
    if (this.contactCardEnabled) {
      this.tooltip = null;
      return;
    }

    if (this.tooltipOverride) {
      this.tooltip = this.tooltipOverride;
      return;
    }
    if (this.iconBtn) {
      this.tooltip = 'Select User(s)';
    } else if (this.selectedUserIds.length > 1) {
      this.tooltip = 'Multiple Users';
    } else if (this.user) {
      this.tooltip = this.user?.metadata?.name
        ? `${this.user?.metadata?.name?.first} ${this.user?.metadata?.name?.last}`
        : null;
    } else this.tooltip = this.accountabilityChart ? 'Add Seatholder' : 'Please select user';
  }

  onSelect(user: User): void {
    if (this.accountabilityChart) return this.selectUser.emit(user);
    if (this.submitAllUsersOnEverySelect) this.selectUsersIds.emit(this.selectedUserIds);
    if (!this.multiple) {
      if (this.user && this.user._id === user._id) return;
      this.user = user;
      this.selectUser.emit(user);
    } else if (this.selectedUserIds.includes(user._id)) {
      this.selectedUserIds.splice(this.selectedUserIds.indexOf(user._id), 1);
      if (!this.selectedUserIds.length) this.removeUser.emit();
      this.user = this.users.find((u: User) => u._id === this.selectedUserIds[0]);
    } else {
      this.selectedUserIds.push(user._id);
      this.user = this.users.find((u: User) => u._id === user._id);
      this.userId = user._id;
      if (!this.checkIfCurrentUserOnTeam()) {
        const idx = this.selectedUserIds.indexOf(this.stateService.currentCompanyUser$.value._id);
        this.selectedUserIds.splice(idx, 1);
      }
      if (this.updateAlways) this.onSelectUsers();
      if (this.disableNotInSeatUsers) {
        this.selectedUserIds = this.selectedUserIds.filter((id: string) =>
          this.seatHolders.some((s: SeatHolder) => s.userId === id)
        );
      }
      if (this.disableUnconfirmedUsers) {
        this.selectedUserIds = this.selectedUserIds.filter(
          (id: string) => this.users.find((u: User) => u._id === id)?.active
        );
      }
    }
    this.changed = true;
    this.getToolTip();
  }

  onSelectUsers(): void {
    this.user = this.users.find((user: User) => user._id === this.selectedUserIds[0]);
    this.selectUsersIds.emit(this.selectedUserIds);

    from(this.selectedUserIds)
      .pipe(
        mergeMap((userId: string) => this.userService.getUser(userId)),
        toArray()
      )
      .subscribe({
        next: (users: User[]) => {
          this.selectUsers.emit(users);
        },
      });

    this.changed = false;
    this.getToolTip();
    if (this.clearSelectedAfterClose) this.selectedUserIds = [];
  }

  checkIfCurrentUserOnTeam(): boolean {
    if (!this.selectedUserIds.includes(this.stateService.currentCompanyUser$.value._id)) return true;
    return !!this.users.find((u: User) => u._id === this.stateService.currentCompanyUser$.value._id);
  }

  saveOnClose(): void {
    if (this.multiple && this.changed) this.onSelectUsers();
  }

  trackByUserId(i: number, u: User): string {
    return u._id;
  }

  /** Programmatically open the user select */
  open(): void {
    this.addUserButton.nativeElement.click();
  }
}
