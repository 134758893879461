<div
  #selectLauncher="ninetySelectLauncher"
  (isOpenChange)="onOpenChange($event)"
  [disabled]="select.disabled || select.readonly"
  [ngClass]="{
    disabled: select.disabled,
    readonly: select.readonly,
  }"
  [ninetySelectLauncher]="selectOverlay"
  class="inline-teams-select"
  id="{{ select.id }}-trigger"
  positioningStrategy="below">
  <div *ngIf="teamNames; else placeholder" [ninetyTooltip]="teamNames" class="team-names">
    {{ teamNames }}
  </div>
  <ng-template #placeholder> Select team(s)</ng-template>
  <terra-icon
    [class.icon-up]="selectLauncher.isOpen"
    class="teams-select-expand-collapse"
    icon="caret-down"
    size="16"
    variant="bold" />
</div>

<ng-template #selectOverlay>
  <div class="team-select-overlay" id="{{ select.id }}-overlay">
    <ninety-input-wrapper variant="outline">
      <terra-icon icon="search" ninetyInputPrefix />
      <input
        #autoComplete="ninetyAutoComplete"
        #input
        (isIntersecting)="input.focus()"
        [ninetyNotifyIntersect]="true"
        [variant]="null"
        id="{{ select.id + '-input' }}"
        ninetyAutoComplete
        placeholder="Search teams..." />
      <ninety-button
        (click)="search(null); autoComplete.clear()"
        (keyup.enter)="search(null); autoComplete.clear()"
        (keyup.space)="search(null); autoComplete.clear()"
        *ngIf="fuseProvider.fuse.hasSearchTerm$ | ngrxPush"
        [attr.id]="select.id + '-clear-btn'"
        flat
        icon
        ninetyInputSuffix>
        <terra-icon icon="x" size="20" />
      </ninety-button>
    </ninety-input-wrapper>

    <div *ngIf="fuseProvider.fuse.hasNoResults$ | ngrxPush; else optionList" class="empty-state">
      {{ select.noResultsText }}
    </div>

    <ng-template #optionList>
      <div
        (ngModelChange)="onSelectValue($event)"
        [multiple]="true"
        [ngModel]="select.selected"
        id="{{ select.id }}-option-list"
        ninetyOptionList>
        <div
          #item="ninetyOption"
          *ngFor="let option of fuseProvider.fuse.managedOptions$ | ngrxPush; trackBy: trackBy"
          [attr.data-option-name]="option.value.name"
          [style.display]="option.visible ? '' : 'none'"
          [value]="option.value"
          class="team-option"
          id="{{ select.id }}-option-{{ option.value._id }}"
          ninetyOption>
          <terra-checkbox [ngModel]="item.isSelected" />
          <span
            *ngIf="option.match?.matches; else inline"
            [innerHTML]="option.value.name | boldSearchResults : option.match.matches">
          </span>
          <ng-template #inline>{{ option.value.name }}</ng-template>
        </div>
      </div>
    </ng-template>

    <div class="save-button-wrapper">
      <ninety-button (click)="close()" [disabled]="!select.selected.length" class="full-width" filled primary>
        OK
      </ninety-button>
    </div>
  </div>
</ng-template>
