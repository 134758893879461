import { RoleCode } from './role-code';
import { RoleName } from './role-name';

export const RoleNameAsCode: Readonly<Record<RoleName, RoleCode>> = {
  [RoleName.owner]: RoleCode.owner,
  [RoleName.admin]: RoleCode.admin,
  [RoleName.manager]: RoleCode.manager,
  [RoleName.managee]: RoleCode.managee,
  [RoleName.observer]: RoleCode.observer,
  [RoleName.coach]: RoleCode.owner,

  // deprecated
  [RoleName.lite]: RoleCode.lite,
};
