import { createSelector } from '@ngrx/store';

import { selectMazState } from '@ninety/getting-started/_state';
import { MazStatus } from '@ninety/ui/legacy/shared/models/maz/maz-status.enum';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentPerson } from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';

export namespace MazSelectors {
  export const selectMazChatEnabled = createSelector(
    selectFeatureFlag(FeatureFlagKeys.mazChat),
    selectCurrentPerson,
    (mazChatFlag, { mazStatus }) => mazChatFlag && mazStatus === MazStatus.ENABLED
  );
  export const selectIsMazChatExpanded = createSelector(selectMazState, state => state.mazChatExpanded);
  export const selectCommonQuestions = createSelector(selectMazState, state => state.commonQuestions);
  export const selectConversationHistory = createSelector(selectMazState, state => state.mazMessages);
  export const selectWaitingForMazResponse = createSelector(selectMazState, state => state.waitingForMazResponse);

  /** if feature flag is on and user has not opted in or out of maz */
  export const selectAskUserToEnableMaz = createSelector(
    selectFeatureFlag(FeatureFlagKeys.mazChat),
    selectCurrentPerson,
    (mazChatFlag, { mazStatus }) => mazChatFlag && !mazStatus
  );
}
