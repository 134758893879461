<div class="main-content meetings-list-content" [class.sidenav-expanded]="filterService.sideNavExpanded$ | async">
  <div class="container">
    <mat-card class="ninety-card title-card">
      <div>
        <ninety-button
          compact
          primary
          data-cy="meetings-list_print-agenda-button"
          target="_blank"
          class="uppercase right-space-10"
          (click)="printAgenda()"
          [disabled]="!(userTeams$ | async)"
          *ngIf="
            stateService.meetingType === MeetingType.weekly ||
            stateService.meetingType === MeetingType.quarterly ||
            stateService.meetingType === MeetingType.annualDayOne
          ">
          Print Agenda
          <terra-icon icon="file-print" />
        </ninety-button>
        <ninety-button
          compact
          primary
          data-cy="meetings-list_start-meeting-button"
          class="uppercase right-space-10"
          (click)="openStartMeetingDialog()"
          [disabled]="!(userTeams$ | async)"
          color="accent"
          *ngIf="stateService.isManageeOrAbove">
          Start {{ stateService.language.meeting.item }}
          <terra-icon icon="monitor-play" />
        </ninety-button>
      </div>
    </mat-card>

    <mat-card class="ninety-card meetings-card" *ngIf="activeMeetings$ | async as activeMeetings">
      <mat-card-header>
        <mat-card-title>Active {{ meetingType }}</mat-card-title>
      </mat-card-header>
      <mat-toolbar class="header-divs">
        <ninety-button
          compact
          secondary
          disableRipple
          *ngIf="stateService.meetingType === MeetingType.other"
          class="type-title">
          Type
        </ninety-button>
        <ninety-button compact secondary disableRipple class="date-title">Date</ninety-button>
        <ninety-button compact secondary disableRipple class="presenter">Presenter</ninety-button>
      </mat-toolbar>
      <mat-list class="ninety-list">
        <mat-list-item
          class="list-item"
          matRipple
          *ngFor="let meeting of filteredActiveMeetings; let i = index"
          attr.data-cy="meetings-list_active-list-item-{{ i }}"
          (click)="onSelectMeeting(meeting)"
          [ngClass]="{
            opened: selectedMeeting?._id === meeting._id || selectedMeeting?._id === meeting.previousMeetingId,
          }">
          <div class="meeting-type" *ngIf="stateService.meetingType === MeetingType.other">
            {{ meetingName(meeting) }}
          </div>
          <div class="meeting-date">{{ meeting.date | date : 'medium' : undefined : stateService.locale }}</div>

          <div class="count-icons-wrapper">
            <ninety-icon-with-count icon="attach_file" [count]="meeting.attachments?.length"></ninety-icon-with-count>
          </div>

          <div class="presenter">
            <terra-avatar
              *ngIf="store.select(selectUserAvatarInfo(meeting.presenterUserId)) | ngrxPush as avatar"
              [initials]="avatar.userInitials"
              [src]="avatar.pictureURL"
              [ninetyContactCardHover]="meeting.presenterUserId"
              [interactive]="true" />
          </div>
          <div class="fill-remaining-space"></div>
          <ninety-button
            compact
            primary
            data-cy="meetings-list_action-button"
            (click)="meetingService.getAndStartMeeting(meeting._id)"
            class="uppercase right-space-10"
            matTooltip="{{
              meeting
                | getStartOrContinueMeetingTextPipe : stateService.meetingIsAnnual : stateService.language.meeting.item
            }}"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip"
            attr.aria-label="{{
              meeting
                | getStartOrContinueMeetingTextPipe : stateService.meetingIsAnnual : stateService.language.meeting.item
            }}"
            ninetyStopPropagation>
            {{
              meeting
                | getStartOrContinueMeetingTextPipe : stateService.meetingIsAnnual : stateService.language.meeting.item
            }}
            <ng-container
              *ngIf="
                stateService.meetingIsAnnual && !meeting.endDate;
                then startIcon;
                else continueIcon
              "></ng-container>
            <ng-template #startIcon>
              <terra-icon icon="monitor-play" />
            </ng-template>
            <ng-template #continueIcon>
              <terra-icon icon="redo" variant="fill" />
            </ng-template>
          </ninety-button>
          <ninety-button
            flat
            icon
            negative
            *ngIf="meeting.presenterUserId === stateService.currentCompanyUser._id"
            data-cy="meetings-list_delete-button"
            (click)="deleteMeeting(meeting)"
            matTooltip="Delete Meeting"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip"
            aria-label="Delete Meeting"
            ninetyStopPropagation>
            <terra-icon icon="delete" />
          </ninety-button>
        </mat-list-item>
      </mat-list>
      <mat-card-footer *ngIf="activeMeetings?.length">
        <terra-divider />
        <mat-paginator
          data-cy="paginator"
          [length]="numberOfActiveMeetings"
          [pageSize]="activeMeetingsPageSize"
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
          (page)="onActiveMeetingsPageEvent($event)">
        </mat-paginator>
      </mat-card-footer>
      <ninety-alert *ngIf="activeMeetings && !activeMeetings.length" alertClass="secondary">
        This team does not have any active {{ meetingType }}.
      </ninety-alert>
    </mat-card>

    <mat-card class="ninety-card meetings-card">
      <mat-card-header>
        <mat-card-title>Past {{ meetingType }}</mat-card-title>
      </mat-card-header>
      <mat-toolbar class="header-btns">
        <mat-card-subtitle class="presenter-title">Presenter</mat-card-subtitle>
        <mat-card-subtitle class="type-title" *ngIf="stateService.meetingType === MeetingType.other"
          >Type</mat-card-subtitle
        >
        <mat-card-subtitle class="past-date-title">Date</mat-card-subtitle>
        <mat-card-subtitle class="duration-title">Duration</mat-card-subtitle>
        <div class="fill-remaining-space"></div>
        <mat-card-subtitle class="rating-title">Rating</mat-card-subtitle>
        <mat-card-subtitle class="recap-title">Resend</mat-card-subtitle>
      </mat-toolbar>
      <mat-list class="ninety-list">
        <mat-list-item
          attr.data-cy="meetings-list_past-list-item-{{ i }}"
          class="list-item"
          matRipple
          *ngFor="let meeting of pastMeetings; let i = index"
          (click)="onSelectMeeting(meeting)"
          [ngClass]="{
            opened: selectedMeeting?._id === meeting._id || selectedMeeting?._id === meeting.previousMeetingId,
          }">
          <div class="meeting-presenter">
            <terra-avatar
              *ngIf="store.select(selectUserAvatarInfo(meeting.presenterUserId)) | ngrxPush as avatar"
              [initials]="avatar.userInitials"
              [src]="avatar.pictureURL"
              [ninetyContactCardHover]="meeting.presenterUserId"
              [interactive]="true" />
          </div>
          <div class="meeting-type" *ngIf="stateService.meetingType === MeetingType.other">
            {{ meetingName(meeting) }}
          </div>
          <div class="past-meeting-date">{{ meeting.date | date : 'medium' : undefined : stateService.locale }}</div>
          <div class="meeting-duration">
            <ninety-meeting-duration [meeting]="meeting"></ninety-meeting-duration>
          </div>
          <div class="fill-remaining-space"></div>
          <div class="meeting-average">{{ getMeetingAverageRating(meeting) }}</div>
          <div class="resend-recap">
            <ninety-button
              primary
              flat
              icon
              *ngIf="stateService.isAdminOrOwner || stateService.companyUserId === meeting.presenterUserId"
              (click)="meetingService.sendMeetingRecapEmail(meeting, true)"
              matTooltip="Resend Email Recap"
              matTooltipPosition="above"
              matTooltipClass="ninety-tooltip"
              aria-label="Resend Email Recap"
              ninetyStopPropagation
              [disabled]="spinnerService.primary$ | async">
              <terra-icon icon="send" />
            </ninety-button>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-card-footer *ngIf="pastMeetings?.length">
        <terra-divider />
        <mat-paginator
          data-cy="paginator"
          [length]="numberOfPastMeetings"
          [pageSize]="pastMeetingsPageSize"
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
          (page)="onPastMeetingsPageEvent($event)">
        </mat-paginator>
      </mat-card-footer>
      <ninety-alert *ngIf="pastMeetings && !pastMeetings.length" alertClass="secondary">
        This team does not have any past {{ meetingType }}.
      </ninety-alert>
    </mat-card>
  </div>
</div>
