/* eslint-disable @angular-eslint/no-output-rename, @angular-eslint/no-outputs-metadata-property */
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, TrackByFunction } from '@angular/core';
import { PushPipe } from '@ngrx/component';

import { BoldSearchResultsPipe } from '@ninety/accountability-chart/pipes/bold-search-results.pipe';
import { SelectChipTemplateDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/select-chip-template.directive';
import { SelectImplementationDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/select-implementation.directive';
import { ChipSelectKitchenSinkComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/implementations/chip-select-kitchen-sink/chip-select-kitchen-sink.component';
import { ManagedOptions } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/services/fuse-search.service.model';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';

import { FuseTeamDirective } from '../../services/fuse-team.directive';

import { SelectTeamByIdPipe } from './select-team-by-id.pipe';

@Component({
  selector: 'ninety-chip-team-select-box',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    ChipSelectKitchenSinkComponent,
    SelectChipTemplateDirective,
    PushPipe,
    SelectTeamByIdPipe,
    BoldSearchResultsPipe,
  ],
  hostDirectives: [
    {
      directive: SelectImplementationDirective,
      inputs: ['multiple', 'readonly', 'disabled', 'id', 'placeholder', 'noResultsText', 'selected: selectedTeamIds'],
      outputs: ['selectedChange: selectedTeamIdsChanged'],
    },
    {
      directive: FuseTeamDirective,
      inputs: ['ninetyFuseTeam: teams$', 'ninetyFuseTeamOptions: fuseOptions'],
    },
  ],
  template: `
    <ninety-chip-select-kitchen-sink [trackByFn]="trackBy" [chipTemplate]="chipTemplate">
      <ng-template let-option>
        <span
          *ngIf="option.match?.matches; else inline"
          [innerHTML]="option.value.name | boldSearchResults : option.match.matches">
        </span>
        <ng-template #inline>{{ option.value.name }}</ng-template>
      </ng-template>
    </ninety-chip-select-kitchen-sink>

    <ng-template #chipTemplate="ninetySelectChipTemplate" ninetySelectChipTemplate let-teamId="value">
      {{ ($any(teamId) | selectTeamById | ngrxPush)?.name ?? '' }}
    </ng-template>
  `,
})
export class ChipTeamSelectBoxComponent {
  protected trackBy: TrackByFunction<ManagedOptions<Team>> = (_, item) => item.value._id;
}
