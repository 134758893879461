import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs';

import { TerraSwitchModule } from '@ninety/terra';
import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Team, TeamSettings } from '@ninety/ui/legacy/shared/models/_shared/team';
import { MeasurableWeeklyStartDay } from '@ninety/ui/legacy/shared/models/company/company';
import { CompanySettings } from '@ninety/ui/legacy/shared/models/company/company-settings';
import { MeasurableWeeklyStartDays } from '@ninety/ui/legacy/shared/models/company/measurable-weekly-start-days';
import {
  PeriodInterval,
  ScorecardPeriodToHideCurrentPeriod,
} from '@ninety/ui/legacy/shared/models/scorecard/period-interval.enum';
import { HideCurrentPeriodOnScorecard } from '@ninety/ui/legacy/shared/models/scorecard/show-current-period-on-scorecard';
import { TeamListStateActions } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.actions';
import { selectCompanySettings } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

@Component({
  selector: 'ninety-scorecard-settings',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LetDirective,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    TerraSwitchModule,
  ],
  templateUrl: './scorecard-settings.component.html',
  styleUrls: ['./scorecard-settings.component.scss'],
})
export class ScorecardSettingsComponent implements OnInit {
  //also used to hide hideCurrentPeriodOnScorecard on the company settings tab
  @Input() selectedTeam: Team;

  @HostBinding('class') class = 'settings-wrp';
  teamSettings: TeamSettings;

  measurableWeeklyStartDays = MeasurableWeeklyStartDays;
  PeriodInterval = PeriodInterval;
  hideCurrentPeriodOnScorecardCopy: HideCurrentPeriodOnScorecard;
  ScorecardPeriodToHideCurrentPeriod = ScorecardPeriodToHideCurrentPeriod;
  settings$ = this.store.select(selectCompanySettings).pipe(map(settings => cloneDeep(settings)));
  language$ = this.store.select(selectLanguage);

  constructor(public stateService: StateService, private companyService: CompanyService, private store: Store) {}

  ngOnInit() {
    this.teamSettings = cloneDeep(this.selectedTeam?.settings);
  }

  startDayIsSame(option: MeasurableWeeklyStartDay, value: MeasurableWeeklyStartDay): boolean {
    return option.offset === value.offset;
  }

  updateSettings<K extends keyof CompanySettings>(prop: K, value: CompanySettings[K]): void {
    this.companyService.updateSettingsWithKeyValue(prop, value).subscribe();
    //using selectedTeam to detect if on the company settings page(not scorecard dialog) and update the scorecard tabs
    if (!this.selectedTeam && prop === 'scorecardTrailing') {
      this.stateService.scorecardTrailingTabToggle$.next(value as any);
    }
  }

  onToggleCurrentPeriod() {
    this.store.dispatch(
      TeamListStateActions.updateSettings({
        teamId: this.selectedTeam._id,
        update: { ...this.teamSettings },
      })
    );
  }
}
