import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { merge } from 'lodash';
import { Observable, catchError, filter, mergeMap, tap, throwError } from 'rxjs';

import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { MyNinetyWidgetSettings, UserSettings } from '@ninety/ui/legacy/shared/models/_shared/user-settings';
import { MeasurableChartData } from '@ninety/ui/legacy/shared/models/charts/measurable-chart-data';
import { RockStatusChartData } from '@ninety/ui/legacy/shared/models/charts/rock-status-chart-data';
import { TodoCompletionChartData } from '@ninety/ui/legacy/shared/models/charts/todo-completion-chart-data';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { MyNinetyWidgetTypeKeys } from '@ninety/ui/legacy/shared/models/my-90/my-90-widget-type-keys';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockSortField } from '@ninety/ui/legacy/shared/models/rocks/rock-sort-field';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';
import { PeriodInterval } from '@ninety/ui/legacy/shared/models/scorecard/period-interval.enum';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';
import { TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

import { RockService } from '../../rocks/_shared/rock.service';
import { TodoService } from '../../todos/_shared/todo.service';

@Injectable({
  providedIn: 'root',
})
export class My90Service {
  private my90Api = '/api/v4/MyFocus';
  gettingTodos: boolean;
  gettingRocks: boolean;
  gettingMilestones: boolean;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private stateService: StateService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private rockService: RockService,
    private todoService: TodoService,
    private userService: UserService,
    private store: Store
  ) {}

  getMyFocusRocks(
    teamId: string,
    page: number,
    pageSize: number,
    sortField: RockSortField,
    sortDirection: SortDirection,
    statusCode: RockStatusCode,
    archived: boolean
  ): Observable<PagedResponse<Rock>> {
    this.gettingRocks = true;
    const params = QueryParamsService.build(
      {
        teamId,
        page,
        pageSize,
        sortField,
        sortDirection,
        statusCode,
        archived,
        searchText: this.filterService.searchText$.value.trim(),
      },
      true
    );
    return this.http.get<PagedResponse<Rock>>(`${this.my90Api}/Rocks`, { params }).pipe(
      tap(resp => {
        this.gettingRocks = false;
        this.rockService.teamRocksPaginated$.next(resp);
      }),
      catchError((e: unknown) => {
        this.gettingRocks = false;
        return this.errorService.notify(
          e,
          `Could not load ${this.stateService.language.rock.items} for ${this.stateService.language.my90.route} page.
            Please try refreshing the page.`
        );
      })
    );
  }

  getPersonalArchivedTodos(page: number, pageSize: number): Observable<PagedResponse<Todo>> {
    this.gettingTodos = true;
    const params = QueryParamsService.build(
      {
        page,
        pageSize,
        teamId: extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId),
        searchText: this.filterService.searchText$.value.trim(),
        personalArchived: true,
      },
      true
    );
    return this.http.get<PagedResponse<Todo>>(`${this.my90Api}/Todos`, { params }).pipe(
      tap(() => (this.gettingTodos = false)),
      catchError((e: unknown) => {
        this.gettingTodos = false;
        return this.errorService.notify(
          e,
          `Could not load personal archived ${this.stateService.language.todo.items} for ${this.stateService.language.my90.route} page.
              Please try refreshing the page.`
        );
      })
    );
  }

  getTodosChartData(): Observable<TodoCompletionChartData> {
    return this.http
      .get<TodoCompletionChartData>(`${this.my90Api}/TodosChartData`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.todo.items} chart data. Please try again.`
          )
        )
      );
  }

  getMeasurablesChartData(
    periodInterval: PeriodInterval = this.stateService.currentUser.settings.measurableChart.periodInterval
  ): Observable<MeasurableChartData> {
    return this.http
      .get<MeasurableChartData>(`${this.my90Api}/MeasurablesChartData?periodInterval=${periodInterval}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.measurable.items} chart data. Please try again.`
          )
        )
      );
  }

  getRocksChartData(): Observable<RockStatusChartData> {
    return this.http
      .get<RockStatusChartData>(`${this.my90Api}/RocksStatusChartData`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.rock.items} chart data. Please try again.`
          )
        )
      );
  }

  confirmArchiveCompletedDialog(): Observable<boolean> {
    const confirmDeleteDialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
      data: {
        title: 'Archive Completed?',
        message: `All completed ${this.stateService.language.todo.items} will be archived.`,
        confirmButtonText: 'Archive',
      },
    });
    return confirmDeleteDialogRef.afterClosed();
  }

  archiveCompletedTodos(): Observable<void> {
    return this.confirmArchiveCompletedDialog().pipe(
      filter(archiveAll => !!archiveAll),
      tap(() => (this.gettingTodos = true)),
      mergeMap(() => this.todoService.archiveMyCompletedTodos()),
      catchError((e: unknown) => {
        this.gettingTodos = false;
        return throwError(e);
      }),
      tap(() => (this.gettingTodos = false))
    );
  }

  /**
   * Patch a widgets settings by their key. Method takes care of merging any existing settings and constructing the
   * smallest possible PATCH given the current model.
   *
   * Handles:
   * 1. No My90 settings
   * 2. MyNinety settings, but no settings for this key
   * 3. MyNinety settings, and settings for this key
   */
  patchMy90SettingByWidgetKey(key: MyNinetyWidgetTypeKeys, update: Partial<MyNinetyWidgetSettings>): Observable<User> {
    const newSettingsPatch: Partial<UserSettings> = {
      myNinety: {
        widgets: {
          [key]: { ...update },
        },
      },
    };

    const settingsUpdate: Partial<UserSettings> = merge(
      {},
      this.stateService.currentUser.settings.myNinety
        ? { myNinety: this.stateService.currentUser.settings.myNinety }
        : { myNinety: {} },
      newSettingsPatch
    );

    return this.userService.updateUserSettings(settingsUpdate);
  }
}
