import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { TeammateInvitePayloadModel } from '@ninety/login/_api/models/teammate-invite-payload.model';
import { SignUp } from '@ninety/ui/legacy/shared/models/_shared/sign-up';
import { RefreshToken } from '@ninety/ui/legacy/shared/models/auth/refresh-token';
import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { ClearbitEmployeeRange, ClearbitIndustry } from '@ninety/ui/legacy/shared/models/company/clearbit.model';
import { HowFamiliar } from '@ninety/ui/legacy/shared/models/company/how-familiar.enum';
import { RegisterCompanyResponse } from '@ninety/ui/legacy/shared/models/company/register-company-response';
import { SignupCompanyRole } from '@ninety/ui/legacy/shared/models/user/signup-company-role.enum';

import { StepConfig } from './signup-state.model';

export const SignupStateActions = createActionGroup({
  source: 'Signup',
  events: {
    'Start Spinner': emptyProps(),
    'Stop Spinner': emptyProps(),
    'Navigate to Accept Invite': props<{ email: string }>(),
    'Ignore Invites': emptyProps(),
    'Go To Step': props<{ index: number }>(),
    'Company Invite Step Answer': props<{ acceptInvite: boolean }>(),
    'Display Invite State Dialog': props<{ inviteStatus: any }>(),
    'Do Not Check Email': emptyProps(),
    'Check Email': props<{ email: string }>(),
    'Sign Up': emptyProps(),
    'Get Clearbit Details': emptyProps(),
    'Set Clearbit Details': props<{ industry: string; employees: string }>(),
    'Clearbit Not Found': emptyProps(),
    'Set Signup Status': emptyProps(),
    'Signup Status Api error': emptyProps(),
    'Get Pending Company Invites': emptyProps(),
    'Set Pending Company Invites': props<{ companyInvites: string[] }>(),
    'Set Account Details': props<{ firstName: string; lastName: string; password: string }>(),
    'Set Company Details': props<{
      companyName: string;
      industry: ClearbitIndustry;
      employees: ClearbitEmployeeRange;
      signupCompanyRole: SignupCompanyRole;
      submitEvent: SubmitEvent;
      bosStep: StepConfig;
    }>(),
    'Set Company BOS': props<{
      bos: BusinessOperatingSystem;
      howFamiliar: HowFamiliar;
      runMeetings: boolean;
      submitEvent: SubmitEvent;
    }>(),
    'Skip BOS For 9Oos': emptyProps(),
    'Sign Up with Idp': props<{ idToken: string; user: SignUp }>(),
    'Sign Up with Idp Error': emptyProps(),
    'Finish Sign Up': props<{ idToken: string; user: SignUp }>(),
    'Create Company': props<{ personId: string; personMetadataId: string }>(),
    'Create Company Error': props<{ error: any }>(),
    'Sign Up Company Created': props<{ response: RegisterCompanyResponse }>(),
    'Sign Up Initialize User': props<{ token: RefreshToken }>(),
    'Sign Up Complete': emptyProps(),
    'Navigate To Login': emptyProps(),
    'Set Referral Code': props<{ referralCode: string }>(),
  },
});

export const SignupTeammatesActions = createActionGroup({
  source: 'Signup Add Teammates',
  events: {
    'Start Adding Teammates': emptyProps(),
    'Add Teammate': emptyProps(),
    'Dialog Canceled': emptyProps(),
    'Remove Teammate': props<{ index: number }>(),
    'Edit Teammate': props<{ teammate: TeammateInvitePayloadModel; index: number }>(),
    'Save Teammate': props<{ teammate: TeammateInvitePayloadModel }>(),
    'Update Teammate': props<{ teammate: TeammateInvitePayloadModel; index: number }>(),
    'Add Teammates Later': emptyProps(),
    'Display Skip Teammates Dialog': emptyProps(),
    'Finish And Invite Teammates': emptyProps(),
  },
});
