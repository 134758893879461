export enum PhoneType {
  main = '0000',
  cell = '0001',
  home = '0002',
  work = '0003',
}

export enum PhoneTypeLabel {
  main = 'Main',
  cell = 'Cell',
  home = 'Home',
  work = 'Work',
}
