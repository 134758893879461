import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { ResponsibilitiesChartActions } from '@ninety/accountability-chart/_state/chart/actions/responsibility-chart.actions';
import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { GuideSelectors } from '@ninety/getting-started/guide/_state/guide.selectors';
import { IssuesActions } from '@ninety/issues/_state/issues.actions';
import { MeetingConcludeActions } from '@ninety/pages/meetings/_state/meetings.actions';
import { RocksActions } from '@ninety/rocks/_state/rocks.actions';
import { MeasurableActions } from '@ninety/scorecard/_state/measurables.actions';
import { KpiGroupApiActions } from '@ninety/scorecard-v2/_state/actions/kpi-group-api.actions';
import { ScorecardPageActions } from '@ninety/scorecard-v2/_state/actions/scorecard-page.actions';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { Page, PageViews } from '@ninety/ui/legacy/shared/models/user/page-views';
import { UserInviteActions, UsersStateActions } from '@ninety/ui/legacy/state/app-entities/users/users-state.actions';
import { selectCompanySltTeamId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { VtoStateActions } from '@ninety/vto/_state/vto-state.actions';

/** Follows "Getting Started" Guide: Phases & Steps (Brand New Company) in Confluence */

@Injectable()
export class StepCompletionChecksEffects {
  /** ======================================================================================================
   *                             Brand New Company Completion Steps
   * ======================================================================================================*/

  /** Phase 1 - Step 1 - Invite your Leadership team */
  invitedSomeoneToLeadershipTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserInviteActions.inviteUser),
      map(({ user }) => user.teams.map(t => t.teamId)),
      concatLatestFrom(() => [
        this.store.select(GuideSelectors.selectPhases),
        this.store.select(selectCompanySltTeamId),
      ]),
      filter(([teamIds, phases, sltId]) => !phases?.[0]?.steps?.[0]?.completed && teamIds.includes(sltId)),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** Phase 1 - Step 2 - Create Issues for your Leadership team */
  issueCreatedForLeadershipTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuesActions.createIssue),
      concatLatestFrom(() => [
        this.store.select(GuideSelectors.selectPhases),
        this.store.select(selectCompanySltTeamId),
      ]),
      filter(([{ issue }, phases, sltId]) => !phases?.[0]?.steps?.[1]?.completed && issue.teamId === sltId),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** Phase 1 - Step 3 - Create Rocks for your Leadership team */
  rockCreatedForLeadershipTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksActions.createRock),
      concatLatestFrom(() => [
        this.store.select(GuideSelectors.selectPhases),
        this.store.select(selectCompanySltTeamId),
      ]),
      filter(([{ rock }, phases, sltId]) => !phases?.[0]?.steps?.[2]?.completed && rock.teamId === sltId),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** Phase 1 - Step 4 - Create Measurables for your Leadership team */

  /** Scorecard V2 */
  measurableAddedScorecardV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KpiGroupApiActions.addKpisToKpiGroupSuccess),
      concatLatestFrom(() => [
        this.store.select(GuideSelectors.selectPhases),
        this.store.select(selectCompanySltTeamId),
      ]),
      filter(([{ kpiGroup }, phases, sltId]) => !phases?.[0]?.steps?.[3]?.completed && kpiGroup?.teamId === sltId),
      map(() => GuideActions.checkCompletionStatus())
    )
  );
  /** Legacy scorecard */
  measurableAddedLegacyScorecard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeasurableActions.createAndAddMeasurable),
      concatLatestFrom(() => [
        this.store.select(GuideSelectors.selectPhases),
        this.store.select(selectCompanySltTeamId),
      ]),
      filter(([{ teamId }, phases, sltId]) => !phases?.[0]?.steps?.[3]?.completed && teamId === sltId),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** Phase 1 - Step 5 - Get ready for your first Level 10 Meeting™ */
  /** Phase 2 - Step 1 - Run your first Level 10 Meeting */
  /** Phase 2 - Step 5 - Run a second Level 10 Meeting */
  /** Phase 3 - Step 4 - Run a Quarterly Meeting */
  conductedLevelTenMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingConcludeActions.meetingConcluded),
      filter(({ meetingType }) => meetingType === MeetingType.weekly || meetingType === MeetingType.quarterly),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** Phase 2 - Step 2 - Capture more To-Dos from the Level 10 Meeting
   *
   * Create todo during meeting - handled in meeting service 'subscribeToTodos'
   */

  /** Phase 2 - Step 3 - Encourage the team to populate more Issues
   *
   * Any Issue created after the first Level 10 Meeting concludes for the Leadership team.
   */

  /** Phase 2 - Step 4 - Encourage the team to populate Headlines
   *
   * Any Headline created after the first Level 10 Meeting concludes for the Leadership team.
   */

  /** Phase 3 - Step 1 - Enter your V/TO® */
  vtoUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VtoStateActions.vtoUpdated),
      concatLatestFrom(() => this.store.select(GuideSelectors.selectPhases)),
      filter(([_, phases]) => !phases?.[2]?.steps?.[0]?.completed),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** Phase 3 - Step 2 - Encourage the team to update Quarterly Measurables */

  /** Phase 3 - Step 3 - Capture Long-Term Issues  */

  /** ======================================================================================================
   *                             New Team Member Completion Steps
   * ======================================================================================================*/

  /** Phase 1 - Step 1 - Personalize your profile */
  /** Phase 1 - Step 3 - Review your team’s Issues */
  /** Phase 1 - Step 4 - Review your team’s Rocks */
  /** Phase 3 - Step 1 - Align with your company and team's V/TO® */
  visitedPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.updateSuccess),
      filter(({ changes }) => !!changes.pageViews),
      map(() => GuideActions.checkCompletionStatus())
    )
  );

  /** these pages have init actions that we can listen to */
  /** Phase 1 - Step 5 - Review your team’s Measurables */
  /** Phase 3 - Step 2 - Review the Accountability Chart */
  trackPageView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScorecardPageActions.init, ResponsibilitiesChartActions.init),
      map(({ type }) => setPage(type)),
      filter(page => !!page),
      map(page => GuideActions.pageViewed({ page }))
    )
  );

  /** Phase 1 - Step 2 - Get familiar with your role and permissions in Ninety */

  /** Phase 2 - Step 1 - Join or host a Level 10 Meeting™ */

  /** Phase 2 - Step 2 - Capture more To-Dos from the Level 10 Meeting */

  /** Phase 2 - Step 3 - Capture more Issues for the next Level 10 Meeting */

  /** Phase 3 - Step 3 - Plan for your Quarterly Conversation */

  constructor(private actions$: Actions, private store: Store) {}
}

function setPage(type: string): keyof PageViews {
  const actionType = type.toLowerCase();

  if (actionType.includes('responsibilities')) return Page.responsibilitiesChart;
  if (actionType.includes('scorecard')) return Page.scorecard;

  return null;
}
