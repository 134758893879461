import { createSelector } from '@ngrx/store';

import { selectCompany } from '../company/company-state.selectors';
import { selectShouldHaveAccessToSiteBillingV2 } from '../company/subscription/subscription-state.selectors';
import { selectCurrentPerson } from '../current-person/current-person.selectors';

export const selectAuthShouldHaveAccess = createSelector(
  selectCurrentPerson,
  selectCompany,
  selectShouldHaveAccessToSiteBillingV2,
  (person, company, shouldHaveAccessToSiteBillingV2) => {
    if (person.isImplementer || company.implementerFree) return true;
    return shouldHaveAccessToSiteBillingV2;
  }
);

export const selectNavLinksDisabled = createSelector(selectAuthShouldHaveAccess, shouldHaveAccess => !shouldHaveAccess);
