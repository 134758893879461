import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { PageHeaderComponent } from '@ninety/_layouts/components/page-header/page-header.component';
import { NinetyTabsBarComponent } from '@ninety/pages/assessments/_shared/tabs-bar/ninety-tabs-bar.component';
import { MeetingsStateSelectors } from '@ninety/pages/meetings/_state/meetings.selectors';
import { TerraModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { ButtonGroupComponent } from '@ninety/ui/legacy/components/buttons/button-group/button-group.component';
import { MeetingTypeToMeetingAgendaLanguage } from '@ninety/ui/legacy/shared/index';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import {
  selectCurrentUserIsManageeOrAbove,
  selectCurrentUserIsManagerOrAbove,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { FeatureFlagKeys, selectLanguage } from '@ninety/ui/legacy/state/index';
import { selectCurrentRoute } from '@ninety/ui/legacy/state/route.selectors';

import { AgendaV2Service } from '../_services/agenda-v2.service';
import { MeetingsAgendasV2Actions, MeetingsPageActions, MeetingsTabsActions } from '../_state/meetings.actions';

@Component({
  selector: 'ninety-meetings-v2',
  imports: [
    CommonModule,
    NinetyTabsBarComponent,
    MatTabsModule,
    LetDirective,
    RouterModule,
    ButtonComponent,
    TerraModule,
    ButtonGroupComponent,
    MatMenuModule,
    PageHeaderComponent,
  ],
  templateUrl: './meetings-v2.component.html',
  standalone: true,
  styleUrls: ['./meetings-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingsV2Component implements OnInit, OnDestroy {
  private store = inject(Store);
  public agendaV2Service = inject(AgendaV2Service);

  meetingRoutes = MeetingRoutes;
  meetingTypeToMeetingAgendaLanguage = MeetingTypeToMeetingAgendaLanguage;
  shouldHideDescriptionOnMobile = true;

  readonly currentRoute$ = this.store.select(selectCurrentRoute);
  readonly isManagerOrAbove$ = this.store.select(selectCurrentUserIsManagerOrAbove);
  readonly showAgendasV2$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.meetingAgendasV2));
  readonly meetingScheduling$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.meetingScheduling));
  readonly language$ = this.store.select(selectLanguage);
  readonly isManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);
  readonly teamName$ = this.store.select(MeetingsStateSelectors.selectSelectedTeamName);

  readonly agendaTypeOrIdOrNew$ = this.store.select(MeetingsStateSelectors.selectAgendaTypeOrAgendaIdOrNewFromUrl);

  readonly selectedAgendaName$ = this.store.select(MeetingsStateSelectors.selectSelectedTeamAgendaName);
  readonly selectedAgendaIsCustom$ = this.store.select(MeetingsStateSelectors.selectSelectedIsCustom);

  ngOnInit(): void {
    this.store.dispatch(MeetingsTabsActions.init());
  }

  ngOnDestroy(): void {
    this.store.dispatch(MeetingsTabsActions.reset());
  }

  openUniversalCreateModal() {
    this.store.dispatch(MeetingsPageActions.openUniversalCreateModal());
  }

  triggerSaveNewCustomAgenda() {
    this.store.dispatch(MeetingsAgendasV2Actions.triggerSaveNewCustomAgenda());
  }

  triggerUpdateAgenda() {
    this.store.dispatch(MeetingsAgendasV2Actions.triggerUpdateAgenda());
  }

  triggerDeleteCustomAgenda() {
    this.store.dispatch(MeetingsAgendasV2Actions.triggerDeleteCustomAgenda());
  }

  triggerDiscardChanges() {
    this.store.dispatch(MeetingsAgendasV2Actions.triggerDiscardChanges());
  }

  triggerResetAgenda() {
    this.store.dispatch(MeetingsAgendasV2Actions.triggerResetAgenda());
  }
}
