/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';

import { selectCompanyId } from '../..';
import { CompanyService } from '../../../_core/services/company.service';

import { CompanyActions } from './company-state.actions';

@Injectable()
export class CompanyStateEffects {
  constructor(private actions$: Actions, private store: Store, private companyService: CompanyService) {}

  updateCompany$ = createEffect(() => this.actions$.pipe(ofType(CompanyActions.handleCompanyUpdate)));

  updateCompanyTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.handleCompanyUpdate),
      filter(({ changes }) => !!changes?.settings?.colorBranding),
      map(({ changes }) => CompanyActions.updateTheme({ colorBranding: changes.settings.colorBranding }))
    )
  );

  refreshCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.refreshCurrentCompanyFromDb),
      concatLatestFrom(() => this.store.select(selectCompanyId)),
      switchMap(([, companyId]) =>
        this.companyService.getCompanyById(companyId).pipe(map(company => CompanyActions.init({ company })))
      )
    )
  );
}
