import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';

import { StateService } from '../../../_core/services/state.service';
import { LocalStorageService } from '../../../_core/services/storage.service';
import { TimeService } from '../../../_core/services/time.service';
import { UserService } from '../../../_core/services/user.service';
import { CompanyUser } from '../../../_shared';
import { User } from '../../../_shared/models/_shared/user';
import { SpinnerActions } from '../../app-global/spinner/spinner-state.actions';
import { appActions } from '../../app.actions';
import { FeatureFlagKeys } from '../feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '../feature-flag/feature-flag-state.selectors';
import { UserTeamsActions } from '../team-list/team-list-state.actions';

import { UserPreferencesActions, UserSettingsActions, UsersStateActions } from './users-state.actions';
import { selectCurrentUser, UserSelectors } from './users-state.selectors';

@Injectable()
export class UsersStateEffects {
  timezonePromptFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.timezonePrompt));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private stateService: StateService,
    private timeService: TimeService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.update),
      concatMap(({ userId, update }) =>
        // api hands back entire updated user
        this.userService.update(update, userId).pipe(map(() => ({ userId, update })))
      ),
      map(({ userId: _id, update: changes }) => UsersStateActions.updateSuccess({ _id, changes }))
    )
  );

  /** this will update role permissions, etc. if needed.  Hopefully this won't be needed for much longer */
  updateStateServiceCurrentCompanyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersStateActions.updateSuccess),
        concatLatestFrom(() => this.stateService.currentCompanyUser$),
        map(([{ _id, changes }, currentCompanyUser]) => {
          if (_id === currentCompanyUser._id) {
            this.stateService.currentCompanyUser$.next({ ...currentCompanyUser, ...changes } as CompanyUser);
          }
        })
      ),
    { dispatch: false }
  );

  addUsersToTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.addUsersToTeam),
      concatMap(({ users, teamId }) => {
        const updates = users.map(u => {
          if (u.teams.some(t => t.teamId === teamId)) {
            return;
          }
          return this.userService.update({ teams: [...u.teams, { teamId }] }, u._id);
        });
        return forkJoin(updates);
      }),
      map(users => UserTeamsActions.addUsersToTeamSuccess({ users })),
      catchError((error: unknown) => of(UserTeamsActions.addUsersToTeamFailed({ error })))
    )
  );

  removeUserFromTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.removeUserFromTeam),
      concatLatestFrom(({ userId, teamId }) => this.store.select(UserSelectors.selectUserById(userId))),
      concatMap(([{ userId, teamId }, user]) =>
        this.userService.update({ teams: [...user.teams.filter(t => t.teamId !== teamId)] }, user._id).pipe(
          map(user => UserTeamsActions.removeUserFromTeamSuccess({ userId, teamId })),
          catchError((error: unknown) => of(UserTeamsActions.removeUserFromTeamFailed({ error })))
        )
      )
    )
  );

  userUpdateStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserTeamsActions.removeUserFromTeam, UserTeamsActions.addUsersToTeam),
      map(action => SpinnerActions.startPrimary({ source: action.type }))
    )
  );

  userUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserTeamsActions.removeUserFromTeamSuccess,
        UserTeamsActions.removeUserFromTeamFailed,
        UserTeamsActions.addUsersToTeamFailed,
        UserTeamsActions.addUsersToTeamSuccess
      ),
      map(action => SpinnerActions.stopPrimary({ source: action.type }))
    )
  );

  updateUserTutorials = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferencesActions.hideUserTutorial),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      concatMap(([{ userTutorialType }, currentUser]) =>
        this.userService.update({
          tutorialsHidden: { ...currentUser.tutorialsHidden, [userTutorialType]: true },
        })
      ),
      map(user => UserPreferencesActions.userTutorialsUpdated({ tutorialsHidden: user.tutorialsHidden }))
    )
  );

  checkTimezonePrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.checkToShowTimeZoneUpdateDialog),
      concatLatestFrom(() => [this.store.select(selectCurrentUser), this.timezonePromptFlag$]),
      filter(([_, _user, timezonePromptFlag]) => {
        const flagEnabled = !!timezonePromptFlag;
        const notLoginUrl = !this.router.url.includes('/login/');
        const dontAskToUpdateTimezoneAgain = this.localStorageService.get('dontAskToUpdateTimezoneAgain') !== 'true';

        const shouldProceed = flagEnabled && notLoginUrl && dontAskToUpdateTimezoneAgain;
        return shouldProceed;
      }),
      map(([_, user]) => {
        const browserTimezoneEntry = this.timeService.getBrowserTimezone();
        return { userTimeZoneNotFormatted: user?.settings.timezone, browserTimezoneEntry };
      }),
      switchMap(({ userTimeZoneNotFormatted, browserTimezoneEntry }) => {
        const shouldShowPromptOutsideOfLoginFlow = userTimeZoneNotFormatted !== browserTimezoneEntry.name;

        if (shouldShowPromptOutsideOfLoginFlow) {
          return this.userService
            .showTimezoneUpdateDialog(browserTimezoneEntry)
            .pipe(map((user: User) => UserSettingsActions.updateTimezone({ user })));
        } else {
          return of(appActions.noop());
        }
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.deleteUser),
      switchMap(({ _id }) => {
        //Make the call to the backend to delete the user and update the store
        return this.userService.deleteUser(_id).pipe(
          map(() => {
            return UsersStateActions.deleteUserSuccess({ _id }); //Dispatch success action, will refetch directory users
          }),
          catchError((error: unknown) => {
            return of(UsersStateActions.deleteUserFailed({ error }));
          })
        );
      })
    )
  );

  deactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersStateActions.deactivateUser),
      switchMap(({ _id }) => {
        //Make the call to the backend to deactivate the user and update the store
        return this.userService.deactivateUser(_id).pipe(
          map(() => {
            return UsersStateActions.deactivateUserSuccess({ _id }); //Dispatch success action, will refetch directory users
          }),
          catchError((error: unknown) => {
            return of(UsersStateActions.deactivateUserFailed({ error }));
          })
        );
      })
    )
  );

  /************************************************************************************
   * Legacy code block to keep stateService up to date. To be deleted once off stateService
   *************************************************************************************/
  addUsersUpdateStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserTeamsActions.addUsersToTeamSuccess),
        concatLatestFrom(() => this.store.select(selectCurrentUser)),
        map(([{ users }, currentUser]) => users.find(u => u._id === currentUser._id)),
        filter(user => !!user),
        tap(user => {
          const currentUser = this.stateService.currentCompanyUser$.value;
          currentUser.teams = user.teams;
          this.stateService.currentCompanyUser$.next(currentUser);
        })
      ),
    { dispatch: false }
  );

  updateCurrentUserThemeStateService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserPreferencesActions.updateTheme),
        tap(({ theme }) => {
          this.stateService.currentUser.settings.theme = theme;
        })
      ),
    { dispatch: false }
  );
  /************************************************************************************
   * End Legacy Code
   *************************************************************************************/
}
