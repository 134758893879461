import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import type { BulkArchiveResult } from '@ninety/ui/legacy/shared/models/_shared/bulk-archive-result';
import type { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import type { GetTodosQueryParams } from '@ninety/ui/legacy/shared/models/todos/get-todos-query-params';
import type { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

/**
 * Simple service to query the no-typeorm Todos API.
 *
 * Service should evolve with other API methods and response-parsing logic, but should do nothing else - its single
 * responsibility is to consume the API.
 */
@Injectable({
  providedIn: 'root',
})
export class TodoApiService {
  static readonly API_URL = '/api/v4/Todos'; // Note the lowercase d - it's the only difference from the old api.

  constructor(private http: HttpClient) {} // Do not inject other services

  /**
   * See {@link GetTodosQueryParams} for info on how the API will react to different combinations of params.
   */
  getTodos(params?: GetTodosQueryParams): Observable<PagedResponse<Todo>> {
    const compiledParams = QueryParamsService.build(params, true);
    return this.http.get<PagedResponse<Todo>>(TodoApiService.API_URL, { params: compiledParams });
  }

  /**
   * Archives all completed team todos assigned to the authenticated user.
   */
  archiveAllCompletedUserTeam(): Observable<BulkArchiveResult> {
    return this.http.post<BulkArchiveResult>(`${TodoApiService.API_URL}/archive-all-completed-user-team`, {});
  }
}
