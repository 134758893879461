import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { RockRecord, RockRecordsData } from '@ninety/data-import/models/records';
import { DataImportService } from '@ninety/data-import/services/data-import.service';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';

@Injectable()
export class RockImportEffects {
  constructor(private actions$: Actions, private store: Store, private dataImportService: DataImportService) {}

  handleRocksFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleDataFromOneSchema),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.rocks),
      concatLatestFrom(() => [
        this.store.select(DataImportSelectors.selectTeamId),
        this.store.select(TeamSelectors.selectAll),
      ]),
      map(([{ data }, teamId, teams]: [RockRecordsData, string, Team[]]) => {
        const rocks = RockRecord.toRocks(data.records, teamId, teams);
        return DataImportActions.createRocks({ rocks });
      })
    )
  );

  createRocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createRocks),
      switchMap(({ rocks }) =>
        this.dataImportService.createRocks(rocks).pipe(
          map(() => DataImportActions.success({ key: 'rock' })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );
}
