<div class="users-preview">
  <ninety-card class="user-import-preview-card">
    <ninety-card-header class="full-width flex-justify-space-between flex-wrap" header>
      <ninety-card-title-with-count [count]="form.controls?.length" title="Preview"></ninety-card-title-with-count>
    </ninety-card-header>

    <div class="preview-users-table-wrapper">
      <ninety-user-import-preview-table />
    </div>

    <ninety-card-footer footer>
      <ninety-button (click)="cancel()" flat>Cancel</ninety-button>
      <ninety-button (click)="importUsers()" [disabled]="form.invalid" filled primary>
        <terra-icon icon="add-circle" size="20"></terra-icon>
        Import users
      </ninety-button>
    </ninety-card-footer>
  </ninety-card>
</div>
