import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap } from 'rxjs';

import { BillingV2Service } from '../../../_core/services/billingv2.service';
import { selectCurrentUserIsManagerOrAbove } from '../../app-entities/users/users-state.selectors';
import { SubscriptionActions } from '../company/subscription/subscription-state.actions';
import { SpinnerActions } from '../spinner/spinner-state.actions';

import { BillingStateActions } from './billing-state.actions';

@Injectable()
export class BillingStateEffects {
  constructor(private actions$: Actions, private store: Store, private billingV2Service: BillingV2Service) {}

  getSubscriptionsConfigurationOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      map(() => BillingStateActions.getActiveSubscriptionConfigurations())
    )
  );

  getUpdatedSubscriptionOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      map(() => SubscriptionActions.getUpdatedSubscription())
    )
  );

  getCompanyBillingCountsOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      map(() => BillingStateActions.getCompanyBillingCounts())
    )
  );

  getUpcomingInvoiceOnBillingInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.billingInit),
      switchMap(() => this.store.select(selectCurrentUserIsManagerOrAbove)),
      filter(isManagerOrAbove => isManagerOrAbove),
      map(() => BillingStateActions.getUpcomingInvoice())
    )
  );

  getActiveSubscriptionConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getActiveSubscriptionConfigurations),
      exhaustMap(() =>
        this.billingV2Service.getActiveSubscriptionConfigurations().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getActiveSubscriptionConfigurationsFailure({
                error: null,
                customMessage: 'Failed to get subscription configurations',
              });
            }
            return BillingStateActions.getActiveSubscriptionConfigurationsSuccess({
              subscriptionConfigurations: response,
            });
          })
        )
      )
    )
  );

  getCompanyBillingCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getCompanyBillingCounts),
      exhaustMap(() =>
        this.billingV2Service.getBillingCounts().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getCompanyBillingCountsFailure({
                error: null,
                customMessage: 'Failed to get company billing counts',
              });
            }
            return BillingStateActions.getCompanyBillingCountsSuccess({
              companyBillingCounts: response,
            });
          })
        )
      )
    )
  );

  getUpcomingInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getUpcomingInvoice),
      exhaustMap(() =>
        this.billingV2Service.getUpcomingInvoice().pipe(
          map(response => {
            if (!response) {
              return BillingStateActions.getUpcomingInvoiceFailure({
                error: null,
                customMessage: 'Failed to get upcoming invoice',
              });
            }
            return BillingStateActions.getUpcomingInvoiceSuccess({ invoice: response });
          })
        )
      )
    )
  );

  billingStateActionEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingStateActions.getCompanyBillingCountsSuccess, BillingStateActions.getCompanyBillingCountsFailure),
      map(() => SpinnerActions.stopPrimary({ source: 'Subscription' }))
    )
  );
}
