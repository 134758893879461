import { createFeatureSelector } from '@ngrx/store';

import { AppEntitiesState } from './app-entities-state.module';

// Root state key
export const appEntitiesStateKey = 'app-entities';

// Feature state keys nested under app-entities
export const FeatureFlagStateKey = 'feature-flags';
export const TeamsStateKey = 'teams';
export const UsersStateKey = 'users';
export const CompanyUsersStateKey = 'company-users';
export const TeamListStateKey = 'team-list';
export const ArchivedTeamListStateKey = 'archived-list';
export const UserListStateKey = 'user-list';

export const selectEntitiesState = createFeatureSelector<AppEntitiesState>(appEntitiesStateKey);
