import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, switchMap } from 'rxjs';

import { mazChatAnimation } from '@ninety/getting-started/guide/components/getting-started-guide/getting-started-guide.animation';
import { MazActions } from '@ninety/getting-started/maz/_state/maz.actions';
import { MazSelectors } from '@ninety/getting-started/maz/_state/maz.selectors';
import {
  selectCurrentUserId,
  selectUserAvatarInfo,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';

import { MazFeedbackFlag } from '../../models/maz-feedback-flag.enum';
import { MazMessage } from '../../models/maz-message';

@Component({
  selector: 'ninety-maz-chat',
  templateUrl: './maz-chat.component.html',
  styleUrls: ['./maz-chat.component.scss'],
  animations: [mazChatAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MazChatComponent {
  protected readonly isMazChatExpanded$ = this.store.select(MazSelectors.selectIsMazChatExpanded);
  protected readonly mazMessages$ = this.store.select(MazSelectors.selectConversationHistory);
  protected readonly waitingForMazResponse$ = this.store.select(MazSelectors.selectWaitingForMazResponse);
  protected readonly showMazAlert$ = new BehaviorSubject<boolean>(false);
  protected readonly mazToasterFlag$ = new BehaviorSubject<MazFeedbackFlag>(null);

  // handing this to all messages to every message doesn't need to import the store and get the user's picture.url
  protected readonly userAvatarInfo$ = this.store
    .select(selectCurrentUserId)
    .pipe(switchMap(userId => this.store.select(selectUserAvatarInfo(userId))));

  mazInput: string;

  constructor(private store: Store) {}

  toggleChatWindow() {
    this.store.dispatch(MazActions.toggleMazChat());
  }

  sendMessage(): void {
    this.store.dispatch(MazActions.sendMessageToMaz({ userInput: this.mazInput }));
    this.mazInput = null;
  }

  toggleMazFeedbackFlag(message: MazMessage): void {
    const newFlag =
      message.feedback?.flag === MazFeedbackFlag.incorrect ? MazFeedbackFlag.correct : MazFeedbackFlag.incorrect;

    this.mazToasterFlag$.next(newFlag);
    this.showMazAlert$.next(true);

    this.store.dispatch(MazActions.toggleMazFeedbackFlag({ messageId: message._id, mazFeedbackFlag: newFlag }));
  }

  hideMazAlert(): void {
    this.showMazAlert$.next(false);
  }
}
