import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, take } from 'rxjs';

import { ReferralService } from '@ninety/ui/legacy/core/services/referral.service';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';

import { ReferralActions } from './_state/referral.actions';
@Component({
  selector: 'ninety-referral',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReferralComponent {
  loading$ = new BehaviorSubject<boolean>(true);
  protected readonly enableReferralProgram$ = this.featureFlags.getFlag(FeatureFlagKeys.referralProgram);

  constructor(private referralService: ReferralService, private store: Store, private featureFlags: FeatureFlagFacade) {
    this.referralService
      .loaded()
      .pipe(take(1))
      .subscribe({
        next: (loaded: boolean) => {
          if (loaded) {
            this.store.dispatch(ReferralActions.getReferralJWT());
            this.loading$.next(false);
          }
        },
      });
  }
}
