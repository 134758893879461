import { createReducer, on } from '@ngrx/store';

import { FeatureFlagActions } from './feature-flag-state.actions';
import { FeatureFlagStateModel, initialFeatureFlagState } from './feature-flag-state.model';

/*
  if a feature flag cookie exists we will override any values from LaunchDarkly with the values from the cookie
  Flags should be formatted as a JSON.stringified object in the cookie value

  // Set a cookie in the browser console (replace with the flags to override)
  document.cookie = 'feature-flags="{"web-app-contact-card":"true", "flag2":"false", etc}"'; 

  // Clear the cookie
  document.cookie = 'feature-flags="{}"'; 

  W3 spec for cookies: https://www.w3schools.com/js/js_cookies.asp
*/

function featureFlagCookie() {
  const featureFlagCookieName = 'feature-flags';
  const name = featureFlagCookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  try {
    // We don't want an error in the cookie value here to block the app.  Just return an empty object.
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        const value = c.substring(name.length, c.length);
        const featureFlags = JSON.parse(value);
        return featureFlags;
      }
    }
  } catch (e) {
    console.error('Error parsing feature flag cookie', e);
  }
  return {};
}

export const featureFlagStateReducer = createReducer(
  { ...initialFeatureFlagState, ...featureFlagCookie() },
  on(
    FeatureFlagActions.initialFlagsReceived,
    (state, { flags }): FeatureFlagStateModel => ({
      ...state,
      ...flags,
      ...featureFlagCookie(),
    })
  ),
  on(
    FeatureFlagActions.updatedFlagsProcessed,
    (state, { flags }): FeatureFlagStateModel => ({
      ...state,
      ...flags,
      ...featureFlagCookie(),
      userFlagsAreLoaded: true,
    })
  ),
  on(
    FeatureFlagActions.updateFeatureFlag,
    (state, { key, value }): FeatureFlagStateModel => ({
      ...state,
      [key]: value,
    })
  )
);
