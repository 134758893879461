import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { BusinessOperatingSystem } from '../../../_shared/models/company/business-operating-system.enum';
import { Company } from '../../../_shared/models/company/company';

import { CompanyActions } from './company-state.actions';
import { initialCompanyState } from './company-state.model';
import { SubscriptionActions } from './subscription/subscription-state.actions';

export const companyStateReducer = createReducer<Company>(
  initialCompanyState,

  on(CompanyActions.init, (state, { company }): Company => {
    const newState: Company = cloneDeep(company);
    newState.bos ??= BusinessOperatingSystem.ninetyOS;
    return newState;
  }),

  on(
    CompanyActions.updateCompany,
    (state, { changes }): Company => ({
      ...state,
      ...cloneDeep(changes),
    })
  ),

  on(CompanyActions.updateSltTeam, (state, { teamId }): Company => {
    return { ...state, seniorLeadershipTeamId: teamId };
  }),

  on(CompanyActions.updateTheme, (state, { colorBranding }): Company => {
    const clone = cloneDeep(state);
    clone.settings.colorBranding = colorBranding;
    return clone;
  }),

  on(SubscriptionActions.getUpdatedSubscriptionSuccess, (state, { subscription }): Company => {
    const clone = cloneDeep(state);
    clone.subscription = cloneDeep(subscription);
    return clone;
  }),

  on(SubscriptionActions.autoIncrementSubscriptionQuantitySuccess, (state, { subscription }): Company => {
    const clone = cloneDeep(state);
    clone.subscription = cloneDeep(subscription);
    return clone;
  }),

  on(SubscriptionActions.autoIncrementSubscriptionQuantityBillingPageSuccess, (state, { subscription }): Company => {
    const clone = cloneDeep(state);
    clone.subscription = cloneDeep(subscription);
    return clone;
  }),

  on(CompanyActions.updateCompanySetting, (company, { settings }): Company => {
    const clone = cloneDeep(company);
    clone.settings = { ...cloneDeep(company.settings), ...settings };
    return clone;
  }),

  on(CompanyActions.updateLogo, (company, { location }): Company => {
    const clone = cloneDeep(company);
    clone.logo.url = location;
    return clone;
  }),

  on(CompanyActions.updateConversationSettings, (company, settings): Company => {
    const clone = cloneDeep(company);
    clone.leadershipCommitments = settings.leadershipCommitments;
    clone.coachingCommitments = settings.coachingCommitments;
    clone.settings.defaultConversationQuestions = settings.annualReviewQuestions;
    return clone;
  })
);
