import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';

export enum OneSchemaRoleName {
  owner = 'Owner',
  admin = 'Admin',
  manager = 'Manager',
  managee = 'Managee',
  observer = 'Observer',
  coach = 'Implementer / Coach',
}

/** Matches values expected from OneSchema */
export const OneSchemaRoleNameAsCode: Readonly<Record<string, RoleCode>> = {
  [OneSchemaRoleName.owner]: RoleCode.owner,
  [OneSchemaRoleName.admin]: RoleCode.admin,
  [OneSchemaRoleName.manager]: RoleCode.manager,
  [OneSchemaRoleName.managee]: RoleCode.managee,
  [OneSchemaRoleName.observer]: RoleCode.observer,
  [OneSchemaRoleName.coach]: RoleCode.owner,
};
