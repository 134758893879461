import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { AvatarUploadDialogComponent } from '@ninety/ui/legacy/shared/components/avatar-upload-dialog/avatar-upload-dialog.component';
import { AvatarUploadModel } from '@ninety/ui/legacy/shared/components/avatar-upload-dialog/models/AvatarUploadModel';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import { RoleSelectComponent } from '../role-select/role-select.component';

@Component({
  selector: 'ninety-directory-add-invite-user',
  templateUrl: 'directory-add-invite-user.component.html',
  styleUrls: ['directory-add-invite-user.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCommonModule,
    SharedModule,
    MaterialMdcModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RoleSelectComponent,
    TerraIconModule,
    RoleSelectComponent,
    TerraAvatarModule,
  ],
})
export class DirectoryAddInviteUserComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() index: number;
  @Input() directoryOnly: boolean;

  roleSelectOpened = false;
  roleCode = RoleCode;
  formIndex = 0;

  constructor(private stateService: StateService, public dialog: MatDialog) {}

  ngOnInit() {
    this.formIndex = (this.index + 1) * 100;
  }

  openAvatarDialog() {
    const avatarUploadModel: AvatarUploadModel = {
      editeePersonMetadataId: this.stateService.currentUser.personMetadataId,
      editeeUserId: this.stateService.currentUser._id,
      forOtherPerson: true,
      index: this.index,
    };

    this.dialog
      .open(AvatarUploadDialogComponent, {
        id: 'avatar-upload-dialog',
        data: avatarUploadModel,
        maxHeight: '95vh',
      })
      .afterClosed()
      .subscribe(result => {
        this.form.get('avatarImageUrl').setValue(result.url);
      });
  }

  updateUserTeams(teamIds: string[]) {
    this.form.get('teams').setValue(teamIds.map(teamId => ({ teamId })));
  }

  updateUserRole(role: RoleSelectOption) {
    this.form.get('role').setValue(role);
  }
}
