<ng-container
  *ngIf="{
    currentUser: stateService.currentCompanyUser$ | async,
    hasScorecardV2Access: hasScorecardV2Access$ | async,
    meeting: meetingService.currentMeeting$ | async,
    sectionTime: meetingService.sectionTimer$ | async
  } as state">
  <section id="meeting-sidebar" [class.is-open]="showNav && showSidebar">
    <div class="total-time">
      <p class="bottom-space-0">Total:</p>
      <ninety-clock-timer [totalSeconds]="meetingService.meetingTimer$ | async"></ninety-clock-timer>
      <div
        class="countdown red"
        *ngIf="
          meetingService.currentSectionDuration - state.sectionTime <= 10 &&
          meetingService.currentSectionDuration >= state.sectionTime
        ">
        {{ meetingService.currentSectionDuration - state.sectionTime }}
      </div>
    </div>
    <div class="progress-bar-timer">
      <mat-progress-bar
        mode="determinate"
        [value]="meetingService.progressBarValue$ | async"
        color="{{ state.sectionTime <= meetingService.currentSectionDuration ? 'accent' : 'warn' }}"></mat-progress-bar>
      <ninety-clock-timer
        *ngIf="this.meetingService.navigationEnded$ | async"
        [totalSeconds]="state.sectionTime"
        [totalTimeAllotted]="meetingService.currentSectionDuration"></ninety-clock-timer>
    </div>

    <div
      data-cy="meeting-sidenav_meeting-controls"
      class="meeting-controls"
      *ngIf="state.meeting?.presenterUserId === state.currentUser._id">
      <button data-cy="meeting-sidenav_skip-previous-section" mat-icon-button (click)="changeSection(false)">
        <terra-icon icon="skip-back" variant="fill" size="12" />
      </button>
      <button mat-icon-button (click)="onToggleTimers()">
        <terra-icon
          data-cy="meeting-sidenav_play-arrow"
          icon="play"
          variant="fill"
          size="12"
          *ngIf="state.meeting?.paused" />
        <terra-icon
          data-cy="meeting-sidenav_pause"
          icon="pause"
          variant="fill"
          size="12"
          *ngIf="!state.meeting?.paused" />
      </button>
      <button data-cy="meeting-sidenav_skip-next-section" mat-icon-button (click)="changeSection(true)">
        <terra-icon icon="skip-forward" variant="fill" size="12" />
      </button>
      <button
        mat-flat-button
        data-cy="meeting-sidenav_suspend-button"
        class="suspend-btn uppercase"
        [disabled]="suspending"
        (click)="suspend()">
        Suspend
      </button>
    </div>
    <div class="meeting-controls" *ngIf="state.meeting?.presenterUserId !== state.currentUser._id">
      <button mat-button (click)="exit()" class="save-btn">
        <span>Leave {{ stateService.language.meeting.item }}</span
        >&nbsp;
        <terra-icon icon="sign-in" />
      </button>
    </div>
    <terra-divider />
    <mat-list class="ninety-list" *ngIf="state.meeting">
      <mat-list-item
        *ngFor="let section of state.meeting.sections | orderBy : 'ordinal'; let i = index"
        attr.data-cy="meeting-sidenav_nav-{{ section.name }}"
        class="meeting-section-link"
        [class.gray--indicator]="section.showGrayIndicator"
        [class.active]="
          section.path === state.meeting.currentSection.path && section.name === state.meeting.currentSection.name
        "
        (click)="onSectionChange(section, i)"
        [matTooltip]="section.name"
        [matTooltipDisabled]="section.name.length < 60">
        <div class="section-index">{{ i + 1 }}.</div>
        <div class="section-title flex1" [innerHTML]="section.name"></div>
        <div class="section-time">
          <small
            class="muted dflex"
            *ngIf="
              !(
                section.path === state.meeting.currentSection.path && section.name === state.meeting.currentSection.name
              ) && section.elapsedTime > 0
            ">
            (
            <ninety-clock-timer [totalSeconds]="section.elapsedTime"></ninety-clock-timer>
            )
          </small>
          {{ section.duration / 60 }}&nbsp;MIN
        </div>
      </mat-list-item>
    </mat-list>
    <div class="fill-remaining-space"></div>
    <button
      *ngIf="state.meeting?.presenterUserId === state.currentUser._id"
      mat-button
      data-cy="meeting-sidenav_finish-button"
      class="save-btn uppercase"
      [disabled]="this.meetingService.currentMeeting.type === MeetingType.weekly && !this.meetingService.validRatings()"
      (click)="saveAndExit()">
      <span>Finish</span>&nbsp;
      <span *ngIf="state.meeting?.type === MeetingType.annualDayOne">Day 1</span>
      <span *ngIf="state.meeting?.type === MeetingType.annualDayTwo">Day 2</span>
      &nbsp;<terra-icon icon="sign-in" />
    </button>
    <terra-divider />
    <div *ngIf="state.meeting" class="view-tool-div">
      <button
        data-cy="meeting-sidenav_view-tool-button"
        mat-button
        [matMenuTriggerFor]="viewAnotherToolMenu"
        class="menu-btn uppercase"
        aria-label="View Another Tool"
        [disabled]="!state.meeting">
        View Tool
        <terra-icon icon="tool" class="tool-icon" />
      </button>

      <mat-menu #viewAnotherToolMenu="matMenu" xPosition="before" yPosition="above">
        <!-- Scorecard Route -->
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.scorecard.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'data'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.scorecard.route)">
          <terra-icon icon="data" size="20" class="mat-menu-icon" />
          <span>{{ stateService.language.scorecard.route }}</span>

          <!-- TODO Make dynamic -->
          <ninety-chip *ngIf="state.hasScorecardV2Access" class="beta-chip" color="brand">Beta</ninety-chip>
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.rock.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'rocks'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.rock.route)">
          <terra-icon icon="rocks" size="20" class="mat-menu-icon" />
          <span>{{ stateService.language.rock.route }}</span>
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.todo.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'todos'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.todo.route)">
          <terra-icon icon="to-dos" size="20" class="mat-menu-icon" />
          {{ stateService.language.todo.route }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.issue.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'issues'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.issue.route)">
          <terra-icon icon="issues" size="20" class="mat-menu-icon" />
          {{ stateService.language.issue.route }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.headline.items }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'headlines'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.headline.items)">
          <terra-icon icon="headlines" size="20" class="mat-menu-icon" />
          {{ stateService.language.headline.items }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.vto.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'vision'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.vto.route)">
          <terra-icon icon="vision" size="20" class="mat-menu-icon" />
          {{ stateService.language.vto.route }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.acChart.route }}"
          *ngIf="canAccessOrgChart$ | ngrxPush"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'chart'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.acChart.route)">
          <terra-icon icon="responsibilities" size="20" class="mat-menu-icon" />
          {{ stateService.language.acChart.route }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.process.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'process'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.process.route)">
          <terra-icon icon="process" size="20" class="mat-menu-icon" />
          {{ stateService.language.process.route }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.directory.route }}"
          mat-menu-item
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'directory'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.directory.route)">
          <terra-icon icon="directory" size="20" class="mat-menu-icon" />
          {{ stateService.language.directory.route }}
        </ninety-button>
        <ninety-button
          attr.data-cy="meeting-sidenav_tool-{{ stateService.language.mastery.route }}"
          mat-menu-item
          *ngIf="stateService.company.settings?.mastery.enabled && !stateService.isLiteUser"
          [routerLink]="[{ outlets: { detail: null, primary: ['meeting', state.meeting._id, 'mastery'] } }]"
          routerLinkActive="active"
          (click)="changeMeetingTitle(stateService.language.mastery.route)">
          <terra-icon icon="mastery" size="20" class="mat-menu-icon" />
          {{ stateService.language.mastery.route }}
        </ninety-button>
      </mat-menu>
    </div>

    <button
      data-cy="meeting-sidenav_notes-button"
      mat-button
      (click)="toggleNotes()"
      class="notes-btn uppercase"
      [disabled]="!state.meeting">
      <span *ngIf="!showNotes">Show</span>
      <span *ngIf="showNotes">Hide</span>
      <span> Notes </span>
      <terra-icon icon="notepad" />
    </button>
  </section>
</ng-container>
