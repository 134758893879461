<div
  class="getting-started-container"
  *ngrxLet="mazChatEnabled$ as mazEnabled"
  [class.maz-disabled]="!mazEnabled"
  ninetyStopPropagation
  (ninetyClickOutside)="close()">
  <div class="phases-container" *ngrxLet="selectedPhase$ as phase" [class.phase-selected]="phase">
    <ninety-phase-list *ngIf="!phase; else phaseDetails" />

    <ng-template #phaseDetails>
      <ninety-phase-details [phase]="phase" [mazChatEnabled]="mazEnabled" />
    </ng-template>
  </div>
  <div class="maz-footer" *ngIf="mazEnabled">
    <div class="maz-chat-panel" [@toggleMazChat]="(isMazChatExpanded$ | ngrxPush) ? 'expanded' : 'collapsed'">
      <ninety-maz-chat />
    </div>
  </div>
  <div class="opt-in-panel" *ngIf="askUserToEnableMaz$ | ngrxPush">
    <div class="maz-opt-in">
      <div class="header">
        <div class="title">Have a question?</div>
        <ninety-button flat icon class="expand-chat-btn" (click)="optOutOfMaz()" ninetyTooltip="Opt out of Maz">
          <terra-icon icon="x" />
        </ninety-button>
      </div>
      <p>
        Try Maz, our experimental AI Onboarding Companion. Maz doesn't always know the right answers, but it's getting
        smarter all the time!
      </p>
      <ninety-button filled primary (click)="optInToMaz()">
        <terra-icon icon="sparkle" class="prefix-icon" ninetyInputPrefix />
        Turn on Maz
      </ninety-button>
    </div>
  </div>
</div>
