import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraBadgeModule } from '../terra-badge';
import { TerraIconModule } from '../terra-icon';

import { TerraAvatarComponent } from './terra-avatar.component';

@NgModule({
  imports: [CommonModule, TerraAvatarComponent, TerraIconModule, TerraBadgeModule],
  exports: [TerraAvatarComponent, TerraBadgeModule],
})
export class TerraAvatarModule {}
