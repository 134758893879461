import {
  ALL_ROLE_FILTER_OPTION,
  DirectoryRoleFilterOption,
} from '@ninety/directory-v2/models/directory-role-filter-option';
import { DirectorySort } from '@ninety/directory-v2/models/directory-sort';
import {
  ALL_USER_STATUS_FILTER_OPTION,
  DirectoryUserStatusOption,
} from '@ninety/directory-v2/models/directory-user-status-options';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { MatSortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { teamAll } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';

export const DirectoryStateKey = 'directory';

export interface DirectoryState extends DirectoryFilters, DirectoryMatSorting, DirectoryPagination {
  users: User[];
  selectedUser: User;
  canEditSelectedUser: boolean;
  loading: boolean;
  showCheckboxes: boolean;
}

export interface DirectoryFilters {
  searchText: string;
  selectedRole: DirectoryRoleFilterOption;
  selectedUserStatus: DirectoryUserStatusOption;
  teamId: string;
}

export interface DirectoryMatSorting {
  sortField: DirectorySort;
  sortDirection: MatSortDirection;
}

export interface DirectoryPagination {
  pageIndex: number;
  pageSize: number;
  totalUsers: number;
}

export const initialDirectoryState: DirectoryState = {
  users: [],
  selectedUser: null,
  canEditSelectedUser: false,
  loading: true,
  searchText: '',
  selectedRole: ALL_ROLE_FILTER_OPTION,
  teamId: teamAll._id,
  selectedUserStatus: ALL_USER_STATUS_FILTER_OPTION,
  showCheckboxes: false,
  sortField: DirectorySort.firstName,
  sortDirection: MatSortDirection.ASC,
  pageIndex: 0,
  pageSize: 25,
  totalUsers: 0,
};
