import { DirectoryUserRole } from '@ninety/directory-v2/models/directory-user-role';

export interface DirectoryRoleFilterOption {
  title: string;
  userRole: DirectoryUserRole;
}

export const ALL_ROLE_FILTER_OPTION: DirectoryRoleFilterOption = {
  title: 'All',
  userRole: DirectoryUserRole.all,
};
