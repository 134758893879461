export enum EmailType {
  main = '0000',
  personal = '0001',
  work = '0002',
}

export enum EmailTypeLabel {
  main = 'Main',
  personal = 'Personal',
  work = 'Work',
}
