/* eslint-disable @ngrx/prefix-selectors-with-select */
import { createSelector, select } from '@ngrx/store';
import { pipe, skip } from 'rxjs';

import { ResponsibilityChartStateKey, selectResponsibilitiesState } from '@ninety/accountability-chart/_state';
import { GlobalSeatHolderIdentifier } from '@ninety/accountability-chart/_state/chart/actions/seat-holder.actions';
import { seatStateAdapter } from '@ninety/accountability-chart/_state/chart/responsibility-chart.model';
import { OrgChartSearchData } from '@ninety/accountability-chart/components/search/user-seat-search/user-seat-search.component.model';
import {
  createMoveSeatParams,
  filterToSeatsThisSeatCanMoveTo,
  legacyFilterToSeatsThisSeatCanMoveTo,
} from '@ninety/accountability-chart/components/seat-move-dialog/seat-move.component.model';
import { ResponsibilityChartModel } from '@ninety/accountability-chart/models/responsibility-chart.model';
import { CreateSeatModelDefaults, extractTreeInputFromSeat } from '@ninety/accountability-chart/models/seat.model';
import { OrgChartApiPathBuilder } from '@ninety/accountability-chart/services/org-chart-api-path.builder';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { SeatHolder, SeatHolderCreatePick } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-holder';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { UserModelState } from '@ninety/ui/legacy/state/app-entities/users/users-state.model';
import {
  selectCurrentUser,
  selectUserEntities,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { ResponsibilityNavigationSelectors } from '../navigation/responsibility-navigation.selectors';

export function canUserEditChart(
  chart: ResponsibilityChartModel,
  currentUser: Pick<UserModelState, '_id' | 'isManagerOrAbove'>
) {
  if (!chart || !currentUser) return false;

  if (chart.isPrimaryCompanyChart && currentUser.isManagerOrAbove) return true;
  if (chart.createdByUserId === currentUser._id) return true;
  if (chart.sharedWithUserIds.includes(currentUser._id)) return true;

  return false;
}

export namespace ResponsibilityChartSelectors {
  export const featureState = createSelector(selectResponsibilitiesState, state => state[ResponsibilityChartStateKey]);

  export const displayedChart = createSelector(featureState, state => state.chart);
  export const displayedChartTitle = createSelector(displayedChart, chart => chart?.title ?? '');
  export const displayedChartIsLoaded = createSelector(displayedChart, chart => !!chart);
  export const currentChartId = createSelector(displayedChart, chart => chart?._id);
  export const currentChartIsPrimary = createSelector(displayedChart, chart => chart?.isPrimaryCompanyChart);

  /** Whether a user has edit access to the given chart */
  export const canEditCurrentChart = createSelector(displayedChart, selectCurrentUser, canUserEditChart);

  /** Editing is allowed if currently on the /chart/{id} page and user has correct permissions */
  export const editingAllowed = createSelector(
    canEditCurrentChart,
    ResponsibilityNavigationSelectors.onChartPageInEditMode,
    (canEdit, inEditMode) => canEdit && inEditMode
  );

  export const seatSingularLanguage = createSelector(selectLanguage, language => language?.acChart.seat);
  export const seatPluralLanguage = createSelector(selectLanguage, language => language?.acChart.seats);
  export const seatHolderSingularLanguage = createSelector(selectLanguage, language => language?.acChart.seatHolder);
  export const chartSingularLang = createSelector(selectLanguage, language => language?.acChart.chart);

  export const zoomScale = createSelector(featureState, state => state.zoomScale);

  export const canZoomOut = createSelector(zoomScale, scale => scale.canZoomOut());

  export const {
    selectAll: allSeats,
    // selectIds: allSeatIds,
    selectEntities: allSeatEntities,
    // selectTotal,
  } = seatStateAdapter.getSelectors(featureState);

  export const seatById = (id: string) => createSelector(allSeatEntities, entities => entities[id]);

  export const getNewSeatDefaultsForParent = (parentId: string) =>
    createSelector(
      seatById(parentId),
      childrenOfSeatCount(parentId),
      (parent: SeatModel, ordinal): CreateSeatModelDefaults => {
        if (!parent) return null;

        return {
          companyId: parent.companyId,
          chartId: parent.chartId,
          ordinal,
        };
      }
    );

  export const getNewSeatHolderParams = (seatId: string, userId: string) =>
    createSelector(seatById(seatId), currentChartId, (seat, chartId): SeatHolderCreatePick => {
      if (!seat) return null;

      return {
        chartId,
        seatId,
        userId,
        companyId: seat.companyId,
        managerSeatId: seat.parentSeatId,
        ordinal: seat.seatHolders.length,
      };
    });

  export const getGlobalSeatHolderIdentifierFor = (seatId: string, userId: string) =>
    createSelector(seatById(seatId), (seat): GlobalSeatHolderIdentifier => {
      if (!seat) return null;

      const seatHolder = seat.seatHolders.find(holder => holder.userId === userId);
      if (!seatHolder) return null;

      return {
        chartId: seat.chartId,
        seatId,
        _id: seatHolder._id,
      };
    });

  export const seatNameFromId = (id: string) => createSelector(seatById(id), seat => seat?.name ?? '');

  export const childrenOfSeat = (id: string) =>
    createSelector(allSeats, seats => seats.filter((seat: SeatModel) => seat.parentSeatId === id));

  export const childrenOfSeatCount = (id: string) => createSelector(childrenOfSeat(id), children => children.length);

  export const seatHasChildren = (id: string) => createSelector(childrenOfSeatCount(id), count => count > 0);

  export const getSeatHoldersWithUsers = (seatHolders: SeatHolder[]) =>
    createSelector(selectUserEntities, users =>
      seatHolders?.length
        ? seatHolders
            .map(seatHolder => ({
              ...seatHolder,
              user: users[seatHolder.userId],
            }))
            .filter(seatHolder => !!seatHolder.user)
        : []
    );

  export const treeModelArray = createSelector(allSeats, seats =>
    seats.map((node: SeatModel) => extractTreeInputFromSeat(node))
  );

  /**
   * The tree model array is the data source for the tree component. We skip the first emit to block rendering of the
   * tree in this components template until the seats are actually loaded. We only take the first emit after the skip
   * as once we have passed nodes to the component, we have to call render() on the component to update the tree.
   */
  export const treeModelArrayToForwardToComponent = pipe(select(treeModelArray), skip(1));

  export const topSeatId = createSelector(featureState, state => state.topSeatId);

  export const topSeat = createSelector(topSeatId, allSeatEntities, (id, entities) => entities[id]);

  export const topSeatIsVisionary = createSelector(topSeat, seat => seat?.visionary);
  export const visionarySeat = createSelector(allSeats, seats => seats.find((seat: SeatModel) => seat.visionary));
  export const integratorSeat = createSelector(allSeats, seats => seats.find((seat: SeatModel) => seat.integrator));

  export const initialDepth = createSelector(topSeatIsVisionary, visionary => (visionary ? 2 : 1));

  /** @deprecated TODO only used in tests */
  export const visibleSeatCount = createSelector(featureState, state => state.visibleSeatCount);

  // TODO move to user-search component-store
  export const orgChartSearchData = createSelector(allSeatEntities, selectUserEntities, (seats, users) =>
    Object.values(seats)
      .map((seat): OrgChartSearchData[] => {
        // Ensure we always map a seat to a search result, regardless of if seat holders exist or point to valid users
        const length = seat.seatHolders?.length;
        if (!length) return [{ name: seat.name, seatId: seat._id, user: null }];

        const projections = seat.seatHolders.reduce((acc: OrgChartSearchData[], seatHolder) => {
          const foundUser = users[seatHolder.userId];
          if (!foundUser) return acc;

          const name = `${foundUser.fullName} - ${seat.name}`;
          acc.push({
            name,
            seatId: seat._id,
            user: foundUser,
          });
          return acc;
        }, []);

        if (projections.length > 0) return projections;

        return [{ name: seat.name, seatId: seat._id, user: null }];
        // }
      })
      .flat()
  );

  /** @deprecated TODO remove this flag */
  export const flagAllowsSettingAsPrimary = selectFeatureFlag(FeatureFlagKeys.responsibiliitesBetaSetAsPrimary);

  export const apiPathBuilderForCompany = createSelector(selectCompanyId, companyId =>
    OrgChartApiPathBuilder.ofCompany(companyId)
  );

  export const apiPathBuilderForCompanyWithUsers = createSelector(
    selectUserEntities,
    apiPathBuilderForCompany,
    (users, builder) => ({ builder, users })
  );

  export const apiPathBuilderForChart = createSelector(
    apiPathBuilderForCompany,
    ResponsibilityChartSelectors.currentChartId,
    (builder, chartId) => builder.chart(chartId)
  );

  export const disableTopSeatSelect = selectFeatureFlag(FeatureFlagKeys.responsibilitiesHideTopSeatSelect);

  export const detailSeatId = createSelector(featureState, state => state.detailSeatId);
  export const isOpenInDetails = (id: string) => createSelector(detailSeatId, detailId => detailId === id);
  export const descendantsOfDetailSeat = createSelector(featureState, state => state.descendantsOfDetailSeat);

  export const possibleManagersOfDetailSeat = createSelector(
    allSeats,
    descendantsOfDetailSeat,
    ResponsibilityNavigationSelectors.seatDetailIsCreate,
    (seats, descendants, isCreate) => {
      if (isCreate || !descendants) return seats.filter(s => !s.visionary);
      return filterToSeatsThisSeatCanMoveTo(seats, descendants);
    }
  );

  export const seatsThatCanBeMovedTo = (seatToMove: SeatModel, childIds: string[]) =>
    createSelector(ResponsibilityChartSelectors.allSeats, (seats: SeatModel[]) =>
      legacyFilterToSeatsThisSeatCanMoveTo(seats, seatToMove, childIds)
    );

  export const moveSeatParams = (seatToMove: SeatModel, childIds: string[]) =>
    createSelector(
      seatsThatCanBeMovedTo(seatToMove, childIds),
      selectUserEntities,
      (seats: SeatModel[], idToUser: Record<string, User>) => createMoveSeatParams(seats, idToUser)
    );

  export const deleteSeatConfirmationDialogParams = (id: string) =>
    createSelector(selectLanguage, seatHasChildren(id), (language, hasChildren) => {
      const data: ConfirmDialogData = {
        title: `Delete ${language?.acChart.seat}`,
        message: `Once a ${language?.acChart.seat} is deleted, all its content will be lost. This action can't be undone.`,
        additionalMessage: hasChildren
          ? `This ${language?.acChart.seat} has children. Deleting it will delete all its children.`
          : '',
      };

      return data;
    });

  export const useV3SeatDetails = selectFeatureFlag(FeatureFlagKeys.responsibilitiesSeatDetailsV3);
}
