import { AfterViewInit, Directive, ElementRef, Inject, InjectionToken, OnDestroy, Renderer2 } from '@angular/core';

import { NinetyInputDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/text-input/directives/ninety-input.directive';

/** A callback that is called when the input of an autocomplete directive changes. */
export type AutoCompleteCallback = (input: string) => void;

/** A handler for the callback that is called when the input of an autocomplete directive changes. */
export interface AutoCompleteCallbackHandler {
  onInput: AutoCompleteCallback;
}

/** A token used to provide a callback handler for an autocomplete directive. */
export const AUTO_COMPLETE_CALLBACK_HANDLER = new InjectionToken<AutoCompleteCallbackHandler>(
  'AUTO_COMPLETE_CALLBACK_HANDLER'
);

/**
 * Directive which manages an input element and calls an injected {@link AutoCompleteCallbackHandler} when the input
 * changes.
 */
@Directive({
  selector: '[ninetyAutoComplete]',
  exportAs: 'ninetyAutoComplete',
  standalone: true,
  hostDirectives: [NinetyInputDirective],
})
export class AutoCompleteDirective implements AfterViewInit, OnDestroy {
  /** Internal handler used to destroy the event listener created after view init */
  private unListen: () => void;

  constructor(
    @Inject(AUTO_COMPLETE_CALLBACK_HANDLER) private callbackHandler: AutoCompleteCallbackHandler, // Provided by some parent, or fail
    private input: NinetyInputDirective, // Applied to the host element via directive composition
    private host: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.unListen = this.renderer.listen(this.host.nativeElement, 'input', (_: Event) => {
      this.callbackHandler.onInput(this.input.value);
    });
  }

  ngOnDestroy(): void {
    if (this.unListen) this.unListen();
  }

  clear(): void {
    this.input.clear();
  }
}
