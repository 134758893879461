import { CdkOption } from '@angular/cdk/listbox';
import { Directive } from '@angular/core';

@Directive({
  selector: '[ninetyOption]',
  standalone: true,
  exportAs: 'ninetyOption',
  hostDirectives: [
    {
      directive: CdkOption,
      inputs: ['cdkOption: value', 'cdkOptionDisabled: disabled', 'id'],
    },
  ],
})
export class OptionDirective<T = unknown> {
  constructor(private option: CdkOption<T>) {}

  get isSelected(): boolean {
    return this.option.isSelected();
  }

  get disabled(): boolean {
    return this.option.disabled;
  }
}
