import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash';
import { Observable, Subject, catchError, map, tap } from 'rxjs';

import { CompanyMeetingAgendaUpdateByType } from '../../_shared/models/_shared/team';
import { Company } from '../../_shared/models/company/company';
import { CompanyAffiliateResponse } from '../../_shared/models/company/company-affiliate-response';
import { CompanyModel } from '../../_shared/models/company/company-model';
import { CompanySettings } from '../../_shared/models/company/company-settings';
import { ImplementerCompanyResponse } from '../../_shared/models/company/implementer-company';
import { RegisterCompanyResponse } from '../../_shared/models/company/register-company-response';
import { TemplateSectionsToApply } from '../../_shared/models/company/template-sections-to-apply';
import { MasterySettings } from '../../_shared/models/mastery/mastery';
import { MeetingAgenda } from '../../_shared/models/meetings/meeting-agenda';
import { CustomerData } from '../../_shared/models/partner-hub/customer-data';
import { CompanyActions } from '../../_state/app-global/company/company-state.actions';
import { selectCompany } from '../../_state/app-global/company/company-state.selectors';
import { SpinThenNotifyOrError } from '../decorators/spin-then-notify-or-error';
import { SpinnerAndCatchError } from '../decorators/spinner-and-catch-error';

import { ErrorService } from './error.service';
import { SpinnerService } from './spinner.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private readonly companyApi = '/api/v4/Companies';
  companyMasterySettingsUpdated$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private stateService: StateService,
    private store: Store,
    private snackbar: MatSnackBar,
    private spinnerService: SpinnerService //needed for decorators
  ) {}

  getAllCompanies(idAndNameOnly = false): Observable<Company[]> {
    return this.http
      .get<Company[]>(`${this.companyApi}?idAndNameOnly=${idAndNameOnly}`)
      .pipe(catchError(ErrorService.handle));
  }

  getCompanyByAffiliateCode(affiliateCode: string): Observable<CompanyAffiliateResponse> {
    return this.http
      .get<Company>(`/api/v4/CompanyAffiliate?affiliateCode=${affiliateCode}`)
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not get company with provided affiliate name.'))
      );
  }

  getImplementerCompanies(): Observable<ImplementerCompanyResponse> {
    return this.http
      .get<ImplementerCompanyResponse>(`${this.companyApi}/ImplementerCompanies`)
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not get child companies.')));
  }

  @SpinThenNotifyOrError({ err: 'Could not update the company. Please try again.' })
  update(update: Partial<Company>): Observable<void> {
    this.store.dispatch(CompanyActions.updateCompany({ changes: update }));
    return this.http.patch<void>(this.companyApi, update);
  }

  @SpinThenNotifyOrError({ err: "Could not update the company's default meeting agendas. Please try again." })
  updateMeetingAgendasByType(update: CompanyMeetingAgendaUpdateByType): Observable<void> {
    const { agendaType, teamAgenda } = update;

    return this.http.patch<void>(`${this.companyApi}/MeetingAgendas`, { [agendaType]: teamAgenda }).pipe(
      tap(() => {
        if (!this.stateService.company.hasOwnProperty('meetingAgendas')) {
          this.store.dispatch(CompanyActions.updateCompany({ changes: { meetingAgendas: {} } }));

          // ToDo: Remove company mutation as part of DEV-7249
          this.stateService.company.meetingAgendas = {};
        }

        if (agendaType === 'custom' && Array.isArray(teamAgenda)) {
          this.store.dispatch(CompanyActions.updateCompany({ changes: { meetingAgendas: { custom: teamAgenda } } }));

          // ToDo: Remove company mutation as part of DEV-7249
          this.stateService.company.meetingAgendas.custom = teamAgenda;
        } else if (agendaType !== 'custom' && !Array.isArray(teamAgenda)) {
          this.store.dispatch(
            CompanyActions.updateCompany({ changes: { meetingAgendas: { [agendaType]: teamAgenda } } })
          );

          // ToDo: Remove company mutation as part of DEV-7249
          this.stateService.company.meetingAgendas[agendaType] = teamAgenda;
        }
      })
    );
  }

  @SpinThenNotifyOrError({ err: 'Could not delete the custom agenda. Please try again.' })
  deleteCustomAgenda(agendaId: string, companyId = this.stateService.companyId): Observable<MeetingAgenda[]> {
    return this.http.delete<MeetingAgenda[]>(`${this.companyApi}/${companyId}/DeleteCustomAgenda/${agendaId}`).pipe(
      tap(customAgendas => {
        this.store.dispatch(
          CompanyActions.updateCompany({
            changes: {
              meetingAgendas: {
                ...this.stateService.company.meetingAgendas,
                custom: customAgendas,
              },
            },
          })
        );
        // ToDo: Remove company mutation as part of DEV-7249
        this.stateService.company.meetingAgendas.custom = customAgendas;
      })
    );
  }

  @SpinThenNotifyOrError()
  applyTemplate(
    companyIds: string[],
    sectionsToApply: TemplateSectionsToApply,
    configQuery: { templateId?: string; companyId?: string }
  ): Observable<void> {
    return this.http.patch<void>(`${this.companyApi}/ApplyTemplate`, {
      companyIds,
      sectionsToApply,
      ...configQuery,
    });
  }

  @SpinThenNotifyOrError()
  updateSettings(update: Partial<CompanySettings>, companyId?: string): Observable<void> {
    const params = companyId ? new HttpParams().set('companyId', companyId) : {};

    // Eagerly update the store with the new settings
    if (!companyId || companyId === this.stateService.company._id) {
      this.store.dispatch(CompanyActions.updateCompanySetting({ settings: update }));
    }

    return this.http.patch<void>(`${this.companyApi}/Settings`, update, { params }).pipe(
      concatLatestFrom(() => this.store.select(selectCompany)),
      map(([_, { _id: currentCompanyId }]) => {
        // if no companyId was passed in, it's assumed to be the current company
        // can be a different company if updating a company on partner hub
        if (companyId && companyId !== currentCompanyId) return;
        this.checkAndUpdateTheme(companyId, update);
        this.store.dispatch(CompanyActions.updateCompanySetting({ settings: update }));

        // maintain current state service company settings
        // ToDo: Remove company mutation as part of DEV-7249
        this.stateService.company.settings = _merge({}, this.stateService.company.settings, _cloneDeep(update));
        this.companyMasterySettingsUpdated$.next();

        if (update.hasOwnProperty('agreementBasedTodos')) {
          this.store.dispatch(
            CompanyActions.updatedABTodosSetting({ agreementBasedTodos: update.agreementBasedTodos })
          );
        }
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not update the company.  Please try again.'))
    );
  }

  // @deprecated - use updateWithKeyValue instead
  updateWithProp(prop: keyof Company): Observable<void> {
    return this.update({ [prop]: this.stateService.company[prop] });
  }

  updateWithKeyValue<K extends keyof Company>(prop: K, value: Company[K]): Observable<void> {
    return this.update({ [prop]: value });
  }

  // @deprecated - use updateSettingsWithKeyValue instead
  updateSettingsWithProp(prop: keyof CompanySettings): Observable<void> {
    return this.updateSettings({ [prop]: this.stateService.company.settings[prop] });
  }

  updateSettingsWithKeyValue<K extends keyof CompanySettings>(prop: K, value: CompanySettings[K]): Observable<void> {
    this.store.dispatch(CompanyActions.updateCompanySetting({ settings: { [prop]: value } }));
    return this.updateSettings({ [prop]: value });
  }

  updateMastery(mastery: MasterySettings, companyId?: string): Observable<void> {
    return this.updateSettings({ mastery: _cloneDeep(mastery) }, companyId);
  }

  delete(): Observable<void> {
    return this.http
      .delete<void>(this.companyApi)
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not delete the company.  Please try again.'))
      );
  }

  @SpinnerAndCatchError
  getCompanyById(companyId: string): Observable<Company> {
    return this.http.get<Company>(`${this.companyApi}/${companyId}`);
  }

  @SpinnerAndCatchError
  getCompanyAndOwnerAdmins(companyId: string): Observable<CustomerData> {
    return this.http.get<CustomerData>(`/api/v4/CompanyAndOwnerAdmins/${companyId}`);
  }

  registerCompany(company: CompanyModel) {
    return this.http.post<RegisterCompanyResponse>('/api/v4/Companies', company).pipe(
      tap((res: RegisterCompanyResponse) => {
        this.store.dispatch(CompanyActions.registerCompany({ response: res }));

        // null - no coupon
        // false - failed to apply coupon
        // true - applied coupon successfully
        if (res.appliedCoupon === false) {
          this.snackbar.open(
            'Your company has been created but the discount code was not valid. Please visit the billing page to re-enter the code.',
            undefined,
            { duration: 5000 }
          );
        }
      }),
      catchError((e: unknown) => {
        // @ts-ignore
        this.store.dispatch(CompanyActions.registerCompanyFail({ company, error: e }));

        this.snackbar.open('Something went wrong while registering your company, please contact support.', undefined, {
          duration: 3000,
        });

        return this.errorService.oops(e);
      })
    );
  }

  checkAndUpdateTheme(companyId: string, update: Partial<CompanySettings>): void {
    if (update.colorBranding && (!companyId || companyId === this.stateService.company._id)) {
      this.stateService.company.settings.colorBranding = update.colorBranding;
      this.store.dispatch(CompanyActions.updateTheme({ colorBranding: _cloneDeep(update.colorBranding) }));
    }
  }

  pushAgendaByType(companyId: string, agendaType: string) {
    return this.http
      .post<void>(`/api/v4/Companies/${companyId}/PushCompanyAgenda/${agendaType}`, {})
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not push team meeting agenda. Please try again.'))
      );
  }

  pushCustomAgenda(companyId: string, customAgendaId: string) {
    return this.http
      .post<void>(`/api/v4/Companies/${companyId}/PushCompanyCustomAgenda/${customAgendaId}`, {})
      .pipe(
        catchError((e: unknown) => this.errorService.notify(e, 'Could not push team meeting agenda. Please try again.'))
      );
  }
}
