import { Injectable } from '@angular/core';
import type { LDClient, LDContext, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { basicLogger, initialize } from 'launchdarkly-js-client-sdk';

import { environment } from '@ninety/ui/web/environments';

import { FeatureFlagFacade } from '../../_state/app-entities/feature-flag/feature-flag-state.facade';

@Injectable({ providedIn: 'root' })
export class LaunchDarklyService {
  ldClient: LDClient = null;
  ldUserContext: LDContext = null;

  constructor(private featureFlagFacade: FeatureFlagFacade) {}

  init(context: LDSingleKindContext): void {
    // Initialize only once
    if (this.ldUserContext !== null) {
      return;
    }

    this.ldUserContext = context;
    this.ldClient = initialize(environment.launchDarklySdkKey, context, { logger: basicLogger({ level: 'warn' }) });

    this.ldClient.on('ready', () => {
      const allFlags = this.ldClient.allFlags();
      this.featureFlagFacade.initFlagsFromLaunchDarkly(allFlags);
    });

    this.ldClient.on('change', () => {
      const allFlags = this.ldClient.allFlags();
      this.featureFlagFacade.processFeatureFlagUpdates(allFlags);
    });
  }

  async updateContext(updatedUserContext: LDSingleKindContext): Promise<void> {
    // If for some evil race condition this fires before Init,
    // fire the Init with this user
    if (!this.ldUserContext) {
      this.init(updatedUserContext);
      return;
    }

    // This will refresh the flags for the authenticated user
    // and fire the change event.
    await this.ldClient.identify(updatedUserContext);
    this.ldUserContext = this.ldClient.getContext();
  }

  closeConnection(): void {
    this.ldClient.close();
  }
}
