import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FeatureFlagActions } from './feature-flag-state.actions';
import { FeatureFlagStateModel } from './feature-flag-state.model';
import * as selectors from './feature-flag-state.selectors';

@Injectable({ providedIn: 'root' })
export class FeatureFlagFacade {
  featureFlags$ = this.store.select(selectors.selectFeatureFlags);
  selectUserFlagsAreLoaded$ = this.store.select(selectors.selectUserFlagsAreLoaded);

  constructor(private store: Store) {}

  public getFlag<T extends FeatureFlagStateModel, K extends keyof FeatureFlagStateModel>(key: K): Observable<T[K]> {
    return this.store.select(selectors.selectFeatureFlag(key));
  }

  public initLaunchDarkly() {
    this.store.dispatch(FeatureFlagActions.initLaunchDarkly());
  }

  public updateLaunchDarklyUser() {
    this.store.dispatch(FeatureFlagActions.updateLaunchDarklyUser());
  }

  public initFlagsFromLaunchDarkly(flags: Record<string, any>): void {
    this.store.dispatch(FeatureFlagActions.initialFlagsReceived({ flags: flags as FeatureFlagStateModel }));
  }

  public processFeatureFlagUpdates(flags: Record<string, any>): void {
    this.store.dispatch(FeatureFlagActions.processFeatureFlagUpdates({ flags: flags as FeatureFlagStateModel }));
  }
}
