<div
  class="input-overlay"
  ninetyInputVariant="outline"
  [class.ninety-input--error]="hasError?.ninetyHasError"
  cdkTrapFocus
  cdkTrapFocusAutoCapture
  (keydown.escape)="launcher.onEscapeOfOverlay()">
  <div class="selected-wrapper">
    <div class="chip-list" *ngIf="optionListInUse.value.length > 0">
      <div class="chip-item" *ngFor="let match of optionListInUse.value; let index = index">
        <ninety-chip [removable]="true" (removed)="optionListInUse.deselectValue(match)">
          <ng-container
            *ngTemplateOutlet="chipTemplate ? chipTemplate.ref : defaultChipTemplate; context: { value: match }" />
        </ninety-chip>
      </div>
    </div>

    <div *ngIf="optionListInUse.value.length === 0" class="full-width">
      <ng-container *ngTemplateOutlet="input"></ng-container>
    </div>

    <div class="button-container">
      <ninety-select-clear-button [optionList]="optionListInUse" />
      <ninety-select-toggle-button [optionList]="optionListInUse" [isOpen]="true" (click)="launcher.destroyOverlay()" />
    </div>
  </div>

  <div *ngIf="optionListInUse.value.length > 0">
    <ng-container *ngTemplateOutlet="input"></ng-container>
  </div>
  <ng-content select="list"></ng-content>
</div>

<ng-template #defaultChipTemplate ninetySelectChipTemplate let-value="value"> {{ value }} </ng-template>

<!-- Traps the input content slot in a single place, which we then reuse with ngTemplateOutlet-->
<ng-template #input>
  <ng-content select="input"></ng-content>
</ng-template>
