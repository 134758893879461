import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';

import { StateService } from '@ninety/ui/legacy/core/index';
import { FeatureFlagFacade, FeatureFlagKeys } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.main-header--shadow]': 'enableTerrafyToolbarsFeatureFlag',
  },
})
export class MainHeaderComponent {
  @Input() inMeeting: boolean;

  private readonly _enableTerrafyToolbarsFeatureFlag$ = this._featureFlags.getFlag(FeatureFlagKeys.terrafyToolbars);
  enableTerrafyToolbarsFeatureFlag = false;

  constructor(private _featureFlags: FeatureFlagFacade) {
    this._enableTerrafyToolbarsFeatureFlag$.pipe(take(1)).subscribe(isEnabled => {
      this.enableTerrafyToolbarsFeatureFlag = isEnabled;
    });
  }
}
