import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { delay, filter, merge, startWith, Subject, takeUntil, tap } from 'rxjs';

import { TerraInputBoolean } from '../../models';
import { TerraDescriptionComponent } from '../forms/terra-description';
import { TerraAvatarComponent } from '../terra-avatar/terra-avatar.component';
import { TerraCheckboxModule } from '../terra-checkbox';
import { TerraIconComponent } from '../terra-icon';

import {
  TerraOptionPrefixTemplateRefDirective,
  TerraOptionSuffixTemplateRefDirective,
} from './terra-option-slots.directive';
import { TerraOptionBase, TERRA_OPTION_BASE } from './terra-option.interface';

let optionUniqueId = 1;
@Component({
  selector: 'terra-option',
  standalone: true,
  exportAs: 'terraOption',
  imports: [CommonModule, TerraCheckboxModule, ReactiveFormsModule, ObserversModule],
  templateUrl: './terra-option.component.html',
  styleUrls: ['./terra-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TERRA_OPTION_BASE,
      useExisting: TerraOptionComponent,
    },
  ],
})
export class TerraOptionComponent implements AfterContentInit, OnDestroy, TerraOptionBase {
  /** @ignore */
  @ContentChild(TerraOptionPrefixTemplateRefDirective, { read: TemplateRef, static: true })
  _prefixTemplate: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TemplateRef<any> | undefined;

  /** @ignore */
  @ContentChild(TerraOptionSuffixTemplateRefDirective, { read: TemplateRef, static: true })
  _suffixTemplate: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TemplateRef<any> | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChildren(TerraIconComponent) private _icons!: QueryList<TerraIconComponent<any>>;
  @ContentChildren(TerraAvatarComponent) private _avatars!: QueryList<TerraAvatarComponent>;

  @ViewChild('label', { static: true }) private _optionLabel!: ElementRef;

  @ContentChild(TerraDescriptionComponent, { static: true })
  private _optionDescription!: ElementRef<TerraDescriptionComponent>;

  private _destroyed$ = new Subject<void>();

  protected _highlighted = false;

  private _optionId = `terra-option-${optionUniqueId++}`;
  get optionId() {
    return this._optionId;
  }

  /** Ouput when an option has been selected */
  // eslint-disable-next-line @angular-eslint/no-output-native, @typescript-eslint/no-explicit-any
  @Output() select = new EventEmitter<any>();

  /** Output when the content of the option changes */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() content = new EventEmitter<any>();

  /**
   * @ignore
   * Used by tightly coupled parents to toggle the checkbox
   */
  set checkbox(value: boolean) {
    this._checkbox = value;
    this._changeDetectorRef.markForCheck();
  }
  protected _checkbox = false;

  /**
   * Selected state of the option
   * @default false
   */
  get selected(): boolean {
    return this._selected;
  }
  set selected(value: boolean) {
    this._selected = value;
    this._selected ? this._formControl.setValue(true) : this._formControl.setValue(false);
    this._changeDetectorRef.markForCheck();
  }
  protected _selected = false;

  protected _formControl = new FormControl(this._selected);

  /**
   * Disabled state of the option
   * @default false
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this._formControl.disable() : this._formControl.enable();
    this._avatars?.map(avatar => (avatar.inactive = this._disabled));
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Value of the option, required
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input({ required: true }) get value(): any {
    return this._value;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set value(value: any) {
    this._value = value;
    this._changeDetectorRef.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _value: any;

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _ngZone: NgZone
  ) {}

  /** @ignore */
  public _onClick($event: Event): void {
    $event.stopImmediatePropagation(); // Stops native click from continuing to propagate
    !this.disabled && this.select.emit(this._value);
  }

  protected _projectedContentChanged(): void {
    this._ngZone.run(() => {
      this.content.emit(this._value);
    });
  }

  ngAfterContentInit(): void {
    const iconEnforceSize$ = this._icons.changes.pipe(
      startWith(this._icons),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((icons: QueryList<TerraIconComponent<any>>) => {
        // Options that have a description are auto-changed to the large size (including icon size)
        // This will enforce the icon size accordingly
        const hasDescription = !!this._optionDescription;
        icons.map(icon => {
          if (hasDescription) {
            icon.size = 36;
          } else {
            icon.size = 20;
          }
        });
        this._changeDetectorRef.markForCheck();
      })
    );

    const avatarEnforceSize$ = this._avatars.changes.pipe(
      startWith(this._avatars),
      filter(() => !!this._avatars),
      delay(0),
      tap((avatars: QueryList<TerraAvatarComponent>) => {
        avatars.map(avatar => (avatar.inactive = this.disabled));
      }),
      tap((avatars: QueryList<TerraAvatarComponent>) => {
        const hasDescription = !!this._optionDescription;
        avatars.map(avatar => {
          if (hasDescription) {
            avatar.size = 'large';
          } else {
            avatar.size = 'small';
          }
          this._changeDetectorRef.markForCheck();
        });
      })
    );

    merge(iconEnforceSize$, avatarEnforceSize$).pipe(takeUntil(this._destroyed$)).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _scrollIntoView(): void {
    this._elementRef.nativeElement.scrollIntoView({ block: 'center' });
  }

  // Below needed as part of KeyManager interface

  private _getLabel(): string {
    return (this._optionLabel.nativeElement.textContent || '').trim();
  }

  /** @ignore */
  getLabel(): string {
    return this._getLabel();
  }

  /** @ignore */
  setActiveStyles() {
    this._highlighted = true;
    this._scrollIntoView();
    this._changeDetectorRef.markForCheck();
  }

  /** @ignore */
  setInactiveStyles(): void {
    this._highlighted = false;
    this._changeDetectorRef.markForCheck();
  }
}
