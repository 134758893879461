import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { TerraIconModule } from '@ninety/terra';

import { RoleService } from '../../../_core/services/role.service';
import { StateService } from '../../../_core/services/state.service';
import { StopPropagationDirective } from '../../directives/stop-propagation.directive';

const trappedNavigationKeys: Set<string> = new Set([' ', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']);

@Component({
  selector: 'ninety-inline-editable-title',
  templateUrl: './inline-editable-title.component.html',
  styleUrls: ['./inline-editable-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    StopPropagationDirective,
    FlexLayoutModule, // TODO deprecated, use better approach.
    TerraIconModule,
  ],
})
export class InlineEditableTitleComponent implements OnInit {
  private _title: string;
  private _originalTitle: string;
  private _disabled = false;

  private userCanEdit: boolean;

  control = new UntypedFormControl('');
  editing = false;

  @Input() editButtonTooltip = 'Edit title';

  /**
   * set to true when an item is added inline and it is not saved yet in the database(doesn't have an _id)
   */
  @Input() inlineAdd = false;

  private _required = true;
  @Input() set required(value: boolean) {
    this._required = value;

    if (value) this.control.addValidators(Validators.required);
    else this.control.removeValidators(Validators.required);
  }
  get required() {
    return this._required;
  }

  @Input() strikethrough = false;

  @Input() toolTipDisabled = false;

  /**
   * Apply line clamp styling (expand to two lines, then add ellipsis) when the screen width is less than 600px.
   *
   * Note, scss only has support for lineClampXS=2
   */
  @Input() lineClampXS: number;

  /**
   * Apply line clamp styling (expand to two lines, then add ellipsis) regardless of screen width.
   */
  @Input() lineClampToTwoLines = false;

  /**
   * When true, the title will be truncated with ellipsis when it overflows the container. This is expected to be true
   * in all cases except the scorecard, where the title should wrap.
   */
  @Input() applyEllipsis = true;

  @Input() set title(value: string) {
    this._originalTitle = value;

    // Dont reset control value during every change detection cycle
    if (this._title !== value) {
      this.control.setValue(value);
      this._title = value;
      // turn on editing mode when setting title to blank (adding new blank component)
      if (value === '' && this.required) {
        this.editing = true;
        //focus when adding inline
        setTimeout(() => this.titleInput.nativeElement.focus());
      }
    }
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Output() titleChanged = new EventEmitter<string>();

  @Output() cancelAddOneInline: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('titleInput') titleInput: ElementRef;

  /**
   * These keys are used by some unknown accessibility tools in Chrome,
   * Angular, or Material to navigate through a list. They must be disabled in
   * this component, or pressing space or a num key will cause a navigation event
   * which will cause the detail to open (on space) or a different list item to
   * be selected (on a num key, or several pressed right after each other)
   */
  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') this.cancel();

    if (trappedNavigationKeys.has(event.key)) {
      event.stopImmediatePropagation();
    }
  }

  @HostBinding('class.truncate-text') get truncateText(): boolean {
    return this.applyEllipsis;
  }

  constructor(private stateService: StateService) {}

  get title(): string {
    return this._title;
  }

  // TODO: Remove template getter
  get disabled(): boolean {
    return !this.userCanEdit || this._disabled;
  }

  ngOnInit(): void {
    if (this.required) this.control.addValidators(Validators.required);
    else this.control.removeValidators(Validators.required);

    this.control.setValue(this.title);
    this.userCanEdit = RoleService.minRole.lite(this.stateService.currentUser.roleCode!);
  }

  toggleEdit(): void {
    this.editing = !this.editing;

    if (this.editing) {
      setTimeout(() => this.titleInput.nativeElement.focus());
    }
  }

  save(): void {
    if (this.inlineAdd && this.control.invalid) this.cancel();

    if (this.control.valid) {
      // If valid, always toggle
      this.toggleEdit();

      // Only emit on changed value
      if (this.control.value !== this._title) {
        this._title = this.control.value;
        this.titleChanged.emit(this.control.value);
      }
    }
  }

  cancel() {
    if (this.title) this.control.setValue(this._originalTitle);

    this.cancelAddOneInline.emit();
    this.toggleEdit();
  }
}
