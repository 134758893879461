import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Inject,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';

/**
 * Directive which emits whether the element is intersecting with the viewport
 */
@Directive({
  selector: '[ninetyNotifyIntersect]',
  standalone: true,
  exportAs: 'ninetyNotifyIntersectRef',
})
export class NotifyIntersectDirective implements OnInit, OnDestroy, OnChanges {
  /** ninetyNotifyIntersect - Whether to emit intersection changes. When false, the directive does not maintain a subscription. When true, the directive creates an intersection observer and emits changes in visibility to any subscribers. */
  @Input() ninetyNotifyIntersect = false;

  /** rootMargin - The margin around the root. Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left). This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections. */
  @Input() rootMargin = '0px';

  /** threshold - The threshold(s) at which to trigger a change in visibility of the target. This can be a single number or an array of numbers between 0.0 and 1.0. A value of 0.0 indicates that even a single visible pixel counts as the target being visible, while 1.0 indicates that the entire target must be visible. */
  @Input() threshold = 0;

  @Output() isIntersecting = new EventEmitter<boolean>();

  private sub: Subscription;

  ngOnInit(): void {
    this.sub = this.createAndObserve();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ninetyNotifyIntersect?.currentValue) {
      this.sub = this.createAndObserve();
    } else {
      this.sub?.unsubscribe();
    }
  }

  constructor(@Inject(ElementRef) private element: ElementRef) {}

  createAndObserve() {
    return (this.ninetyNotifyIntersect ? this.createObserver() : EMPTY).subscribe(isIntersecting => {
      this.isIntersecting.emit(isIntersecting);
    });
  }

  /**
   * Creates an intersection observer and observes the element, emitting changes in visibility to any subscribers
   * @returns an observable which emits whether element is intersecting with the viewport
   */
  private createObserver(): Observable<boolean> {
    const options: IntersectionObserverInit = {
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };
    return new Observable<boolean>(subscriber => {
      const intersectionObserver = new IntersectionObserver(entries => {
        const { isIntersecting } = entries[0];
        subscriber.next(isIntersecting);
      }, options);

      intersectionObserver.observe(this.element.nativeElement);

      return {
        unsubscribe() {
          intersectionObserver.disconnect();
        },
      };
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
