import { OverlayModule } from '@angular/cdk/overlay';
import { Portal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewContainerRef } from '@angular/core';

import { PopoverOriginDirective } from './popover-origin.directive';

@Component({
  selector: 'ninety-popover',
  standalone: true,
  template: `
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="onBackdropClick()"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop">
      <span class="popover-container">
        <ng-container [cdkPortalOutlet]="portal"></ng-container>
      </span>
    </ng-template>
  `,
  imports: [OverlayModule, PortalModule, CommonModule, PopoverOriginDirective],
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  portal: Portal<unknown>;
  @Input() set content(ref: TemplateRef<unknown>) {
    this.portal = new TemplatePortal(ref, this._viewContainerRef);
  }
  @Input() trigger: PopoverOriginDirective;
  @Input() isOpen: boolean;

  @Output() popoverClosed = new EventEmitter<void>();
  constructor(private _viewContainerRef: ViewContainerRef) {}

  onBackdropClick() {
    this.isOpen = false;
    this.popoverClosed.next();
  }
}
