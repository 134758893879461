import { DirectoryState } from '@ninety/directory-v2/_state/directory-state.model';
import { DirectorySort } from '@ninety/directory-v2/models/directory-sort';
import { DirectoryUserRole } from '@ninety/directory-v2/models/directory-user-role';
import { DirectoryUserStatusV2 } from '@ninety/ui/legacy/shared/models/directory/directory-user-status-v2';
import { MatToNinetySortAdapter, SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { teamAll } from '@ninety/ui/legacy/state/app-entities/team-list/api/team-list.model';

export class GetDirectoryParams {
  //filters
  searchText: string;
  teamId: string;
  userStatus: DirectoryUserStatusV2;
  userRole: DirectoryUserRole;

  // sorting
  sortField: DirectorySort;
  sortDirection: SortDirection;

  // pagination
  pageIndex: number;
  pageSize: number;

  /** transform state data into what the backend needs */
  static fromState(state: DirectoryState): GetDirectoryParams {
    return {
      searchText: state.searchText?.trim(),
      teamId: state.teamId === teamAll._id ? null : state.teamId,
      userStatus: state.selectedUserStatus.status,
      userRole: state.selectedRole.userRole,
      sortField: state.sortField,
      sortDirection: MatToNinetySortAdapter[state.sortDirection],
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
    };
  }
}
