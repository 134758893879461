import { Component, Directive } from '@angular/core';

@Component({
  selector: 'terra-select-search',
  standalone: true,
  template: '<ng-content select="terra-search-input" />',
})
export class TerraSelectSearchComponent {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'terra-select-trigger',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TerraSelectTriggerComponent {}
