import { TooltipPosition } from '@angular/material/tooltip';
import { createSelector } from '@ngrx/store';

import { RoleService } from '../../../_core/services/role.service';
import { RoleName, RoleNameAsCode, User } from '../../../_shared';
import { RoleCode } from '../../../_shared/models/_shared/role-code';
import { getRolesMetadata } from '../../../_shared/models/_shared/role-metadata';
import { RoleSelectOption } from '../../../_shared/models/_shared/role-select-option';
import { selectBos, selectIsTrialingLegacy } from '../../app-global/company/company-state.selectors';
import { selectLanguage, selectLanguageArea } from '../../app-global/language/language.selectors';
import {
  selectAllUsers,
  selectCurrentUser,
  selectCurrentUserId,
  selectRawCurrentUser,
} from '../users/users-state.selectors';

export const selectRolesMetadata = createSelector(selectBos, selectLanguage, selectRawCurrentUser, (bos, language) =>
  getRolesMetadata(bos, language)
);

export const selectRoleSelectData = createSelector(
  selectRolesMetadata,
  selectRawCurrentUser,
  selectIsTrialingLegacy,
  (rolesMetadata, currentUser, isTrialingLegacy): RoleSelectOption[] =>
    rolesMetadata.map(role => {
      const canAssign =
        currentUser.isImplementer ||
        RoleService.canUserAssignRoleCode({
          userRoleCode: currentUser.roleCode,
          otherRoleCode: role.roleCode,
        });
      const isItemDisabled =
        (!isTrialingLegacy &&
          currentUser.roleCode === RoleCode.observer &&
          RoleService.minRole.managee(role.roleCode) &&
          !role.isImplementer) ||
        !canAssign;
      return {
        ...role,
        cannotAssign: !canAssign,
        isItemDisabled,
        itemCssClass: !(role.roleCode == RoleCode.observer || role.isImplementer) ? 'paid-role' : '',
        text: role.title,
        tooltip: isItemDisabled ? `Add more licenses to update your teammate's Role` : '',
        tooltipPosition: 'left' as TooltipPosition,
        value: { title: role.title, roleCode: role.roleCode },
      } as RoleSelectOption;
    })
);

export const selectUserRole = (roleCode: RoleCode, isImplementer: boolean) =>
  createSelector(selectRoleSelectData, roles => {
    return roles.find(role => role.roleCode === roleCode && role.isImplementer === isImplementer);
  });

export const selectUserRoleByName = (role: string) =>
  createSelector(selectRoleSelectData, roles => {
    const roleCode = RoleNameAsCode[role.toLowerCase()] || RoleCode.managee;
    const isImplementer = role.toLowerCase() === RoleName.coach;
    return roles.find(r => r.isImplementer === isImplementer && r.roleCode === roleCode);
  });

export const selectCannotEditRole = (user: User) =>
  createSelector(selectCurrentUser, selectAllUsers, (currentUser, allUsers) => {
    const numberOfOwners = allUsers.filter(u => u.roleCode === RoleCode.owner && !u.isHelpful).length;
    return !(
      (user._id === currentUser._id && numberOfOwners > 1) ||
      currentUser.isImplementer ||
      RoleService.canUserAssignRoleCode({
        userRoleCode: currentUser.roleCode,
        otherRoleCode: user.roleCode,
      })
    );
  });

export const selectCannotEditOwnerToolTip = (user: User) =>
  createSelector(
    selectAllUsers,
    selectCurrentUserId,
    selectLanguageArea('directory'),
    (allUsers, currentUserId, directoryLanguage): string => {
      const numberOfOwners = allUsers.filter(u => u.roleCode === RoleCode.owner && !u.isHelpful).length;

      if (user.roleCode !== RoleCode.owner || numberOfOwners > 1) return '';
      if (user._id === currentUserId) {
        return `You cannot change your role because you are the only ${directoryLanguage.owner}.
      You must first make someone else an ${directoryLanguage.owner}`;
      }
      return `You cannot change this role because they are the only ${directoryLanguage.owner}`;
    }
  );
