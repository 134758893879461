import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, of } from 'rxjs';

import { selectDoesUserHaveAccessToOrgChart } from '../../_state/composite-selectors/user-team.selectors';

@Injectable({
  providedIn: 'root',
})
export class PrivateAccountabilityChartGuard {
  constructor(private router: Router, private store: Store) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectDoesUserHaveAccessToOrgChart).pipe(
      map(hasAccess => (hasAccess ? true : this.reRouteToHome())),
      catchError(() => of(this.reRouteToHome()))
    );
  }

  private reRouteToHome() {
    return this.router.parseUrl('/home');
  }
}
