import { MemoizedSelector, Store } from '@ngrx/store';
import { take } from 'rxjs';

/*
  The only intended use of this function is to be a temporary solution for removing dependency on the
  StateService without a full refactor to a Store.

  Example:
    Replace this:
      object.companyId = this.stateService.companyId;

    With:
      object.companyId = extractValueFromStore(this.store, selectCompanyId);

  Please don't abuse this by one off selecting from the store in components and other places where we should be using an async rxjs pipeline.
*/
export function extractValueFromStore<T>(store: Store, selector: MemoizedSelector<any, T>): T {
  let value: T;

  store
    .select(selector)
    .pipe(take(1))
    .subscribe(val => {
      value = val as T;
    });

  return value;
}
