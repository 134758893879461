import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'terra-input-prefix',
  template: '<ng-content select="terra-icon" />',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TerraInputPrefixComponent {}

@Component({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'terra-input-suffix',
  template: '<ng-content select="terra-icon" />',
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TerraInputSuffixComponent {}
