import { Person } from '../_shared/person';
import { DirectoryUserStatusV2 } from '../directory/directory-user-status-v2';
import { EmailType } from '../directory/email-type';
import { PhoneNumber } from '../directory/phone-number';
import { PhoneType } from '../directory/phone-type';
import { PageViews } from '../user/page-views';

import { Email } from './email';
import { PersonMetadata } from './person-metadata';
import { RoleCode } from './role-code';
import { Team } from './team';
import { TeamMeasurables } from './team-measurables';
import { UserSettings } from './user-settings';
import { UserSms } from './user-sms';

export class User {
  _id?: string;
  active?: boolean;
  bio?: string | null;
  title?: string | null;
  company?: {
    _id?: string;
    companyId: string;
    lastAccessed?: string;
  };
  deleted?: boolean;
  emailAddresses?: Email[];
  primaryEmail?: string;
  fullName?: string;
  hasBeenInvited?: boolean;
  hasRubiconAccess?: boolean | null; // ToDo: Remove this property.  Confirm safe to remove from BE as well.
  isHelpful?: boolean;
  isImplementer?: boolean;
  lastAccessedTeamId?: string;
  /** @deprecated */
  liteMeasurables?: TeamMeasurables;
  metadata?: PersonMetadata;
  ordinal?: number;
  personId?: string;
  personMetadataId?: string;
  phoneNumbers?: PhoneNumber[];
  preImplementer: PreImplementer;
  roleCode?: RoleCode;
  settings: UserSettings;
  sms: Partial<UserSms>;
  teams?: { teamId: string }[];
  tutorialsHidden?: UserTutorials;

  /** helper field only used for the directory page, will not persist */
  toBeInvited?: boolean;

  /** whether to show the getting started guide */
  guideEnabled?: boolean;

  /** whether the getting started guide has been seen or not.  We open it up automatically the first time */
  guideViewed?: boolean;

  /** whether the user has dismissed the getting started guide and will no longer be shown */
  guideDismissed?: boolean;

  /** used for getting started guide.  Only gets updated if guide is enabled.
   * Certain definitions of done are determined if the user has visited a certain page */
  pageViews?: PageViews;

  /** for different visual cues on the directory page */
  status?: DirectoryUserStatusV2;

  // aggregation only
  person?: Person;

  constructor(companyId: string, email?: string, phoneNumber?: string) {
    this.company = { companyId };
    this.emailAddresses = email ? [new Email(email, EmailType.main)] : null;
    this.phoneNumbers = phoneNumber ? [new PhoneNumber(phoneNumber, PhoneType.main)] : null;
  }
}

export interface PersonMetadataResponse {
  route: string;
  clientError: any;
  error: any;
  personMetadata: PersonMetadata;
}

export interface DeleteUserResponse {
  route: string;
  clientError: any;
  error: any;
}

export interface InviteUserResponse {
  route: string;
  response: {
    userId: string;
    confirmation: string;
  };
  clientError: any;
  error: any;
}

export interface AdminEmail {
  email: string;
  name: {
    first: string;
    last: string;
  };
}

export interface PreImplementer {
  roleCode: RoleCode;
  teams: Team[];
}

export interface UserTutorials {
  myNinetyTeamTodos?: boolean;
  myNinetyRocks?: boolean;
  assesmentsGettingStarted?: boolean;
}
