import { Pipe, PipeTransform } from '@angular/core';
import { endOfDay, isWithinInterval, startOfDay, subDays } from 'date-fns';

@Pipe({
  name: 'disableLaunchSchedule',
  standalone: true,
})
/**
 * enable only one launch btn for upcoming schedules, disabled if meeting in progress, not first schedule, or not in range
 */
export class DisableLaunchSchedulePipe implements PipeTransform {
  transform(date: Date | string, meetingInProgress: boolean): boolean {
    if (meetingInProgress) {
      return true;
    }

    const today = new Date();
    const isInRange = isWithinInterval(new Date(date), {
      start: startOfDay(subDays(today, 1)), // Start of yesterday
      end: endOfDay(today),
    });

    return !isInRange;
  }
}
