<ng-container
  *ngIf="
    {
      chipMessage: chipMessage$ | async,
      companySettings: companySettings$ | async,
      createDialogIsOpen: createDialogService.isOpen$ | async,
      currentMeeting: currentMeeting$ | async,
      currentPerson: currentPerson$ | async,
      disableNavLinks: disableNavLinks$ | async,
      enableLearningModule: enableLearningModule$ | async,
      enableTerrafyToolbarsFeatureFlag: enableTerrafyToolbarsFeatureFlag$ | async,
      enableSurveys: enableSurveys$ | async,
      isEditing: isEditing$ | async,
      isFetchingData: filterService.isFetchingData$ | async,
      isManageeOrAbove: isManageeOrAbove$ | async,
      isObserver: isObserver$ | async,
      language: language$ | async,
      mainToolbarHasShadow: mainToolbarHasShadow$ | async,
      meetingTeam: meetingService.team$ | async,
      pageTitle: pageTitle$ | async,
      participants: participants$ | async,
      toolBarHidden: mainToolbarHidden$ | async,
      canAccessInsights: canAccessInsights$ | async,
      scorecardBeta: scorecardBeta$ | async,
      directoryV2: directoryV2$ | async,
      currentConversationMeeting: currentConversationMeeting$ | async,
      canAccessMeetingsV2: canAccessMeetingsV2$ | async,
    } | ngrxPush as data
  ">
  <!-- Terrafy Toolbar Header -->
  <header
    *ngIf="data.enableTerrafyToolbarsFeatureFlag && data.currentPerson && !data.toolBarHidden"
    class="terrafy-top-toolbar__header"
    [class.has-box-shadow]="data.mainToolbarHasShadow !== false">
    <button
      data-cy="top-toolbar_toggle-meeting-side-nav-button"
      *ngIf="meetingStarted"
      mat-icon-button
      (click)="stateService.toggleMeetingSideNav(!stateService.toggleMeetingSideNavB$.value)">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu Trigger -->
    <button
      data-cy="top-toolbar_hamburger-menu-button"
      *ngIf="!(meetingStarted || data.isEditing)"
      #responsiveMenuTrigger
      class="menu-button-hamburger"
      mat-icon-button
      [matMenuTriggerFor]="responsiveNavMenu">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu -->
    <mat-menu #responsiveNavMenu="matMenu">
      <ng-template matMenuContent>
        <div class="responsive-nav-menu">
          <ninety-company-menu [menuItem]="true"></ninety-company-menu>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'partner-hub' } }]"
            routerLinkActive="active"
            *ngIf="data.currentPerson?.partnerType">
            <terra-icon icon="users" />
            Partner Hub
          </a>
          <a
            mat-menu-item
            [href]="'/dashboard'"
            *ngIf="data.companySettings.dashboard"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="dashboard" />
            {{ data.language.dashboard.route }}
          </a>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'home' } }]"
            routerLinkActive="active"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="my-90" />
            {{ data.language.my90.route }}
          </a>
          <a
            *ngIf="data.canAccessInsights"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'insights' } }]"
            routerLinkActive="active">
            <terra-icon icon="lightbulb" />
            <!-- TODO: Custom Language -->
            Insights
          </a>
          <!-- Scorecard 1.0 -->
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data' } }]"
            routerLinkActive="active">
            <terra-icon icon="data" />
            {{ data.language.scorecard.route }}
          </a>
          <!-- Scorecard 2.0 -->
          <a
            *ngIf="data.scorecardBeta"
            mat-menu-item
            data-product="main-sidenav__scorecard-mobile-beta-link"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data-v2' } }]"
            routerLinkActive="active">
            <terra-icon icon="data" />
            {{ data.language.scorecard.route }} (Beta)
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'rocks' } }]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="rocks" />
            {{ data.language.rock.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'todos' } }]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="to-dos" />
            {{ data.language.todo.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'issues' } }]"
            routerLinkActive="active">
            <terra-icon icon="issues" />
            {{ data.language.issue.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="
              data.disableNavLinks
                ? null
                : [{ outlets: { detail: null, primary: data.canAccessMeetingsV2 ? 'meetings-v2' : 'meetings' } }]
            "
            routerLinkActive="active">
            <terra-icon icon="meetings" />
            {{ data.language.meeting.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'headlines' } }]"
            routerLinkActive="active">
            <terra-icon icon="headlines" />
            {{ data.language.headline.items }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'vision' } }]"
            routerLinkActive="active">
            <terra-icon icon="vision" />
            {{ data.language.vto.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'chart' } }]"
            routerLinkActive="active"
            *ngIf="canAccessOrgChart$ | ngrxPush">
            <terra-icon icon="responsibilities" />
            {{ data.language.acChart.route }}
          </a>
          <a
            *ngIf="data.isManageeOrAbove"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: '1-on-1' } }]"
            routerLinkActive="active">
            <terra-icon matTooltip="Feedback" icon="one-on-one" />
            {{ data.language.feedback.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'process' } }]"
            routerLinkActive="active">
            <terra-icon icon="process" matTooltip="Process" />
            {{ data.language.process.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route }}
          </a>
          <a
            *ngIf="data.directoryV2"
            mat-menu-item
            data-product="main-sidenav__directory-v2-link"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory-v2' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route }} V2
          </a>
          <a
            mat-menu-item
            *ngIf="data.companySettings?.mastery.enabled && !stateService.isLiteUser"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'mastery' } }]"
            routerLinkActive="active">
            <terra-icon icon="mastery" />
            {{ data.language.mastery.route }}
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableLearningModule"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'knowledge' } }]"
            routerLinkActive="active">
            <terra-icon icon="learn" />
            Knowledge
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableSurveys"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'assessments' } }]"
            routerLinkActive="active">
            <terra-icon icon="surveys" />
            {{ data.language.survey.route }}
          </a>
          <terra-divider />
          <ninety-settings-menu
            class="settings-menu-extended"
            (settingLinkClicked)="handleSettingLinkClicked()"></ninety-settings-menu>
          <a mat-menu-item (click)="logout()">
            <terra-icon icon="sign-out" />
            Log Out
          </a>
        </div>
      </ng-template>
    </mat-menu>

    <div
      style="visibility: hidden; position: fixed"
      [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y"
      [matMenuTriggerFor]="presenceMenu"></div>

    <mat-menu #presenceMenu="matMenu">
      <ng-template matMenuContent let-userId="userId" let-index="index">
        <button mat-menu-item class="hidden"></button>
        <button mat-menu-item (click)="meetingService.changePresenter(userId)">
          <span>Make presenter</span>
        </button>
      </ng-template>
    </mat-menu>

    <div>
      <h1
        fxHide.lt-sm
        attr.data-cy-dynamic="top-toolbar_page-title__{{ data.pageTitle }}"
        data-cy="top-toolbar_page-title"
        class="page-title terrafy-top-toolbar__title"
        [class.no-border-left]="meetingStarted">
        <span [innerHTML]="data.pageTitle | safeHtml"></span>
        <span *ngIf="data.meetingTeam as team">- {{ team?.name }}</span>
      </h1>
      <p fxHide.lt-sm class="terrafy-top-toolbar__description">{{ _getPageDescription(data.pageTitle) }}</p>
    </div>
    <mat-chip-list
      data-cy="top-toolbar_chip-message"
      fxHide.lt-sm="true"
      *ngIf="data.chipMessage as message"
      matTooltip="Shared Configurations and companies that have prevented their coach control are Read Only."
      matTooltipClass="ninety-tooltip">
      <mat-chip selected color="accent" class="ninety-chip">{{ message }}</mat-chip>
    </mat-chip-list>
    <div class="fill-remaining-space"></div>

    <ng-container *ngIf="data.currentMeeting">
      <div
        *ngIf="
          data.currentMeeting && (data.participants?.userIds?.length > 0 || data.participants?.usernames?.length > 0)
        "
        class="presence-container">
        <ng-container *ngFor="let userId of data.participants.userIds; let i = index">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL"
            (contextmenu)="openContextMenu($event, userId, i, data.participants.isObserver[i])" />
        </ng-container>
        <div
          [matTooltip]="data.participants.usernames.join(' \n ')"
          *ngIf="data.participants.usernames.length > 0"
          matTooltipClass="ninety-tooltip multiline-tooltip"
          matTooltipPosition="above"
          ninetyStopPropagation
          class="d-print-none more-participants">
          <span class="user-initials"> + {{ data.participants.usernames.length }} </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="data.currentConversationMeeting">
      <div *ngIf="data.currentConversationMeeting.occupants?.length > 0" class="presence-container">
        <ng-container *ngFor="let userId of data.currentConversationMeeting.occupants">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL" />
        </ng-container>
      </div>
    </ng-container>

    <ninety-button
      filled
      primary
      (click)="openUniversalCreateModal()"
      data-cy="top-toolbar_create-button"
      *ngIf="data.isManageeOrAbove"
      [disabled]="
        data.disableNavLinks ||
        data.isFetchingData ||
        data.isObserver ||
        !(userTeams$ | async)?.length ||
        !!data.createDialogIsOpen
      ">
      Create
    </ninety-button>
  </header>

  <!-- Legacy Mat Toolbar -->
  <mat-toolbar
    *ngIf="!data.enableTerrafyToolbarsFeatureFlag && data.currentPerson && !data.toolBarHidden"
    class="top-toolbar"
    [class.has-box-shadow]="data.mainToolbarHasShadow !== false">
    <button
      data-cy="top-toolbar_toggle-meeting-side-nav-button"
      *ngIf="meetingStarted"
      mat-icon-button
      (click)="stateService.toggleMeetingSideNav(!stateService.toggleMeetingSideNavB$.value)">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu Trigger -->
    <button
      data-cy="top-toolbar_hamburger-menu-button"
      *ngIf="!(meetingStarted || data.isEditing)"
      #responsiveMenuTrigger
      class="menu-button-hamburger"
      mat-icon-button
      [matMenuTriggerFor]="responsiveNavMenu">
      <terra-icon icon="list" />
    </button>
    <!-- Mobile Navigation Menu -->
    <mat-menu #responsiveNavMenu="matMenu">
      <ng-template matMenuContent>
        <div class="responsive-nav-menu">
          <ninety-company-menu [menuItem]="true"></ninety-company-menu>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'partner-hub' } }]"
            routerLinkActive="active"
            *ngIf="data.currentPerson?.partnerType">
            <terra-icon icon="users" />
            Partner Hub
          </a>
          <a
            mat-menu-item
            [href]="'/dashboard'"
            *ngIf="data.companySettings.dashboard"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="dashboard" />
            {{ data.language.dashboard.route }}
          </a>
          <a
            mat-menu-item
            [routerLink]="[{ outlets: { detail: null, primary: 'home' } }]"
            routerLinkActive="active"
            [class.disabled]="data.disableNavLinks">
            <terra-icon icon="my-90" />
            {{ data.language.my90.route }}
          </a>
          <a
            *ngIf="data.canAccessInsights"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'insights' } }]"
            routerLinkActive="active">
            <terra-icon icon="lightbulb" />
            <!-- TODO: Custom Language -->
            Insights
          </a>
          <!-- Scorecard 1.0 -->
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data' } }]"
            routerLinkActive="active">
            <terra-icon icon="data" />
            {{ data.language.scorecard.route }}
          </a>
          <!-- Scorecard 2.0 -->
          <a
            *ngIf="data.scorecardBeta"
            mat-menu-item
            data-product="main-sidenav__scorecard-mobile-beta-link"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'data-v2' } }]"
            routerLinkActive="active">
            <terra-icon icon="data" />
            {{ data.language.scorecard.route }} (Beta)
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'rocks' } }]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="rocks" />
            {{ data.language.rock.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'todos' } }]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <terra-icon icon="to-dos" />
            {{ data.language.todo.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'issues' } }]"
            routerLinkActive="active">
            <terra-icon icon="issues" />
            {{ data.language.issue.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="
              data.disableNavLinks
                ? null
                : [{ outlets: { detail: null, primary: data.canAccessMeetingsV2 ? 'meetings-v2' : 'meetings' } }]
            "
            routerLinkActive="active">
            <terra-icon icon="meetings" />
            {{ data.language.meeting.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'headlines' } }]"
            routerLinkActive="active">
            <terra-icon icon="headlines" />
            {{ data.language.headline.items }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'vision' } }]"
            routerLinkActive="active">
            <terra-icon icon="vision" />
            {{ data.language.vto.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'chart' } }]"
            routerLinkActive="active"
            *ngIf="canAccessOrgChart$ | ngrxPush">
            <terra-icon icon="responsibilities" />
            {{ data.language.acChart.route }}
          </a>
          <a
            *ngIf="data.isManageeOrAbove"
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: '1-on-1' } }]"
            routerLinkActive="active">
            <terra-icon matTooltip="Feedback" icon="one-on-one" />
            {{ data.language.feedback.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'process' } }]"
            routerLinkActive="active">
            <terra-icon icon="process" matTooltip="Process" />
            {{ data.language.process.route }}
          </a>
          <a
            mat-menu-item
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route }}
          </a>
          <a
            *ngIf="data.directoryV2"
            mat-menu-item
            data-product="main-sidenav__directory-v2-link"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'directory-v2' } }]"
            routerLinkActive="active">
            <terra-icon icon="directory" />
            {{ data.language.directory.route }} V2
          </a>
          <a
            mat-menu-item
            *ngIf="data.companySettings?.mastery.enabled && !stateService.isLiteUser"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'mastery' } }]"
            routerLinkActive="active">
            <terra-icon icon="mastery" />
            {{ data.language.mastery.route }}
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableLearningModule"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'knowledge' } }]"
            routerLinkActive="active">
            <terra-icon icon="learn" />
            Knowledge
          </a>
          <a
            mat-menu-item
            *ngIf="data.enableSurveys"
            [class.disabled]="data.disableNavLinks"
            [routerLink]="data.disableNavLinks ? null : [{ outlets: { detail: null, primary: 'assessments' } }]"
            routerLinkActive="active">
            <terra-icon icon="surveys" />
            {{ data.language.survey.route }}
          </a>
          <terra-divider />
          <ninety-settings-menu
            class="settings-menu-extended"
            (settingLinkClicked)="handleSettingLinkClicked()"></ninety-settings-menu>
          <a mat-menu-item (click)="logout()">
            <terra-icon icon="sign-out" />
            Log Out
          </a>
        </div>
      </ng-template>
    </mat-menu>

    <div
      style="visibility: hidden; position: fixed"
      [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y"
      [matMenuTriggerFor]="presenceMenu"></div>

    <mat-menu #presenceMenu="matMenu">
      <ng-template matMenuContent let-userId="userId" let-index="index">
        <button mat-menu-item class="hidden"></button>
        <button mat-menu-item (click)="meetingService.changePresenter(userId)">
          <span>Make presenter</span>
        </button>
      </ng-template>
    </mat-menu>

    <h1
      fxHide.lt-sm
      attr.data-cy-dynamic="top-toolbar_page-title__{{ data.pageTitle }}"
      data-cy="top-toolbar_page-title"
      class="page-title"
      [class.no-border-left]="meetingStarted">
      <span [innerHTML]="data.pageTitle | safeHtml"></span>
      <span *ngIf="data.meetingTeam as team">- {{ team?.name }}</span>
    </h1>
    <mat-chip-list
      data-cy="top-toolbar_chip-message"
      fxHide.lt-sm="true"
      *ngIf="data.chipMessage as message"
      matTooltip="Shared Configurations and companies that have prevented their coach control are Read Only."
      matTooltipClass="ninety-tooltip">
      <mat-chip selected color="accent" class="ninety-chip">{{ message }}</mat-chip>
    </mat-chip-list>
    <div class="fill-remaining-space"></div>

    <ng-container *ngIf="data.currentMeeting">
      <div
        *ngIf="
          data.currentMeeting && (data.participants?.userIds?.length > 0 || data.participants?.usernames?.length > 0)
        "
        class="presence-container">
        <ng-container *ngFor="let userId of data.participants.userIds; let i = index">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL"
            (contextmenu)="openContextMenu($event, userId, i, data.participants.isObserver[i])" />
        </ng-container>
        <div
          [matTooltip]="data.participants.usernames.join(' \n ')"
          *ngIf="data.participants.usernames.length > 0"
          matTooltipClass="ninety-tooltip multiline-tooltip"
          matTooltipPosition="above"
          ninetyStopPropagation
          class="d-print-none more-participants">
          <span class="user-initials"> + {{ data.participants.usernames.length }} </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="data.currentConversationMeeting">
      <div *ngIf="data.currentConversationMeeting.occupants?.length > 0" class="presence-container">
        <ng-container *ngFor="let userId of data.currentConversationMeeting.occupants">
          <terra-avatar
            *ngIf="store.select(selectUserAvatarInfo(userId)) | ngrxPush as avatar"
            class="meeting-user"
            [initials]="avatar.userInitials"
            [src]="avatar.pictureURL" />
        </ng-container>
      </div>
    </ng-container>

    <ninety-button
      filled
      primary
      (click)="openUniversalCreateModal()"
      data-cy="top-toolbar_create-button"
      *ngIf="data.isManageeOrAbove"
      [disabled]="
        data.disableNavLinks ||
        data.isFetchingData ||
        data.isObserver ||
        !(userTeams$ | async)?.length ||
        !!data.createDialogIsOpen
      ">
      Create
    </ninety-button>
  </mat-toolbar>
</ng-container>
