import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

import { extractValueFromStore } from '../../../_state';
import { UserSelectors } from '../../../_state/app-entities/users/users-state.selectors';

@Pipe({
  name: 'ownerOnTeam',
  standalone: true,
})
export class OwnerOnTeamPipe implements PipeTransform {
  constructor(private store: Store) {}
  transform(selectedUserIds: string[], teamId: string): boolean {
    for (const id of selectedUserIds) {
      const user = extractValueFromStore(this.store, UserSelectors.selectUserById(id));
      if (user.teams.find(team => team.teamId === teamId)) {
        return true;
      }
    }
  }
}
