import { createReducer, on } from '@ngrx/store';

import { UserTeamsActions } from '../team-list/team-list-state.actions';
import { UsersStateActions } from '../users/users-state.actions';

import { UserListStateActions } from './user-list-state.actions';
import { userListInitialState, userListStateAdapter, UserListStateModel } from './user-list-state.model';

export const UserListStateReducer = createReducer(
  userListInitialState,
  on(UserListStateActions.getUserListSuccess, (state, { users }) => userListStateAdapter.setAll(users, state)),
  on(
    UserListStateActions.setLoggedInUser,
    (state, { loggedInUser }): UserListStateModel => ({
      ...state,
      loggedInUser,
    })
  ),
  on(UserTeamsActions.addUsersToTeam, (state, { users, teamId }) => {
    const newState = { ...state };

    if (users.some(user => user._id === state.loggedInUser._id)) {
      newState.loggedInUser = {
        ...newState.loggedInUser,
        teams: [...state.loggedInUser.teams, { teamId }],
      };
    }

    const updates = users.map(user => ({
      id: user._id,
      changes: { teamIds: [...state.entities[user._id].teamIds, teamId] },
    }));
    return userListStateAdapter.updateMany(updates, newState);
  }),
  on(UserTeamsActions.removeUserFromTeam, (state, { userId, teamId }) => {
    const newState = { ...state };

    if (state.loggedInUser._id === userId) {
      newState.loggedInUser = {
        ...newState.loggedInUser,
        teams: state.loggedInUser.teams.filter(team => team.teamId !== teamId),
      };
    }
    const update = {
      id: userId,
      changes: {
        teamIds: state.entities[userId].teamIds.filter(id => id !== teamId),
      },
    };
    return userListStateAdapter.updateOne(update, newState);
  }),
  on(UsersStateActions.updateOne, UsersStateActions.updateSuccess, (state, { _id, changes }) => {
    const update = {
      id: _id,
      changes: {
        ...(changes?.roleCode ? { roleCode: changes.roleCode } : null),
        ...(changes?.isImplementer ? { isImplementer: changes.isImplementer } : null),
        ...(changes?.teams ? { teamIds: changes.teams.map(t => t.teamId) } : null),
      },
    };
    // Only update for properties we track in this store
    if (Object.keys(update.changes).length > 0) {
      return userListStateAdapter.updateOne(update, state);
    }
    return state;
  })
);
