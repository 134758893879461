import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './user-import-confirm-dialog.component.html',
  styleUrls: ['./user-import-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserImportConfirmDialogComponent {
  acknowledgeCost = false;
  teamsAreReady = false;

  constructor(public dialogRef: MatDialogRef<UserImportConfirmDialogComponent, boolean>) {}

  proceedToImport() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close();
  }
}
