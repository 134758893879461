import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Subscription, map, tap } from 'rxjs';

import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { MenuComponent } from '@ninety/ui/legacy/components/menu/menu.component';
import { NavigationMenuItemComponent } from '@ninety/ui/legacy/components/menu-items/navigation-menu-item/navigation-menu-item.component';
import { POPOVER_TOKEN, PopoverService } from '@ninety/ui/legacy/components/popover/popover.service';
import { AuthService } from '@ninety/ui/legacy/core/services/auth.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { Theme } from '@ninety/ui/legacy/shared/models/enums/theme';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { UserModelState } from '@ninety/ui/legacy/state/app-entities/users/users-state.model';
import {
  selectCurrentUser,
  selectHasUserSettingsAlerts,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectIsBillingV2Company } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';
import * as HelpfulSelectors from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';

@Component({
  selector: 'ninety-user-profile-menu',
  templateUrl: 'user-profile-menu.component.html',
  styleUrls: ['user-profile-menu.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NavigationMenuItemComponent,
    MenuComponent,
    LetDirective,
    PushPipe,
    TerraIconModule,
    TerraAvatarModule,
  ],
})
export class UserProfileMenuComponent implements OnInit, OnDestroy {
  /**
   * if disabled is true, show different styles and don't allow navClick
   */
  @Input() disabled = false;

  readonly hideReferFriendMenuItem$ = this.featureFlagFacade
    .getFlag(FeatureFlagKeys.hideReferFriendMenuItem)
    .pipe(map(value => value || this.user.isImplementer));

  readonly Theme = Theme; // theme enum for use in HTML
  private userSubscription: Subscription;
  public user: UserModelState;

  readonly isHelpful$ = this.store.select(HelpfulSelectors.selectHasAnyHelpfulPermission);
  readonly hasPartnerPermission$ = this.store.select(HelpfulSelectors.selectHasPartnerPermissions);
  protected billingUrl: string;
  private isBillingV2Company = false;
  protected hasSettingsAlerts$ = this.store.select(selectHasUserSettingsAlerts);

  constructor(
    public stateService: StateService,
    private featureFlagFacade: FeatureFlagFacade,
    private store: Store,
    public authService: AuthService,
    private router: Router,
    @Inject(POPOVER_TOKEN) private popoverRef: PopoverService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.store
      .select(selectCurrentUser)
      .pipe(
        tap(user => {
          this.user = user;
        })
      )
      .subscribe();

    this.userSubscription = this.store
      .select(selectIsBillingV2Company)
      .pipe(
        tap(isBillingV2Company => {
          this.isBillingV2Company = isBillingV2Company;
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  itemClicked(url: string): void {
    /*
      Changed from using routerLink to this to handle auxiliary routes.
      For some reason loading this component in the PopoverService doesn't allow us to override the detail outlet.
      I wasn't able to find another way to get this to work. 😔 )
    */
    this.router.navigateByUrl(url);
    this.close();
  }

  close() {
    this.popoverRef.close();
  }

  openReferralUrl() {
    window.open(
      'https://www.ninety.io/refer-a-friend?utm_source=cs_referral&utm_campaign=cs_referral&utm_id=cs_referral',
      '_blank'
    );
    this.close();
  }

  logout() {
    this.authService.logout();
    this.close();
  }
}
