import { Directive, TemplateRef } from '@angular/core';

export interface SelectValue<T> {
  value: T;
}

/** Directive used to supply a template used to render a chip by the select module. */
@Directive({
  selector: 'ng-template[ninetySelectChipTemplate]',
  standalone: true,
  exportAs: 'ninetySelectChipTemplate',
})
export class SelectChipTemplateDirective {
  constructor(public readonly ref: TemplateRef<unknown>) {}

  // TODO while this does work as expected, it doesnt enable inferring the type T of the SelectValue<T> interface
  static ngTemplateContextGuard<T>(_: unknown, ctx: SelectValue<T>): ctx is SelectValue<T> {
    return true;
  }
}
