import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CreateTeamDto } from '@ninety/ui/legacy/core/index';

import { TeamsComponentListModel } from './teams-component.model';

export const TeamComponentActions = createActionGroup({
  source: 'Team List Page',
  events: {
    'Display Add Team Dialog': emptyProps(),

    'Confirm Delete Team': props<{ team: TeamsComponentListModel }>(),
    'Delete Team': props<{ teamId: string }>(),
    'Delete Team Success': props<{ teamId: string }>(),
    'Delete Team Error': props<{ error: any }>(),

    'Confirm Archive Team': props<{ team: TeamsComponentListModel }>(),
    'Archive Team': props<{ teamId: string }>(),
    'Archive Team Success': props<{ teamId: string }>(),
    'Archive Team Error': props<{ error: any }>(),

    'Reactivate Team': props<{ team: TeamsComponentListModel }>(),
    'Reactivate Team Success': props<{ team: TeamsComponentListModel }>(),
    'Reactivate Team Error': props<{ error: any }>(),

    'Update Team': props<{ teamId: string; update: Partial<TeamsComponentListModel> }>(),
    'Update Team Success': props<{ team: TeamsComponentListModel }>(),
    'Update Team Error': props<{ error: any }>(),

    'Confirm Set Slt Team': props<{ team: TeamsComponentListModel }>(),
    'Set SLT Team': props<{ teamId: string }>(),
    'Set SLT Team Success': props<{ teamId: string }>(),
    'Set SLT Team Failure': props<{ error: any }>(),
  },
});

export const TeamDialogActions = createActionGroup({
  source: 'New Team Dialog',
  events: {
    'Create Team': props<{ team: CreateTeamDto }>(),
    'Create Team Success': props<{ _id: string; team: CreateTeamDto }>(),
    'Create Team Error': props<{ error: any }>(),
  },
});
