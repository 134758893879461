import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Attribute,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { TerraInputBoolean } from '../../../models';
import { TerraErrorStateMatcher } from '../../forms/terra-error-state-matcher';

export type TerraAutoComplete = 'on' | 'off' | string;

/**
 * Base class for all Terra "text-ish" input components
 * This gives common functionality to all text-ish inputs that extend this class
 *
 * Some requirements:
 *  - The extending class must have an input with #input on it so we can reference it
 *  - The extending template will need to connect this properties to their input
 *  - To style it please see the base.scss file for notes
 *
 * @example
 *  See TerraTextInputComponent for an example of how to extend this class, and template
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
  },
})
export class TerraInputBaseClass implements ControlValueAccessor, OnInit {
  @ViewChild('input', { static: true }) protected _input!: ElementRef<HTMLInputElement>;
  // Implemented as part of ControlValueAccessor.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onChange!: (_: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched!: (_: unknown) => void;

  /**
   * Set the autocomplete of the input
   */
  @Input() get autocomplete(): string {
    return this._autocomplete;
  }
  set autocomplete(value: string) {
    this._autocomplete = value;
    this._changeDetectorRef.markForCheck();
  }
  private _autocomplete = 'off';

  /**
   * Set the placeholder of the input
   */
  @Input() get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this._changeDetectorRef.markForCheck();
  }
  private _placeholder = '';

  /**
   * Set the disabled state of the input
   */
  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: TerraInputBoolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _disabled = false;

  /**
   * Set the readonly state of the input
   */
  @Input() get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: TerraInputBoolean) {
    this._readonly = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _readonly = false;

  /**
   * Optional input to override aria-label
   * @default null
   */
  @Input('aria-label') ariaLabel: string | null = null;
  /**
   * Optional input to override aria-labelledby
   * @default null
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;
  /**
   * Optional input to override aria-describedby
   * @default null
   */
  @Input('aria-describedby') ariaDescribedby: string | null = null;

  constructor(
    @Self() protected readonly _ngControl: NgControl,
    @Optional() @Attribute('autofocus') private readonly _autofocus: string | null,
    protected readonly _terraErrorStateMatcher: TerraErrorStateMatcher,
    protected readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef
  ) {
    this._ngControl.valueAccessor = this;
  }

  ngOnInit() {
    if (this._autofocus === '') {
      this.focus();
    }
  }

  /**
   * Focus the input
   */
  focus() {
    this._input.nativeElement.focus();
    // Manually call event so that (focus) on template correctly reacts
    this._input.nativeElement.dispatchEvent(new FocusEvent('focus'));
  }

  /**
   * Blur the input
   */
  blur() {
    this._input.nativeElement.blur();
    // Manually call event so that (blur) on template correctly reacts
    this._input.nativeElement.dispatchEvent(new FocusEvent('blur'));
  }

  protected _touched() {
    this._onTouched(true);
  }

  protected _blurred(event: FocusEvent) {
    this._touched();
    // Blur/focus events don't bubble, so we have to dispatch a custom event to one above us
    const customEvent = new CustomEvent('blur', { bubbles: false, cancelable: true, detail: { originalEvent: event } });
    this._elementRef.nativeElement.dispatchEvent(customEvent);
  }

  protected _focused(event: FocusEvent) {
    // Blur/focus events don't bubble, so we have to dispatch a custom event to one above us
    const customEvent = new CustomEvent('focus', {
      bubbles: false,
      cancelable: true,
      detail: { originalEvent: event },
    });
    this._elementRef.nativeElement.dispatchEvent(customEvent);
  }

  protected _inputValue: string | undefined;

  /** @ignore */
  writeValue(value: string | undefined): void {
    this._inputValue = value;
    this._changeDetectorRef.markForCheck();
  }

  /** @ignore */
  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  /** @ignore */
  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouched = fn;
  }

  /** @ignore */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @ignore */
  protected _onModelChange(value: string | undefined) {
    this._inputValue = value;
    this._onChange(value);
  }
}
