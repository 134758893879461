import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';

import { MeetingsPageAlphaComponent } from './meetings-page-alpha.component';
import { MeetingsPageComponent } from './meetings-page.component';

@Component({
  selector: 'ninety-meetings-alpha-wrapper',
  imports: [CommonModule, MeetingsPageAlphaComponent, MeetingsPageComponent, LetDirective],
  standalone: true,
  template: `
    <ng-container *ngrxLet="meetingScheduling$ as meetingScheduling">
      <ng-container *ngIf="meetingScheduling; else meetingsPageAlpha">
        <ninety-meetings></ninety-meetings>
      </ng-container>

      <ng-template #meetingsPageAlpha> <ninety-meetings-alpha></ninety-meetings-alpha> </ng-template>
    </ng-container>
  `,
})
/**
 * Delete this temporary wrapper and update the meetings-routing.module.ts to route directly to the meetings-page.component
 * once meeting scheduling hits beta
 */
export class MeetingsAlphaWrapperComponent {
  store = inject(Store);

  meetingScheduling$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.meetingScheduling));
}
