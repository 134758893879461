<div
  *ngrxLet="{
    language: facade.language$,
    teams: facade.teamsOptions$,
    loading: facade.loading$
  } as vm">
  <ninety-action-bar>
    <div class="action-bar-left">
      <ninety-quick-filter
        pillTitle="Team"
        [pillIcon]="'users-three'"
        (selectionChange)="facade.teamSelected($event)"
        [options]="vm.teams"
        [disabled]="vm.loading"
        data-cy="team-filter"
        aria-label="Team filter"
        listFixedLocation="below">
      </ninety-quick-filter>
    </div>
    <div class="action-bar-right">
      <ninety-button
        primary
        stroked
        (click)="facade.printMeetingAgendas()"
        [disabled]="vm.loading"
        data-cy="print-meeting-agenda"
        attr.aria-label="Print {{ vm.language.meeting.item }} Agenda"
        listFixedLocation="below">
        <terra-icon icon="file-print" aria-hidden="true" /> Print {{ vm.language.meeting.item }} Agenda
      </ninety-button>
    </div>
  </ninety-action-bar>

  <div class="card-wrp">
    <ninety-active-meeting></ninety-active-meeting>
  </div>

  <ninety-meeting-schedules></ninety-meeting-schedules>
</div>
