<ng-container *ngIf="enableReferralProgram$ | async; else notEnabled">
  <div *ngIf="loading$ | async; else loaded">
    <div class="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <ng-template #loaded>
    <impact-embed widget="p/24474/w/referrerWidget"><div>Loading...</div></impact-embed>
  </ng-template>
</ng-container>
<ng-template #notEnabled>
  <div class="text-center">
    <h2>Referral Program is not enabled</h2>
  </div>
</ng-template>
