import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { TodoRecord, TodoRecordsData } from '@ninety/data-import/models/records';
import { DataImportService } from '@ninety/data-import/services/data-import.service';

@Injectable()
export class TodoImportEffects {
  constructor(private actions$: Actions, private store: Store, private dataImportService: DataImportService) {}

  handleTodosFromImport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleDataFromOneSchema),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.todos),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTeamId)),
      map(([{ data }, teamId]: [TodoRecordsData, string]) => {
        const todos = TodoRecord.toTodos(data.records, teamId);
        return DataImportActions.createTodos({ todos });
      })
    )
  );

  createTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createTodos),
      switchMap(({ todos }) =>
        this.dataImportService.createTodos(todos).pipe(
          map(() => DataImportActions.success({ key: 'todo' })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );
}
