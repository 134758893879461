/* eslint @ngrx/prefer-effect-callback-in-block-statement: off */
/* Conflicting Rules between eslint and ngrx-lint */
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { of, switchMap, tap } from 'rxjs';

import { selectCompany } from '../..';
import { LaunchDarklyService } from '../../../_core/services/launch-darkly.service';
import { StripeSubscriptionStatuses } from '../../../_shared/models/billingv2/stripe-subscription-statuses.enum';
import { selectCurrentPerson } from '../../app-global/current-person/current-person.selectors';
import { selectCurrentUser } from '../users/users-state.selectors';

import { FeatureFlagActions } from './feature-flag-state.actions';
import { FeatureFlagFacade } from './feature-flag-state.facade';
import { FeatureFlagKeys } from './feature-flag-state.model';

const dispatchFalse = {
  dispatch: false,
};
@Injectable()
export class FeatureFlagStateEffects {
  initLaunchDarkly$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagActions.initLaunchDarkly),
        tap(() => this.launchDarklyService.init({ kind: 'user', name: 'anonymous', key: 'anonymous', anonymous: true }))
      ),
    dispatchFalse
  );

  closeLaunchDarklyConnection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagActions.closeLaunchDarklyConnection),
        tap(() => this.launchDarklyService.closeConnection())
      ),
    dispatchFalse
  );

  /**
   * See packages/_shared/feature-flags/app-domain-objects-to-ld-user.logic.ts for BE user registration.
   *
   * Updates to the properties used to identify a user here must also take place on the BE.
   */
  updateLaunchDarklyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagActions.updateLaunchDarklyUser),
        concatLatestFrom(() => [
          // ToDo: Combine into a single selector when all 3 have been converted to ngrx
          this.store.select(selectCurrentUser),
          this.store.select(selectCurrentPerson),
          this.store.select(selectCompany),
        ]),
        tap(([_, currentUser, person, company]) => {
          const ldUser: LDSingleKindContext = {
            kind: 'user',
            key: currentUser._id,
            anonymous: false,
            email: person?.primaryEmail?.toLowerCase() ?? '',
            userRoleCode: currentUser.roleCode,
            userHasCreatedCompany: person.hasCreatedCompany ?? false,
            userIsImplementer: person.isImplementer ?? false,
            userPartnerType: person.partnerType,

            companyId: company._id,
            companyName: company.name,
            companyImplementerCode: company.implementerCode ?? '',
            companyImplementerFree: company.implementerFree,
            companyAccountStatus: company.accountStatus,
            companyBos: company.bos ?? '',
            companyCreatedByPersonId: company.createdByPersonId,
            companyIndustry: company.industry ?? '',
            companyEmployeeRange: company.employeeRange ?? '',
            //only using this since we just need to see if the company was coached (even though this field is depricated)
            companyPartnerKey: company.partnerKey ?? '',

            companyTrialing: company?.subscription?.providerStatus === StripeSubscriptionStatuses.Trialing ?? false,

            personId: person._id,
            guideEnabled: currentUser.guideEnabled ?? false,
          };
          this.launchDarklyService.updateContext(ldUser);
        })
      ),
    dispatchFalse
  );

  processFeatureFlagUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagActions.processFeatureFlagUpdates),
      concatLatestFrom(() => this.facade.featureFlags$),
      switchMap(([newFlags, currentFlags]) => {
        if (currentFlags[FeatureFlagKeys.appRefresh] !== newFlags.flags[FeatureFlagKeys.appRefresh]) {
          return of(FeatureFlagActions.refreshApp());
        }
        return of(FeatureFlagActions.updatedFlagsProcessed({ flags: newFlags.flags }));
      })
    )
  );

  refreshApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FeatureFlagActions.refreshApp),
        tap(() => this.reloadPage())
      ),
    dispatchFalse
  );

  constructor(
    private actions$: Actions,
    private facade: FeatureFlagFacade,
    private launchDarklyService: LaunchDarklyService,
    private store: Store
  ) {}

  reloadPage(): void {
    location.reload();
  }
}
