import { Attachment } from '../_shared/attachment';
import { Comment } from '../_shared/comment';
import { FromLinkedItem } from '../linked-items/linked-item-type-enum';

import { Rock } from './rock';

export class Milestone {
  _id?: string;
  companyId?: string;
  completedDate?: Date;
  description?: string;
  dueDate?: string | Date;
  isDeleted?: boolean;
  isDone?: boolean;
  ownedByUserId?: string;
  rockId?: string;
  title?: string;
  toDoId?: string;
  userId?: string; // whoever decided to use ownedByUserId when we already use userId everywhere else in an asshole...
  userOrdinal?: number;
  rock?: Rock;
  teamId?: string;
  createdFrom?: FromLinkedItem;
  attachments?: Attachment[];
  comments?: Comment[];

  constructor(rock: Rock) {
    const today = new Date();
    this.dueDate = new Date(today.setDate(today.getDate() + 30));
    this.ownedByUserId = rock.userId;
    this.rockId = rock._id;
  }
}
