import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';

import { RecaptchaResponse } from '../../_shared/models/_shared/recaptcha-response';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor(private http: HttpClient, private recaptchaV3Service: ReCaptchaV3Service) {}
  private recaptchaUrl = '/api/v4/verifyRecaptcha';

  verifyRecaptchaToken(token: string): Observable<RecaptchaResponse> {
    return this.http.post<RecaptchaResponse>(this.recaptchaUrl, token);
  }

  executeAction(action: string): Observable<string> {
    return this.recaptchaV3Service.execute(action);
  }
}
