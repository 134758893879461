import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isThisYear } from 'date-fns';

@Pipe({
  name: 'upcomingDate',
  standalone: true,
})
// returns dd mmm or dd mmm yyyy for next year or past year
export class UpcomingDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(date: Date | string): string {
    if (!date) return;

    if (isThisYear(new Date(date))) {
      return this.datePipe.transform(date, 'MMM d');
    } else {
      return this.datePipe.transform(date, 'MMM d, y');
    }
  }
}
