import { MazFeedback } from '@ninety/getting-started/maz/models/maz-feedback';
import { MazMessageSender } from '@ninety/getting-started/maz/models/maz-message-sender.enum';

export class MazMessage {
  _id: string;
  sender: MazMessageSender;
  text: string;
  timestamp: string;
  /** only relevant for MazMessageSender.assistant */
  feedback: MazFeedback;
  //model: "gpt-4o"

  constructor(text: string, sender = MazMessageSender.user, _id?: string) {
    this.text = text;
    this.sender = sender;
    this.timestamp = new Date().toISOString();
    this._id = _id;
  }
}

export type ConversationHistory = { conversation_history: MazMessage[] };
