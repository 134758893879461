import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { OptionListDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/option-list.directive';
import { SelectChipTemplateDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/directives/select-chip-template.directive';
import {
  FUSE_PROVIDER_INJECTION_TOKEN,
  FuseSearchProvider,
} from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/services/fuse-provider';

/**
 * Directive which encapsulates the necessary logic to interact with the {@link SelectBoxComponent}. See
 * `Pods/Reporting/Recipes/Inputs/Select/Implementations/Guide` in Storybook for more information.
 */
@Directive({
  selector: '[ninetySelectImplementation]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectImplementationDirective),
      multi: true,
    },
  ],
})
export class SelectImplementationDirective<T> implements ControlValueAccessor, OnInit {
  /** Whether a client can select more than one user */
  @Input({ transform: coerceBooleanProperty }) multiple: BooleanInput;

  /** When true, the select box is disabled, but without any shading */
  @Input({ transform: coerceBooleanProperty }) readonly: BooleanInput; // TODO as directive

  /** When true, the select box is disabled and has shading to convey this. */
  @Input({ transform: coerceBooleanProperty }) disabled: BooleanInput; // TODO as directive

  /**
   * Id applied to the inner select box. Used to construction option ids as well. Querying for #id and clicking the
   * element will launch the select. Ids on both inner select and option are also applied to the [data-cy] attribute.
   *
   * REQUIRED. (after ng17, use `input().required()` instead)
   */
  @Input() id: string;

  /** Placeholder rendered in search input as well as the empty select box */
  @Input() placeholder = 'Select...';

  /** Text displayed when no search results are found */
  @Input() noResultsText = 'No search results found. Please check your spelling or try a different search.';

  /** Template for rendering chips. Passed to select box, which has a default if this is not provided */
  @Input() chipTemplate: SelectChipTemplateDirective;

  /** The items that are currently selected */
  @Input() set selected(selected: T[]) {
    this.respondToExternalValueChange(selected);
  }

  get selected(): T[] {
    return this._selected;
  }

  @Output() selectedChange = new EventEmitter<T[]>();

  /**
   * Reference to the option list directive. Expected to be set by the component which declares the option list. See
   * {@link ChipSelectKitchenSinkComponent} for an example.
   */
  set optionList(value: OptionListDirective<T>) {
    this._optionList = value;
    this._optionList.value = this._selected;
  }

  private _selected: T[] = [];
  private _optionList: OptionListDirective<T>;

  constructor(
    @Inject(FUSE_PROVIDER_INJECTION_TOKEN) protected fuseProvider: FuseSearchProvider<T>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.id) {
      throw new Error('Usages of ninetySelectImplementation must provide an ID to forward to child templates');
    }
  }

  /* Public API */

  /** Ensures form is marked as touched when the inner select is touched */
  onInnerSelectTouch() {
    if (this.onTouched) this.onTouched();
  }

  /** Updates outside world when the selected user ids change */
  updateObserversOnValueChange(selected: T[], opts?: { skipEmitToOutput?: boolean; skipEmitToParentForm?: boolean }) {
    if (this.onTouched) this.onTouched();
    this._selected = selected;

    if (!opts?.skipEmitToParentForm && this.onChange) this.onChange(this._selected);
    if (!opts?.skipEmitToOutput) this.selectedChange.emit(this._selected);
  }

  /** Updates the component when the selected user ids change from the outside */
  respondToExternalValueChange(selected: T[]) {
    this._selected = selected;

    /*
      The first time the value is set (such as by a form during initialization), this directive is initialized before
      the option list. In those cases, the option list will be forwarded the current value of selected when it is set.
    */
    if (this._optionList) this._optionList.value = selected;
  }

  /* ControlValueAccessor */

  onChange: (change: T[]) => void;
  onTouched: () => void;

  writeValue(obj: T[]): void {
    this.respondToExternalValueChange(obj);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (change: T[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
