import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { ungzip } from 'pako';
import { map, Observable } from 'rxjs';

import type { Team, TeamSettings } from '../../_shared/models/_shared/team';
import { MeetingAgenda } from '../../_shared/models/meetings/meeting-agenda';
import { TeamListModel } from '../../_state/app-entities/team-list/api/team-list.model';

export class CreateTeamDto {
  addCreatorToTeam?: boolean;
  name?: string;
  project: boolean;
  deleted: boolean;
  private: boolean;
  archived: boolean;

  constructor(args: Partial<CreateTeamDto> = { project: false, deleted: false, private: false }) {
    Object.assign(this, args);
  }
}

export const TEAMS_API_URL = '/api/v4/Teams';
export const TEAMS_LITE_API_URL = '/api/v4/TeamsLite';

@Injectable({
  providedIn: 'root',
})
export class TeamsApiService {
  constructor(private http: HttpClient) {}

  create(dto: CreateTeamDto): Observable<string> {
    return this.http.post<string>(TEAMS_API_URL, dto);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${TEAMS_API_URL}/${id}`);
  }

  archive(id: string): Observable<void> {
    return this.http.delete<void>(`${TEAMS_API_URL}/${id}/archive`);
  }

  reactivate(id: string): Observable<void> {
    return this.http.get<void>(`${TEAMS_API_URL}/${id}/reactivate`);
  }

  update(id: string, update: Partial<Team>): Observable<void> {
    return this.http.patch<void>(`${TEAMS_API_URL}/${id}`, update);
  }

  updateSettings(teamId: string, update: Partial<TeamSettings>): Observable<void> {
    return this.http.patch<void>(`${TEAMS_API_URL}/${teamId}/Settings`, update);
  }

  // getTeams(): Observable<Team[]> {
  //   return this.http
  //     .get<{ teamsBuffer: { data: Buffer } }>(`${TEAMS_API_URL}`)
  //     .pipe(map(({ teamsBuffer: { data } }) => JSON.parse(ungzip(new Uint8Array(data), { to: 'string' })) as Team[]));
  // }

  getTeamsLite(): Observable<TeamListModel[]> {
    return this.http.get<TeamListModel[]>(`${TEAMS_LITE_API_URL}`);
  }

  getArchivedTeamsLite(): Observable<TeamListModel[]> {
    return this.http.get<TeamListModel[]>(`${TEAMS_LITE_API_URL}/archived`);
  }

  getTeamById(id: string): Observable<Team> {
    return this.http.get<Team>(`${TEAMS_API_URL}/${id}`);
  }

  /**
   * Used for data upload
   */
  getTeamsByCompanyId(companyId: string): Observable<Team[]> {
    return this.http.get<Team[]>(`/api/v4/Companies/${companyId}/Teams`);
  }

  pushAgendaByType(teamId: string, agendaType: string): Observable<void> {
    return this.http.post<void>(`${TEAMS_API_URL}/${teamId}/pushAgenda/${agendaType}`, {});
  }

  pushCustomAgenda(teamId: string, customAgendaId: string): Observable<void> {
    return this.http.post<void>(`${TEAMS_API_URL}/${teamId}/pushCustomAgenda/${customAgendaId}`, {});
  }

  deleteCustomAgenda(teamId: string, agendaId: string): Observable<MeetingAgenda[]> {
    return this.http.delete<MeetingAgenda[]>(`${TEAMS_API_URL}/${teamId}/DeleteCustomAgenda/${agendaId}`);
  }
}
