import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';

import { UniversalCreateActions } from '@ninety/layouts/_state/universal-create/universal-create.actions';
import { selectUserDialogModePreference } from '@ninety/layouts/_state/universal-create/universal-create.selectors';
import { CreateDialogService } from '@ninety/layouts/services/create-dialog.service';

@Injectable()
export class UniversalCreateEffects {
  constructor(private actions$: Actions, private store: Store, private createDialogService: CreateDialogService) {}

  openUniversalCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UniversalCreateActions.open),
        concatLatestFrom(() => this.store.select(selectUserDialogModePreference)),
        switchMap(([{ data, mode }, userMode]) => this.createDialogService.open(data, mode || userMode))
      ),
    { dispatch: false }
  );
}
