import { InjectionToken } from '@angular/core';
import { IFuseOptions } from 'fuse.js';
import { Observable } from 'rxjs';

import { FuseSearchService } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/services/fuse-search.service';

/**
 * Interface defining the base data concerns necessary for the select component. See
 * `Pods/Reporting/Recipes/Inputs/Select/Implementations/Guide` in Storybook for more information.
 */
export interface FuseSearchProvider<T> {
  fuseOptions: IFuseOptions<T>;
  fuse: FuseSearchService<T>;
  data$: Observable<T[]>;
}

/** Injection token for the {@link FuseSearchProvider} */
export const FUSE_PROVIDER_INJECTION_TOKEN = new InjectionToken<FuseSearchProvider<never>>(
  'FUSE_PROVIDER_INJECTION_TOKEN'
);
