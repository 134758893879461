import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';

import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';

@Pipe({
  name: 'selectTeamById',
  standalone: true,
})
export class SelectTeamByIdPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(id: string): Observable<Team> {
    return this.store.select(TeamSelectors.selectById(id)).pipe(filter(team => !!team));
  }
}
