import { createSelector } from '@ngrx/store';

import { CurrentUserSelectors } from '@ninety/ui/legacy/state/app-entities/user-list/user-list-state.selectors';
import {
  selectCompany,
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsManagerOrAbove,
  TeamSelectors,
} from '@ninety/ui/legacy/state/index';

import { TeamsComponentListModel } from './teams-component.model';

export const selectTeamsListForTeamsComponent = createSelector(
  TeamSelectors.selectAll,
  CurrentUserSelectors.selectUser,
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsManagerOrAbove,
  selectCompany,
  (teams, user, adminOrOwner, managerOrAbove, company) => {
    return teams
      .map(
        team =>
          ({
            ...team,
            loggedInUserBelongsToTeam: user.teams.some(t => t.teamId === team._id),
            canEdit:
              adminOrOwner ||
              (managerOrAbove &&
                (user.teams.some(t => t.teamId === team._id) || team.removedUsers?.some(uid => uid === user._id))),
            isSlt: company.seniorLeadershipTeamId === team._id,
          } as TeamsComponentListModel)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }
);
