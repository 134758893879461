import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraIconModule } from '../../terra-icon';

import { TerraInputPrefixComponent, TerraInputSuffixComponent } from './../terra-input-base/terra-input-base.slots';
import { TerraTextInputComponent } from './terra-text-input.component';

@NgModule({
  imports: [
    CommonModule,
    TerraTextInputComponent,
    TerraIconModule,
    TerraInputPrefixComponent,
    TerraInputSuffixComponent,
  ],
  exports: [TerraTextInputComponent, TerraIconModule, TerraInputPrefixComponent, TerraInputSuffixComponent],
})
export class TerraTextInputModule {}
