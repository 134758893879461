import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@ngrx/entity';

import { TeamListModel } from '../../_state/app-entities/team-list/api/team-list.model';

@Pipe({
  name: 'getTeamNameById',
})
export class GetTeamNameByIdPipe implements PipeTransform {
  transform(teamId: string, teams: Dictionary<TeamListModel>): string {
    if (teams[teamId] === undefined) {
      console.log(`Team ${teamId} not found in GetTeamNameByIdPipe`);
      return '';
    }

    return teams[teamId].name;
  }
}
