import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, catchError, map, of, switchMap, tap } from 'rxjs';

import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { SpinnerService } from '@ninety/ui/legacy/core/index';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { OrdinalOrUserOrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { MilestoneStatus } from '@ninety/ui/legacy/shared/models/milestone/milestone-status.enum';
import { MilestoneSortField } from '@ninety/ui/legacy/shared/models/my-focus/milestone-sort-field';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { RockMessageType } from '@ninety/ui/legacy/shared/models/rocks/rock-message-type';
import { TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

import { RockService } from './rock.service';

@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  private milestonesApi = '/api/v4/Milestones';

  pagedMilestones$ = new BehaviorSubject<PagedResponse<Milestone>>(null);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private stateService: StateService,
    private rockService: RockService,
    private filterService: FilterService,
    private detailService: DetailService<Milestone>,
    private store: Store,
    private spinnerService: SpinnerService
  ) {
    this.detailService.rockMilestoneUpdate$
      .pipe(switchMap((changes: Partial<Milestone>) => this.update(changes._id, changes, changes.rockId)))
      .subscribe();

    this.detailService.milestoneUpdate$.subscribe({
      next: (changes: Partial<Milestone>) => {
        this.updateLocal(changes);
      },
    });
  }

  getMilestoneById(milestoneId: string, skipLocal = true): Observable<Milestone> {
    if (skipLocal) {
      return this.http
        .get<Milestone>(`${this.milestonesApi}/${milestoneId}`)
        .pipe(
          catchError((e: unknown) =>
            this.errorService.notify(
              e,
              `Could not get ${this.stateService.language.milestone.item}.  Please try again.`
            )
          )
        );
    }

    return this.pagedMilestones$.pipe(
      map(resp => resp?.items?.find(r => r._id === milestoneId)),
      switchMap(milestone => {
        if (!!milestone) return of(milestone);

        return this.getMilestoneById(milestoneId);
      })
    );
  }

  createMilestone(milestone: Milestone): Observable<Milestone> {
    // TODO: Move to server
    milestone.companyId = this.stateService.company._id;

    return this.http.post<Milestone>(this.milestonesApi, milestone).pipe(
      tap((milestone: Milestone) => {
        if (this.rockService.shouldBroadcast) {
          this.rockService.broadcastMessage({ messageType: 'new-milestone', document: milestone }).subscribe();
        }
        this.spinnerService.stop();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not create ${this.stateService.language.milestone.item}. Please try again.`)
      )
    );
  }

  update(milestoneId: string, update: Partial<Milestone>, rockIdForMeetingSync?: string): Observable<void> {
    return this.http.patch<void>(`${this.milestonesApi}/${milestoneId}`, update).pipe(
      tap(() => {
        this.detailService.milestoneUpdate$.next({ _id: milestoneId, ...update });
        if (this.rockService.shouldBroadcast && rockIdForMeetingSync) {
          // somehow, when in a meet and editing the milestone's title inline, we get a
          // ERROR TypeError: Converting circular structure to JSON
          // I could not find how the rock is put back on the milestone, so deleting here as well
          delete update.rock;

          this.rockService
            .broadcastMessage({
              messageType: RockMessageType.milestone,
              document: { _id: milestoneId, rockId: rockIdForMeetingSync, ...update },
            })
            .subscribe();
        }
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not update ${this.stateService.language.milestone.item}.Please try again.`)
      )
    );
  }

  updateOrdinals(milestones: Milestone[], prop: 'userOrdinal' | 'ordinal' = 'userOrdinal'): void {
    const models: OrdinalOrUserOrdinalUpdate[] = milestones.map(
      (m: Milestone, i: number) => new OrdinalOrUserOrdinalUpdate(m._id, i, prop)
    );
    this.http
      .put<any>(`${this.milestonesApi}/UserOrdinals`, { models })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Could not update order.  Please try again.`)))
      .subscribe();
  }

  getMyFocusMilestones(
    page: number,
    pageSize: number,
    sortField: MilestoneSortField,
    sortDirection: SortDirection,
    statusCode: MilestoneStatus = this.stateService.currentUser.settings.myMilestonesStatus,
    searchText = this.filterService.searchText$?.value ?? '',
    teamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId)
  ): Observable<PagedResponse<Milestone>> {
    const params = QueryParamsService.build(
      {
        teamId,
        page,
        pageSize,
        sortField,
        sortDirection,
        statusCode,
        searchText,
      },
      true
    );

    return this.http.get<PagedResponse<Milestone>>(`/api/v4/MyFocus/Milestones`, { params }).pipe(
      tap(response => this.pagedMilestones$.next(response)),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not load ${this.stateService.language.milestone.items} for ${this.stateService.language.my90.route} page.
            Please try refreshing the page.`
        )
      )
    );
  }

  private updateLocal(update: Partial<Milestone>): void {
    const milestone = this.pagedMilestones$.value?.items.find(i => i._id === update._id);
    if (milestone) {
      Object.assign(milestone, update);
      this.pagedMilestones$.next({
        items: this.pagedMilestones$.value?.items,
        totalCount: this.pagedMilestones$.value?.items.length,
      });
    }
  }
}
