<ninety-select-box
  (click)="select.onInnerSelectTouch()"
  [chipTemplate]="chipTemplate"
  [placeholder]="select.placeholder"
  [readonly]="select.readonly"
  [attr.id]="select.id"
  [attr.data-cy]="select.id">
  <ninety-input-wrapper [variant]="null" ngProjectAs="input">
    <input
      ninetyAutoComplete
      [ninetyNotifyIntersect]="true"
      (isIntersecting)="input.focus()"
      #input
      #autoComplete="ninetyAutoComplete"
      id="{{ select.id + '-input' }}"
      [variant]="null"
      [placeholder]="select.placeholder" />
    <ninety-button
      *ngIf="fuseProvider.fuse.hasSearchTerm$ | ngrxPush"
      flat
      icon
      ninetyInputSuffix
      [attr.id]="select.id + '-clear-btn'"
      (click)="search(null); autoComplete.clear()">
      <terra-icon icon="x" size="20" />
    </ninety-button>
  </ninety-input-wrapper>
  <div
    ninetyOptionList
    ngProjectAs="list"
    [ngModel]="select.selected"
    (ngModelChange)="select.updateObserversOnValueChange($event)"
    [multiple]="select.multiple"
    [disabled]="select.disabled">
    <div
      ninetyOption
      #item="ninetyOption"
      *ngFor="let option of fuseProvider.fuse.managedOptions$ | ngrxPush; trackBy: trackByFn"
      [value]="option.value._id"
      [style.display]="option.visible ? '' : 'none'"
      [id]="select.id + '-option-' + option.value._id"
      [attr.id]="select.id + '-option-' + option.value._id"
      [attr.data-cy]="select.id + '-option-' + option.value._id">
      <ninety-fake-checkbox *ngIf="select.multiple" [isSelected]="item.isSelected" />
      <ng-container *ngTemplateOutlet="template; context: { $implicit: option }"></ng-container>
    </div>
    <div *ngIf="fuseProvider.fuse.hasNoResults$ | ngrxPush" class="empty-state">
      {{ select.noResultsText }}
    </div>
  </div>
</ninety-select-box>
