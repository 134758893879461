<div
  class="view"
  ninetyInputVariant="outline"
  [attr.tabindex]="readonly ? 0 : -1"
  [class.disabled]="optionList.disabled"
  [class.ninety-input--error]="hasError?.ninetyHasError"
  [ninetySelectLauncher]="menuTemplate"
  [disabled]="readonly || optionList.disabled">
  <div class="chip-list" [ngSwitch]="viewToRender">
    <ng-container *ngSwitchCase="'multi'">
      <div class="chip-item" *ngFor="let option of optionList.value; let index = index">
        <ninety-chip [color]="optionList.disabled ? 'neutral' : 'brand'">
          <ng-container
            *ngTemplateOutlet="chipTemplate ? chipTemplate.ref : defaultChipTemplate; context: { value: option }" />
        </ninety-chip>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'single'">
      <ng-container
        *ngTemplateOutlet="
          chipTemplate ? chipTemplate.ref : defaultChipTemplate;
          context: { value: optionList.value[0] }
        " />
    </ng-container>

    <ng-container *ngSwitchCase="'placeholder'">
      <span class="placeholder">{{ placeholder }}</span>
    </ng-container>
  </div>

  <div class="button-container" *ngIf="!readonly">
    <ninety-select-clear-button [optionList]="optionList" />
    <ninety-select-toggle-button [optionList]="optionList" [isOpen]="false" />
  </div>

  <ng-template #menuTemplate>
    <ninety-option-overlay [optionListInput]="optionList" [chipTemplate]="chipTemplate">
      <ng-content select="input" ngProjectAs="input"></ng-content>
      <ng-content select="list" ngProjectAs="list"></ng-content>
    </ninety-option-overlay>
  </ng-template>
</div>

<ng-template #defaultChipTemplate ninetySelectChipTemplate let-value="value"> {{ value }}</ng-template>
