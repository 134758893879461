import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, partition as _partition } from 'lodash';
import { catchError, concatMap, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';

import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { NewItemService } from '@ninety/_layouts/services/new-item.service';
import { DetailService } from '@ninety/detail-view/_services/detail.service';
import {
  DetailServiceMilestoneActions,
  DetailServiceRockActions,
  DetailViewActions,
} from '@ninety/detail-view/_state/detail-view.actions';
import { My90Service } from '@ninety/my-ninety/services/my-90.service';
import { MilestoneCreateDialogComponent } from '@ninety/rocks/_shared/milestone-create-dialog/milestone-create-dialog.component';
import { MilestoneService } from '@ninety/rocks/_shared/milestone.service';
import { RockService } from '@ninety/rocks/_shared/rock.service';
import { SpinnerService } from '@ninety/ui/legacy/core/index';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { ArchivedRockResponse } from '@ninety/ui/legacy/shared/index';
import { DetailType } from '@ninety/ui/legacy/shared/models/_shared/detail-type.enum';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import { OrdinalOrUserOrdinalUpdate } from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { FromLinkedItem, LinkedItemTypeEnum } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { MyNinetyWidgetTypeKeys } from '@ninety/ui/legacy/shared/models/my-90/my-90-widget-type-keys';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';
import * as FeatureFlagSelectors from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { UserListSelectors, FeatureFlagKeys, TeamSelectors } from '@ninety/ui/legacy/state/index';

import { RocksStateActions } from './rocks-v2-state.actions';
import * as rockSelectors from './rocks-v2-state.selectors';

@Injectable()
export class RocksStateEffects {
  constructor(
    private actions$: Actions,
    private rockService: RockService,
    private milestoneService: MilestoneService,
    private detailService: DetailService<Rock>,
    private store: Store,
    private createDialogService: CreateDialogService,
    private notifyService: NotifyService,
    private newItemService: NewItemService,
    private my90Service: My90Service,
    private dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {}

  onPageInitEnableDetailActionStreaming$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.pageInit),
      map(() => DetailViewActions.enableStreaming())
    )
  );

  onPageDestroyDisableDetailActionStreaming$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.pageDestroy),
      map(() => DetailViewActions.disableStreaming())
    )
  );

  openCreateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.openCreateDialog),
        switchMap(() => this.createDialogService.open({ itemType: ItemType.rock }))
      ),
    { dispatch: false }
  );

  openMilestoneCreateDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.openMilestoneCreateDialog),
      exhaustMap(({ rock }) =>
        this.dialog
          .open(MilestoneCreateDialogComponent, {
            data: { rock },
          })
          .afterClosed()
      ),
      filter(milestone => !!milestone),
      map(milestone => {
        return RocksStateActions.addMilestoneToStore({ milestone });
      })
    )
  );

  getArchivedRocks = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.getArchivedRocks),
      switchMap(({ pageIndex, pageSize, sortField, direction }) =>
        this.rockService.getArchivedRocks(pageIndex, pageSize, sortField, direction).pipe(
          tap(() => this.spinnerService.stop()),
          map((resp: ArchivedRockResponse) =>
            RocksStateActions.getArchivedRocksSuccess({ rocks: resp.data, totalCount: resp.totalCount })
          ),
          catchError((error: unknown) => of(RocksStateActions.getArchivedRocksFailed({ error })))
        )
      )
    )
  );

  getRocksAndMilestones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RocksStateActions.filterByStatus,
        RocksStateActions.filterByUser,
        RocksStateActions.filterByTeam,
        RocksStateActions.paginationChange,
        RocksStateActions.sortChange
      ),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectGetRockQueryParams),
        this.store.select(rockSelectors.selectRocksV3),
      ]),
      filter(([, , rocksV3]) => !rocksV3),
      switchMap(([, params]) => {
        return this.rockService.getRocksAndMilestonesV2(params).pipe(
          map(rocksAndMilestones => RocksStateActions.getRocksAndMilestonesSuccess({ rocksAndMilestones })),
          catchError((error: unknown) => of(RocksStateActions.getRocksAndMilestonesFailed({ error })))
        );
      })
    )
  );

  getRocksAndMilestonesV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RocksStateActions.filterByStatus,
        RocksStateActions.filterByUser,
        RocksStateActions.filterByTeam,
        RocksStateActions.paginationChange,
        RocksStateActions.sortChange
      ),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectGetRockQueryParams),
        this.store.select(UserListSelectors.selectAllNoObservers),
        this.store.select(TeamSelectors.selectAll),
        this.store.select(rockSelectors.selectRocksV3),
      ]),
      filter(([, , , , rocksV3]) => rocksV3),
      switchMap(([action, params, users, teams]) => {
        if (action.type === RocksStateActions.filterByTeam.type) {
          const teamId = action.teamId;
          const team = teams.find(t => t._id === teamId);
          const [usersOnTeam, _] = _partition(users, user => !!user.teamIds.find(id => id === teamId));
          return this.rockService.getRocksByTeamIdV2(params).pipe(
            map(items => {
              this.spinnerService.stop();
              return RocksStateActions.getRocksByTeamSuccess({ rocks: items[params.teamId], users: usersOnTeam, team });
            }),
            catchError((error: unknown) => of(RocksStateActions.getRocksByTeamFailed({ error })))
          );
        } else {
          return of(RocksStateActions.getRocksAndMilestonesFailed({ error: 'RocksV3 not supported' }));
        }
      })
    )
  );

  openRockInDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.openRockInDetailView),
      map(action =>
        DetailViewActions.opened({
          config: { pathParts: [DetailType.rockStore, action.rockId] },
          source: 'Rock Selected',
        })
      )
    )
  );

  openRockV2InDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.openRockV2InDetailView),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => !rocksV3),
      map(([action]) => {
        return DetailViewActions.opened({
          config: { pathParts: [DetailType.rockStoreV2, action.rockId] },
          source: 'Rock Selected',
        });
      })
    )
  );

  openRockV3InDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.openRockV2InDetailView),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => rocksV3),
      map(([action]) => {
        return DetailViewActions.opened({
          config: { pathParts: [DetailType.rockV3, action.rockId] },
          source: 'Rock Selected',
        });
      })
    )
  );

  updateRockFromDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceRockActions.updated),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectSelectedRockId)]),
      map(([action, selectedRockId]) =>
        RocksStateActions.updateRock({ update: { id: selectedRockId, changes: action.update } })
      )
    )
  );

  deleteRockCommentFromDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceRockActions.deleteComment),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocks)]),
      map(([{ event }, rocks]) => {
        let comments = [];
        const rock = rocks.find(r => r._id === event._id);
        if (rock) {
          comments = rock.comments?.filter(
            c =>
              c.userId !== event.comment.userId ||
              new Date(c.createdDate).getTime() !== new Date(event.comment.createdDate).getTime()
          );
          return RocksStateActions.updateRock({ update: { id: event._id, changes: { comments } } });
        } else {
          return;
        }
      })
    )
  );

  //TODO NEXT: redo milestone list in rock detail view and load directly from the store
  reloadRockMilestonesOnAdd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.addMilestoneToStore),
        concatLatestFrom(({ milestone }) => [
          this.store.select(rockSelectors.selectMilestonesForRock(milestone.rockId)),
          this.store.select(rockSelectors.selectRocksV3),
        ]),
        filter(([, , rocksV3]) => !rocksV3),
        tap(([_, milestones]) => this.detailService.updateInputsItem({ milestones }))
      ),
    { dispatch: false }
  );

  updateMilestoneFromDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceMilestoneActions.updated),
      map(action => {
        //NOTE: updating a milestone always sends the id, we need to remove it first
        const { _id: id, ...changes } = action.update;
        if (!id) {
          return RocksStateActions.updateMilestoneInStoreFailed({ error: 'Incorrect milestone id' });
        }
        return RocksStateActions.updateMilestoneInStore({ update: { id, changes } });
      })
    )
  );

  deleteMilestoneFromRockDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceMilestoneActions.deleted),
      map(({ milestoneId }) => RocksStateActions.deleteMilestoneFromStore({ milestoneId }))
    )
  );

  addMilestoneFromDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceMilestoneActions.added),
      map(({ milestone }) => RocksStateActions.addMilestoneToStore({ milestone }))
    )
  );

  updateRock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RocksStateActions.updateRock,
        RocksStateActions.updateRockAndDeleteCompanyRock,
        RocksStateActions.updateRockAndAddCompanyRock
      ),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectSelectedRockId),
        this.store.select(rockSelectors.selectRocksV3),
      ]),
      filter(([, , rocksV3]) => !rocksV3),
      concatMap(([{ update }, selectedRockId]) => {
        //TODO NEXT: should we close detail view if the selected team doesn't match
        //should we always do it?
        if (selectedRockId === update.id) {
          //update detail view if card is open
          this.detailService.updateInputsItem(update.changes);
        }
        return this.rockService.update(update.id as string, update.changes).pipe(
          map(_ => RocksStateActions.updateRockSuccess()),
          catchError((error: unknown) => of(RocksStateActions.updateRockFailed({ error })))
        );
      })
    )
  );

  updateRockV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        RocksStateActions.updateRock,
        RocksStateActions.updateRockAndDeleteCompanyRock,
        RocksStateActions.updateRockAndAddCompanyRock
      ),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => rocksV3),
      concatMap(([{ update }]) => {
        if (update.changes.teamId) {
          this.detailService.close().subscribe();
        }
        return this.rockService.updateV2(update.id as string, update.changes).pipe(
          map(_ => RocksStateActions.updateRockSuccess()),
          catchError((error: unknown) => of(RocksStateActions.updateRockFailed({ error })))
        );
      })
    )
  );

  deleteRockFromDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.deleted),
      filter(({ itemType }) => this.isRockAction(itemType)),
      map(({ item }) => RocksStateActions.delete({ rock: _cloneDeep(item) as Rock }))
    )
  );

  deleteRock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.delete),
      concatMap(({ rock }) =>
        this.rockService.delete(rock).pipe(
          map(_ => RocksStateActions.deleteSuccess({ rockId: rock._id })),
          catchError((error: unknown) => of(RocksStateActions.deleteFailed({ error })))
        )
      )
    )
  );

  makeItAnIssue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createIssue),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ rock }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: rock._id,
              type: LinkedItemTypeEnum.rock,
            };
          }
          this.newItemService.openUniversalCreate(rock as Item, ItemType.issue, createdFrom);
        })
      ),
    {
      dispatch: false,
    }
  );

  createATodo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createTodo),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ rock }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: rock._id,
              type: LinkedItemTypeEnum.rock,
            };
          }
          this.newItemService.openUniversalCreate(rock as Item, ItemType.todo, createdFrom);
        })
      ),
    {
      dispatch: false,
    }
  );

  createARock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createRockFromContextMenu),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ rock }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: rock._id,
              type: LinkedItemTypeEnum.rock,
            };
          }
          this.newItemService.openUniversalCreate(rock as Item, ItemType.rock, createdFrom);
        })
      ),
    {
      dispatch: false,
    }
  );

  createAHeadline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createHeadline),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ rock }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: rock._id,
              type: LinkedItemTypeEnum.rock,
            };
          }
          this.newItemService.openUniversalCreate(rock as Item, ItemType.headline, createdFrom);
        })
      ),
    {
      dispatch: false,
    }
  );

  closeDetailOnDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.deleted),
      filter(({ itemType }) => this.isRockAction(itemType)),
      map(() => DetailViewActions.close())
    )
  );

  archiveRockFromDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.toggledArchived),
      filter(({ itemType }) => this.isRockAction(itemType)),
      map(({ item }) => RocksStateActions.toggleArchived({ rock: _cloneDeep(item) as Rock }))
    )
  );

  archiveRock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.toggleArchived),
      concatMap(({ rock }) =>
        this.rockService.toggleArchive(_cloneDeep(rock)).pipe(
          map(_ => RocksStateActions.toggleArchivedSuccess({ rockId: rock._id })),
          catchError((error: unknown) => of(RocksStateActions.toggleArchivedFailed({ error })))
        )
      )
    )
  );

  closeDetailOnArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.toggledArchived),
      filter(({ itemType }) => this.isRockAction(itemType)),
      map(() => DetailViewActions.close())
    )
  );

  updateUserFromDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceRockActions.updatedUser),
      concatLatestFrom(_ => [
        this.store.select(rockSelectors.selectSelectedRock),
        this.store.select(rockSelectors.selectFilterByUserId),
      ]),
      concatLatestFrom(([{ userId }, selectedRock, selectedUserId]) => [
        userId === selectedUserId
          ? of(true) //no need to check milestone users if the updated user is the same as the current user
          : this.store.select(rockSelectors.selectRockHasMilestoneForUser(selectedRock._id, selectedRock.userId)),
      ]),
      switchMap(([[{ userId }, selectedRock], rockHasMilestoneForUser]) =>
        this.rockService.update(selectedRock._id, { userId }).pipe(
          map(_ => ({
            rockId: selectedRock._id,
            userId,
            rockHasMilestoneForUser,
          }))
        )
      ),
      map(({ rockId, userId, rockHasMilestoneForUser }) =>
        RocksStateActions.updateUserSuccess({
          rockId,
          userId,
          removeFromList: !rockHasMilestoneForUser,
        })
      ),
      catchError((error: unknown) => of(RocksStateActions.updateUserFailed({ error })))
    )
  );

  updateUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateUserSuccess),
      concatMap(({ rockId, userId, removeFromList }) => {
        if (removeFromList) {
          return of(RocksStateActions.removeFromList({ rockId, closeDetailView: removeFromList }));
        } else {
          return of(RocksStateActions.updateRock({ update: { id: rockId, changes: { userId } } }));
        }
      })
    )
  );

  closeDetailWhenRemovedFromList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.removeFromList),
      filter(({ closeDetailView }) => closeDetailView),
      map(() => DetailViewActions.close())
    )
  );

  updateStatusCodeFromDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceRockActions.updatedStatusCode, RocksStateActions.updateStatusCode),
      concatMap(({ event }) => {
        const update: Partial<Rock> = {
          completed: event.statusCode === RockStatusCode.complete,
          statusCode: event.statusCode,
          completedDate: event.completedDate,
        };

        return of(RocksStateActions.updateRock({ update: { id: event.rock._id, changes: update } }));
      })
    )
  );

  updateListIfRockStatusIsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailServiceRockActions.updatedStatusCode),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectSelectedRockId),
        this.store.select(rockSelectors.selectFilterByRockStatus),
      ]),
      concatMap(([{ event }, selectedRockId, selectedRockStatus]) => {
        if (!selectedRockStatus) return of();

        if (selectedRockStatus !== event.statusCode) {
          return of(RocksStateActions.removeFromList({ rockId: selectedRockId }));
        } else {
          //This is for cases when the list doesn't contain the rock opened in detail view
          //TODO NEXT: add support for when a team is selected in the filter bar
          return of(RocksStateActions.addToList({ rock: event.rock }));
        }
      })
    )
  );

  updateTeamFromDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.teamChanged),
      filter(({ itemType }) => this.isRockAction(itemType)),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectSelectedRockId)]),
      concatMap(([changes, selectedRockId]) =>
        of(RocksStateActions.updateRock({ update: { id: selectedRockId, ...changes } }))
      )
    )
  );

  clearSelectedRockOnClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.closed),
      filter(({ itemType }) => this.isRockAction(itemType)),
      map(() => RocksStateActions.clearSelectedRock())
    )
  );

  updateUserOrdinals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateUserOrdinals),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectRocks),
        this.store.select(rockSelectors.selectPagination),
        this.store.select(rockSelectors.selectFilterBy),
        this.store.select(rockSelectors.selectSort),
      ]),
      map(([{ previousIndex, currentIndex }, rocks, pagination, filterBy, sort]) => {
        const start = Math.min(previousIndex, currentIndex) + pagination.index * pagination.size;
        const stop = Math.max(previousIndex, currentIndex) + pagination.index * pagination.size;

        const rocksWithNewOrdinals = rocks.filter(t => t.userOrdinal >= start && t.userOrdinal <= stop);

        this.rockService.updateOrdinalsV2(
          rocksWithNewOrdinals.map((r: Rock) => new OrdinalOrUserOrdinalUpdate(r._id, r.userOrdinal, 'userOrdinal')),
          'userOrdinal',
          filterBy.userId,
          sort
        );

        return RocksStateActions.clearSort();
      })
    )
  );

  updatePaginationOnUserSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.updatePaginationOnUserSettings),
        switchMap(({ size }) =>
          this.my90Service.patchMy90SettingByWidgetKey(MyNinetyWidgetTypeKeys.ROCKS_AND_MILESTONES, {
            pageSize: size,
          })
        )
      ),
    { dispatch: false }
  );

  printRock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.print),
        tap(({ rock }) => this.rockService.printToPDF(rock._id))
      ),
    { dispatch: false }
  );

  notify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.notify),
        tap(({ message }) => this.notifyService.notify(message))
      ),
    { dispatch: false }
  );

  clearSelectedMilestoneOnClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailViewActions.closed),
      filter(({ itemType }) => this.isMilestoneAction(itemType)),
      map(() => RocksStateActions.clearSelectedMilestone())
    )
  );

  updateMilestone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateMilestone),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => !rocksV3),
      concatMap(([{ update }]) =>
        this.milestoneService.update(update.id as string, update.changes).pipe(
          map(_ => RocksStateActions.updateMilestoneSuccess()),
          catchError((error: unknown) => of(RocksStateActions.updateMilestoneFailed({ error })))
        )
      )
    )
  );

  updateMilestoneV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateMilestone),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => rocksV3),
      concatMap(([{ update }]) =>
        this.milestoneService.update(update.id as string, update.changes).pipe(
          map(_ => RocksStateActions.updateMilestoneInStore({ update })),
          catchError((error: unknown) => of(RocksStateActions.updateMilestoneFailed({ error })))
        )
      )
    )
  );

  updateMilestoneFromList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateMilestoneFromList),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectSelectedRockId),
        this.store.select(rockSelectors.selectRocksV3),
      ]),
      filter(([, , rocksV3]) => !rocksV3),
      concatMap(([{ rockId, update }, selectedRockId]) => {
        if (selectedRockId === rockId) {
          //update detail view if card is open
          this.detailService.updateInputsItemArray({ _id: update.id.toString(), ...update.changes }, 'milestones');
        }
        return this.milestoneService.update(update.id as string, update.changes).pipe(
          map(_ => RocksStateActions.updateMilestoneInStore({ update })),
          catchError((error: unknown) => of(RocksStateActions.updateMilestoneFailed({ error })))
        );
      })
    )
  );

  updateMilestoneFromListV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateMilestoneFromList),
      concatLatestFrom(() => [
        this.store.select(rockSelectors.selectSelectedRockId),
        this.store.select(rockSelectors.selectRocksV3),
      ]),
      filter(([, , rocksV3]) => rocksV3),
      concatMap(([{ rockId, update }, selectedRockId]) => {
        if (selectedRockId === rockId) {
          //update detail view if card is open
          this.detailService.updateInputsItemArray({ _id: update.id.toString(), ...update.changes }, 'milestones');
        }
        return this.milestoneService.update(update.id as string, update.changes).pipe(
          map(_ =>
            RocksStateActions.updateMilestoneInStore({
              update: { id: update.id as string, changes: { ...update.changes } },
            })
          ),
          catchError((error: unknown) => of(RocksStateActions.updateMilestoneFailed({ error })))
        );
      })
    )
  );

  deleteMilestone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.deleteMilestone),
      concatMap(({ milestoneId }) =>
        this.milestoneService.update(milestoneId, { isDeleted: true }).pipe(
          map(_ => RocksStateActions.deleteMilestoneSuccess()),
          catchError((error: unknown) => of(RocksStateActions.deleteMilestoneFailed({ error })))
        )
      )
    )
  );

  closeMilestoneDetailOnDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.deleteMilestone),
      map(() => DetailViewActions.close())
    )
  );

  openMilestoneCardV2InDetailView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.openMilestoneCardV2InDetailView),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => !rocksV3),
      map(([{ milestoneId }]) => {
        return DetailViewActions.opened({
          config: { pathParts: [DetailType.milestoneStore, milestoneId] },
          source: 'Milestone Selected',
        });
      })
    )
  );

  openMilestoneCardV2InDetailViewV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.openMilestoneCardV2InDetailView),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => rocksV3),
      map(([{ milestoneId }]) => {
        return DetailViewActions.opened({
          config: { pathParts: [DetailType.milestoneV2, milestoneId] },
          source: 'Milestone Selected',
        });
      })
    )
  );

  makeItAnIssueFromMilestone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createIssueFromMilestone),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ milestone }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: milestone._id,
              type: LinkedItemTypeEnum.milestone,
            };
          }
          this.newItemService.openUniversalCreate(
            { ...milestone, userId: milestone.ownedByUserId } as Item, // Universalcreate requires userId to be set
            ItemType.issue,
            createdFrom
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  createATodoFromMilestone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createTodoFromMilestone),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ milestone }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: milestone._id,
              type: LinkedItemTypeEnum.milestone,
            };
          }
          this.newItemService.openUniversalCreate(
            { ...milestone, userId: milestone.ownedByUserId } as Item, // Universalcreate requires userId to be set
            ItemType.todo,
            createdFrom
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  createAHeadlineFromMilestone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createHeadlineFromMilestone),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ milestone }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: milestone._id,
              type: LinkedItemTypeEnum.milestone,
            };
          }
          this.newItemService.openUniversalCreate(
            { ...milestone, userId: milestone.ownedByUserId } as Item, // Universalcreate requires userId to be set
            ItemType.headline,
            createdFrom
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  createARockFromMilestone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RocksStateActions.createRockFromMilestone),
        concatLatestFrom(() => [
          this.store.select(FeatureFlagSelectors.selectFeatureFlag(FeatureFlagKeys.linkedItems)),
        ]),
        tap(([{ milestone }, linkedItemsEnabled]) => {
          let createdFrom: FromLinkedItem;
          if (linkedItemsEnabled) {
            createdFrom = {
              id: milestone._id,
              type: LinkedItemTypeEnum.milestone,
            };
          }
          this.newItemService.openUniversalCreate(
            { ...milestone, userId: milestone.ownedByUserId } as Item, // Universalcreate requires userId to be set
            ItemType.rock,
            createdFrom
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  updateOrdinalV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.updateOrdinals),
      concatLatestFrom(() => [this.store.select(rockSelectors.selectRocksV3)]),
      filter(([_, rocksV3]) => rocksV3),
      switchMap(([{ rocks, ordinalType }]) => {
        this.rockService.updateOrdinals(rocks, ordinalType);
        return of(RocksStateActions.updateOrdinalsSuccess());
      }),
      catchError((error: unknown) => of(RocksStateActions.updateOrdinalsFailed({ error })))
    )
  );

  addNewRockV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.addRockV2),
      switchMap(({ rock }) => {
        const item = _cloneDeep(rock) as unknown as Item;
        const oldId = item._id;
        item._id = undefined;
        return this.rockService.create(item, [item.userId]).pipe(
          switchMap(() => [RocksStateActions.removeFromList({ rockId: oldId })]),
          catchError((error: unknown) =>
            of(RocksStateActions.removeFromList({ rockId: oldId }), RocksStateActions.addRockV2Failed({ error }))
          )
        );
      })
    )
  );

  createMilestoneFromRock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RocksStateActions.createMilestoneFromRock),
      switchMap(({ rock, milestone }) => {
        const cloneMilestone = _cloneDeep(milestone);
        cloneMilestone._id = undefined;
        return this.milestoneService.createMilestone(cloneMilestone).pipe(
          switchMap(newMilestone => [
            RocksStateActions.addMilestoneToRockLocal({ rock, milestone: newMilestone }),
            RocksStateActions.deleteMilestoneFromRockLocal({ milestone }),
          ]),
          catchError((error: unknown) =>
            of(
              RocksStateActions.deleteMilestoneFromRockLocal({ milestone }),
              RocksStateActions.createMilestoneFromRockFailed({ error })
            )
          )
        );
      })
    )
  );

  private isRockAction(itemType: DetailType) {
    return (
      itemType === DetailType.rockStore ||
      itemType === DetailType.rock ||
      itemType === DetailType.rockStoreV2 ||
      itemType === DetailType.rockV3
    );
  }

  private isMilestoneAction(itemType: DetailType) {
    return (
      itemType === DetailType.milestoneStore || itemType === DetailType.milestone || itemType === DetailType.milestoneV2
    );
  }
}
