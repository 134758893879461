import { TeammateInvitePayload } from '@ninety/create-company/add-teammate-dialog/add-teammate-dialog.component';
import { BusinessOperatingSystem } from '@ninety/ui/legacy/shared/models/company/business-operating-system.enum';
import { ClearbitEmployeeRange, ClearbitIndustry } from '@ninety/ui/legacy/shared/models/company/clearbit.model';
import { HowFamiliar } from '@ninety/ui/legacy/shared/models/company/how-familiar.enum';
import { SignupCompanyRole } from '@ninety/ui/legacy/shared/models/user/signup-company-role.enum';

export const SignupStateKey = 'signup';

export interface StepConfig {
  completed: boolean;
  visible: boolean;
}

export interface SignUpRegistration {
  signupCompanyRole: SignupCompanyRole;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  companyName: string;
  industry: ClearbitIndustry;
  employees: ClearbitEmployeeRange;
  bos: BusinessOperatingSystem;
  howFamiliar: HowFamiliar;
  runMeetings: boolean;
  marketingReferralCode?: string;
}

export interface SignupStateModel {
  addingTeammates: boolean;
  api: {
    checkEmail: boolean;
    checkInvites: boolean;
  };
  clearBitFound: boolean; // hideSurvey;
  inviteCompanies: string[];
  isSpinning: boolean;
  personIds: { personId: string; personMetadataId: string };
  registration: SignUpRegistration;
  signUpComplete: boolean;
  signUpEvent: SubmitEvent;
  steps: {
    selectedIndex: number;
    welcome: StepConfig;
    invites: StepConfig;
    account: StepConfig;
    company: StepConfig;
    bos: StepConfig;
  };
  teammates: TeammateInvitePayload[];
}

export const initialSignupRegistration = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  companyName: '',
  bos: BusinessOperatingSystem.ninetyOS,
  signupCompanyRole: null,
  industry: null,
  employees: null,
  howFamiliar: null,
  runMeetings: true,
  marketingReferralCode: null,
};

export const initialSignupSteps = {
  selectedIndex: 0,
  welcome: {
    completed: false,
    visible: true,
  },
  invites: {
    completed: false,
    // This is an optional step and should be off by default
    visible: false,
  },
  account: {
    completed: false,
    visible: true,
  },
  company: {
    completed: false,
    visible: true,
  },
  bos: {
    completed: false,
    visible: true,
  },
};

export const initialSignupState: SignupStateModel = {
  isSpinning: false,
  steps: initialSignupSteps,
  api: {
    checkEmail: false,
    checkInvites: false,
  },
  inviteCompanies: [],
  clearBitFound: false,
  registration: initialSignupRegistration,
  teammates: [],
  addingTeammates: false,
  personIds: null,
  signUpComplete: false,
  signUpEvent: null,
};
