import { OneSchemaData } from '@ninety/data-import/models/one-schma-data';
import { IssueRecord } from '@ninety/data-import/models/records/issue-record';
import { RockRecord } from '@ninety/data-import/models/records/rock-record';
import { TodoRecord } from '@ninety/data-import/models/records/todo-record';
import { UserRecord } from '@ninety/data-import/models/records/user-record';

export * from './issue-record';
export * from './rock-record';
export * from './todo-record';
export * from './user-record';

export type IssueRecordsData = { data: OneSchemaData<IssueRecord> };
export type RockRecordsData = { data: OneSchemaData<RockRecord> };
export type TodoRecordsData = { data: OneSchemaData<TodoRecord> };
export type UserRecordsData = { data: OneSchemaData<UserRecord> };
