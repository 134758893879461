import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';

import {
  ClearbitCompanyAttributes,
  ClearbitEmployeeRange,
  ClearbitIndustry,
} from '@ninety/ui/legacy/shared/models/company/clearbit.model';

@Injectable()
export class ClearbitService {
  private readonly apiUrl = '/api/v4/Analytics/Clearbit';

  constructor(private http: HttpClient) {}

  getCompanyByEmail(email: string): Observable<ClearbitCompanyAttributes> {
    return this.http.post<ClearbitCompanyAttributes>(`${this.apiUrl}/Company`, { email }).pipe(
      map(resp => ({
        industry: this.getValidClearbitIndustry(resp?.industry),
        employeesRange: this.getValidClearbitEmployeeRange(resp?.employeesRange),
      })),
      catchError((e: unknown) => {
        console.error(e);

        return throwError(e);
      })
    );
  }

  /**
   * Filter out unsupported industries.
   */
  private getValidClearbitIndustry(industry: ClearbitIndustry | string): ClearbitIndustry {
    if (!industry) return undefined;

    if (!Object.values(ClearbitIndustry).includes(industry as ClearbitIndustry)) {
      return ClearbitIndustry.other;
    }

    return industry as ClearbitIndustry;
  }

  /**
   * Filter out unsupported employee ranges.
   */
  private getValidClearbitEmployeeRange(range: ClearbitEmployeeRange): ClearbitEmployeeRange {
    if (!range) return undefined;

    switch (range) {
      case ClearbitEmployeeRange['1-10']:
      case ClearbitEmployeeRange['11-50']:
      case ClearbitEmployeeRange['51-250']:
      case ClearbitEmployeeRange['251-1K']:
        return range;
      default:
        return ClearbitEmployeeRange['1K+'];
    }
  }
}
