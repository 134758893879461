import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PersonMetadata } from '../../../_shared/models/_shared/person-metadata';
import { User } from '../../../_shared/models/_shared/user';
import { UserTeamsActions } from '../team-list/team-list-state.actions';

import {
  DirectoryProfileCardActions,
  UserInviteActions,
  UserPreferencesActions,
  UserSettingsActions,
  UsersStateActions,
} from './users-state.actions';
import { UsersStateModel, usersInitialState, usersStateAdapter } from './users-state.model';

export const UsersStateReducer = createReducer(
  usersInitialState,

  on(
    UsersStateActions.setCurrentUser,
    (state, { userId }): UsersStateModel => ({
      ...state,
      currentUserId: userId,
    })
  ),
  on(UsersStateActions.loadAllUsers, (state, { users }) => usersStateAdapter.setAll(users, state)),
  on(UsersStateActions.addOne, (state, { user }) => usersStateAdapter.addOne(user, state)),
  on(UsersStateActions.addMany, (state, { users }) => usersStateAdapter.addMany(users, state)),
  on(UsersStateActions.removeOne, (state, { _id }) => usersStateAdapter.removeOne(_id, state)),
  on(
    UsersStateActions.updateOne,
    DirectoryProfileCardActions.userDeleted,
    UsersStateActions.updateSuccess,
    (state, { _id: id, changes }) => usersStateAdapter.updateOne({ id, changes }, state)
  ),
  on(UsersStateActions.updateMany, (state, { updates }) => usersStateAdapter.updateMany(updates, state)),
  on(UserInviteActions.inviteUser, (state, { user }) => usersStateAdapter.addOne(user, state)),

  on(UsersStateActions.setAvatarUrl, (state: UsersStateModel, { _id, url }) => {
    const currentUserMetadata: PersonMetadata = state.entities[_id].metadata;

    //create an updated PersonMetadata
    const updatedMetadata: PersonMetadata = {
      ...currentUserMetadata,
      picture: {
        url: url,
      },
    };

    //create an Update to be passed to the usersStateAdapter
    const update: Update<User> = {
      id: _id,
      changes: {
        metadata: updatedMetadata,
      },
    };

    //make the update
    return usersStateAdapter.updateOne(update, state);
  }),

  on(UserTeamsActions.removeUserFromTeamSuccess, (state, { userId, teamId }) => {
    const update: Update<User> = {
      id: userId,
      changes: {
        teams: state.entities[userId].teams.filter(t => t.teamId !== teamId),
      },
    };

    return usersStateAdapter.updateOne(update, state);
  }),

  on(UserTeamsActions.addUsersToTeamSuccess, (state, { users }) => {
    const userUpdates: Update<User>[] = users.map(u => ({
      id: u._id,
      changes: {
        teams: u.teams,
      },
    }));

    return usersStateAdapter.updateMany(userUpdates, state);
  }),

  on(UserPreferencesActions.updateTheme, (state, { theme }) => {
    const currentUser = state.entities[state.currentUserId];

    const update: Update<User> = {
      id: currentUser._id,
      changes: {
        settings: {
          ...currentUser.settings,
          theme: theme,
        },
      },
    };
    return usersStateAdapter.updateOne(update, state);
  }),

  on(UsersStateActions.updateSms, (state, { _id, countryCode, number }) => {
    const currentUser = state.entities[_id];
    if (!currentUser) {
      return state;
    }

    const update: Update<User> = {
      id: currentUser._id,
      changes: {
        sms: {
          ...currentUser.sms,
          countryCode: countryCode,
          number: number,
          verified: true,
          notifications: true,
        },
      },
    };
    return usersStateAdapter.updateOne(update, state);
  }),

  on(UserPreferencesActions.userTutorialsUpdated, (state, { tutorialsHidden }) => {
    const currentUser = state.entities[state.currentUserId];
    if (!currentUser) {
      return state;
    }

    const update: Update<User> = {
      id: currentUser._id,
      changes: {
        tutorialsHidden: tutorialsHidden,
      },
    };

    return usersStateAdapter.updateOne(update, state);
  }),

  on(UserSettingsActions.updateTimezone, (state, { user }) => {
    const update: Update<User> = {
      id: user._id,
      changes: {
        settings: user.settings,
      },
    };

    return usersStateAdapter.updateOne(update, state);
  })
);
