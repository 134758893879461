import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';

import { ErrorTagService } from '../analytics/google-analytics/services/error-tag.service';
import { SegmentTrackEvent } from '../analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '../analytics/segment/segment.actions';
import { DatadogLogger } from '../vendor/datadog';
import { DatadogLoggerStatic } from '../vendor/datadog/datadog-logger.static';

import { SpinnerService } from './spinner.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private spinnerService: SpinnerService,
    private toastr: ToastrService,
    public stateService: StateService,
    private errorTagService: ErrorTagService,
    private datadogLogger: DatadogLogger,
    private store: Store
  ) {}

  private static getMessage(e: unknown): string {
    let errorMessage: string;

    if (e instanceof HttpErrorResponse) {
      errorMessage = `An error occurred: ${e.error.message}`;
    } else if (e instanceof Error) {
      errorMessage = e.message;
    } else if ((e as any)?.error) {
      // TODO: Figure out what's throwing this error and what the interface is
      // Update: Cause - HttpInterceptors spread HttpResponse error into a new object
      errorMessage = (e as any).error?.errorMessage || (e as any)?.message || 'An error occurred';
    } else {
      errorMessage = 'An error occurred';
    }

    return errorMessage;
  }

  static handle(e: unknown): Observable<never> {
    const errorMessage = ErrorService.getMessage(e);

    // Can't use DI since this is a static method
    const logger = DatadogLoggerStatic.getLogger();
    logger.error({ message: errorMessage, error: e });

    return throwError(() => new Error(errorMessage));
  }

  /**
   * Non-static version of ErrorService.handle. Has access to all injected dependencies.
   */
  catchError(e: unknown): Observable<never> {
    const errorMessage = ErrorService.getMessage(e);

    this.datadogLogger.error({ message: errorMessage, error: e });

    return throwError(() => new Error(errorMessage));
  }

  oops(e: unknown): Observable<never> {
    this.spinnerService.stop();

    const errorMessage = ErrorService.getMessage(e);

    this.errorTagService.trackError({ message: errorMessage });
    this.datadogLogger.error({ message: errorMessage, error: e });

    return throwError(() => new Error(errorMessage));
  }

  notify(
    e: unknown,
    message?: string,
    title = 'Error',
    toastConfig?: Partial<IndividualConfig>,
    stopSpinner = true
  ): Observable<never> {
    if (stopSpinner) this.spinnerService.stop();

    const errorMessage = ErrorService.getMessage(e);
    const toastMsg = message ? message : errorMessage;

    this.errorTagService.trackError({ message: errorMessage });
    this.store.dispatch(
      SegmentActions.track({ event: SegmentTrackEvent.ERROR, params: { message, title, source: 'ErrorService' } })
    );
    this.toastr.error(toastMsg, title, toastConfig);
    this.datadogLogger.error({
      message: errorMessage,
      error: e,
    });

    return throwError(() => new Error(errorMessage));
  }
}
