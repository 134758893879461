import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';

@Component({
  selector: 'ninety-role-cell',
  template: ` <ninety-inline-role-select (updateRole)="writeValue($event)" [selectedRole]="_selectedRole" /> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleCellComponent),
      multi: true,
    },
  ],
})
export class RoleCellComponent implements ControlValueAccessor {
  protected _selectedRole: RoleSelectOption;

  /** ControlValueAccessor implementation */
  onChange: (value: RoleSelectOption) => void = role => {
    if (!role) return;
    this._selectedRole = role;
  };
  onTouched: () => void = () => {};

  writeValue(value: RoleSelectOption): void {
    if (!value) return;
    this._selectedRole = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: RoleSelectOption) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
}
