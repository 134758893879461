<section id="conversation-sidenav" *ngIf="manager && managee && showNav">
  <ng-container *ngIf="feedbackService.conversationSectionUpdate$ | async as currentSection">
    <div class="conversation-sections" *ngIf="!viewingTool">
      <h3 *ngIf="isConversationManager || canEvaluateSelf">
        {{ managee?.metadata?.name | displayFullName }}
        <small *ngIf="!isMeeting"
          >(review {{ isConversationManager ? 'your ' + stateService.language.feedback.managee : 'yourself' }})</small
        >
      </h3>
      <ol *ngIf="isConversationManager || canEvaluateSelf">
        <li
          attr.data-cy="conversation-sidenav_managee-section-name-{{ section.title }}"
          *ngFor="let section of manageeSections"
          attr.data-cy="conversation-sidenav_sidenav-link-{{ section.title }}"
          class="sidenav-link"
          [ngClass]="{ active: currentSection?.elementId === section.elementId }"
          (click)="setSection(section)">
          {{ section.title }}
        </li>
      </ol>
      <h3 *ngIf="isConversationManager || canEvaluateManager">
        {{ manager?.metadata?.name?.first + ' ' + manager?.metadata?.name?.last }}
        <small *ngIf="!isMeeting"
          >(review {{ isConversationManager ? 'yourself' : 'your ' + stateService.language.feedback.manager }})</small
        >
      </h3>
      <ol *ngIf="isConversationManager || canEvaluateManager">
        <li
          attr.data-cy="conversation-sidenav_manager-section-name-{{ section.title }}"
          *ngFor="let section of managerSections"
          class="sidenav-link"
          [ngClass]="{ active: currentSection?.elementId === section.elementId }"
          (click)="setSection(section)">
          {{ section.title }}
        </li>
        <ng-container *ngIf="isMeeting">
          <li
            class="sidenav-link"
            *ngFor="let section of signatureSections"
            (click)="setSection(section)"
            [ngClass]="{ active: currentSection?.elementId === section.elementId }">
            {{ section.title }}
          </li>
        </ng-container>
      </ol>
    </div>
  </ng-container>

  <div class="fill-remaining-space"></div>
  <div class="flex-column" *ngIf="showSaveButtons$ | async">
    <ninety-button
      icon
      stroked
      class="button-tertiary full-width"
      data-cy="conversation-sidenav_save-for-later-button"
      (click)="saveConversation(false)"
      [disabled]="feedbackService.disableSidenavButtons$ | async">
      <terra-icon icon="save" size="20" />
      <span>Save for Later</span>
    </ninety-button>
    <ninety-button
      filled
      primary
      icon
      data-cy="converssation-sidenav_finish-button"
      class="button-primary full-width"
      (click)="isMeeting ? finishMeeting() : saveConversation(true)"
      [disabled]="feedbackService.disableSidenavButtons$ | async">
      <terra-icon icon="check" size="20" />
      <span>Finish</span>
    </ninety-button>
  </div>
  <div *ngIf="isMeeting" class="flex-column view-tool-container">
    <ninety-button
      icon
      flat
      [matMenuTriggerFor]="viewAnotherToolMenu"
      data-cy="conversation-sidenav_view-tool-button"
      class="menu-btn uppercase full-width view-tool-button"
      aria-label="View Another Tool"
      [disabled]="!isMeeting">
      View Tool
      <terra-icon icon="tool" />
    </ninety-button>

    <mat-menu #viewAnotherToolMenu="matMenu" xPosition="before" yPosition="above">
      <a
        mat-menu-item
        [routerLink]="[
          '',
          {
            outlets: {
              detail: null,
              primary: [
                '1-on-1',
                'discussion',
                conversationId,
                conversationBOS === 'EOS' ? conversationToolType.meeting : conversationToolType.conversationMeeting
              ]
            }
          }
        ]"
        routerLinkActive="active">
        <terra-icon icon="one-on-one" />
        {{ stateService.language.feedback.route }} {{ stateService.language.feedback.meeting }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.data] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="data" />
        {{ stateService.language.scorecard.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.rocks] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="mountains" />
        {{ stateService.language.rock.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.todos] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="to-dos" />
        {{ stateService.language.todo.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.issues] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="issues" />
        {{ stateService.language.issue.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.vto] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="vision" />
        {{ stateService.language.vto.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.ac] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="responsibilities" />
        {{ stateService.language.acChart.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          { outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.process] } }
        ]"
        routerLinkActive="active">
        <terra-icon icon="process" matTooltip="Process" />
        {{ stateService.language.process.route }}
      </a>
      <a
        mat-menu-item
        [routerLink]="[
          '',
          {
            outlets: { detail: null, primary: ['1-on-1', 'discussion', conversationId, conversationToolType.directory] }
          }
        ]"
        routerLinkActive="active">
        <terra-icon icon="directory" />
        {{ stateService.language.directory.route }}
      </a>
    </mat-menu>
  </div>
</section>
