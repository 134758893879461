import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { Team } from '../../../_shared/models/_shared/team';
import { mongoIdAdapter } from '../../../_shared/ngrx/mongo-id-adapter';
import { SortByNamePipe } from '../../../_shared/pipes/sort-by-name.pipe';

import { TeamListModel } from './api/team-list.model';

const selectId = mongoIdAdapter<Team & { _id: string }>();
export const teamListStateAdapter = createEntityAdapter<TeamListModel>({
  selectId,
  sortComparer: SortByNamePipe.ascending,
});
export interface TeamListStateModel extends EntityState<TeamListModel> {
  archivedTeamsLoaded: boolean;
  filterBarTeam: Team;
  editedTeamId: string;
  fetchingTeams: boolean;
}

export const teamListInitialState: TeamListStateModel = teamListStateAdapter.getInitialState({
  filterBarTeam: null,
  editedTeamId: null,
  fetchingTeams: true,
  archivedTeamsLoaded: false,
});
