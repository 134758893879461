import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraIconModule } from '../terra-icon';

import { TerraBadgeComponent } from './terra-badge.component';

@NgModule({
  imports: [CommonModule, TerraBadgeComponent, TerraIconModule],
  exports: [TerraBadgeComponent],
})
export class TerraBadgeModule {}
