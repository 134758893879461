import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FilterOptions } from '@ninety/ui/legacy/shared/models/_shared/filter-options';

export const FilterBarActions = createActionGroup({
  source: 'Filter Bar',
  events: {
    // Changes passed props, resets omitted props to their default
    'Set Options': props<{ options?: FilterOptions }>(),
    // Only changes the props passed
    'Set Explicit': props<{ options: FilterOptions }>(),
    Hide: emptyProps(),
    Show: emptyProps(),
    Enabled: emptyProps(),
    Disabled: emptyProps(),
  },
});

export const GridFilterBarActions = createActionGroup({
  source: 'Filter Bar - Grid',
  events: {
    'Enter Layout Mode': emptyProps(),
    'Leave Layout Mode': emptyProps(),
    /** Cancel and reset any un-saved, in-flight changes to the layout */
    'Cancel Changes': emptyProps(),
    /** The filterbar only requests persist. GridLayoutEffects handles actually persisting changes. */
    'Request Persist Changes': emptyProps(),
  },
});
