export enum DirectoryUserStatusV2 {
  all = 'all',

  /** users who are active. Invited users who have accepted their invite. */
  active = 'active',

  /** active users with non-free roles (coaches and observers). */
  activeAndPaid = 'active-and-paid',

  /** invited users who haven’t accepted it yet. */
  invitePending = 'invite-pending',

  /** users who are only in the directory (haven’t been invited or has been deactivated). */
  directoryOnly = 'directory-only',

  /** not used right now */
  free = 'free',
}
