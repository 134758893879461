import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { UserIdToAvatarPipe } from '@ninety/ui/legacy/shared/pipes/user/user-id-to-avatar.pipe';
import {
  selectCurrentUserId,
  selectCurrentUserIsManageeOrAbove,
  selectCurrentUserIsOwner,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { FeatureFlagKeys, selectFeatureFlag } from '@ninety/ui/legacy/state/index';

import { MeetingDialogActions, MeetingsPageActions, MeetingSchedulingActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';

@Component({
  selector: 'ninety-active-meeting',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    LetDirective,
    ButtonComponent,
    TerraAvatarModule,
    PushPipe,
    UserIdToAvatarPipe,
    TerraIconModule,
  ],
  templateUrl: './active-meeting.component.html',
  styleUrls: ['./active-meeting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveMeetingComponent implements OnInit {
  private store = inject(Store);

  ngOnInit(): void {
    this.store.dispatch(MeetingsPageActions.getActiveMeetingInfo());
  }

  team$ = this.store.select(MeetingsStateSelectors.selectSelectedTeam);
  language$ = this.store.select(selectLanguage);
  meetingStatus$ = this.store.select(MeetingsStateSelectors.selectMeetingStatus);
  meetingName$ = this.store.select(MeetingsStateSelectors.selectMeetingName);
  loading$ = this.store.select(MeetingsStateSelectors.selectLoading);
  currentUserIsManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);
  currentUserIsOwner$ = this.store.select(selectCurrentUserIsOwner);
  attendees$: Observable<string[]> = this.store.select(MeetingsStateSelectors.selectAttendees);
  meetingSchedulingFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.meetingScheduling));

  currentUserIsOwnerOrPresenter$ = this.store.select(selectCurrentUserIsOwner).pipe(
    concatLatestFrom(() => [
      this.store.select(MeetingsStateSelectors.selectMeetingStatusPresenterUserId),
      this.store.select(selectCurrentUserId),
    ]),
    map(([isOwner, presenterId, userId]) => isOwner || presenterId === userId)
  );

  openStartMeetingDialog() {
    this.store.dispatch(MeetingDialogActions.openStartMeetingDialog());
  }

  openScheduleMeetingDialog() {
    this.store.dispatch(MeetingSchedulingActions.openScheduleMeetingDialog());
  }

  joinMeeting() {
    this.store.dispatch(MeetingsPageActions.getMeeting());
  }

  resumeActiveMeeting() {
    this.store.dispatch(MeetingsPageActions.resumeMeeting());
  }

  openDeleteMeetingDialog() {
    this.store.dispatch(MeetingsPageActions.openDeleteMeetingDialog());
  }
}
