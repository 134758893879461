export interface TeamListModel {
  _id: string;
  name: string;
  project: boolean;
  private: boolean;
  deleted: boolean;
  archived: boolean;
  removedUsers?: string[];
}

export const teamAll: TeamListModel = {
  name: 'All',
  _id: 'all',
  project: false,
  private: false,
  deleted: false,
  archived: false,
};

export const teamNone: TeamListModel = {
  name: 'None',
  _id: 'none',
  project: false,
  private: false,
  deleted: false,
  archived: false,
};
