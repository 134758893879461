import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { PopoverOriginDirective } from '@ninety/ui/legacy/components/popover/popover-origin.directive';
import { PopoverComponent } from '@ninety/ui/legacy/components/popover/popover.component';
import { selectRoleSelectData } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';

@Component({
  selector: 'ninety-role-info-overlay',
  standalone: true,
  imports: [CommonModule, TerraDividerModule, PopoverComponent, PopoverOriginDirective, TerraIconModule, PushPipe],
  templateUrl: './role-info-overlay.component.html',
  styleUrls: ['./role-info-overlay.component.scss'],
})
export class RoleInfoOverlayComponent {
  showRoleInfoOverlay = false;
  protected readonly roles$ = this.store.select(selectRoleSelectData);

  constructor(private store: Store) {}
}
