import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs';

import { UserService } from '../../../_core/services/user.service';

import { UserListStateActions } from './user-list-state.actions';

@Injectable()
export class UserListStateEffects {
  constructor(private action$: Actions, private userService: UserService) {}

  $getUserList = createEffect(() =>
    this.action$.pipe(
      ofType(UserListStateActions.getUserList),
      concatMap(() => this.userService.getUserList({})),
      map(userList => UserListStateActions.getUserListSuccess({ users: userList }))
    )
  );
}
