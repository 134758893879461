export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** represents Material SortDirector
 * warning - this can also be empty string
 * **/
export enum MatSortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

/** Converts the Ninety SortDirection to MatSortDirection */
export const NinetyToMatSortAdapter: Record<SortDirection, MatSortDirection> = {
  [SortDirection.ASC]: MatSortDirection.ASC,
  [SortDirection.DESC]: MatSortDirection.DESC,
};

/** Converts the MatSortDirection to Ninety SortDirection */
export const MatToNinetySortAdapter: Record<MatSortDirection, SortDirection> = {
  [MatSortDirection.ASC]: SortDirection.ASC,
  [MatSortDirection.DESC]: SortDirection.DESC,
};
