import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { GETTING_STARTED_STATE_KEY, GettingStartedReducers } from '@ninety/getting-started/_state/index';
import { GuideEffects } from '@ninety/getting-started/guide/_state/guide.effects';
import { StepCompletionChecksEffects } from '@ninety/getting-started/guide/_state/step-completion-checks.effects';
import { MazAnalyticsEffects } from '@ninety/getting-started/maz/_state/maz-analytics.effects';
import { MazEffects } from '@ninety/getting-started/maz/_state/maz.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(GETTING_STARTED_STATE_KEY, GettingStartedReducers),
    EffectsModule.forFeature([GuideEffects, MazEffects, StepCompletionChecksEffects, MazAnalyticsEffects]),
  ],
})
export class GettingStartedStateModule {}
