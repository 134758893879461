export enum RoleName {
  owner = 'owner',
  admin = 'admin',
  manager = 'manager',
  managee = 'managee',
  observer = 'observer',
  coach = 'coach',

  // deprecated
  lite = 'lite',
}
