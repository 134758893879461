import { DirectoryUserStatusV2 } from '@ninety/ui/legacy/shared/models/directory/directory-user-status-v2';

export interface DirectoryUserStatusOption {
  title: string;
  status: DirectoryUserStatusV2;
}

export const ALL_USER_STATUS_FILTER_OPTION: DirectoryUserStatusOption = {
  title: 'All',
  status: DirectoryUserStatusV2.all,
};

export const DIRECTORY_USER_STATUS_FILTER_OPTIONS: DirectoryUserStatusOption[] = [
  ALL_USER_STATUS_FILTER_OPTION,
  { title: 'Active', status: DirectoryUserStatusV2.active },
  { title: 'Active and Paid', status: DirectoryUserStatusV2.activeAndPaid },
  { title: 'Invite Pending', status: DirectoryUserStatusV2.invitePending },
  { title: 'Directory only', status: DirectoryUserStatusV2.directoryOnly },
];
