import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Team, User } from '../../../_shared';
import {
  TeamMeetingAgendaPush,
  TeamMeetingAgendaUpdateByType,
  TeamSettings,
} from '../../../_shared/models/_shared/team';

import { TeamListModel } from './api/team-list.model';

export const TeamListStateActions = createActionGroup({
  source: 'Team List',
  events: {
    // Get
    'Get Team List': emptyProps(),
    'Get Team List Success': props<{ teams: TeamListModel[] }>(),

    'Get Archived Team List': emptyProps(),
    'Get Archived Team List Success': props<{ archivedTeams: TeamListModel[] }>(),
    'Get Archived Team List Error': props<{ error: any }>(),

    // Edit
    'Edit Team': props<{ teamId: string }>(),

    // Selected Team
    'Change FilterBar Team': props<{ id: string }>(),
    'Get Selected Team': props<{ id: string }>(),
    'Get Selected Team Success': props<{ team: Team }>(),
    'Get Selected Team Error': props<{ error: any }>(),
    'Update Selected Team In Memory': props<{ teamId: string; changes: Partial<Team> }>(),
    'Update Selected Team Setting In Memory': props<{ teamId: string; changes: Partial<TeamSettings> }>(),

    // Updates
    'Add One': props<{ team: TeamListModel }>(),
    'Remove One': props<{ teamId: string }>(),

    'Update One': props<{ id: string; update: Partial<TeamListModel> }>(),
    'Update One Success': props<{ id: string; update: Partial<TeamListModel> }>(),
    'Update One Error': props<{ error: any }>(),

    'Update Team': props<{ id: string; update: Partial<Team> }>(),

    // Set Edited Team
    'Open Team Detail': props<{ teamId: string }>(),
    'Set Edited Team Id': props<{ teamId: string }>(),

    // Settings
    'Update Settings': props<{ teamId: string; update: Partial<TeamSettings> }>(),
    'Update Settings Success': props<{ teamId: string; update: Partial<TeamSettings> }>(),
    'Update Settings Error': props<{ error: any }>(),

    'Push Custom Agenda': props<{ agendaRequest: TeamMeetingAgendaPush }>(),
    'Push Custom Agenda Success': props<{ teamId: string }>(),
    'Push Custom Agenda Error': props<{ error: any }>(),

    'Delete Custom Agenda': props<{ teamId: string; agendaId: string }>(),
    'Delete Custom Agenda Success': props<{ teamId: string; agendaId: string }>(),
    'Delete Custom Agenda Error': props<{ error: any }>(),

    'Update Team Meeting Agenda': props<{ update: TeamMeetingAgendaUpdateByType }>(),
    'Update Team Meeting Agenda Success': props<{ update: TeamMeetingAgendaUpdateByType }>(),
    'Update Team Meeting Agenda Error': props<{ error: any }>(),
  },
});

export const UserTeamsActions = createActionGroup({
  source: 'Team Detail',
  events: {
    'Add Users To Team': props<{ users: User[]; teamId: string }>(),
    'Add Users To Team Success': props<{ users: User[] }>(),
    'Add Users To Team Failed': props<{ error: unknown }>(),

    'Remove User From Team': props<{ userId: string; teamId: string }>(),
    'Remove User From Team Success': props<{ userId: string; teamId: string }>(),
    'Remove User From Team Failed': props<{ error: unknown }>(),

    'Close Detail View': emptyProps(),
  },
});
