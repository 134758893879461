import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MazMessage } from '@ninety/getting-started/maz/models/maz-message';
import { MazMessageSender } from '@ninety/getting-started/maz/models/maz-message-sender.enum';
import { UserAvatarInfo } from '@ninety/ui/legacy/state/app-entities/users/users-state.model';

import { MazFeedbackFlag } from '../../models/maz-feedback-flag.enum';

@Component({
  selector: 'ninety-maz-message',
  templateUrl: './maz-message.component.html',
  styleUrls: ['./maz-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MazMessageComponent {
  @Input() message: MazMessage;
  // just so we don't need to subscribe to the store a million times.
  @Input() avatarInfo?: UserAvatarInfo;

  @Output() toggleMazFeedbackFlag = new EventEmitter<string>();

  MazMessageSender = MazMessageSender;
  MazFeedbackFlag = MazFeedbackFlag;

  toggleFlag(): void {
    this.toggleMazFeedbackFlag.emit(this.message._id);
  }
}
