import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LetDirective, PushPipe } from '@ngrx/component';

import { GettingStartedStateModule } from '@ninety/getting-started/_state/getting-started-state.module';
import { GettingStartedGuideComponent } from '@ninety/getting-started/guide/components/getting-started-guide/getting-started-guide.component';
import { PhaseComponent } from '@ninety/getting-started/guide/components/phase/phase.component';
import { PhaseDetailsComponent } from '@ninety/getting-started/guide/components/phase-details/phase-details.component';
import { PhaseListComponent } from '@ninety/getting-started/guide/components/phase-list/phase-list.component';
import { PhaseStepComponent } from '@ninety/getting-started/guide/components/phase-step/phase-step.component';
import { ChatWindowHeightDirective } from '@ninety/getting-started/maz/components/maz-chat/chat-window-height.directive';
import { MazChatComponent } from '@ninety/getting-started/maz/components/maz-chat/maz-chat.component';
import { MazCommonQuestionsComponent } from '@ninety/getting-started/maz/components/maz-common-questions/maz-common-questions.component';
import { MazMessageComponent } from '@ninety/getting-started/maz/components/maz-message/maz-message.component';
import { MazWaitingForResponseComponent } from '@ninety/getting-started/maz/components/maz-waiting-for-response/maz-waiting-for-response.component';
import { MazMessageHtmlPipe } from '@ninety/getting-started/maz/pipes/maz-message-html.pipe';
import { TerraAvatarModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { ProgressBarComponent } from '@ninety/ui/legacy/components/progress-bar/progress-bar.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { TextInputWrapperComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/text-input/components/text-input-wrapper/text-input-wrapper.component';
import { NinetyInputDirective } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/text-input/directives/ninety-input.directive';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';

import { MazChatToasterComponent } from './maz/components/maz-chat-toaster/maz-chat-toaster.component';

@NgModule({
  declarations: [
    GettingStartedGuideComponent,
    PhaseComponent,
    PhaseListComponent,
    PhaseDetailsComponent,
    PhaseStepComponent,
    MazChatComponent,
    MazMessageComponent,
    ChatWindowHeightDirective,
    MazCommonQuestionsComponent,
    MazMessageHtmlPipe,
    MazWaitingForResponseComponent,
    MazChatToasterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GettingStartedStateModule,
    ButtonComponent,
    LetDirective,
    ProgressBarComponent,
    PushPipe,
    TerraIconModule,
    NinetyInputDirective,
    TextInputWrapperComponent,
    NinetyTooltipDirective,
    TerraAvatarModule,
  ],
  exports: [GettingStartedGuideComponent],
})
export class GettingStartedModule {}
