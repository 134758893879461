<div
  *ngrxLet="{
    language: facade.language$,
    teams: facade.teamsOptions$,
    loading: facade.loading$,
    meetingTypes: facade.meetingTypeOptions$
  } as vm">
  <ninety-action-bar>
    <div class="action-bar-left">
      <ninety-quick-filter
        pillTitle="Team"
        [pillIcon]="'users-three'"
        (selectionChange)="facade.teamSelected($event)"
        [options]="vm.teams"
        [disabled]="vm.loading"
        data-cy="team-filter"
        aria-label="Team filter"
        listFixedLocation="below">
      </ninety-quick-filter>
      <ninety-quick-filter
        pillTitle="Type"
        [options]="vm.meetingTypes"
        (change)="facade.meetingTypeSelected($event)"
        [disabled]="vm.loading"
        data-cy="type-filter"
        aria-label="Type filter">
      </ninety-quick-filter>
    </div>
    <div class="action-bar-right">
      <ninety-button
        primary
        stroked
        (click)="facade.printMeetingAgendas()"
        [disabled]="vm.loading"
        data-cy="print-meeting-agenda"
        attr.aria-label="Print {{ vm.language.meeting.item }} Agenda"
        listFixedLocation="below">
        <terra-icon icon="file-print" aria-hidden="true" /> Print {{ vm.language.meeting.item }} Agenda
      </ninety-button>
    </div>
  </ninety-action-bar>

  <div class="card-wrp">
    <ninety-active-meeting></ninety-active-meeting>
  </div>

  <div class="card-wrp meetings-v2-card-wrp">
    <ninety-card
      class="meetings-v2-card"
      *ngrxLet="{
        templateType: facade.templateType$ | async,
        totalCount: facade.totalCount$ | async
      } as cardVm">
      <ninety-widget-skeleton
        *ngIf="cardVm.templateType === WidgetTemplateType.loading"
        [tableBodyCount]="LOADING_SKELETON_ROWS"></ninety-widget-skeleton>

      <ninety-card-header header *ngIf="cardVm.templateType !== WidgetTemplateType.loading">
        <ninety-card-title-with-count
          title="{{ vm.language.meeting.item }} History"
          [count]="facade.totalCount$ | async"></ninety-card-title-with-count>
        <span class="description">Your team's {{ vm.language.meeting.items }}, in one place.</span>
      </ninety-card-header>

      <ng-container [ngSwitch]="cardVm.templateType">
        <div *ngSwitchCase="WidgetTemplateType.noQueryResults" id="meetings-v2-{{ WidgetTemplateType.noQueryResults }}">
          <ninety-empty-list-view
            title="You have no {{ vm.language.meeting.items }}."
            iconName="search"
            customMessage="Please try a different search.">
          </ninety-empty-list-view>
        </div>

        <div *ngSwitchCase="WidgetTemplateType.error" id="meetings-v2-{{ WidgetTemplateType.error }}">
          <ninety-empty-list-view [widgetErrorState]="true"> </ninety-empty-list-view>
        </div>

        <ninety-meetings-table
          *ngSwitchCase="WidgetTemplateType.data"
          [dataSource]="facade.pastMeetings$"
          [sort]="facade.selectSortForTable$ | async"
          [selectedId]="facade.selectedId$ | async"
          [dateFormat]="facade.dateFormat"
          (sortChange)="facade.sortChange($event)"
          (meetingSelected)="facade.onMeetingSelected($event)"></ninety-meetings-table>

        <ng-container
          *ngrxLet="{
            page: facade.page$,
            totalCount: facade.totalCount$,
          } as vmFooter">
          <ninety-card-footer
            footer
            *ngIf="cardVm.templateType === WidgetTemplateType.data && vmFooter.totalCount > vmFooter.page.size"
            class="full-width flex-justify-space-between flex-wrap">
            <ninety-pagination
              *ngrxLet="facade.page$ as page"
              class="flex flex-1 top-space-15"
              #pager
              [length]="vmFooter.totalCount"
              [pageIndex]="vmFooter.page.index"
              [pageSize]="vmFooter.page.size"
              (page)="facade.paginate($event)">
            </ninety-pagination>
          </ninety-card-footer>
        </ng-container>
      </ng-container>
    </ninety-card>
  </div>
</div>
