import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { TEST_DATA } from '@ninety/data-import/_state/data-import.model';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { DataImportType } from '@ninety/data-import/models/data-import-types';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { selectTeamFilterOptions } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';

@Component({
  selector: 'ninety-data-import',
  template: `
    <ng-container
      *ngrxLet="{
        templateOptions: templateOptions$,
        showTeamFilter: showTeamFilter$,
        teamOptions: teamOptions$,
        selected: selectedImportType$,
        showOneSchemaIframe: showOneSchemaIframe$,
        showPreviewTable: showPreviewTable$
      } as data">
      <ninety-action-bar *ngIf="data?.templateOptions">
        <div left class="import-selects">
          <ninety-quick-filter
            [options]="data.templateOptions"
            pillTitle="Import"
            [disabled]="data.templateOptions?.length <= 1"
            [pillIcon]="data.selected.icon"
            (selectionChange)="selectTemplate(asDataImportType($event))"></ninety-quick-filter>

          <ninety-quick-filter
            *ngIf="data.showTeamFilter"
            [options]="data.teamOptions"
            pillTitle="Team"
            (selectionChange)="selectTeam(asTeam($event))"></ninety-quick-filter>
        </div>

        <div class="action-buttons">
          <ninety-button (click)="test()" flat>Test</ninety-button>
          <ninety-button stroked primary [routerLink]="data.selected.routerLink" (click)="destroy()">
            <terra-icon [icon]="data.selected.icon" size="20" />
            Go to {{ data.selected.correspondingPage }}
          </ninety-button>

          <ninety-button filled primary (click)="downloadCSVTemplate()">
            <terra-icon icon="download" size="20" />
            Download template
          </ninety-button>
        </div>
      </ninety-action-bar>

      <!-- Banner will go here.  There's no way to know when file is uploaded at this time -->

      <ninety-one-schema-import *ngIf="data.showOneSchemaIframe"></ninety-one-schema-import>

      <ninety-user-import-card *ngIf="data.showPreviewTable"></ninety-user-import-card>
    </ng-container>
  `,
  styles: [
    `
      ninety-action-bar {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .import-selects,
      .action-buttons {
        display: flex;
        gap: 8px;
      }
    `,
  ],
})
export class DataImportComponent implements OnInit, OnDestroy {
  protected readonly showOneSchemaIframe$ = this.store.select(DataImportSelectors.selectShowOneSchemaIframe);
  protected readonly showPreviewTable$ = this.store.select(DataImportSelectors.selectShowPreviewTable);

  protected readonly templateOptions$ = this.store.select(DataImportSelectors.selectTemplateOptions);
  protected readonly showTeamFilter$ = this.store.select(DataImportSelectors.selectShowTeamFilter);
  protected readonly teamOptions$ = this.store.select(selectTeamFilterOptions);
  protected readonly selectedImportType$ = this.store.select(DataImportSelectors.selectImportType);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(DataImportActions.init());
  }

  ngOnDestroy() {
    this.destroy();
  }

  selectTemplate(option: DataImportType) {
    this.store.dispatch(DataImportActions.selectImportType({ option }));
  }

  selectTeam({ _id: teamId }: Team) {
    this.store.dispatch(DataImportActions.selectTeam({ teamId }));
  }

  downloadCSVTemplate() {
    this.store.dispatch(DataImportActions.downloadCSVTemplate());
  }

  destroy() {
    this.store.dispatch(DataImportActions.destroy());
  }

  asDataImportType = (option): DataImportType => option as DataImportType;
  asTeam = (option): Team => option as Team;

  test() {
    this.store.dispatch(DataImportActions.buildImportUserFormArray({ records: TEST_DATA }));
  }
}
