import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { MeetingsAgendasV2Actions } from '@ninety/pages/meetings/_state/meetings.actions';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { MeetingAgendaService } from '@ninety/ui/legacy/core/services/meeting-agenda.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import {
  DeleteTeamCustomAgenda,
  MeetingAgendas,
  Team,
  TeamMeetingAgendaPush,
  TeamMeetingAgendaUpdateByType,
} from '@ninety/ui/legacy/shared/models/_shared/team';
import { TeamListStateActions, TeamSelectors } from '@ninety/ui/legacy/state/index';

@Component({
  template: `<ninety-meeting-agendas
    class="meeting-settings"
    *ngIf="selectedTeam$ | ngrxPush as selectedTeam"
    [selectedTeam]="selectedTeam"
    [hidePushButton]="true"
    (saveTeamAgendaByType)="updateTeamMeetingAgendaByType($event)"
    (deleteTeamCustomAgenda)="deleteTeamMeetingAgenda($event)"
    (pushTeamAgenda)="pushTeamAgenda($event)"
    (getResetAgendas)="getResetAgendas()"
    (getTeamWithSettings)="getTeamWithSettings($event)"
    [resetAgendas]="resetAgendas$ | ngrxPush"></ninety-meeting-agendas>`,
  styles: [
    `
      .meeting-settings {
        height: calc(100vh - 160px);
        overflow-y: auto;
      }
    `,
  ],
})
export class TeamMeetingAgendasComponent {
  selectedTeam$ = this.store.select(TeamSelectors.selectFilterBarTeam);
  private _resetAgendas = new BehaviorSubject<MeetingAgendas>(null);
  resetAgendas$ = this._resetAgendas.asObservable();

  constructor(
    public filterService: FilterService,
    private spinnerService: SpinnerService,
    private agendasService: MeetingAgendaService,
    private store: Store
  ) {
    this.spinnerService.stop();
  }

  updateTeamMeetingAgendaByType(update: TeamMeetingAgendaUpdateByType): void {
    this.store.dispatch(TeamListStateActions.updateTeamMeetingAgenda({ update }));
  }

  deleteTeamMeetingAgenda({ teamId, agendaId }: DeleteTeamCustomAgenda): void {
    this.store.dispatch(TeamListStateActions.deleteCustomAgenda({ teamId, agendaId }));
  }

  pushTeamAgenda(agendaRequest: TeamMeetingAgendaPush): void {
    this.store.dispatch(TeamListStateActions.pushCustomAgenda({ agendaRequest }));
  }

  getResetAgendas(): void {
    this.agendasService.getCurrentCompanyAgendas().subscribe({
      next: agendas => this._resetAgendas.next(agendas),
    });
  }

  getTeamWithSettings(team: Team) {
    this.store.dispatch(MeetingsAgendasV2Actions.selectTeam({ team: team }));
  }
}
